import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import { useContext } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'
import { toast } from 'react-toastify'
import { serverPath } from '../../config/key'
import UserContext from '../../context/UserContext'
import { AddUserSchema } from '../../utils/validationSchema'

const AddUserModal = () => {
  const { countries, userGeoData } = useContext(UserContext)
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm({ resolver: yupResolver(AddUserSchema) })
  const handleCountryChange = (e) => {
    const data = countries.filter((el) => el.name === e.target.value)
    setValue('country', e.target.value)
    setValue('countryCode', data[0]?.countryCode)
  }
  const handlePhoneChange = (e) => {
    setValue('phone', '+' + e)
  }
  // const handleChange = (e) => {
  //   const { name, value } = e.target
  //   setLogInData({
  //     ...logInData,
  //     [name]: value,
  //   })
  // }
  const handleAddUser = async (values) => {
    try {
      const response = await axios.post(`${serverPath}/users/addUser`, values, {
        headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      const { status } = response.data
      if (status === 200) {
        toast.success('User added successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
      }
    } catch (error) {
      if (error?.response?.data) {
        toast.error(error.response.data.msg, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
      }
    }
  }

  return (
    <div className="add-user-popup-content consultation-popup-content">
      <Form onSubmit={handleSubmit(handleAddUser)}>
        <Form.Group>
          <Form.Control
            className={`${errors?.name ? `error-border` : ``}`}
            name="name"
            type="text"
            placeholder="Name"
            {...register('name')}
          />
          {errors?.name ? (
            <span className="error-msg">{errors.name.message}</span>
          ) : (
            ''
          )}
        </Form.Group>

        <Form.Group>
          <Form.Control
            className={`${errors?.email ? `error-border` : ``}`}
            name="email"
            type="email"
            placeholder="Email"
            {...register('email')}
          />
          {errors?.email ? (
            <span className="error-msg">{errors.email.message}</span>
          ) : (
            ''
          )}
        </Form.Group>
        <Form.Group>
          <Form.Control
            className={`${errors?.password ? `error-border` : ``}`}
            type="password"
            name="password"
            placeholder="Password"
            {...register('password')}
          />
          {errors?.password ? (
            <span className="error-msg">{errors?.password?.message}</span>
          ) : (
            ''
          )}
        </Form.Group>
        <Form.Group>
          <Form.Select
            className={`${errors?.country ? `error-border` : ``}`}
            name="country"
            onChange={handleCountryChange}
          >
            <option
              value={userGeoData?.country_name ? userGeoData?.country_name : ''}
            >
              {userGeoData?.country_name
                ? userGeoData?.country_name
                : 'Select Country'}
            </option>
            {countries &&
              countries?.length > 0 &&
              countries.map((el) => (
                <option value={el?.name}>{el?.name}</option>
              ))}
          </Form.Select>
          {errors?.country ? (
            <span className="error-msg">{errors.country.message}</span>
          ) : (
            ''
          )}
        </Form.Group>
        <Form.Group>
          <PhoneInput
            className={`${errors?.phone ? `error-border` : ``}`}
            country={userGeoData?.country_code?.toLowerCase()}
            onChange={handlePhoneChange}
            inputProps={{
              name: 'phone',
            }}
          />
          {errors?.phone ? (
            <span className="error-msg">{errors?.phone?.message}</span>
          ) : (
            ''
          )}
        </Form.Group>

        <Button variant="primary" type="submit" className="orange-btn">
          Add User
        </Button>
      </Form>
    </div>
  )
}

export default AddUserModal
