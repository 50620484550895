import React, { useState, useEffect } from "react";
import Footer from "../footer/footer";
import Header from "../header/header";
import { Form, Button, FormControl } from "react-bootstrap";
import searchlogo from "../../assets/images/header/search-logo.svg";
import searchiconwhite from "../../assets/images/header/search-white.svg";
import closeicon from "../../assets/images/header/close-white.svg";
import "animate.css";
const Layout = ({ children }) => {
  const [head, setHead] = useState(false);
  const [bannerView, setBannerView] = useState(true);
  const [seachpopup, setSeachPopup] = useState(false);

  const handleSearch = () => {
    setSeachPopup(!seachpopup);
  };
  const {
    location: { pathname },
  } = window;
  useEffect(() => {
    if (
      pathname === "/" ||
      pathname === "/login" ||
      pathname === "/forgot" ||
      pathname === "/register" ||
      pathname === "/phoneverify" ||
      pathname.search("/verifyLead") !== -1
    ) {
      setHead(true);
    }
    if (pathname === "/") {
      setBannerView(true);
    } else {
      setBannerView(false);
    }
    // if(pathname !== '/visa/holiday') {
    //   setBannerView(true)
    // }
    // else{
    //   setBannerView(false)
    // }
  }, [pathname]);

  const scrollToElement = () => {
    let faq = document.getElementById("faq");
    if (faq) {
      faq.scrollIntoView();
    } else {
      window.location.href = "/#faq";
    }
  };

  const scrollToElementVisa = () => {
    let visa = document.getElementById("visa-type");
    if (visa) {
      visa.scrollIntoView();
    } else {
      window.location.href = "/#visa-type";
    }
  };

  return (
    <div
      className={`main-layout ${head ? `white-layout` : ``} ${
        bannerView ? "top-banner-show" : ""
      }`}
    >
      <Header
        head={head}
        seachpopup={seachpopup}
        handleSearch={handleSearch}
        scrollToElement={scrollToElement}
        scrollToElementVisa={scrollToElementVisa}
        bannerView={bannerView}
        setBannerView={setBannerView}
      />

      <div className='content'>{children}</div>

      <Footer
        seachpopup={seachpopup}
        handleSearch={handleSearch}
        scrollToElement={scrollToElement}
        scrollToElementVisa={scrollToElementVisa}
      />

      {seachpopup ? (
        <div className='searc-container'>
          <div className='search-content'>
            <div
              className='search-close-btn'
              onClick={handleSearch}
              role='presentation'
            >
              <img src={closeicon} alt='' />
            </div>
            <img src={searchlogo} alt='' />
            <Form>
              <FormControl
                type='search'
                placeholder='Type Word then Press Enter'
                aria-label='Search'
              />
              <Button>
                <img src={searchiconwhite} alt='' />
              </Button>
            </Form>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Layout;
