import React from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Layout from "../layout/layout";
import { Form, Button } from "react-bootstrap";
import logo from "../../assets/images/footer/logo.svg";
import envelope from "../../assets/images/login/envelope.svg";
import call from "../../assets/images/login/call.svg";
import facebook from "../../assets/images/login/facebook.svg";
import linkedin from "../../assets/images/login/linkedin.svg";
import twitter from "../../assets/images/login/twitter.svg";
import "./login.css";
import Helmet from "react-helmet";
import { ResetPasswordSchema } from "../../utils/validationSchema";
import { useState } from "react";
import {useTranslation} from "react-i18next";

const Forgot = () => {
  const [email, setEmail] = useState("");
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(ResetPasswordSchema) });
  const { t } = useTranslation();
  const handleChange = (e) => {
    const { value } = e.target;
    setEmail(value);
  };

  const handleResetPassword = (values) => {
    console.log("values =>", values);
  };

  return (
    <Layout>
      <Helmet>
        <body className="hide-layout" />
      </Helmet>

      <div className="login-page">
        <div className="login-grid">
          <div className="login-content">
            <Link to="/">
              <img src={logo} alt="" className="logo-login" />
            </Link>

            <h2>Welcome!</h2>

            <div className="login-contact-info">
              <span>
                <img src={envelope} alt="" />
                {t('contactEmail')}
              </span>
              <span>
                <img src={call} alt="" />
                {t('contactPhone')}
              </span>
            </div>

            <div className="login-social">
              <ul>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/uis.canada"
                  >
                    <img src={facebook} alt="" />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkedin.com/company/uis-canada-official/"
                  >
                    <img src={linkedin} alt="" />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://twitter.com/uis_canada"
                  >
                    <img src={twitter} alt="" />
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="login-form">
            <div className="login-popup-content">
              <h2>Reset Your Password</h2>
              <p>Enter your email below to receive a reset link.</p>

              <Form onSubmit={handleSubmit(handleResetPassword)}>
                <Form.Group>
                  <Form.Control
                    className={`${errors?.email ? `error-border` : ``}`}
                    type="text"
                    placeholder="Email Address"
                    name="email"
                    defaultValue={email}
                    {...register("email")}
                    onChange={handleChange}
                  />
                  {errors?.email ? (
                    <span className="error-msg">{errors.email.message}</span>
                  ) : (
                    ""
                  )}
                </Form.Group>

                <br></br>

                <Button type="submit">Reset Password</Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Forgot;
