import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import { Form, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { serverPath } from '../../config/key'
import UserContext from '../../context/UserContext'
import { PhoneOtpSchema } from '../../utils/validationSchema'
import Layout from '../layout/layout'
import { Helmet } from 'react-helmet'
import logo from '../../assets/images/footer/logo.svg'
import envelope from '../../assets/images/login/envelope.svg'
import call from '../../assets/images/login/call.svg'
import facebook from '../../assets/images/login/facebook.svg'
import linkedin from '../../assets/images/login/linkedin.svg'
import twitter from '../../assets/images/login/twitter.svg'
import { useTranslation } from 'react-i18next'

const RegisterOtpVerify = () => {
  const navigate = useNavigate()
  const { setToken, user } = useContext(UserContext)

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(PhoneOtpSchema) })
  const { t } = useTranslation()
  const handleVerifyOtp = async (values) => {
    try {
      const res = await axios.post(`${serverPath}/users/registerVerifyOtp`, {
        ...values,
        phone: user.phoneNumber
          ? user.phoneNumber
          : localStorage.getItem('phone'),
      })
      if (res?.data?.status === 200) {
        toast.success(res.data.msg, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
        setToken(res.data.token)
        localStorage.setItem('token', res.data.token)
        localStorage.removeItem('phone')
        localStorage.setItem('user', JSON.stringify(user))
        navigate('/welcome')
      }
    } catch (error) {
      if (error?.response?.data) {
        toast.error(error.response.data.msg, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
      }
    }
  }

  return (
    <Layout>
      <Helmet>
        <body className="hide-layout" />
      </Helmet>

      <div className="login-page">
        <div className="login-grid">
          <div className="login-content">
            <Link to="/">
              <img src={logo} alt="" className="logo-login" />
            </Link>

            <h2>Welcome!</h2>

            <div className="login-contact-info">
              <span>
                <img src={envelope} alt="" />
                {t('contactEmail')}
              </span>
              <span>
                <img src={call} alt="" />
                {t('contactPhone')}
              </span>
            </div>

            <div className="login-social">
              <ul>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/uis.canada"
                  >
                    <img src={facebook} alt="" />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkedin.com/company/uis-canada-official/"
                  >
                    <img src={linkedin} alt="" />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://twitter.com/uis_canada"
                  >
                    <img src={twitter} alt="" />
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="login-form">
            <div className="login-popup-content">
              <h2>Verify</h2>
              <p>An OTP was sent to your phone number.</p>

              <Form onSubmit={handleSubmit(handleVerifyOtp)}>
                <Form.Group>
                  <Form.Control
                    type="string"
                    placeholder="Enter Your OTP"
                    name="otp"
                    {...register('otp')}
                  />
                  {errors?.otp ? (
                    <span className="error-msg">{errors.otp.message}</span>
                  ) : (
                    ''
                  )}
                </Form.Group>

                <br></br>

                <Button type="submit">Verify</Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default RegisterOtpVerify
