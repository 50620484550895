/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react'
import { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
// import FailedPage from './failedPage'
import facebook from '../../assets/images/thankyou/fb.svg'
import linkedin from '../../assets/images/thankyou/linkedin.svg'
import twitter from '../../assets/images/thankyou/twitter.svg'
const PaymentFailed = () => {
  const { state } = useLocation()
  const search = useLocation().search
  const [openFailedPage, setOpenFailedPage] = useState(false)
  const [paymentError, setPaymentError] = useState('')
  useEffect(() => {
    if (new URLSearchParams(search).get('payment') === 'failed') {
      setOpenFailedPage(true)
      setPaymentError(new URLSearchParams(search).get('err'))
    }
  }, [])
  const handleClose = () => {
    setOpenFailedPage(false)
  }
  return (
    <div className="payment-failed-page thank-you-page">
      <Modal
        centered
        show={openFailedPage}
        onHide={handleClose}
        className="payment-failed"
      >
        <Modal.Body>
          {/* <FailedPage paymentError={paymentError} /> */}
          <div className="thank-you-grid">
            <div className="thank-you-content">
              <img src="" alt="" />
              <h3>We have a problem...</h3>
              <p>
                {state?.description ||
                  new URLSearchParams(search).get('err') || { paymentError }}
              </p>
              <p>
                Please provide the above error message to your support
                representative.
              </p>
            </div>

            <div className="thank-you-social">
              <p>Let’s Connect!</p>
              <ul>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/uis.canada"
                  >
                    <img src={facebook} alt="" />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkedin.com/company/uis-canada-official/"
                  >
                    <img src={linkedin} alt="" />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://twitter.com/uis_canada"
                  >
                    <img src={twitter} alt="" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default PaymentFailed
