export const dependentTouristVisaMain = [
  {
    name: 'dependentPassportCopy',
    label: 'Passport Copy',
    secondLabel: '(front page + stamp / visa pages if any)',
  },
  {
    name: 'dependentDigitalPhoto',
    label: 'Digital Photo',
    secondLabel: '',
  },
  {
    name: 'dependentBirthCertificate',
    label: 'Copy of Birth Certificate',
    secondLabel: '',
  },
]
export const dependentStudentVisaMain = [
  {
    name: 'dependentPassportCopy',
    label: 'Passport Copy',
    secondLabel: '(front page + stamp / visa pages if any)',
  },
  {
    name: 'dependentDigitalPhoto',
    label: 'Digital Photo',
    secondLabel: '',
  },
  {
    name: 'dependentBirthCertificate',
    label: 'Copy of Birth Certificate',
    secondLabel: '',
  },
]
export const dependentInvestorVisaMain = [
  {
    name: 'dependentBirthCertificate',
    label: 'Copy of Birth Certificate',
    secondLabel: '',
  },
  {
    name: 'dependentDeclaration',
    label: 'Additional Dependents Declaration IMM 0008DEP',
    secondLabel: '(if applicable)',
  },
  {
    name: 'adoptionPapers',
    label: 'Adoption Papers (see Guidelines) ',
    secondLabel: '',
  },
  {
    name: 'nonAccompanyingGuardian',
    label:
      'Non-Accompanying Parent/Guardian for Minors Immigrating to Canada IMM5604 - Original (see Guidelines)',
    secondLabel: '(if applicable)',
  },
]
export const dependentPnpExpressEntryVisaVisaMain = [
  {
    name: 'dependentPassportCopy',
    label: 'Passport Copy',
    secondLabel: '(front page + stamp / visa pages if any)',
  },
  {
    name: 'dependentDigitalPhoto',
    label: 'Digital Photo',
    secondLabel: '',
  },
  {
    name: 'dependentBirthCertificate',
    label: 'Copy of Birth Certificate',
    secondLabel: '',
  },
]
export const dependentSelfEmpVisaMain = [
  {
    name: 'dependentBirthCertificate',
    label: 'Copy of Birth Certificate',
    secondLabel: '',
  },
  {
    name: 'dependentDeclaration',
    label: 'Additional Dependents Declaration IMM 0008DEP',
    secondLabel: '(if applicable)',
  },
  {
    name: 'adoptionPapers',
    label: 'Adoption Papers (see Guidelines) ',
    secondLabel: '',
  },
  {
    name: 'nonAccompanyingGuardian',
    label:
      'Non-Accompanying Parent/Guardian for Minors Immigrating to Canada IMM5604 - Original (see Guidelines)',
    secondLabel: '(if applicable)',
  },
]
export const dependentSelfEmpWorkPermitVisaMain = [
  {
    name: 'dependentPassportCopy',
    label: 'Passport Copy',
    secondLabel: '(front page + stamp / visa pages if any)',
  },
  {
    name: 'dependentDigitalPhoto',
    label: 'Digital Photo',
    secondLabel: '',
  },
  {
    name: 'dependentBackgroundDecl',
    label: 'Background/Declaration (if 18 years or older)',
    secondLabel: '',
  },
]
