import React, { useState } from 'react'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import PhoneInput from 'react-phone-input-2'
import { serverPath } from '../../config/key'
import { useTranslation } from 'react-i18next'
import { Form, Button, Spinner } from 'react-bootstrap'
import { ContactSchema } from '../../utils/validationSchema'
import checkicon from '../../assets/images/header/success.svg'
import { useContext } from 'react'
import UserContext from '../../context/UserContext'
import './visa.css'
import { toast } from 'react-toastify'

const VisaContact = () => {
  const { t } = useTranslation()
  const [loading,setLoading]=useState(false)
  const [succ, setSucc] = useState(false)
  const { userGeoData } = useContext(UserContext)
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(ContactSchema) })

  const handleChange = (e) => {
    setValue('phoneNumber', '+' + e)
  }

  const handleSubmitForm = async (values) => {
    try {
      setLoading(true)
      const response = await axios.post(`${serverPath}/forms/consult`, {
        ...values,
        ip: userGeoData.ip,
        countryCode: userGeoData.country_code,
      })
      setLoading(false)
      if (response?.data?.success) {
        setSucc(true)
      }
    } catch (error) {
      setLoading(false)
      if (error?.response?.data) {
        toast.error('Could not submit form', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    }
  }

  return (
    <div className="visa-contact">
      <div className="container">
        <div className="visa-contact-content">
          <h2>{t('visaContact.visaHeading1')}</h2>
          <p>{t('visaContact.visaParagraph1')}</p>

          <div className="visa-contact-form">
            <Form onSubmit={handleSubmit(handleSubmitForm)}>
              <Form.Group>
                <Form.Control
                  name="name"
                  type="text"
                  placeholder={t('visaContact.fullname')}
                  {...register('name')}
                />
                {errors?.name ? (
                  <span className="error-msg">{errors.name.message}</span>
                ) : (
                  ''
                )}
              </Form.Group>

              <Form.Group>
                <Form.Control
                  name="email"
                  type="email"
                  placeholder={t('visaContact.emailaddress')}
                  {...register('email')}
                />
                {errors?.email ? (
                  <span className="error-msg">{errors.email.message}</span>
                ) : (
                  ''
                )}
              </Form.Group>

              <Form.Group>
                <PhoneInput
                  country={userGeoData?.country_code?.toLowerCase()}
                  onChange={handleChange}
                  inputProps={{
                    name: 'phoneNumber',
                  }}
                />

                {errors?.phoneNumber ? (
                  <span className="error-msg">
                    {errors.phoneNumber.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Group>

              <Button
                variant="primary"
                type="submit"
                className={`${succ ? `success-btn` : ``}`}
                disabled={loading}
              >
                {loading && (
                      <Spinner
                        as="span"
                        variant="light"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        animation="border"
                      />
                    )}
                    &nbsp;{t('visaContact.submit')}{' '}
                {succ ? <img src={checkicon} alt="" /> : ''}
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VisaContact
