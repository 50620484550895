import React from "react";
import Layout from "../layout/layout";
import VisaContact from "./visacontact";
import { useTranslation } from "react-i18next";
import expressicon from "../../assets/images/visa/express-icon.svg";
import howwork1 from "../../assets/images/visa/how-work-1.png";
import howwork2 from "../../assets/images/visa/how-work-2.png";
import howwork3 from "../../assets/images/visa/how-work-3.png";
import "./visa.css";

const Express = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <div className='express-visa-page'>
        <div className='express-visa-banner'>
          <div className='container'>
            <div className='express-visa-banner-heading'>
              <img src={expressicon} alt='' />
              <div className='express-visa-banner-heading-title'>
                <h5>{t("expressVisapage.expressHeading1")}</h5>
                <h2>{t("expressVisapage.expressHeading2")}</h2>
              </div>
            </div>

            <p>{t("expressVisapage.expressParagraph1")}</p>

            <p>{t("expressVisapage.expressParagraph2")}</p>

            <p>{t("expressVisapage.expressParagraph3")}</p>

            <p>{t("expressVisapage.expressParagraph4")}</p>
          </div>
        </div>

        <div className='express-how-work'>
          <div className='container'>
            <div className='express-how-work-grid'>
              <div className='express-how-work-content'>
                <h3>{t("expressWork.expressHeading1")}</h3>
                <p>{t("expressWork.expressParagraph1")}</p>

                <p>{t("expressWork.expressParagraph2")}</p>

                <p>{t("expressWork.expressParagraph3")}</p>
              </div>

              <div className='express-how-work-cover'>
                <img src={howwork1} alt='' />
              </div>
            </div>

            <div className='express-how-work-grid how-work-flip'>
              <div className='express-how-work-content'>
                <p>{t("expressWorkcover.expressParagraph1")}</p>

                <p>{t("expressWorkcover.expressParagraph2")}</p>

                <p>{t("expressWorkcover.expressParagraph3")}</p>
              </div>

              <div className='express-how-work-cover'>
                <img src={howwork2} alt='' />
              </div>
            </div>
          </div>
        </div>

        <div className='express-program'>
          <div className='container'>
            <div className='express-program-grid'>
              <div className='express-program-content'>
                <h3>{t("expressProgram.expressHeading1")}</h3>
                <h4>{t("expressProgram.expressHeading2")}</h4>

                <ul>
                  <li>{t("expressProgram.expressList1")}</li>
                  <li>{t("expressProgram.expressList2")}</li>
                  <li>{t("expressProgram.expressList3")}</li>
                  <li>{t("expressProgram.expressList4")}</li>
                </ul>

                <p>{t("expressProgram.expressParagraph1")}</p>

                <p>
                  <b>{t("expressProgram.expressParagraph2")}</b>
                </p>
              </div>

              <div className='express-program-cover'>
                <img src={howwork3} alt='' />
              </div>
            </div>
          </div>
        </div>

        <VisaContact />
      </div>
    </Layout>
  );
};

export default Express;
