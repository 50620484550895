import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { serverPath } from "../../config/key";
import footerlogo from "../../assets/images/footer/logo.svg";
import facebook from "../../assets/images/footer/facebook.svg";
import linkedin from "../../assets/images/footer/linkedin.svg";
import twitter from "../../assets/images/footer/twitter.svg";
import searchicon from "../../assets/images/footer/search.svg";
import locationicon from "../../assets/images/footer/location.svg";
import emailicon from "../../assets/images/footer/email.svg";
import callicon from "../../assets/images/footer/phone.svg";
import topicon from "../../assets/images/footer/goto.svg";
import visalogo from "../../assets/images/footer/visalogo.png";
import isralogo from "../../assets/images/footer/isralogo.png";
import { useTranslation } from "react-i18next";
import "./footer.css";
/* eslint-disable */
const Footer = ({ handleSearch, scrollToElement, scrollToElementVisa }) => {
  const [visible, setVisible] = useState(false);

  const { t, i18n } = useTranslation();
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  const [contactinfo, setContactInfo] = useState({});

  const contactData = async () => {
    let ipData;
    try {
      ipData = await axios.get(
        "https://geolocation-db.com/json/8dd79c70-0801-11ec-a29f-e381a788c2c0"
      );
    } catch (e) {
      ipData = {
        data: {
          country_code: "US",
          country_name: "United States Of America",
          ip: "0.0.0.0",
        },
      };
    }

    const res = await axios.post(`${serverPath}/contact/contactInfo`, {
      countryCode: ipData.data.country_code,
      countryName: ipData.data.country_name,
      ip: ipData.data.IPv4,
      lang: t('language')
    });
    if (res.data) {
      const cinfo = res.data;
      setContactInfo(cinfo);
    }
  };

  useEffect(() => {
    contactData();
  }, []);

  useEffect(() => {
    i18n.on('languageChanged', contactData);
    return () => {
      i18n.off('languageChanged', contactData);
    };
  }, []);

  return (
    <div className="footer">
      <div className="container">
        <div className="top-footer">
          <div className="footer-logo">
            <Link to="/">
              <img src={footerlogo} alt="" />
            </Link>
          </div>

          <div className="footer-menu">
            <ul>
              <li>
                <span role="presentation" onClick={scrollToElementVisa}>
                  {t("footer.visa")}
                </span>
              </li>
              <li>
                <Link to="/about"> {t("footer.about")}</Link>
              </li>
              <li>
                <span role="presentation" onClick={scrollToElement}>
                  {t("footer.fag")}
                </span>
              </li>
              {/* <li><a href="https://www.uiscanada.com/blog/">Blog</a></li> */}
              <li>
                <Link to="/reviews">{t("footer.reviews")}</Link>
              </li>
              <li>
                <Link to="/contact">{t("footer.contact")}</Link>
              </li>
            </ul>
          </div>

          <div
            className="footer-search"
            onClick={handleSearch}
            role="presentation"
          >
            <img src={searchicon} alt="" />
          </div>

          <div className="footer-social">
            <ul>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/uis.canada"
                >
                  <img src={facebook} alt="" />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.linkedin.com/company/uis-canada-official/"
                >
                  <img src={linkedin} alt="" />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://twitter.com/uis_canada"
                >
                  <img src={twitter} alt="" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="bottom-footer">
          <div className="footer-grid">
            <p>
              <img src={locationicon} alt="" />
              {contactinfo?.address?.line1}<br />
              {contactinfo?.address?.line2}
            </p>
            <br />
            <p hidden={!contactinfo.address2}>
              <img src={locationicon} alt="" />
              {contactinfo?.address2?.line1}<br />
              {contactinfo?.address2?.line2}
            </p>
          </div>

          <div className="footer-grid">
            <p>
              <img src={emailicon} alt="" />
              {contactinfo?.email}
            </p>
            <p>
              <img src={callicon} alt="" />
              {contactinfo?.phone?.phone1}
            </p>
            <br />
            <p hidden={!contactinfo.phone?.phone2}>
              <img src={callicon} alt="" />
              <span>{contactinfo?.phone?.phone2} {contactinfo?.phone?.tollFree}</span>
            </p>
          </div>

          <div className="footer-grid">
            <ul>
              <li>
                <Link to="/privacypolicy">{t("footerGrid.privacyPolicy")}</Link>
              </li>
              <li>
                <Link to="/termconditions">
                  {t("footerGrid.termConditions")}
                </Link>
              </li>
            </ul>
          </div>

          <div className="footer-grid">
            <p>{contactinfo?.uisCode}</p>
          </div>
          <div>
            <div className="footer-payment">
              <img src={isralogo} alt="" />
              <img src={visalogo} alt="" />
            </div>
            {visible ? (
              <div className="footer-goto" onClick={scrollToTop}>
                <img src={topicon} alt="" />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
