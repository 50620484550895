import React from 'react'
import AdminLayout from './adminLayout/adminLayout'

const AdminPanel = () => {
  return (
    <AdminLayout>
      <div className="admin-home">
        <h2>Loged in as Admin!</h2>
      </div>
    </AdminLayout>
  )
}

export default AdminPanel
