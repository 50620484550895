/* eslint-disable react/style-prop-object */
const InvoiceTemplate = ({ invoiceData }) => {
  var options = { year: 'numeric', month: 'long', day: 'numeric' }

  const { companyId: company, productId: product } = invoiceData || {}
  return (
    <body
      bgcolor="white"
      style={{
        margin: '0px',
        padding: 0,
        height: '100%',
        width: '100%!important',
        backgroundColor: 'white',
        fontFamily: 'Raleway',
        fontStyle: 'sans-serif',
      }}
    >
      <table
        class="body-wrap"
        style={{
          margin: 0,
          padding: 0,
          width: '100%',
          borderCollapse: 'collapse',
        }}
      >
        <tr style={{ margin: 0, padding: 0 }}>
          <td style={{ margin: 0, padding: 0 }}></td>
          <td
            class="container"
            bgcolor="#ffffff"
            style={{
              margin: '0 auto!important',
              padding: 0,
              display: 'block!important',
              maxWidth: '700px!important',
              clear: 'both!important',
            }}
          >
            <div
              class="content"
              style={{
                margin: '0 auto',
                padding: '0px 40px',
                paddingTop: '30px',
                maxWidth: '700px',
                display: 'block',
              }}
            >
              <table style={{ width: '100%', color: '#666' }}>
                <tr>
                  <td style={{ fontSize: '10px', color: '#333' }}></td>

                  <td align="right">
                    <table>
                      <tr>
                        <td
                          style={{
                            fontSize: '14px',
                            fontWeight: '700',
                            color: '#333',
                          }}
                        >
                          {company?.name}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontSize: '10px',
                            fontWeight: '400',
                            color: '#999',
                          }}
                        >
                          Address: {company?.address}
                          <br></br>No. {company?.registrationNumber} Telephone:{' '}
                          {company?.phone}
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </div>

            <div
              style={{
                margin: '0 auto',
                padding: '0px 40px',
                paddingTop: '20px',
                maxWidth: '700px',
                display: 'block',
              }}
            >
              <table style={{ width: '100%', color: '#666' }}>
                <tr>
                  <td
                    style={{
                      color: '#3E3F42',
                      fontSize: '20px',
                      fontWeight: '700',
                    }}
                  >
                    CREDIT NOTE
                  </td>
                  <td></td>
                </tr>
              </table>
            </div>

            <div
              class=" content"
              style={{
                margin: '0 auto',
                padding: '0px 40px',
                paddingTop: '10px',
                maxWidth: '700px',
                display: 'block',
              }}
            >
              <table style={{ width: '100%', color: '#666' }}>
                <tr>
                  <td
                    style={{
                      fontSize: '10px',
                      fontWeight: '400',
                      color: '#999',
                    }}
                  >
                    {invoiceData?.clientName}
                    <br></br>
                    {invoiceData?.clientAddress} , {invoiceData?.clientCity}
                    <br></br>Meyerton,Gauteng<br></br>1960<br></br>{' '}
                    {invoiceData?.clientCountry}
                    <br></br>
                    {invoiceData?.clientEmail}
                    <br></br>
                    {invoiceData?.clientPhone}
                  </td>
                  <td align="right">
                    <table>
                      <tr>
                        <td>
                          <tr
                            style={{
                              fontSize: '10px',
                              fontWeight: '400',
                              color: '#999',
                            }}
                          >
                            <td>Invoice Number:</td>{' '}
                            <td>{invoiceData?.invoiceNumber}</td>
                          </tr>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <tr
                            style={{
                              fontSize: '10px',
                              fontWeight: '400',
                              color: '#999',
                            }}
                          >
                            <td>Invoice Date:</td>{' '}
                            <td>
                              {new Date(invoiceData?.date)?.toLocaleDateString(
                                'en-US',
                                options,
                              )}
                            </td>
                          </tr>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <tr
                            style={{
                              fontSize: '10px',
                              fontWeight: '400',
                              color: '#999',
                            }}
                          >
                            <td>Order Number:</td> <td>17167</td>
                          </tr>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <tr
                            style={{
                              fontSize: '10px',
                              fontWeight: '400',
                              color: '#999',
                            }}
                          >
                            <td>Order Date:</td> <td>February 13, 2022</td>
                          </tr>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <tr
                            style={{
                              fontSize: '10px',
                              fontWeight: '400',
                              color: '#999',
                            }}
                          >
                            <td>Payment Method:</td>{' '}
                            <td>{invoiceData?.paymentMethod}</td>
                          </tr>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </div>

            <div
              class="content"
              style={{
                margin: '0 auto',
                padding: '0px 40px',
                paddingTop: '60px',
                maxWidth: '700px',
                display: 'block',
              }}
            >
              <table
                style={{
                  width: '100%',
                  color: '#666',
                  borderCollapse: 'collapse',
                }}
              >
                <thead>
                  <tr style={{ backgroundColor: '#000' }}>
                    <th
                      style={{
                        color: '#fff',
                        fontSize: '12px',
                        textAlign: 'left',
                        borderBottom: '1px solid rgba(62, 63, 66, 0.5)',
                        fontWeight: '600',
                        padding: '10px',
                      }}
                    >
                      Product
                    </th>
                    <th
                      style={{
                        color: '#fff',
                        fontSize: '12px',
                        textAlign: 'left',
                        borderBottom: '1px solid rgba(62, 63, 66, 0.5)',
                        fontWeight: '600',
                        padding: '10px',
                      }}
                    >
                      Qty
                    </th>
                    <th
                      style={{
                        color: '#fff',
                        fontSize: '12px',
                        textAlign: 'left',
                        borderBottom: '1px solid rgba(62, 63, 66, 0.5)',
                        fontWeight: '600',
                        padding: '10px',
                      }}
                    >
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span
                        style={{
                          color: '#999',
                          fontSize: '12px',
                          padding: '10px',
                        }}
                      >
                        {product?.name} <br />
                        SKU : {product?.sku}
                      </span>
                    </td>
                    <td>
                      <span
                        style={{
                          color: '#999',
                          fontSize: '12px',
                          padding: '10px',
                        }}
                      >
                        1
                      </span>
                    </td>

                    <td>
                      <span
                        style={{
                          color: '#999',
                          fontSize: '12px',
                          padding: '10px',
                        }}
                      >
                        ${product?.price}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              style={{
                margin: '0 auto',
                padding: '0px 40px',
                paddingTop: '30px',
                maxWidth: '700px',
                display: 'block',
              }}
            >
              <table style={{ width: '100%', color: '#666' }}>
                <tr>
                  {/* <td><span style={{"fontSize":"12px","fontWeight":"600","color": "#333"}}>Reason for refund</span><br></br><span style={{"fontSize":"10px","fontWeight":"400","color": "#999"}}>Partial refund</span></td> */}
                  <td align="right">
                    <table>
                      <tr>
                        <td>
                          <tr>
                            <td
                              style={{
                                fontSize: '12px',
                                fontWeight: '600',
                                color: '#333',
                                paddingRight: '150px',
                              }}
                            >
                              Subtotal
                            </td>
                            <td
                              align="right"
                              style={{
                                fontSize: '12px',
                                fontWeight: '600',
                                color: '#333',
                              }}
                            >
                              ${product?.price}
                            </td>
                          </tr>
                        </td>
                      </tr>
                      {/* <tr>
                                        <td>
                                        <tr>
                                            <td style={{"fontSize":"12px", "fontWeight":"600","color": "#333", "paddingRight": "150px"}}>Shipping</td> <td align="right" style={{"fontSize":"12px", "fontWeight":"400","color": "#333"}}>$0</td>
                                        </tr>
                                    </td>
                                    </tr> */}

                      <tr>
                        <td>
                          <tr>
                            <td
                              style={{
                                fontSize: '12px',
                                fontWeight: '600',
                                color: '#333',
                                paddingRight: '150px',
                              }}
                            >
                              Total
                            </td>{' '}
                            <td
                              align="right"
                              style={{
                                fontSize: '12px',
                                fontWeight: '400',
                                color: '#333',
                              }}
                            >
                              ${product?.price}
                            </td>
                          </tr>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </div>
          </td>
          <td style={{ margin: '0', padding: '0' }}></td>
        </tr>
      </table>
    </body>
  )
}

export default InvoiceTemplate
