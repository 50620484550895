import React from "react";
import Layout from "../layout/layout";
import { useTranslation } from "react-i18next";
import VisaContact from "../visa/visacontact";
import star from "../../assets/images/header/star.svg";
import starhalf from "../../assets/images/home/star-half.svg";
import starline from "../../assets/images/reviews/star-line.svg";
//import trustpilot from "../../assets/images/reviews/trustpilot.svg";
import review from "../../assets/images/reviews/review.svg";
import rcic from "../../assets/images/reviews/rcic.svg";
import sitejabber from "../../assets/images/reviews/sitejabber.svg";
import banner from "../../assets/images/reviews/banner.png";
import banner1 from "../../assets/images/reviews/banner1.png";
import banner2 from "../../assets/images/reviews/banner2.png";
import banner3 from "../../assets/images/reviews/banner3.png";
import banner4 from "../../assets/images/reviews/banner4.png";
import "./reviews.css";



const Reviews = () => {
    const { t } = useTranslation();
  
    return (

    <Layout>


        <div className="review-page">


            <div className="container">

                <h3>{t("homeTestimonial.heading")}</h3>


                <div className="review-content-grid">

                  
                    <div className="review-box">

                        <div className="review-content">
                            {/* <img src={trustpilot} alt="" /> */}
                            <div className="review-review">
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            </div>
                            <h4>Christian Laursen</h4>
                            <p>Good service and nice staff
                            Good at giving advice, replies fast and helped me get through my visa application in the simplest way possible. I Had Ken turner as my representative and he did it very well.</p>
                            <p> I have now gotten my visa and will be looking forward to moving to Canada</p>
                            <p> Thanks for everything</p>
                            <p>-Christian from Denmark</p>
                        </div>


                        <div className="review-cover">
                            <img src={banner} alt="" />
                        </div>


                        <div className="review-content">
                            <img src={rcic} alt="" />
                            <div className="review-review">
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            </div>
                            <h4>Hayley L, Working Holiday.</h4>
                            <p>The whole visa process is extremely tiresome… I’m saying this as someone who helped his older brother through this process and saw up close how frustrating it can become. I was dreading the whole thing when my turn came. But UIS Canada made this a pleasant (and much easier) experience for me. IT was still annoying as hell but I really appreciate the help I got.</p>
                        </div>


                        <div className="review-content">
                            <img src={review} alt="" />
                            <div className="review-review">
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={starhalf} alt="" />
                            </div>
                            <h4>Quincy Wiley</h4>
                            <p>Travail de qualité,toujours à l’écoute,je suis conquis, n’hésitez surtout pas.</p>
                        </div>


                        <div className="review-content">
                            <img src={review} alt="" />
                            <div className="review-review">
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={starhalf} alt="" />
                            </div>
                            <h4>Gustavo Wilson</h4>
                            <p>Si je devais recommander un service d’immigration, ce serait UIS, pour leur sérieux et le respect des délais.</p>
                        </div>

                      

                        <div className="review-content">
                            {/* <img src={trustpilot} alt="" /> */}
                            <div className="review-review">
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={starhalf} alt="" />
                            </div>
                            <h4>Philip Wrigley</h4>
                            <p>Would like to thank Ken and team for processing my visa for Canada would recommend UIS for all visa applications been hassle free.</p>

                            <p>Philip case #55290</p>
                        </div>


                        <div className="review-content">
                            {/* <img src={trustpilot} alt="" /> */}
                            <div className="review-review">
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={starline} alt="" />
                            </div>
                            <h4>Vanessa</h4>
                            <p>I started this journey with UIS Canada almost at the end of last year, and it’s been a good experience. Every single person I spoke to were extremely helpful and patient with me, I spoke to multiple people throughout this process, but Ken Turner was the one who I feel helped me out the most and reassured me when I needed it. I got the visa(working holiday visa) I applied for very quickly, something I didn’t think was possible haha. Thank you to UIS Canada for all the help!</p>
                        </div>

                     


                        <div className="review-content">
                            <img src={sitejabber} alt="" />
                            <div className="review-review">
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={starline} alt="" />
                            </div>
                            <h4>Hamza C.</h4>
                            <p>If you're a student looking for a study visa in Canada. Then based on my experience UIS is the best option for you. The account managers are very supportive and professional during the documentation process. Step by step process might take a little time but this how it is. Just will recommend more transparency in charges to build customer trust easily. Ideal for you if you want to live and work in Canda legally</p>
                        </div>

                        <div className="review-cover">
                            <img src={banner4} alt="" />
                        </div>

                        <div className="review-content">
                            <img src={sitejabber} alt="" />
                            <div className="review-review">
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            </div>
                            <h4>Raypaul E.</h4>
                            <p>UIS Canada really assisted me to get my Student Visa, They were extremely professional, and their customer services are excellent. I didn't have even the slightest idea on the essential documents as well as information needed to migrate to Canada. They guided me through required steps for immigration process and appropriate communication is offered all through the visa application process.</p>
                        </div>


                    </div>


                    <div className="review-box">


                        <div className="review-content">
                            <img src={review} alt="" />
                            <div className="review-review">
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={starhalf} alt="" />
                            </div>
                            <h4>Tyler Miller</h4>
                            <p>J’ai découvert le site de UIS Canada par le biais d’un ami,j’ai suivi la procédure pour l’obtention de mon visa avec succès,mon seul regret c’est de ne l’avoir pas connu plus tôt.</p>
                        </div>

                        <div className="review-content">
                            {/* <img src={trustpilot} alt="" /> */}
                            <div className="review-review">
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={starhalf} alt="" />
                            </div>
                            <h4>Kirk Ronen</h4>
                            <p>UIS has been instrumental in changing my life for the better. They have and will always have my trust and support. Personally, my experience has shrunk the time I will be able to live with my family from 5-10 years down to 1 year.</p>
                            <p>Since I am immigrating through a work program, the career development department has been crucially important to me. My representative, Kevin, is working daily to achieve not only a job, but a well suited, respectable salary, and specifically located career for me.</p>
                            <p>Thank you everyone who is a part of the UIS Canada team.</p>
                            <p>Kirk Ronen</p>
                        </div>

                        <div className="review-content">
                            {/* <img src={trustpilot} alt="" /> */}
                            <div className="review-review">
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={starhalf} alt="" />
                            </div>
                            <h4>Rey Roth</h4>
                            <p>C’est la première fois que j’écris un avis pour UIS Canada, honnêtement c’est une très bonne agence,avec un service impeccable,première expérience pour moi et première réussite,aucun regret.</p>
                        </div>

                        <div className="review-content">
                            <img src={review} alt="" />
                            <div className="review-review">
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            </div>
                            <h4>Joshua Franklin</h4>
                            <p>La procédure Visa pour le Canada est très compliquée, et me tourner vers UIS Canada cette fois-ci, a rendu les choses plus faciles pour moi, je le recommande sans hésiter,merci.</p>
                        </div>

                        
                        <div className="review-cover">
                            <img src={banner2} alt="" />
                        </div>

                        <div className="review-content">
                            <img src={review} alt="" />
                            <div className="review-review">
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            </div>
                            <h4>Troy Howard</h4>
                            <p>Équipe très professionnelle!!! Faut l’avoir vécu pour y croire, ils ont mis toutes les chances de mon côté je peux désormais vivre mon rêve Canadien, je remercie tout le personnel UIS.</p>
                        </div>

                        <div className="review-content">
                            {/* <img src={trustpilot} alt="" /> */}
                            <div className="review-review">
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            </div>
                            <h4>Dani Jimenez Cremers</h4>
                            <p>Mr. Daniel. Working Holiday Visa Spain</p>

                            <p>Mr. Kent has been incredibly helpful and always available when I needed him. Really attentive team and I would recommend them services. Nevertheless, take into consideration that the process takes time, but at the end, they will manage to get your Visa!</p>
                        </div>


                        <div className="review-content">
                            {/* <img src={trustpilot} alt="" /> */}
                            <div className="review-review">
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            </div>
                            <h4>Conor Oflaherty</h4>
                            <p>My experience with UIS Canada from start to finish was very positive! I was mainly dealing with agent by the name of Ken Turner and I found him to be extremely helpful and always available and willing to help me with any questions or queries I had. He was also great and reminded me of documents I needed and deadlines I had to meet so he help keep me on my toes during the process. I would definitely recommend using UIS Canada to anyone looking to apply for a whv.</p>
                        </div>

                        <div className="review-content">
                            <img src={sitejabber} alt="" />
                            <div className="review-review">
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            </div>
                            <h4>Gregor E.</h4>
                            <p>Great service from friendly advisors! Kept informed and in check during the process. Payments are quick to be asked for however its the awkward bit so I assume representatives want this done and dusted ASAP. Highly recommend to anyone intresting. All you need is a positive attitude and some patience whilst going through the hoops!</p>
                            <p>Gregor, Scotland UK</p>
                        </div>

                        <div className="review-content">
                            <img src={sitejabber} alt="" />
                            <div className="review-review">
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            </div>
                            <h4>Rangiuru C.</h4>
                            <p>I've been considering doing a working gap year to Canada for a few years now and worried about how hard it will be to get a visa. Ken took those worries away as soon as I spoke to him. He made the process super easy and straight forward. Any questions I had he was sure to answer and even said I can give him a call anytime have questions after my Visa had been approved and granted.</p>
                        </div>

                    </div>


                    <div className="review-box">

                        <div className="review-content">
                            {/* <img src={trustpilot} alt="" /> */}
                            <div className="review-review">
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={starhalf} alt="" />
                            </div>
                            <h4>Joseph Seka</h4>
                            <p>My entire relation with Kevin is excellent. I trust him fully, and rely on him. He is accurate, honest with me, and very helpfull.</p>
                        </div>

                        <div className="review-content">
                            <img src={rcic} alt="" />
                            <div className="review-review">
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={starhalf} alt="" />
                            </div>
                            <h4>Ryan Foster</h4>
                            <p>UIS Canada is one of the most incredible immigration experts, that is the reason I pick them for my immigration process. My consultant was exceptional during the application process and after! I moved to Canada a couple of months ago on a PNP visa and I’m very happy. Thank you!</p>
                        </div>


                        <div className="review-cover">
                            <img src={banner1} alt="" />
                        </div>

                        <div className="review-content">
                            {/* <img src={trustpilot} alt="" /> */}
                            <div className="review-review">
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={starline} alt="" />
                            </div>
                            <h4>Danny Coghill</h4>
                            <p>Was helped through the Process well, kind and friendly (ken turner)
                            Only thing I can say is that all the expenses throughout should be mentioned at the start.</p>
                        </div>


                        <div className="review-content">
                            <img src={review} alt="" />
                            <div className="review-review">
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            </div>
                            <h4>Luca Williamson</h4>
                            <p>Je suis un homme d’affaires,et j’ai récemment installé mon entreprise au Canada,UIS m’a permis de régler rapidement le volet Visa et félicitations pour le professionnalisme.</p>
                        </div>


                        <div className="review-content">
                            <img src={review} alt="" />
                            <div className="review-review">
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            </div>
                            <h4>Luca Cox</h4>
                            <p>Quand une de mes amies m’a parlé de UIS,pour moi c’était juste comme tous les anciens services d’immigration que j’ai connus dans le passé,beaucoup de frustrations,des dossiers qui n’ont jamais abouti,j’étais un cas désespéré, aucune crédibilité,après mes multiples échecs j’ai eu mon visa .</p>
                        </div>


                        <div className="review-content">
                            {/* <img src={trustpilot} alt="" /> */}
                            <div className="review-review">
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            </div>
                            <h4>Bram van Beers</h4>
                            <p>Case number: 55196
                            Ken Turner helped me really well during the progress. The assistance was pretty pro-active, which I liked a lot.
                            UIS Canada knows exactly what to do and which paperworks I needed to do!</p>

                            <p>Kind regards, Bram van Beers</p>
                        </div>

                        <div className="review-content">
                            {/* <img src={trustpilot} alt="" /> */}
                            <div className="review-review">
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={starline} alt="" />
                            </div>
                            <h4>Louie Taylor</h4>
                            <p>Very helpful service, can’t wait to get out there now!</p>
                        </div>

                        
                        <div className="review-cover">
                            <img src={banner3} alt="" />
                        </div>

                        <div className="review-content">
                            <img src={sitejabber} alt="" />
                            <div className="review-review">
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            </div>
                            <h4>Rachel T.</h4>
                            <p>I would just like to start by thanking the UIS Canada team especially to Ken! Ken helped me gather all the relevant documents needed and made the whole process so easy for myself. I entered the 2022 Pool at the end of January and within 3 weeks of that got my invitation to apply, biometrics done and got my POE today. Ken and the team were so helpful every step of the way. Once again thank you so much, made my life so much easier! Would definitely recommend!</p>
                        </div>


                        <div className="review-content">
                            <img src={sitejabber} alt="" />
                            <div className="review-review">
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            </div>
                            <h4>Ogunniyi T.</h4>
                            <p>Usually i dont trust sites like this to give me needed and exact information. But UIS Canada goes in depth and explains step by step procedure. There are many programs and visas. You can see which suits you the best. They respond when you contact them and tell you everything you need to know if there are some doubts.</p>
                        </div>


                    </div>
               


                </div>

           

            </div>


            <VisaContact />

           
        </div>

    </Layout>

    );
  }
  
export default Reviews;