const mokeData = [
  {
    page: "lv3",
    sections: [
      {
        isShow: true,
        type: "topHeader",
        content: {
          logo: "/landingV/logo.svg",
        },
      },
      {
        isShow: true,
        type: "topBanner",
        content: {
          heading1: "Discover a new world.",
          heading2: "Discover you.",
          bgImage: "/landing/top-banner.webp",
          bgMobImage: "/landing/top-banner-mbl.webp",
        },
      },
      {
        isShow: true,
        type: "tellMore",
        content: {
          heading: "Full Working Holiday Experience Canada",
          description:
            "Get ready for the cultural adventure of a lifetime: living abroad, traveling, and making new friends. Join thousands of happy travelers who've already found their way to Canada with a Working Holiday Visa!",
          btnName: "Tell me more!",
        },
      },
      {
        isShow: true,
        type: "workingHoliday",
        content: {
          heading: "All-In-One Working Holiday Bundle",
          description:
            "Universal Immigration Services (UIS) Canada is offering a phenomenal travel experience in collaboration with the leading travel agency Travel-For-Work. Enjoy a premium package for all your Working Holiday needs:",
          left: {
            heading: "Working Holiday Premium",
            rating: "4.8/5",
            ratingDesc: "based on 1,793 customer reviews",
            barcode: "/landing/barcode.svg",
            bgImage: "/landing/left-card.webp",
            bgMobImage: "/landing/left-card-mbl.webp",
          },
          rightHeader: {
            destination: {
              icon: "/landing/destination.svg",
              title: "Destination",
              name: "Canada",
            },
            cost: {
              icon: "/landing/cost.svg",
              title: "VISA COST",
              name: "Included",
            },
            age: {
              icon: "/landing/age.svg",
              title: "AGE",
              name: "18-35",
            },
          },
          rightContent: [
            "Full Working Holiday Visa Service.",
            "4-Night stay at one of the hottest accommodation spots.",
            "FREE and private airport shuttle.",
            "Guaranteed job interview.",
            "Prepaid SIM card including 1 month of calls, SMS, and data.",
            "Personal travel concierge.",
            "Landsea Tours sightseeing tour.",
            "Pub crawl or similar excursion.",
            "Ongoing local team support.",
          ],
          stamp: "/landing/stamp.svg",
        },
      },
      {
        isShow: true,
        type: "form",
        content: {
          heading: "Want to Know More About the Working Holiday Bundle in Canada?",
          description:
            "Whether you have questions about jobs, visa services, accommodations or anything else, we can help you with your Working Holiday adventure.",
        },
      },
      {
        isShow: true,
        type: "gallery",
        content: {
          gallery: [
            "/landing/gallery1.webp",
            "/landing/gallery2.webp",
            "/landing/gallery3.webp",
            "/landing/gallery4.webp",
            "/landing/gallery5.webp",
          ],
        },
      },
      {
        isShow: true,
        type: "testimonial",
        content: {
          heading: "Our Working Holiday Testimonials",
          sliderContent: [
            {
              profilePic: "",
              description:
                "Hey, I just want to say a massive thank you to UIS Canada. I've just received my visa and a special thank you to Ken Turner because he worked day and night to answer my questions and queries that I had. Thanks Ken I'll see you soon!",
              name: "Coleen .B., 33",
              country: "Ireland",
              star: [
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star-half.svg",
              ],
            },
            {
              profilePic: "",
              description:
                "I want to say thank you to UIS Canada for getting me my working holiday visa. I'm working here on a farm in Ontario and it's an awesome country! I love it. Thank you very much.",
              name: "Tim. M., 24",
              country: "Germany",
              star: [
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
              ],
            },
            {
              profilePic: "",
              description:
                "Hi UIS Canada and Travel For Work, thank you for an amazing time in Canada! This entire experience has been a dream come true and I already met new friends and we went hiking and I'm super happy. Thank you again!",
              name: "Nicolette. J., 19",
              country: "France",
              star: [
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
              ],
            },
            {
              profilePic: "",
              description:
                "Hi everyone, this is my thank you for helping me get a visa, booking my job and accommodation and going above and beyond to ensure I have everything sorted out before and after my arrival to Canada. Keep up the good work",
              name: "Daniel. G., 30",
              country: "Australia",
              star: [
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star-half.svg",
              ],
            },
          ],
          sponsor: [
            "/landing/sponser1.svg",
            "/landing/sponser2.svg",
            "/landing/sponser3.svg",
            "/landing/sponser4.svg",
          ],
        },
      },
      {
        isShow: true,
        type: "about",
        content: {
          heading:
            "About Universal Immigration Services (UIS) Canada and Travel-For-Work",
          left: [
            {
              heading: "UIS Canada",
              description:
                "is a private immigration company that specializes in helping people make a fresh start in Canada. We exclusively subcontract fully authorized Regulated Canadian Immigration Consultants. These are consultants of good standing, certified by the Immigration Consultants of Canada Regulatory Authority (ICCRC).",
            },
            {
              heading: "Travel For Work",
              description:
                "is an agency that helps people make the most out of their vacation time. They also specialize in setting up ground operations for international travel companies in Canada.",
            },
          ],
          btnName: "Contact us",
          right: [
            {
              icon: "/landing/about1.svg",
              title: "10,665 travelers served since 2017",
            },
            {
              icon: "/landing/about2.svg",
              title: "Work with authorized and licensed RCIC’s.",
            },
            {
              icon: "/landing/about3.svg",
              title: "Work with authorized and licensed RCIC’s.",
            },
            {
              icon: "/landing/about4.svg",
              title: "24/6 global support services.",
            },
          ],
        },
      },
      {
        isShow: true,
        type: "bottomBanner",
        content: {
          bgImage: "/landing/bottom-banner.webp",
          bgMobImage: "/landing/bottom-banner-mbl.webp",
        },
      },
      {
        isShow: true,
        type: "footer",
        content: {
          logo: "/landing/logo.svg",
          description:
            "UIS Canada is an accredited Canadian consultancy for immigration and visas. We exclusively subcontract fully authorized Regulated Canadian Immigration Consultants. These are consultants of good standing, certified by the Immigration Consultants of Canada Regulatory Authority (ICCRC).",
          supportEmail: "support@uiscanada.com",
          supportPhone: "+1-604-262-3728",
          payment: {
            visa: "/landing/visa.webp",
          },
        },
      },
    ],
  },
  {
    page: "lv4",
    sections: [
      {
        isShow: true,
        type: "topHeader",
        content: {
          logo: "/landingV/logo.svg",
        },
      },
      {
        isShow: true,
        type: "topBanner",
        content: {
          heading1: "Discover a new world.",
          heading2: "Discover you.",
          bgImage: "/landing/top-banner.webp",
          bgMobImage: "/landing/top-banner-mbl.webp",
        },
      },
      {
        isShow: true,
        type: "tellMore",
        content: {
          heading: "Full Working Holiday Experience Canada",
          description:
            "We make working holiday experiences fun, easy, and hassle-free. From the early visa stages to job bookings and unbelievable adventures in Canada, we will stand by you every step of the way.",
          btnName: "Tell me more!",
        },
      },
      {
        isShow: true,
        type: "workingHoliday",
        content: {
          heading: "All-In-One Working Holiday Bundle",
          description:
            "Universal Immigration Services (UIS) Canada is offering a phenomenal travel experience in collaboration with the leading travel agency Travel-For-Work. Enjoy a premium package for all your Working Holiday needs:",
          left: {
            heading: "Working Holiday Premium",
            rating: "4.8/5",
            ratingDesc: "based on 1,793 customer reviews",
            barcode: "/landing/barcode.svg",
            bgImage: "/landing/left-card.webp",
            bgMobImage: "/landing/left-card-mbl.webp",
          },
          rightHeader: {
            destination: {
              icon: "/landing/destination.svg",
              title: "Destination",
              name: "Canada",
            },
            cost: {
              icon: "/landing/cost.svg",
              title: "VISA COST",
              name: "Included",
            },
            age: {
              icon: "/landing/age.svg",
              title: "AGE",
              name: "18-35",
            },
          },
          rightContent: [
            "Full Working Holiday Visa Service.",
            "4-Night stay at one of the hottest accommodation spots.",
            "FREE and private airport shuttle.",
            "Guaranteed job interview.",
            "Prepaid SIM card including 1 month of calls, SMS, and data.",
            "Personal travel concierge.",
            "Landsea Tours sightseeing tour.",
            "Pub crawl or similar excursion.",
            "Ongoing local team support.",
          ],
          stamp: "/landing/stamp.svg",
        },
      },
      {
        isShow: true,
        type: "form",
        content: {
          heading: "Want to Know More About the Working Holiday Bundle in Canada?",
          description:
            "Whether you have questions about jobs, visa services, accommodations or anything else, we can help you with your Working Holiday adventure.",
        },
      },
      {
        isShow: true,
        type: "gallery",
        content: {
          gallery: [
            "/landing/gallery1.webp",
            "/landing/gallery2.webp",
            "/landing/gallery3.webp",
            "/landing/gallery4.webp",
            "/landing/gallery5.webp",
          ],
        },
      },
      {
        isShow: true,
        type: "testimonial",
        content: {
          heading: "Our Working Holiday Testimonials",
          sliderContent: [
            {
              profilePic: "",
              description:
                "Hey, I just want to say a massive thank you to UIS Canada. I've just received my visa and a special thank you to Ken Turner because he worked day and night to answer my questions and queries that I had. Thanks Ken I'll see you soon!",
              name: "Coleen .B., 33",
              country: "Ireland",
              star: [
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star-half.svg",
              ],
            },
            {
              profilePic: "",
              description:
                "I want to say thank you to UIS Canada for getting me my working holiday visa. I'm working here on a farm in Ontario and it's an awesome country! I love it. Thank you very much.",
              name: "Tim. M., 24",
              country: "Germany",
              star: [
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
              ],
            },
            {
              profilePic: "",
              description:
                "Hi UIS Canada and Travel For Work, thank you for an amazing time in Canada! This entire experience has been a dream come true and I already met new friends and we went hiking and I'm super happy. Thank you again!",
              name: "Nicolette. J., 19",
              country: "France",
              star: [
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
              ],
            },
            {
              profilePic: "",
              description:
                "Hi everyone, this is my thank you for helping me get a visa, booking my job and accommodation and going above and beyond to ensure I have everything sorted out before and after my arrival to Canada. Keep up the good work",
              name: "Daniel. G., 30",
              country: "Australia",
              star: [
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star-half.svg",
              ],
            },
          ],
          sponsor: [
            "/landing/sponser1.svg",
            "/landing/sponser2.svg",
            "/landing/sponser3.svg",
            "/landing/sponser4.svg",
          ],
        },
      },
      {
        isShow: true,
        type: "about",
        content: {
          heading:
            "About Universal Immigration Services (UIS) Canada and Travel-For-Work",
          left: [
            {
              heading: "UIS Canada",
              description:
                "is a private immigration company that specializes in helping people make a fresh start in Canada. We exclusively subcontract fully authorized Regulated Canadian Immigration Consultants. These are consultants of good standing, certified by the Immigration Consultants of Canada Regulatory Authority (ICCRC).",
            },
            {
              heading: "Travel For Work",
              description:
                "is an agency that helps people make the most out of their vacation time. They also specialize in setting up ground operations for international travel companies in Canada.",
            },
          ],
          btnName: "Contact us",
          right: [
            {
              icon: "/landing/about1.svg",
              title: "10,665 travelers served since 2017",
            },
            {
              icon: "/landing/about2.svg",
              title: "Work with authorized and licensed RCIC’s.",
            },
            {
              icon: "/landing/about3.svg",
              title: "Work with authorized and licensed RCIC’s.",
            },
            {
              icon: "/landing/about4.svg",
              title: "24/6 global support services.",
            },
          ],
        },
      },
      {
        isShow: true,
        type: "bottomBanner",
        content: {
          bgImage: "/landing/bottom-banner.webp",
          bgMobImage: "/landing/bottom-banner-mbl.webp",
        },
      },
      {
        isShow: true,
        type: "footer",
        content: {
          logo: "/landing/logo.svg",
          description:
            "UIS Canada is an accredited Canadian consultancy for immigration and visas. We exclusively subcontract fully authorized Regulated Canadian Immigration Consultants. These are consultants of good standing, certified by the Immigration Consultants of Canada Regulatory Authority (ICCRC).",
          supportEmail: "support@uiscanada.com",
          supportPhone: "+1-604-262-3728",
          payment: {
            visa: "/landing/visa.webp",
          },
        },
      },
    ],
  },

  {
    page: "lv1",
    sections: [
      {
        isShow: true,
        type: "topHeader",
        content: {
          logo: "/landingV/logo.svg",
        },
      },
      {
        isShow: true,
        type: "topBanner",
        content: {
          heading1: "",
          heading2: "",
          bgImage: "/landing/google_banner.webp",
          bgMobImage: "/landing/topBannerMobV2.webp",
        },
      },
      {
        isShow: false,
        type: "tellMore",
        content: {
          heading: "Full Working Holiday Experience Canada",
          description:
            "We make working holiday experiences fun, easy, and hassle-free. From the early visa stages to job bookings and unbelievable adventures in Canada, we will stand by you every step of the way.",
          btnName: "Tell me more!",
        },
      },
      {
        isShow: true,
        type: "workingHoliday",
        content: {
          heading: "All-In-One",
          heading2: "Working Holiday Bundle",
          description:
            "Get ready for the cultural adventure of a lifetime: living abroad, traveling, and making new friends. Join thousands of happy travelers who've already found their way to Canada with a Working Holiday Visa!",
          left: {
            heading: "Working Holiday Premium",
            rating: "4.8/5",
            ratingDesc: "based on 1,793 customer reviews",
            barcode: "/landing/barcode.svg",
            bgImage: "/landing/left-card.webp",
            bgMobImage: "/landing/left-card-mbl.webp",
          },
          rightHeader: {
            destination: {
              icon: "/landing/destination.svg",
              title: "Destination",
              name: "Canada",
            },
            cost: {
              icon: "/landing/cost.svg",
              title: "VISA COST",
              name: "Included",
            },
            age: {
              icon: "/landing/age.svg",
              title: "AGE",
              name: "18-35",
            },
          },
          rightContent: [
            "Full Working Holiday Visa Service.",
            "4-Night stay at one of the hottest accommodation spots.",
            "FREE and private airport shuttle.",
            "Guaranteed job interview.",
            "Prepaid SIM card including 1 month of calls, SMS, and data.",
            "Personal travel concierge.",
            "Landsea Tours sightseeing tour.",
            "Pub crawl or similar excursion.",
            "Ongoing local team support.",
          ],
          stamp: "/landing/stamp.svg",
        },
      },
      {
        isShow: true,
        type: "form",
        content: {
          heading: "Want to Know More About the Working Holiday Bundle in Canada?",
          description:
            "Whether you have questions about jobs, visa services, accommodations or anything else, we can help you with your Working Holiday adventure.",
        },
      },
      {
        isShow: true,
        type: "gallery",
        content: {
          gallery: [
            "/landing/gallery1.webp",
            "/landing/gallery2.webp",
            "/landing/gallery3.webp",
            "/landing/gallery4.webp",
            "/landing/gallery5.webp",
          ],
        },
      },
      {
        isShow: true,
        type: "testimonial",
        content: {
          heading: "Our Working Holiday Testimonials",
          sliderContent: [
            {
              profilePic: "",
              description:
                "Hey, I just want to say a massive thank you to UIS Canada. I've just received my visa and a special thank you to Ken Turner because he worked day and night to answer my questions and queries that I had. Thanks Ken I'll see you soon!",
              name: "Coleen .B., 33",
              country: "Ireland",
              star: [
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star-half.svg",
              ],
            },
            {
              profilePic: "",
              description:
                "I want to say thank you to UIS Canada for getting me my working holiday visa. I'm working here on a farm in Ontario and it's an awesome country! I love it. Thank you very much.",
              name: "Tim. M., 24",
              country: "Germany",
              star: [
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
              ],
            },
            {
              profilePic: "",
              description:
                "Hi UIS Canada and Travel For Work, thank you for an amazing time in Canada! This entire experience has been a dream come true and I already met new friends and we went hiking and I'm super happy. Thank you again!",
              name: "Nicolette. J., 19",
              country: "France",
              star: [
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
              ],
            },
            {
              profilePic: "",
              description:
                "Hi everyone, this is my thank you for helping me get a visa, booking my job and accommodation and going above and beyond to ensure I have everything sorted out before and after my arrival to Canada. Keep up the good work",
              name: "Daniel. G., 30",
              country: "Australia",
              star: [
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star-half.svg",
              ],
            },
          ],
          sponsor: [
            "/landing/sponser1.svg",
            "/landing/sponser2.svg",
            "/landing/sponser3.svg",
            "/landing/sponser4.svg",
          ],
        },
      },
      {
        isShow: true,
        type: "about",
        content: {
          heading:
            "About Universal Immigration Services (UIS) Canada and Travel-For-Work",
          left: [
            {
              heading: "UIS Canada",
              description:
                "is a private immigration company that specializes in helping people make a fresh start in Canada. We exclusively subcontract fully authorized Regulated Canadian Immigration Consultants. These are consultants of good standing, certified by the Immigration Consultants of Canada Regulatory Authority (ICCRC).",
            },
            {
              heading: "Travel For Work",
              description:
                "is an agency that helps people make the most out of their vacation time. They also specialize in setting up ground operations for international travel companies in Canada.",
            },
          ],
          btnName: "Contact us",
          right: [
            {
              icon: "/landing/about1.svg",
              title: "10,665 travelers served since 2017",
            },
            {
              icon: "/landing/about2.svg",
              title: "Work with authorized and licensed RCIC’s.",
            },
            {
              icon: "/landing/about3.svg",
              title: "Work with authorized and licensed RCIC’s.",
            },
            {
              icon: "/landing/about4.svg",
              title: "24/6 global support services.",
            },
          ],
        },
      },
      {
        isShow: true,
        type: "bottomBanner",
        content: {
          bgImage: "/landing/bottom-banner.webp",
          bgMobImage: "/landing/bottom-banner-mbl.webp",
        },
      },
      {
        isShow: true,
        type: "footer",
        content: {
          logo: "/landing/logo.svg",
          description:
            "UIS Canada is an accredited Canadian consultancy for immigration and visas. We exclusively subcontract fully authorized Regulated Canadian Immigration Consultants. These are consultants of good standing, certified by the Immigration Consultants of Canada Regulatory Authority (ICCRC).",
          supportEmail: "support@uiscanada.com",
          supportPhone: "+1-604-262-3728",
          payment: {
            visa: "/landing/visa.webp",
          },
        },
      },
    ],
  },
  {
    page: "lv2",
    sections: [
      {
        isShow: true,
        type: "topHeader",
        content: {
          logo: "/landingV/logo.svg",
        },
      },
      {
        isShow: true,
        type: "topBanner",
        content: {
          heading1: "",
          heading2: "",
          bgImage: "/landing/google_banner.webp",
          bgMobImage: "/landing/topBannerMobV2.webp",
        },
      },
      {
        isShow: false,
        type: "tellMore",
        content: {
          heading: "Full Working Holiday Experience Canada",
          description:
            "We make working holiday experiences fun, easy, and hassle-free. From the early visa stages to job bookings and unbelievable adventures in Canada, we will stand by you every step of the way.",
          btnName: "Tell me more!",
        },
      },
      {
        isShow: true,
        type: "workingHoliday",
        content: {
          heading: "All-In-One",
          heading2: "Working Holiday Bundle",
          description:
            "We make working holiday experiences fun, easy, and hassle-free. From the early visa stages to job bookings and unbelievable adventures in Canada, we will stand by you every step of the way.",
          left: {
            heading: "Working Holiday Premium",
            rating: "4.8/5",
            ratingDesc: "based on 1,793 customer reviews",
            barcode: "/landing/barcode.svg",
            bgImage: "/landing/left-card.webp",
            bgMobImage: "/landing/left-card-mbl.webp",
          },
          rightHeader: {
            destination: {
              icon: "/landing/destination.svg",
              title: "Destination",
              name: "Canada",
            },
            cost: {
              icon: "/landing/cost.svg",
              title: "VISA COST",
              name: "Included",
            },
            age: {
              icon: "/landing/age.svg",
              title: "AGE",
              name: "18-35",
            },
          },
          rightContent: [
            "Full Working Holiday Visa Service.",
            "4-Night stay at one of the hottest accommodation spots.",
            "FREE and private airport shuttle.",
            "Guaranteed job interview.",
            "Prepaid SIM card including 1 month of calls, SMS, and data.",
            "Personal travel concierge.",
            "Landsea Tours sightseeing tour.",
            "Pub crawl or similar excursion.",
            "Ongoing local team support.",
          ],
          stamp: "/landing/stamp.svg",
        },
      },
      {
        isShow: true,
        type: "form",
        content: {
          heading: "Want to Know More About the Working Holiday Bundle in Canada?",
          description:
            "Whether you have questions about jobs, visa services, accommodations or anything else, we can help you with your Working Holiday adventure.",
        },
      },
      {
        isShow: true,
        type: "gallery",
        content: {
          gallery: [
            "/landing/gallery1.webp",
            "/landing/gallery2.webp",
            "/landing/gallery3.webp",
            "/landing/gallery4.webp",
            "/landing/gallery5.webp",
          ],
        },
      },
      {
        isShow: true,
        type: "testimonial",
        content: {
          heading: "Our Working Holiday Testimonials",
          sliderContent: [
            {
              profilePic: "",
              description:
                "Hey, I just want to say a massive thank you to UIS Canada. I've just received my visa and a special thank you to Ken Turner because he worked day and night to answer my questions and queries that I had. Thanks Ken I'll see you soon!",
              name: "Coleen .B., 33",
              country: "Ireland",
              star: [
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star-half.svg",
              ],
            },
            {
              profilePic: "",
              description:
                "I want to say thank you to UIS Canada for getting me my working holiday visa. I'm working here on a farm in Ontario and it's an awesome country! I love it. Thank you very much.",
              name: "Tim. M., 24",
              country: "Germany",
              star: [
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
              ],
            },
            {
              profilePic: "",
              description:
                "Hi UIS Canada and Travel For Work, thank you for an amazing time in Canada! This entire experience has been a dream come true and I already met new friends and we went hiking and I'm super happy. Thank you again!",
              name: "Nicolette. J., 19",
              country: "France",
              star: [
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
              ],
            },
            {
              profilePic: "",
              description:
                "Hi everyone, this is my thank you for helping me get a visa, booking my job and accommodation and going above and beyond to ensure I have everything sorted out before and after my arrival to Canada. Keep up the good work",
              name: "Daniel. G., 30",
              country: "Australia",
              star: [
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star-half.svg",
              ],
            },
          ],
          sponsor: [
            "/landing/sponser1.svg",
            "/landing/sponser2.svg",
            "/landing/sponser3.svg",
            "/landing/sponser4.svg",
          ],
        },
      },
      {
        isShow: true,
        type: "about",
        content: {
          heading:
            "About Universal Immigration Services (UIS) Canada and Travel-For-Work",
          left: [
            {
              heading: "UIS Canada",
              description:
                "is a private immigration company that specializes in helping people make a fresh start in Canada. We exclusively subcontract fully authorized Regulated Canadian Immigration Consultants. These are consultants of good standing, certified by the Immigration Consultants of Canada Regulatory Authority (ICCRC).",
            },
            {
              heading: "Travel For Work",
              description:
                "is an agency that helps people make the most out of their vacation time. They also specialize in setting up ground operations for international travel companies in Canada.",
            },
          ],
          btnName: "Contact us",
          right: [
            {
              icon: "/landing/about1.svg",
              title: "10,665 travelers served since 2017",
            },
            {
              icon: "/landing/about2.svg",
              title: "Work with authorized and licensed RCIC’s.",
            },
            {
              icon: "/landing/about3.svg",
              title: "Work with authorized and licensed RCIC’s.",
            },
            {
              icon: "/landing/about4.svg",
              title: "24/6 global support services.",
            },
          ],
        },
      },
      {
        isShow: true,
        type: "bottomBanner",
        content: {
          bgImage: "/landing/bottom-banner.webp",
          bgMobImage: "/landing/bottom-banner-mbl.webp",
        },
      },
      {
        isShow: true,
        type: "footer",
        content: {
          logo: "/landing/logo.svg",
          description:
            "UIS Canada is an accredited Canadian consultancy for immigration and visas. We exclusively subcontract fully authorized Regulated Canadian Immigration Consultants. These are consultants of good standing, certified by the Immigration Consultants of Canada Regulatory Authority (ICCRC).",
          supportEmail: "support@uiscanada.com",
          supportPhone: "+1-604-262-3728",
          payment: {
            visa: "/landing/visa.webp",
          },
        },
      },
    ],
  },

  {
    page: "v2",
    sections: [
      {
        isShow: true,
        type: "topHeader",
        content: {
          logo: "/landingV/logo.svg",
        },
      },
      {
        isShow: true,
        type: "topBanner",
        content: {
          heading1: "Discover a new world.",
          heading2: "Discover you.",
          bgImage: "/landing/top-banner.webp",
        },
      },
      {
        isShow: true,
        type: "tellMore",
        content: {
          heading: "Full Working Holiday Experience Canada",
          description:
            "Get ready for the cultural adventure of a lifetime: living abroad, traveling, and making new friends. Join thousands of happy travelers who've already found their way to Canada with a Working Holiday Visa!",
          btnName: "Tell me more!",
        },
      },
      {
        isShow: true,
        type: "workingHoliday",
        content: {
          heading: "All-In-One Working Holiday Bundle",
          description:
            "Universal Immigration Services (UIS) Canada is offering a phenomenal travel experience in collaboration with the leading travel agency Travel-For-Work. Enjoy a premium package for all your Working Holiday needs:",
          left: {
            heading: "Working Holiday Premium",
            rating: "4.8/5",
            ratingDesc: "based on 1,793 customer reviews",
            barcode: "/landing/barcode.svg",
            bgImage: "/landing/left-card.webp",
            bgMobImage: "/landing/left-card-mbl.webp",
          },
          rightHeader: {
            destination: {
              icon: "/landing/destination.svg",
              title: "Destination",
              name: "Canada",
            },
            cost: {
              icon: "/landing/cost.svg",
              title: "VISA COST",
              name: "Included",
            },
            age: {
              icon: "/landing/age.svg",
              title: "AGE",
              name: "18-35",
            },
          },
          rightContent: [
            "Full Working Holiday Visa Service.",
            "4-Night stay at one of the hottest accommodation spots.",
            "FREE and private airport shuttle.",
            "Guaranteed job interview.",
            "Prepaid SIM card including 1 month of calls, SMS, and data.",
            "Personal travel concierge.",
            "Landsea Tours sightseeing tour.",
            "Pub crawl or similar excursion.",
            "Ongoing local team support.",
          ],
          stamp: "/landing/stamp.svg",
        },
      },
      {
        isShow: true,
        type: "form",
        content: {
          heading: "Want to Know More About Working Holiday in Canada?",
          description:
            "Whether you have questions about job, visa, accommodation or something else, we can help you with your Working Holiday adventure.",
        },
      },
      {
        isShow: true,
        type: "gallery",
        content: {
          gallery: [
            "/landing/gallery1.webp",
            "/landing/gallery2.webp",
            "/landing/gallery3.webp",
            "/landing/gallery4.webp",
            "/landing/gallery5.webp",
          ],
        },
      },
      {
        isShow: true,
        type: "testimonial",
        content: {
          heading: "Our Working Holiday Testimonials",
          sliderContent: [
            {
              profilePic: "",
              description:
                "Hey, I just want to say a massive thank you to UIS Canada. I've just received my visa and a special thank you to Ken Turner because he worked day and night to answer my questions and queries that I had. Thanks Ken I'll see you soon!",
              name: "Coleen .B., 33",
              country: "Ireland",
              star: [
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star-half.svg",
              ],
            },
            {
              profilePic: "",
              description:
                "I want to say thank you to UIS Canada for getting me my working holiday visa. I'm working here on a farm in Ontario and it's an awesome country! I love it. Thank you very much.",
              name: "Tim. M., 24",
              country: "Germany",
              star: [
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
              ],
            },
            {
              profilePic: "",
              description:
                "Hi UIS Canada and Travel For Work, thank you for an amazing time in Canada! This entire experience has been a dream come true and I already met new friends and we went hiking and I'm super happy. Thank you again!",
              name: "Nicolette. J., 19",
              country: "France",
              star: [
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
              ],
            },
            {
              profilePic: "",
              description:
                "Hi everyone, this is my thank you for helping me get a visa, booking my job and accommodation and going above and beyond to ensure I have everything sorted out before and after my arrival to Canada. Keep up the good work",
              name: "Daniel. G., 30",
              country: "Australia",
              star: [
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star.svg",
                "/landing/star-half.svg",
              ],
            },
          ],
          sponsor: [
            "/landing/sponser1.svg",
            "/landing/sponser2.svg",
            "/landing/sponser3.svg",
            "/landing/sponser4.svg",
          ],
        },
      },
      {
        isShow: true,
        type: "about",
        content: {
          heading:
            "About Universal Immigration Services (UIS) Canada and Travel-For-Work",
          left: [
            {
              heading: "UIS Canada",
              description:
                "is a private immigration company that specializes in helping people make a fresh start in Canada. We exclusively subcontract fully authorized Regulated Canadian Immigration Consultants. These are consultants of good standing, certified by the Immigration Consultants of Canada Regulatory Authority (ICCRC).",
            },
            {
              heading: "Travel For Work",
              description:
                "is an agency that helps people make the most out of their vacation time. They also specialize in setting up ground operations for international travel companies in Canada.",
            },
          ],
          btnName: "Contact us",
          right: [
            {
              icon: "/landing/about1.svg",
              title: "10,665 travelers served since 2017",
            },
            {
              icon: "/landing/about2.svg",
              title: "Work with authorized and licensed RCIC’s.",
            },
            {
              icon: "/landing/about3.svg",
              title: "Work with authorized and licensed RCIC’s.",
            },
            {
              icon: "/landing/about4.svg",
              title: "24/6 global support services.",
            },
          ],
        },
      },
      {
        isShow: true,
        type: "bottomBanner",
        content: {
          bgImage: "/landing/bottom-banner.webp",
          bgMobImage: "/landing/bottom-banner-mbl.webp",
        },
      },
      {
        isShow: true,
        type: "footer",
        content: {
          logo: "/landing/logo.svg",
          description:
            "UIS Canada is an accredited Canadian consultancy for immigration and visas. We exclusively subcontract fully authorized Regulated Canadian Immigration Consultants. These are consultants of good standing, certified by the Immigration Consultants of Canada Regulatory Authority (ICCRC).",
          supportEmail: "support@uiscanada.com",
          supportPhone: "+1-604-262-3728",
          payment: {
            visa: "/landing/visa.webp",
          },
        },
      },
    ],
  },
  {
    page: "v2",
    sections: [
      {
        isShow: true,
        type: "topHeader",
        content: {
          logo: "/landingV/logo.svg",
        },
      },
      {
        isShow: true,
        type: "topBannerV2",
        content: {
          heading1: "Work. Save. Travel. Repeat!",
          //heading2:"Discover you.",
          bgImage: "",
        },
      },
      {
        isShow: true,
        type: "aboutV2",
        content: {
          heading: "Some sort of Title",
          description:
            "Lorem ipsum dolor sit amet consectetur. Nunc leo id suspendisse convallis tellus nullam pulvinar sed nisi. Elit eu donec in diam. In elementum lacinia condimentum proin non pharetra. Interdum euismod ornare elit vel pellentesque nunc a risus. Suspendisse pretium augue turpis enim justo pharetra vestibulum nisi odio.",
          gallery: ["first"],
        },
      },
      {
        isShow: true,
        type: "workingHolidayV2",
        content: {
          heading: "All-In-One Working Holiday Bundle",
          description:
            "Universal Immigration Services (UIS) Canada is offering a phenomenal travel experience in collaboration with the leading travel agency Travel-For-Work.Enjoy a premium package for all your Working Holiday needs:",
          left: {
            heading: "Working Holiday Premium",
            rating: "4.8/5",
            bgImage: "",
          },
          leftFooter: {
            destination: {
              icon: "",
              title: "Destination",
              name: "Canada",
            },
            cost: {
              icon: "",
              title: "VISA COST",
              name: "Included",
            },
            age: {
              icon: "",
              title: "AGE",
              name: "18-35",
            },
          },
          rightHeading: "What Included?",
          rightContent: [
            "Full Working Holiday Visa Service.",
            "4-Night stay at one of the hottest accommodation spots.",
            "FREE and private airport shuttle.",
            "Guaranteed job interview.",
            "Prepaid SIM card including 1 month of calls, SMS, and data.",
            "Personal travel concierge.",
            "Landsea Tours sightseeing tour.",
            "Pub crawl or similar excursion.",
            "Ongoing local team support.",
          ],
          stamp: "",
          btnName: "Ok, let’s do this!",
        },
      },
      {
        isShow: true,
        type: "form",
        content: {
          heading: "Leave Your Details to Fing Out More",
          description: "",
          bgImage: "",
        },
      },
      {
        isShow: false,
        type: "gallery",
        content: {
          gallery: [
            "../../assets/images/landing/gallery1.jpg",
            "../../assets/images/landing/gallery2.jpg",
            "../../assets/images/landing/gallery3.jpg",
            "../../assets/images/landing/gallery4.jpg",
            "../../assets/images/landing/gallery5.jpg",
          ],
        },
      },
      {
        isShow: true,
        type: "testimonialV2",
        content: {
          heading: "Our Working Holiday has an average rating of",
          heading2: "based On",
          rating: "4.8/5",
          review: "1,793 customer reviews",
          sliderContent: [
            {
              profilePic: "",
              description:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nec id aliquam, nulla at consectetur faucibus id. Dictum turpis sed mollis urna eget. Parturient porta sapien mattis sit quis urna malesuada nisl. Dictumst quisque risus, viverra egestas ultrices lorem facilisi sit.",
              name: "Hayley L",
              country: "Germany",
              star: [1, 2, 3, 4, 5],
            },
            {
              profilePic: "",
              description:
                "I want to say thank you to UIS Canada for getting me my working holiday visa. I'm working here on a farm in Ontario and it's an awesome country! I love it. Thank you very much.",
              name: "Tim. M., 24",
              country: "UK",
              star: [1, 2, 3, 4, 5],
            },
            {
              profilePic: "",
              description:
                "Hi UIS Canada and Travel For Work, thank you for an amazing time in Canada! This entire experience has been a dream come true and I already met new friends and we went hiking and I'm super happy. Thank you again!",
              name: "Nicolette. J., 19",
              country: "France",
              star: [1, 2, 3, 4, 5],
            },
            {
              profilePic: "",
              description:
                "Hi everyone, this is my thank you for helping me get a visa, booking my job and accommodation and going above and beyond to ensure I have everything sorted out before and after my arrival to Canada. Keep up the good work",
              name: "Daniel. G., 30",
              country: "Australia",
              star: [1, 2, 3, 4, 5],
            },
          ],
          sponsor: "../../assets/images/landing/sponser1.svg",
        },
      },
      {
        isShow: false,
        type: "about",
        content: {
          heading:
            "About Universal Immigration Services (UIS) Canada and Travel-For-Work",
          left: [
            {
              heading: "UIS Canada",
              description:
                "is a private immigration company that specializes in helping people make a fresh start in Canada. We exclusively subcontract fully authorized Regulated Canadian Immigration Consultants. These are consultants of good standing, certified by the Immigration Consultants of Canada Regulatory Authority (ICCRC).",
            },
            {
              heading: "Travel For Work",
              description:
                "is an agency that helps people make the most out of their vacation time. They also specialize in setting up ground operations for international travel companies in Canada.",
            },
          ],
          btnName: "Contact us",
          right: [
            {
              icon: "../../assets/images/landing/about1.svg",
              title: "10,665 travelers served since 2017",
            },
            {
              icon: "../../assets/images/landing/about2.svg",
              title: "Work with authorized and licensed RCIC’s.",
            },
            {
              icon: "../../assets/images/landing/about3.svg",
              title: "Work with authorized and licensed RCIC’s.",
            },
            {
              icon: "../../assets/images/landing/about4.svg",
              title: "24/6 global support services.",
            },
          ],
        },
      },
      {
        isShow: false,
        type: "bottomBanner",
        content: {
          bgImage: "",
        },
      },
      {
        isShow: true,
        type: "serviceV2",
        content: {
          heading: "Why Go with Universal Immigration Services (UIS) Canada?",
          description: "We care about you! But also:",
          services: [
            { icon: "", description: "10,665 travelers served since 2017" },
            { icon: "", description: "Hundreds of online reviews." },
            { icon: "", description: "24/6 global support services." },
            {
              icon: "",
              description: "Work with authorized and licensed RCIC’s.",
            },
            {
              icon: "",
              description: "Professional teams and a personalized approach.",
            },
          ],
          btnName: "Ok, let’s do this!",
        },
      },
      {
        isShow: true,
        type: "footer",
        content: {
          logo: "",
          description:
            "UIS Canada is an accredited Canadian consultancy for immigration and visas. We exclusively subcontract fully authorized Regulated Canadian Immigration Consultants.These are consultants of good standing, certified by the Immigration Consultants of Canada Regulatory Authority (ICCRC).",
          supportEmail: "support@uiscanada.com",
          supportPhone: "+49-345 454 33 44",
          payment: {
            visa: "",
          },
        },
      },
    ],
  },
  {
    isShow: false,
    type: "bottomBanner",
    content: {
      bgImage: "",
    },
  },
  {
    isShow: true,
    type: "serviceV2",
    content: {
      heading: "Why Go with Universal Immigration Services (UIS) Canada?",
      description: "We care about you! But also:",
      services: [
        { icon: "", description: "10,665 travelers served since 2017" },
        { icon: "", description: "Hundreds of online reviews." },
        { icon: "", description: "24/6 global support services." },
        { icon: "", description: "Work with authorized and licensed RCIC’s." },
        {
          icon: "",
          description: "Professional teams and a personalized approach.",
        },
      ],
      btnName: "Ok, let’s do this!",
    },
  },
  {
    isShow: true,
    type: "footer",
    content: {
      logo: "",
      description:
        "UIS Canada is an accredited Canadian consultancy for immigration and visas. We exclusively subcontract fully authorized Regulated Canadian Immigration Consultants.These are consultants of good standing, certified by the Immigration Consultants of Canada Regulatory Authority (ICCRC).",
      supportEmail: "support@uiscanada.com",
      supportPhone: "+1-604-262-3728",
      payment: {
        visa: "",
      },
    },
  },
];

export default mokeData;
