/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Helmet from 'react-helmet'
import Layout from '../layout/layout'
import facebook from '../../assets/images/thankyou/fb.svg'
import linkedin from '../../assets/images/thankyou/linkedin.svg'
import twitter from '../../assets/images/thankyou/twitter.svg'
// import bg1 from '../../assets/images/thankyou/bg1.jpg'
// import bg2 from '../../assets/images/thankyou/bg1.jpg'
// import bg3 from '../../assets/images/thankyou/bg1.jpg'
// import bg4 from '../../assets/images/thankyou/bg1.jpg'
import showicon from '../../assets/images/login/show-icon.svg'
import hideicon from '../../assets/images/login/hide-icon.svg'
import approved from '../../assets/images/header/approved.svg'
import canIcon from '../../assets/images/thankyou/canadaIcon.svg'
import successPswrd from '../../assets/images/thankyou/pswrdSuccess.svg'
import './thankyou.css'

import UserContext from '../../context/UserContext'
import { Button, Form } from 'react-bootstrap'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { updatePasswordSchema } from '../../utils/validationSchema'
import axios from 'axios'
import { serverPath } from '../../config/key'
import { toast } from 'react-toastify'

// const bg = { bg1, bg2, bg3, bg4 }

const ThankYouPage = () => {
  const { state, search } = useLocation()
  const userToken = new URLSearchParams(search).get('t')
  const userId = new URLSearchParams(search).get('userId')
  const hashToken = new URLSearchParams(search).get('hashToken')
  const { setToken, setUser } = useContext(UserContext)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfPassword, setShowConfPassword] = useState(false)
  const [success, setSuccess] = useState(false)
  const navigate = useNavigate()
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(updatePasswordSchema) })
  useEffect(() => {
    if (userToken) {
      localStorage.setItem('token', userToken)
      setToken(userToken)
      setUser()
    }
  }, [userToken])
  const handleInvoice = () => {
    if (userToken) {
      navigate('/welcome')
    }
  }
  const handleShowPassword = () => {
    setShowPassword(!showPassword)
  }
  const handleShowConfPassword = () => {
    setShowConfPassword(!showConfPassword)
  }
  const updatePassword = async (values) => {
    try {
      const response = await axios.post(`${serverPath}/users/createPassword`, {
        ...values,
        hashToken,
      })
      const { upd, token } = response.data
      if (upd.modifiedCount === 1) {
        reset()
        localStorage.setItem('token', token)
        setToken(token)
        setUser()
        toast.success('Password created successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
        navigate('/thankyou')
        setSuccess(true)
      }
    } catch (error) {
      if (error?.response?.data) {
        toast.error(error.response.data.msg, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
      }
    }
  }
  return (
    <Layout>
      <Helmet>
        <body className="hide-layout thankyou-page-view" />
      </Helmet>

      <div className="thank-you-page">
        {/* <div
          className="thank-you-bg"
          style={{
            backgroundImage: 'url(' + bg[state?.bg] + ')',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        ></div> */}
        {userToken || userId? (
          <div className="thank-you-grid">
            <div className="thank-you-content">
              <img src="" alt="" />
              <h2>Thank You!</h2>
              <h3>Payment completed successfully</h3>
              {userId?<p>Please check your email and create a new password</p>:""}
              <p>{state?.description}</p>
              {!userId?<div className="thank-you-link">
                <span onClick={handleInvoice}>Go to My Account</span>
              </div>:""}
              
            </div>

            <div className="thank-you-social">
              <p>Let’s Connect!</p>
              <ul>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/uis.canada"
                  >
                    <img src={facebook} alt="" />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkedin.com/company/uis-canada-official/"
                  >
                    <img src={linkedin} alt="" />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://twitter.com/uis_canada"
                  >
                    <img src={twitter} alt="" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          ''
        )}
        {/* {userId ? (
          <div className="thank-you-grid">
            <div className="thank-you-content thank-you-pswrd">
              <img src={canIcon} alt="Nav Icon" />
              <h2>The payment is successful!</h2>
              <h6>Please create a password for your new account.</h6>
              <Form
                onSubmit={handleSubmit(updatePassword)}
                className={`${success ? 'success-form' : ''}`}
              >
                <Form.Group>
                  <div className="pass-show">
                    <span className="pass-icon" onClick={handleShowPassword}>
                      {showPassword ? (
                        <img src={hideicon} alt="" />
                      ) : (
                        <img src={showicon} alt="" />
                      )}
                    </span>
                    <Form.Control
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      placeholder="New password"
                      {...register('password')}
                    />
                  </div>

                  {errors?.password ? (
                    <span className="error-msg">{errors.password.message}</span>
                  ) : (
                    ''
                  )}
                </Form.Group>
                <Form.Group>
                  <div className="pass-show">
                    <span
                      className="pass-icon"
                      onClick={handleShowConfPassword}
                    >
                      {showConfPassword ? (
                        <img src={hideicon} alt="" />
                      ) : (
                        <img src={showicon} alt="" />
                      )}
                    </span>
                    <Form.Control
                      type={showConfPassword ? 'text' : 'password'}
                      name="confirmPassword"
                      placeholder="Confirm password"
                      {...register('confirmPassword')}
                    />
                  </div>

                  {errors?.confirmPassword ? (
                    <span className="error-msg">
                      {errors.confirmPassword.message}
                    </span>
                  ) : (
                    ''
                  )}
                </Form.Group>
                <Button type="submit">
                  Submit {success && <img src={approved} alt="approved" />}
                </Button>
              </Form>
            </div>
          </div>
        ) : (
          ''
        )} */}
        {hashToken ? (
          <div className="thank-you-grid">
            <div className="thank-you-content thank-you-pswrd">
              <img src={canIcon} alt="Nav Icon" />
              {/* <h2>The payment is successful!</h2> */}
              <h6>Please create a password for your new account.</h6>
              <Form
                onSubmit={handleSubmit(updatePassword)}
                className={`${success ? 'success-form' : ''}`}
              >
                <Form.Group>
                  <div className="pass-show">
                    <span className="pass-icon" onClick={handleShowPassword}>
                      {showPassword ? (
                        <img src={hideicon} alt="" />
                      ) : (
                        <img src={showicon} alt="" />
                      )}
                    </span>
                    <Form.Control
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      placeholder="New password"
                      {...register('password')}
                    />
                  </div>

                  {errors?.password ? (
                    <span className="error-msg">{errors.password.message}</span>
                  ) : (
                    ''
                  )}
                </Form.Group>
                <Form.Group>
                  <div className="pass-show">
                    <span
                      className="pass-icon"
                      onClick={handleShowConfPassword}
                    >
                      {showConfPassword ? (
                        <img src={hideicon} alt="" />
                      ) : (
                        <img src={showicon} alt="" />
                      )}
                    </span>
                    <Form.Control
                      type={showConfPassword ? 'text' : 'password'}
                      name="confirmPassword"
                      placeholder="Confirm password"
                      {...register('confirmPassword')}
                    />
                  </div>

                  {errors?.confirmPassword ? (
                    <span className="error-msg">
                      {errors.confirmPassword.message}
                    </span>
                  ) : (
                    ''
                  )}
                </Form.Group>
                <Button type="submit">
                  Submit {success && <img src={approved} alt="approved" />}
                </Button>
              </Form>
            </div>
          </div>
        ) : (
          ''
        )}

        {success ? (
          <div className="password-success-container thank-you-grid">
            <div className="thank-you-content thank-you-pswrd">
              <img src={successPswrd} alt="" />
              <h2>Congratulations!</h2>
              <h6>Your password is created successfully.</h6>
            </div>

            <div className="back-home">
              <a href="/">Back to Home</a>
            </div>

            <div className="thank-you-social">
              <p>Let’s Connect!</p>
              <ul>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/uis.canada"
                  >
                    <img src={facebook} alt="" />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkedin.com/company/uis-canada-official/"
                  >
                    <img src={linkedin} alt="" />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://twitter.com/uis_canada"
                  >
                    <img src={twitter} alt="" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </Layout>
  )
}

export default ThankYouPage
