import * as yup from 'yup'

export const LoginSchema = yup
  .object()
  .shape({
    email: yup
      .string()
      /* .email('Please enter valid email') */
      .required('This is required'),
    password: yup.string().required('Password is required'),
  })
  .required()
export const ResetPasswordSchema = yup.object({
  email: yup
    .string()
    .email('Please enter valid email')
    .required('Email is required'),
})

export const PhoneVerifySchema = yup.object({
  isPhone:yup.bool(),
  phone: yup.string().when('isPhone', {
    is: true,
    then: yup.string().required('Phone is required'),
  }),
  isEmail:yup.bool(),
  email: yup.string().when('isEmail', {
    is: true,
    then: yup.string().email('Please enter valid email').required('Email is required'),
  }),
})

export const PhoneOtpSchema = yup.object({
  otp: yup.string().required('OTP is required'),
})

export const ContactSchema = yup.object().shape({
  name: yup.string().max(15,'Name cannot be longer then 15 characters').required('Name is required'),
  email: yup
    .string()
    .email('Please enter valid email')
    .required('Email is required'),
  phoneNumber: yup.string().required('Phone number is required'),
})
export const ConsultForm = yup.object().shape({
  firstName: yup.string().max(15,'First name cannot be longer than 15 characters').required('First name is required'),
  lastName: yup.string().max(15,'Last name cannot be longer than 15 characters').required('Last name is required'),
  email: yup
    .string()
    .email('Please enter valid email')
    .required('Email is required'),
  phoneNumber: yup.string().required('Phone number is required'),
})
export const RegisterSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup
    .string()
    .email('Please enter valid email')
    .required('Email is required'),
  phone: yup.string().required('Phone number is required'),
  password: yup.string().required('Password is required'),
})
export const userSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  address: yup.string().required('Address is required'),
  lastName: yup.string().required('Last name is required'),
  name: yup.string().required('Name is required'),
  city: yup.string().required('City is required'),
  country: yup.string().required('Country is required'),
  phone: yup.string().required('Phone number is required'),
  email: yup
    .string()
    .email('Please enter valid email')
    .required('Email is required'),
})
export const updateUserSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  city: yup.string().required('City is required'),
  country: yup.string().required('Country is required'),
  phone: yup.string().required('Phone number is required'),
  email: yup
    .string()
    .email('Please enter valid email')
})
export const NewsLetterSchema = yup.object({
  email: yup
    .string()
    .email('Please enter valid email')
    .required('Email is required'),
})

export const updatePasswordSchema = yup.object({
  password: yup.string().required('Password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
})
export const invoiceSchema = yup.object().shape({
  companyId: yup.string().required('Company Name is required'),
  //productId: yup.string().required('Product Name is required'),
  brandId: yup.string().required('Brand is required'),

  clientEmail: yup
    .string()
    .email('Please enter valid email')
    .required('Email is required'),
  clientName: yup.string().required('Client Name is required'),
  clientCity: yup.string().required('Client City is required'),
  clientCountry: yup.string().required('Client Country is required'),
  clientAddress: yup.string().required('Client Address is required'),
  paymentMethod: yup.string().required('Invoice type is required'),
  clientPhone: yup.string().required('Phone number is required'),
  sfPaymentMethod: yup.string().required('Sf payment Method is required'),
  paymentId: yup.string().required('Sf payment Method is required'),
})
export const invoiceAddSchema = yup.object().shape({
  companyId: yup.string().required('Company Name is required'),
  //productId: yup.string().required('Product Name is required'),
  brandId: yup.string().required('Brand is required'),
  opportunityId: yup.string().required('Sf opportunity Id is required'),
  clientEmail: yup
    .string()
    .email('Please enter valid email')
    .required('Email is required'),
  clientName: yup.string().required('Client Name is required'),
  clientCity: yup.string().required('Client City is required'),
  clientCountry: yup.string().required('Client Country is required'),
  clientAddress: yup.string().required('Client Address is required'),
  paymentMethod: yup.string().required('Invoice type is required'),
  clientPhone: yup.string().required('Phone number is required'),
  sfPaymentMethod: yup.string().required('Sf payment Method is required'),
  currency: yup.string().required('Currency is required'),
  amount: yup.string().required('Amount is required'),
})

export const userDetailSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email2: yup.string().required('Email is required'),
  address: yup.string().required('Address is required'),
  city: yup.string().required('City is required'),
  phoneNumber: yup.string().required('Phone number is required'),
  // password: yup.string().when('isOpenPassword', {
  //   is: 'Yes',
  //   then: yup.string().required('Password is required'),
  // }),
})
export const education = yup.object().shape({
  highestLevelEdu: yup.string().required('Highest level education is required'),
  fieldStudy: yup.string().required('Field study  is required'),
  institution: yup.string().required('Institution is required'),
  studyDuration: yup.string().required('Study duration is required'),
  countryStudy: yup.string().required('Country study is required'),
  completed: yup.string().required('Completed is required'),
  studyRemainTime: yup.string().required('Study remain time is required'),
  yearsEducation: yup.string().required('Year education is required'),
})
export const workExperience = yup.object().shape({
  numberOfJobs: yup.string().required('Number of jobs is required'),
  officialJobTitle: yup.string().required('Official job title is required'),
  jobDescription: yup.string().required('Job description is required'),
  yearsExperience: yup.string().required('Year Experience is required'),
  jobStartDate: yup.string().required('Job start date is required'),
  jobEndTime: yup.string().required('Job end time is required'),
  fullTimePosition: yup.string().required('Full time position is required'),
  hoursPerWeek: yup.string().required('Hours per week  is required'),
  monthlyIncome: yup.string().required('Monthly Income is required'),
  moneyBroughtToCanada: yup
    .string()
    .required('Money brought to canad is required'),
})
export const PersonalSpouseInfoSchema = yup.object().shape({
  sFirstName: yup.string().required('First Name is required'),
  sMidName: yup.string(),
  sLastName: yup.string().required('Last Name is required'),
  sAge: yup.number(),
  sPhoneNumber: yup.string().required('Phone number is required'),
  sMobile: yup.string(),
  sOfficePhoneNumber: yup.string(),
  sGender: yup.string().required('Gender is required'),

  sPrimaryEmail: yup
    .string()
    .required(
      'Please enter an email address in the format username@domain.com',
    ),
  sSecondaryEmail: yup.string(),
  sDateOfBirth: yup.string().required('Date of birth is required').nullable(),
  sBirthCountry: yup.string().required('Birth country is required'),
  sCity: yup.string().required('City is required'),
  sStreet: yup.string().required('Street is required'),
  sCountryCitizenship: yup.string().required('Country citizenship is required'),
  sCountryResidence: yup.string().required('Country residence is required'),
  sZipCode: yup.string().required('Zip code is required'),
  sMaritalStatus: yup.string().required('Marital status is required'),
  sDependentChildren: yup.string(),
  sCriminalOffense: yup.string().required('Police Record is required'),
  sOffenseTypeDetail: yup.string().when('sCriminalOffense', {
    is: 'Yes',
    then: yup.string().required('Offense type detail is required'),
  }),
  sHealthCondition: yup.string().required('Health condition is required'),
  sHealthConditionDetail: yup.string().when('sHealthCondition', {
    is: 'Yes',
    then: yup.string().required('Health condition detail is required'),
  }),
})

export const PersonalSponsorSchema = yup.object().shape({
  sponsorFirstName: yup.string().required('First Name is required'),
  sponsorMidName: yup.string(),
  sponsorLastName: yup.string().required('Last Name is required'),
  sponsorAge: yup.number(),
  sponsorPhoneNumber: yup.string().required('Phone number is required'),
  sponsorMobile: yup.string(),
  sponsorOfficePhoneNumber: yup.string(),
  sponsorGender: yup.string().required('Gender is required'),
  sponsorDateOfBirth: yup
    .string()
    .required('Date of birth is required')
    .nullable(),
  sponsorPrimaryEmail: yup
    .string()
    .email('Please enter valid email')
    .required(
      'Please enter an email address in the format username@domain.com',
    ),
  sponsorSecondaryEmail: yup.string(),

  sponsorBirthCountry: yup.string().required('Birth country is required'),
  sponsorCity: yup.string().required('City is required'),
  sponsorStreet: yup.string().required('Street is required'),
  sponsorCountryCitizenship: yup
    .string()
    .required('Country citizenship is required'),
  sponsorCountryResidence: yup
    .string()
    .required('Country residence is required'),
  sponsorZipCode: yup.string().required('Zip code is required'),
  sponsorMaritalStatus: yup.string().required('Marital status is required'),
  sponsorDependentChildren: yup.string(),
  sponsorCriminalOffense: yup.string().required('Police Record is required'),
  sponsorOffenseTypeDetail: yup.string().when('sponsorCriminalOffense', {
    is: 'Yes',
    then: yup.string().required('Offense type detail is required'),
  }),
  sponsorHealthCondition: yup.string().required('Health condition is required'),
  sponsorHealthConditionDetail: yup.string().when('sponsorHealthCondition', {
    is: 'Yes',
    then: yup.string().required('Health condition detail is required'),
  }),
})
export const sponsoringMainApplicant = yup.object().shape({
  sponsorFirstName: yup.string().required('First Name is required'),
  sponsorMidName: yup.string(),
  sponsorLName: yup.string().required('Last Name is required'),
  sponsorAge: yup.number(),
  sponsorPhoneNumber: yup.string().required('Phone number is required'),

  sponsorMobile: yup.string(),
  sponsorOfficePhoneNumber: yup.string(),

  sponsorGender: yup.string().required('Gender is required'),

  sponsorPrimaryEmail: yup
    .string()
    .required(
      'Please enter an email address in the format username@domain.com',
    ),
  sponsorSecondaryEmail: yup.string(),
  sponsorDateOfBirth: yup
    .string()
    .required('Date of birth is required')
    .nullable(),
  sponsorBirthCountry: yup.string().required('Birth country is required'),
  sponsorCity: yup.string().required('City is required'),
  sponsorStreet: yup.string().required('Street is required'),
  sponsorCountryCitizenship: yup
    .string()
    .required('Country citizenship is required'),
  sponsorCountryResidence: yup
    .string()
    .required('Country residence is required'),
  sponsorZipCode: yup.string().required('Zip code is required'),
  sponsorMaritalStatus: yup.string().required('Marital status is required'),
  sponsorDependentChildren: yup.string(),
  sponsorCriminalOffense: yup.string().required('Police Record is required'),
  sponsorOffenseTypeDetail: yup.string().when('criminalOffense', {
    is: 'Yes',
    then: yup.string().required('Offense type detail is required'),
  }),
  sponsorHealthCondition: yup.string().required('Health condition is required'),
  sponsorHealthConditionDetail: yup.string().when('healthCondition', {
    is: 'Yes',
    then: yup.string().required('Health condition detail is required'),
  }),
})

export const AddressValidationValidation = yup.array().of(
  yup.object().shape({
    addrCountry: yup.string().required('Country is required'),
    addrStateDist: yup.string().required('State/Province/District is required'),
    addrCityTown: yup.string().required('City/Town is required'),
    addrFullStreet: yup.string().required('Full street address is required'),
    addrZipCode: yup.string().required('Zip code/Postal code is required'),
    addrFromDate: yup.string().required('From Date is required'),
    addrToDate: yup.string().required('To Date is required'),
  }),
)

export const memberOfOrganizationValidation = yup.array().of(
  yup.object().shape({
    organizationName: yup.string().required('Name is required'),
    organizationType: yup.string().required('Type is required'),
    organizationRole: yup.string().required('Role is required'),
    organizationCountry: yup.string().required('Country is required'),
    organizationCity: yup.string().required('City is required'),
    organizationFromDate: yup.string().required('From Date is required'),
    organizationToDate: yup.string().required('To Date is required'),
  }),
)
export const tripValidation = yup.array().of(
  yup.object().shape({
    tripsCountry: yup.string().required('Country is required'),
    tripsCity: yup.string().required('City is required'),
    tripsCountryStatus: yup.string().required('Country status is required'),
    tripsReason: yup.string().required('Reason is required'),
    tripsFromDate: yup.string().required('From Date is required'),
    tripsToDate: yup.string().required('To Date is required'),
  }),
)
export const parentValidation = yup.array().of(
  yup.object().shape({
    parentFullName: yup.string().required('Full Name is required'),
    parentDateOfBirth: yup.string().required('Date of birth is required'),
    parentBirthCountry: yup.string().required('Birth country is required'),
    parentBirthCity: yup.string().required('Birth city is required'),
    parentOccupation: yup.string().required('Occupation is required'),
    parentResidentialAddress: yup
      .string()
      .required('Residential address is required'),
    parentMaritalStatus: yup.string().required('Marital status is required'),
    parentEmailAddress: yup
      .string()
      .email('Please enter valid email')
      .required('Email is required'),
    parentDeceased: yup.string().required('Deceased is required'),
    parentRelation: yup.string().required('Parent relation is required'),
    parentGender: yup.string().required('Gender is required'),
  }),
)
export const siblingValidation = yup.array().of(
  yup.object().shape({
    siblingFullName: yup.string().required('Full Name is required'),
    siblingDateOfBirth: yup.string().required('Date of birth is required'),
    siblingBirthCountry: yup.string().required('Birth country is required'),
    siblingBirthCity: yup.string().required('Birth city is required'),
    siblingOccupation: yup.string().required('Occupation is required'),
    siblingResidentialAddress: yup
      .string()
      .required('Residential address is required'),
    siblingMaritalStatus: yup.string().required('Marital status is required'),
    siblingEmailAddress: yup
      .string()
      .email('Please enter valid email')
      .required('Email is required'),
    siblingDeceased: yup.string().required('Deceased is required'),
    siblingRelation: yup.string().required('Sibling relation is required'),
    siblingGender: yup.string().required('Gender is required'),
  }),
)
export const childValidation = yup.array().of(
  yup.object().shape({
    childFirstName: yup.string().required('First Name is required'),
    childMiddleName: yup.string(),
    childLastName: yup.string().required('Last Name is required'),
    childGender: yup.string(),
    childDateOfBirth: yup.string(),
    childBirthCountry: yup.string(),
    childBirthCity: yup.string().required('Birth city is required'),
    childCitizenship: yup.string(),
    childSecondCitizenship: yup.string(),
    childMaritalStatus: yup.string().required('Marital status is required'),
    childOccupation: yup.string().required('Occupation is required'),
    childResidentialAddress: yup
      .string()
      .required('Residential address is required'),
    childEmailAddress: yup.string(),
  }),
)
export const PersonalMainApplicantInfoSchema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  midName: yup.string(),
  lName: yup.string().required('Last Name is required'),
  age: yup.number(),
  phoneNumber: yup.string().required('Phone number is required'),
  mobile: yup.string(),
  officePhoneNumber: yup.string(),

  gender: yup.string().required('Gender is required'),

  primaryEmail: yup
    .string()
    .email('Please enter valid email')
    .required(
      'Please enter an email address in the format username@domain.com',
    ),
  secondaryEmail: yup.string(),
  dateOfBirth: yup.string().required('Date of birth is required').nullable(),
  birthCountry: yup.string().required('Birth country is required'),
  city: yup.string().required('City is required'),
  street: yup.string().required('Street is required'),
  countryCitizenship: yup.string().required('Country citizenship is required'),
  countryResidence: yup.string().required('Country residence is required'),
  zipCode: yup.string().required('Zip code is required'),
  maritalStatus: yup.string().required('Marital status is required'),
  dependentChildren: yup.string(),
  criminalOffense: yup.string().required('Police Record is required'),
  offenseTypeDetail: yup.string().when('criminalOffense', {
    is: 'Yes',
    then: yup.string().required('Offense type detail is required'),
  }),
  healthCondition: yup.string().required('Health condition is required'),
  healthConditionDetail: yup.string().when('healthCondition', {
    is: 'Yes',
    then: yup.string().required('Health condition detail is required'),
  }),
  canadaBefore: yup.string().required('Canada visit is required'),
  canadaInfo: yup.object().when('canadaBefore', {
    is: 'Yes',
    then: yup.object().shape({
      lastVisitDate: yup.string().required('Last visit is required'),
      location: yup.string().required('Location is required'),
    }),
  }),
  immigrationRefugeesCanBefore: yup
    .string()
    .required('Immigration is required'),
  immigrationInfo: yup.object().when('immigrationRefugeesCanBefore', {
    is: 'Yes',
    then: yup.object().shape({
      passportNumber: yup.string().required('Passport number is required'),
      countryOfIssue: yup.string().required('Country of issue is required'),
      issueDate: yup.string().required('Issue date is required'),
      expiryDate: yup.string().required('Expiry date is required'),
    }),
  }),
  otherName: yup.string().required('Other name is required'),
  otherNameInfo: yup.object().when('otherName', {
    is: 'Yes',
    then: yup.object().shape({
      otherFirstName: yup.string().required('First name is required'),
      otherMidName: yup.string(),
      otherLastName: yup.string().required('Last name is required'),
    }),
  }),
  pastCitizenshipSixMonth: yup
    .string()
    .required('Past citizenship is required'),
  pastCitizenshipInfo: yup.object().when('pastCitizenshipSixMonth', {
    is: 'Yes',
    then: yup.object().shape({
      liveCountry: yup
        .string()
        .required('Past citizenship country is required'),
      liveStatus: yup.string().required('Past citizenship status is required'),
      liveFromDate: yup
        .string()
        .required('Past citizenship from date is required'),
      liveToDate: yup.string().required('Past citizenship to date is required'),
    }),
  }),
  CommonLawRelationship: yup
    .string()
    .required('Common law relationship is required'),
  relationshipInfo: yup.object().when('CommonLawRelationship', {
    is: 'Yes',
    then: yup.object().shape({
      relationFirstName: yup.string().required('First name is required'),
      relationFamilyName: yup.string().required('Family name is required'),
      relationType: yup.string().required('Relation type is required'),
      relationFromDate: yup.string().required('From date is required'),
      relationToDate: yup.string().required('To date is required'),
    }),
  }),
  isAddress: yup.string(),
  addressInformation: yup.array().when('isAddress', {
    is: 'open',
    then: AddressValidationValidation,
  }),
  memberOfOrganization: yup
    .string()
    .required('Member of organization is required'),
  memberOfInformation: yup.array().when('memberOfOrganization', {
    is: 'Yes',
    then: memberOfOrganizationValidation,
  }),
  tripsOutSideInside: yup.string().required('Trips is required'),
  tripInformation: yup.array().when('tripsOutSideInside', {
    is: 'Yes',
    then: tripValidation,
  }),
  isSpouseOpen: yup.bool(),
  spouseInformation: yup.object().when('isSpouseOpen', {
    is: true,
    then: PersonalSpouseInfoSchema,
  }),
  isChildren: yup.bool(),
  childrenInfo: yup.array().when('isChildren', {
    is: true,
    then: childValidation,
  }),
  isParent: yup.bool(),
  parentInformation: yup.array().when('isParent', {
    is: true,
    then: parentValidation,
  }),
  isSibling: yup.bool(),
  siblingInformation: yup.array().when('isSibling', {
    is: true,
    then: siblingValidation,
  }),
})

export const sponsoredChildValidation = yup.array().of(
  yup.object().shape({
    childFirstName: yup.string().required('First Name is required'),
    childMiddleName: yup.string(),
    childLastName: yup.string().required('Last Name is required'),
    childGender: yup.string(),
    childDateOfBirth: yup.string(),
    childBirthCountry: yup.string(),
    childBirthCity: yup.string().required('Birth city is required'),
    childCitizenship: yup.string(),
    childSecondCitizenship: yup.string(),
    childMaritalStatus: yup.string().required('Marital status is required'),
    childOccupation: yup.string().required('Occupation is required'),
    childResidentialAddress: yup
      .string()
      .required('Residential address is required'),
    childEmailAddress: yup.string(),
  }),
)
export const PersonalSponsoredInfoSchema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  midName: yup.string(),
  lName: yup.string().required('Last Name is required'),
  age: yup.number(),
  phoneNumber: yup.string().required('Phone number is required'),

  mobile: yup.string(),
  officePhoneNumber: yup.string(),

  gender: yup.string().required('Gender is required'),

  primaryEmail: yup
    .string()
    .email('Please enter valid email')
    .required(
      'Please enter an email address in the format username@domain.com',
    ),
  secondaryEmail: yup.string(),
  dateOfBirth: yup.string().required('Date of birth is required').nullable(),
  birthCountry: yup.string().required('Birth country is required'),
  city: yup.string().required('City is required'),
  street: yup.string().required('Street is required'),
  countryCitizenship: yup.string().required('Country citizenship is required'),
  countryResidence: yup.string().required('Country residence is required'),
  zipCode: yup.string().required('Zip code is required'),
  maritalStatus: yup.string().required('Marital status is required'),
  dependentChildren: yup.string(),
  criminalOffense: yup.string().required('Police Record is required'),
  offenseTypeDetail: yup.string().when('criminalOffense', {
    is: 'Yes',
    then: yup.string().required('Offense type detail is required'),
  }),
  healthCondition: yup.string().required('Health condition is required'),
  healthConditionDetail: yup.string().when('healthCondition', {
    is: 'Yes',
    then: yup.string().required('Health condition detail is required'),
  }),
  canadaBefore: yup.string().required('Canada visit is required'),
  canadaInfo: yup.object().when('canadaBefore', {
    is: 'Yes',
    then: yup.object().shape({
      lastVisitDate: yup.string().required('Last visit is required'),
      location: yup.string().required('Location is required'),
    }),
  }),
  immigrationRefugeesCanBefore: yup
    .string()
    .required('Immigration is required'),
  immigrationInfo: yup.object().when('immigrationRefugeesCanBefore', {
    is: 'Yes',
    then: yup.object().shape({
      passportNumber: yup.string().required('Passport number is required'),
      countryOfIssue: yup.string().required('Country of issue is required'),
      issueDate: yup.string().required('Issue date is required'),
      expiryDate: yup.string().required('Expiry date is required'),
    }),
  }),
  otherName: yup.string().required('Other name is required'),
  otherNameInfo: yup.object().when('otherName', {
    is: 'Yes',
    then: yup.object().shape({
      otherFirstName: yup.string().required('First name is required'),
      otherMidName: yup.string(),
      otherLastName: yup.string().required('Last name is required'),
    }),
  }),
  pastCitizenshipSixMonth: yup
    .string()
    .required('Past citizenship is required'),
  pastCitizenshipInfo: yup.object().when('pastCitizenshipSixMonth', {
    is: 'Yes',
    then: yup.object().shape({
      liveCountry: yup
        .string()
        .required('Past citizenship country is required'),
      liveStatus: yup.string().required('Past citizenship status is required'),
      liveFromDate: yup
        .string()
        .required('Past citizenship from date is required'),
      liveToDate: yup.string().required('Past citizenship to date is required'),
    }),
  }),
  CommonLawRelationship: yup
    .string()
    .required('Common law relationship is required'),
  relationshipInfo: yup.object().when('CommonLawRelationship', {
    is: 'Yes',
    then: yup.object().shape({
      relationFirstName: yup.string().required('First name is required'),
      relationFamilyName: yup.string().required('Family name is required'),
      relationType: yup.string().required('Relation type is required'),
      relationFromDate: yup.string().required('From date is required'),
      relationToDate: yup.string().required('To date is required'),
    }),
  }),
  isAddress: yup.string(),
  addressInformation: yup.array().when('isAddress', {
    is: 'open',
    then: AddressValidationValidation,
  }),
  memberOfOrganization: yup
    .string()
    .required('Member of organization is required'),
  memberOfInformation: yup.array().when('memberOfOrganization', {
    is: 'Yes',
    then: memberOfOrganizationValidation,
  }),
  tripsOutSideInside: yup.string().required('Trips is required'),
  tripInformation: yup.array().when('tripsOutSideInside', {
    is: 'Yes',
    then: tripValidation,
  }),
  isSpouseOpen: yup.bool(),
  spouseInformation: yup.object().when('isSpouseOpen', {
    is: true,
    then: PersonalSpouseInfoSchema,
  }),
  isChildren: yup.bool(),
  childrenInfo: yup.array().when('isChildren', {
    is: true,
    then: childValidation,
  }),
  isParent: yup.bool(),
  parentInformation: yup.array().when('isParent', {
    is: true,
    then: parentValidation,
  }),
  isSibling: yup.bool(),
  siblingInformation: yup.array().when('isSibling', {
    is: true,
    then: siblingValidation,
  }),
  isSponsor: yup.bool(),
  sponsorInformation: yup.object().when('isSponsor', {
    is: true,
    then: PersonalSponsorSchema,
  }),
  isSponsorChild: yup.bool(),
  sponsorChildrenInfo: yup.array().when('isSponsorChild', {
    is: true,
    then: parentValidation,
  }),
  // isSpouseOpen: yup.bool(),
  // spouseInformation: yup.object().when('isSpouseOpen', {
  //   is: true,
  //   then: PersonalSpouseInfoSchema,
  // }),
})


export const addressValidation = yup.array().of(
  yup.object().shape({
    addrCountry: yup.string().required('Country is required'),
    addrStateDist: yup.string().required('State/Dist is required'),
    addrCityTown: yup.string().required('City/Town is required'),
    addrFullStreet: yup.string().required('Full street is required'),
    addrZipCode: yup.string().required('Zip code is required'),
    addrFromDate: yup.string().required('From date is required'),
    addrToDate: yup.string().required('To date is required'),
  }),
)

export const educationSpouseSchema = yup.object().shape({
  sYearsEducation: yup.string().required('Year of education is required'),
  sHighestLevelEdu: yup
    .string()
    .required('Highest level education is required'),
})
export const educationMainApplicant = yup.object().shape({
  yearsEducation: yup.string().required('Year of education is required'),
  highestLevelEdu: yup.string().required('Highest level education is required'),
  isEducationSpouseOpen: yup.bool(),
  educationSpouseInformation: yup.object().when('isEducationSpouseOpen', {
    is: true,
    then: educationSpouseSchema,
  }),
})
export const addEducationSchema = yup.array().of(
  yup.object().shape({
    fieldStudy: yup.string().required('Field study is required'),
    institution: yup.string().required('Institution is required'),
    countryStudy: yup.string().required('Country education is required'),
    studyDuration: yup.string().required('Study duration is required'),
    completed: yup.string().required('Education completed is required'),
    studyCompletionDetail: yup.string().when('completed', {
      is: 'No',
      then: yup.string().required('Study completion detail is required.'),
    }),
  }),
)

export const addEducationSpouseSchema = yup.array().of(
  yup.object().shape({
    sFieldStudy: yup.string().required('Field study is required'),
    sInstitution: yup.string().required('Institution is required'),
    sCountryStudy: yup.string().required('Country education is required'),
    sStudyDuration: yup.string().required('Study duration is required'),
    sCompleted: yup.string().required('Education completed is required'),
    sStudyCompletionDetail: yup.string().when('sCompleted', {
      is: 'No',
      then: yup.string().required('Study completion detail is required.'),
    }),
  }),
)
export const englishProficiencyTestSchema = yup.object().shape({
  englishDateOfTest: yup.string().required('Date of test is required'),
  englishFinalScore: yup.string().required('Final score is required'),
  englishReadingScore: yup.string().required('Reading score is required'),
  englishWritingScore: yup.string().required('Writing score is required'),
  englishListeningScore: yup.string().required('Listening score is required'),
  englishSpeakingScore: yup.string().required('Speaking score is required'),
})
export const frenchProficiencyTestSchema = yup.object().shape({
  frenchDateOfTest: yup.string().required('Date of test is required'),
  frenchFinalScore: yup.string().required('Final score is required'),
  frenchReadingScore: yup.string().required('Reading score is required'),
  frenchWritingScore: yup.string().required('Writing score is required'),
  frenchListeningScore: yup.string().required('Listening score is required'),
  frenchSpeakingScore: yup.string().required('Speaking score is required'),
})
export const languageExperienceSchema = yup.object().shape({
  levelOfEnglish: yup.string().required('Level of english is required'),
  levelOfFrench: yup.string(),
  englishProficiencyTest: yup.string(),
  englishTest: yup.object().when('englishProficiencyTest', {
    is: 'Yes',
    then: englishProficiencyTestSchema,
  }),
  frenchProficiencyTest: yup.string(),
  frenchTest: yup.object().when('frenchProficiencyTest', {
    is: 'Yes',
    then: frenchProficiencyTestSchema,
  }),
})
export const workExperienceOffshoreSchema = yup.array().of(
  yup.object().shape({
    officialJobTitle: yup.string().required('Official job title is required'),
    jobDescription: yup.string().required('Job description is required'),
    fullTimePosition: yup.string().required('Full time position is required'),
    hoursPerWeek: yup.string().required('Hours week is required'),
    yearsExperience: yup.string().required('Years of experience is required'),
    jobStartDate: yup.string().required('Job start date is required'),
    jobEndTime: yup.string().required('Job end time is required'),
  }),
)
export const workExpeMainSpouseOffshore = yup.array().of(
  yup.object().shape({
    sOfficialJobTitle: yup.string().required('Official job title is required'),
    sJobDescription: yup.string().required('Job description is required'),
    sFullTimePosition: yup.string().required('Full time position is required'),
    sHoursPerWeek: yup.string().required('Hours week is required'),
    sYearsExperience: yup.string().required('Years of experience is required'),
    sJobStartDate: yup.string().required('Job start date is required'),
    sJobEndTime: yup.string().required('Job end time is required'),
  }),
)
export const workExperienceMain = yup.object().shape({
  numberOfJobOffshore: yup
    .string()
    .required('Number of job offshore is required'),
  governMentalPosition: yup.string(),
  govPositionInfo: yup.object().when('governMentalPosition', {
    is: 'Yes',
    then: yup.object().shape({
      positionCountry: yup.string().required('Country is required'),
      jurisdictionLevel: yup
        .string()
        .required('Jurisdiction level is required'),
      departBranch: yup.string().required('Department/Branch is required'),
      activitiesRole: yup.string().required('activities/Role is required'),
      positionFromDate: yup.string().required('From Date is required'),
      positionToDate: yup.string().required('To Date is required'),
    }),
  }),
  militaryExperience: yup.string(),
  militaryInfo: yup.object().when('militaryExperience', {
    is: 'Yes',
    then: yup.object().shape({
      militaryCountry: yup.string().required('Country is required'),
      militaryRank: yup.string().required('Military rank is required'),
      branchUnit: yup.string().required('Branch/Unit number is required'),
      commandingOfficer: yup
        .string()
        .required('Commanding officer name is required'),
      activeCombat: yup.string().required('Active combat is required'),
      militaryFromDate: yup.string().required('From Date is required'),
      militaryToDate: yup.string().required('To Date is required'),
    }),
  }),
  unemploymentPeriod: yup.string(),
  unemploymentInfo: yup.object().when('unemploymentPeriod', {
    is: 'Yes',
    then: yup.object().shape({
      unemploymentCountry: yup.string().required('Country is required'),
      unemploymentCity: yup.string().required('City is required'),
      unemploymentStatus: yup.string().required('Status is required'),
      unemploymentFromDate: yup.string().required('From Date is required'),
      unemploymentToDate: yup.string().required('To Date is required'),
    }),
  }),
  offshoreMainInfo: workExperienceOffshoreSchema,
  isSpouseOpen: yup.bool(),
  sNumberOfJobOffshore: yup.string().when('isSpouseOpen', {
    is: true,
    then: yup.string().required('Study completion detail is required.'),
  }),
  spouseOffshoreInfo: yup.array().when('isSpouseOpen', {
    is: true,
    then: workExpeMainSpouseOffshore,
  }),
})

export const documentSchema = yup.object().shape({
  frontPassportCopy: yup
    .mixed()
    .nullable()
    .required('Front passport copy is required'),
  updatedCv: yup.mixed().nullable().required('Updated CV is required'),
  digitalPhoto: yup.mixed().nullable().required('Digital photo is required'),
  birthCertificate: yup
    .mixed()
    .nullable()
    .required('Birth certificate is required'),
  bankStatement: yup.mixed().nullable().required('Bank statement  is required'),
  paymentReceipts: yup
    .mixed()
    .nullable()
    .required('Payment receipt is required'),
  letterClearanceInfo: yup
    .mixed()
    .nullable()
    .required('Letter clearance info is required'),
  letterAcceptance: yup
    .mixed()
    .nullable()
    .required('Letter acceptance is required'),
  educationalDiploma: yup
    .mixed()
    .nullable()
    .required('Education diploma is required'),
  canadianMedicalExamProof: yup
    .mixed()
    .nullable()
    .required('Canadian medical exam proof is required'),
  visaResident: yup.mixed().nullable().required('Visa residence is required'),
  spouseBirthCertificate: yup
    .mixed()
    .nullable()
    .required('Birth certificate is required'),
  spouseDigitalPhoto: yup
    .mixed()
    .nullable()
    .required('Digital photo is required'),
  spousePassportCopy: yup
    .mixed()
    .nullable()
    .required('Passport copy is required'),
})

export const workExperienceSchema = yup.array().of(
  yup.object().shape({
    yearsEducation: yup.string().required('Year of education is required'),
    highestLevelEdu: yup
      .string()
      .required('Highest level education is required'),
    fieldStudy: yup.string().required('Field study is required'),
    institution: yup.string().required('Institution is required'),
    countryStudy: yup.string().required('Country education is required'),
    studyDuration: yup.string().required('Study duration is required'),
    completed: yup.string().required('Education completed is required'),
    studyCompletionDetail: yup.string().when('completed', {
      is: 'No',
      then: yup.string().required('Study completion detail is required.'),
    }),
  }),
)
export const sponsoringWorkExperience = yup.object().shape({
  sponsoringNumberOfJobOffshore: yup
    .string()
    .required('Number of job offshore is required'),
  isSponsoringSpouseOpen: yup.bool(),
  sponsoringSpouseNumberOfJobOffshore: yup
    .string()
    .when('isSponsoringSpouseOpen', {
      is: true,
      then: yup.string().required('Number of job offshore required'),
    }),
})

export const workExpeSponsoringSpouseOffshore = yup.array().of(
  yup.object().shape({
    sOfficialJobTitle: yup.string().required('Official job title is required'),
    sJobDescription: yup.string().required('Job description is required'),
    sFullTimePosition: yup.string().required('Full time position is required'),
    sHoursPerWeek: yup.string().required('Hours week is required'),
    sYearsExperience: yup.string().required('Years of experience is required'),
    sJobStartDate: yup.string().required('Job start date is required'),
    sJobEndTime: yup.string().required('Job end time is required'),
  }),
)

export const workExpeMainOffshore = yup.array().of(
  yup.object().shape({
    officialJobTitle: yup.string().required('Official job title is required'),
    jobDescription: yup.string().required('Job description is required'),
    fullTimePosition: yup.string().required('Full time position is required'),
    hoursPerWeek: yup.string().required('Hours week is required'),
    yearsExperience: yup.string().required('Years of experience is required'),
    jobStartDate: yup.string().required('Job start date is required'),
    jobEndTime: yup.string().required('Job end time is required'),
  }),
)
export const workExperiencesOutCanada = yup.array().of(
  yup.object().shape({
    InCanOfficialJobTitle: yup
      .string()
      .required('Official job title is required'),
    InCanJobDescription: yup.string().required('Job description is required'),
    InCanFullTimePosition: yup
      .string()
      .required('Full time position is required'),
    InCanHoursPerWeek: yup.string().required('Hours week is required'),
    InCanYearsExperience: yup
      .string()
      .required('Year of education is required'),
    InCanJobStartDate: yup.string().required('Job start date is required'),
    InCanJobEndTime: yup.string().required('Job end time required'),
  }),
)
export const invoiceZipReport = yup.object().shape({
  month: yup.string().required('From Date is required'),
  year: yup.string().required('To date is required'),
  type: yup.string().required('Report type is required'),
})
export const invoiceReportSchema = yup.object().shape({
  fromDate: yup.string().required('From Date is required'),
  toDate: yup.string().required('To date is required'),
  type: yup.string().required('Report type is required'),
  companyId: yup.string().required('Company is required'),
  paymentType: yup.string(),
  reportTypes: yup.bool(),
  reportZip: yup.object().when('reportTypes', {
    is: true,
    then: invoiceZipReport,
  }),
})
export const visaEvaluationSchema = yup.object({
  age: yup.string().required('age is required'),
  workExperience: yup.string().required('Work experience is required'),
  academic: yup.string().required('Academic is required'),
  langWrite: yup
    .number()
    .min(1, 'Language write skills is required')
    .required('Language write skills is required'),
  langRead: yup
    .number()
    .min(1, 'Language read skills is required')
    .required('Language read skills is required'),
  langSpeak: yup
    .number()
    .min(1, 'Language speak skills is required')
    .required('Language speak skills is required'),
  langListen: yup
    .number()
    .min(1, 'Language listening skills is required')
    .required('Language listening skills is required'),
})
export const AddUserSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup
    .string()
    .email('Please enter valid email')
    .required('Email is required'),
  password: yup.string().required('Password is required'),
  phone: yup.string().required('Phone number is required'),
  country: yup.string().required('Country name is required'),
})
export const evaluationPersonalSpouseInfoSchema = yup.object().shape({
  sFirstName: yup.string().required('First Name is required'),
  sMidName: yup.string(),
  sLastName: yup.string().required('Last Name is required'),
  sAge: yup.number(),
  sPhoneNumber: yup.string().required('Phone number is required'),
  sMobile: yup.string(),
  sOfficePhoneNumber: yup.string(),
  sGender: yup.string().required('Gender is required'),

  sPrimaryEmail: yup
    .string()
    .required(
      'Please enter an email address in the format username@domain.com',
    ),
  sSecondaryEmail: yup.string(),
  sDateOfBirth: yup.string().required('Date of birth is required').nullable(),
  sBirthCountry: yup.string().required('Birth country is required'),
  sCity: yup.string().required('City is required'),
  sStreet: yup.string().required('Street is required'),
  sCountryCitizenship: yup.string().required('Country citizenship is required'),
  sCountryResidence: yup.string().required('Country residence is required'),
  sZipCode: yup.string().required('Zip code is required'),
  sMaritalStatus: yup.string().required('Marital status is required'),
  sDependentChildren: yup.string(),
  sCriminalOffense: yup.string().required('Police Record is required'),
  sOffenseTypeDetail: yup.string().when('sCriminalOffense', {
    is: 'Yes',
    then: yup.string().required('Offense type detail is required'),
  }),
  sHealthCondition: yup.string().required('Health condition is required'),
  sHealthConditionDetail: yup.string().when('sHealthCondition', {
    is: 'Yes',
    then: yup.string().required('Health condition detail is required'),
  }),
})
const evaluationChildValidation = yup.array().of(
  yup.object().shape({
    childFirstName: yup.string().required('First Name is required'),
    childMiddleName: yup.string(),
    childLastName: yup.string().required('Last Name is required'),
    childGender: yup.string().required('Gender is required'),
    childDateOfBirth: yup.string().required('Date of birth is required'),
    childBirthCountry: yup.string().required('Birth country is required'),
    childBirthCity: yup.string().required('Birth city is required'),
    childCitizenship: yup.string().required('Citizenship is required'),
    childSecondCitizenship: yup.string(),
  }),
)
export const evaluationPersonalInfoSchema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  midName: yup.string(),
  lName: yup.string().required('Last Name is required'),
  age: yup.number(),
  phoneNumber: yup.string().required('Phone number is required'),
  mobile: yup.string(),
  officePhoneNumber: yup.string(),

  gender: yup.string().required('Gender is required'),

  primaryEmail: yup
    .string()
    .email('Please enter valid email')
    .required(
      'Please enter an email address in the format username@domain.com',
    ),
  secondaryEmail: yup.string(),
  dateOfBirth: yup.string().required('Date of birth is required'),
  birthCountry: yup.string().required('Birth country is required'),
  city: yup.string().required('City is required'),
  street: yup.string().required('Street is required'),
  countryCitizenship: yup.string().required('Country citizenship is required'),
  countryResidence: yup.string().required('Country residence is required'),
  zipCode: yup.string().required('Zip code is required'),
  maritalStatus: yup.string().required('Marital status is required'),
  dependentChildren: yup.string(),
  criminalOffense: yup.string().required('Police Record is required'),
  offenseTypeDetail: yup.string().when('criminalOffense', {
    is: 'Yes',
    then: yup.string().required('Offense type detail is required'),
  }),
  healthCondition: yup.string().required('Health condition is required'),
  healthConditionDetail: yup.string().when('healthCondition', {
    is: 'Yes',
    then: yup.string().required('Health condition detail is required'),
  }),
  isSpouseOpen: yup.bool(),
  spouseInformation: yup.object().when('isSpouseOpen', {
    is: true,
    then: evaluationPersonalSpouseInfoSchema,
  }),
  isChildren: yup.bool(),
  childrenInfo: yup.array().when('isChildren', {
    is: true,
    then: evaluationChildValidation,
  }),
})

export const evaluationEducation = yup.array().of(
  yup.object().shape({
    fieldStudy: yup.string().required('Field study  is required'),
    institution: yup.string().required('Institution is required'),
    studyDuration: yup.string().required('Study duration is required'),
    countryStudy: yup.string().required('Country study is required'),
    completed: yup.string().required('Completed is required'),
    studyCompletionDetail: yup.string().when('completed', {
      is: 'No',
      then: yup.string().required('Study completion detail is required'),
    }),
  }),
)
export const evaluationEducationMain = yup.object().shape({
  yearsEducation: yup.string().required('Year of education is required'),
  highestLevelEdu: yup.string().required('Highest level education is required'),
  educationMainInfo: evaluationEducation,
  isEducationSpouseOpen: yup.bool(),
  educationSpouseInformation: yup.object().when('isEducationSpouseOpen', {
    is: true,
    then: yup.object().shape({
      sYearsEducation: yup.string().required('Year of education is required'),
      sHighestLevelEdu: yup
        .string()
        .required('Highest level education is required'),
    }),
  }),
  educationSpouseInfo: yup.array().when('isEducationSpouseOpen', {
    is: true,
    then: addEducationSpouseSchema,
  }),
})
export const workExpeMainOffshoreValid = yup.array().of(
  yup.object().shape({
    officialJobTitle: yup.string().required('Official job title is required'),
    jobDescription: yup.string().required('Job description is required'),
    fullTimePosition: yup.string().required('Full time position is required'),
    hoursPerWeek: yup.string().required('Hours week is required'),
    yearsExperience: yup.string().required('Years of experience is required'),
    jobStartDate: yup.string().required('Job start date is required'),
    jobEndTime: yup.string().required('Job end time is required'),
    monthlyIncome: yup.string().required('Monthly income is required'),
    moneyBringToCanada: yup
      .string()
      .required('Money bring to canada is required'),
  }),
)
export const workExpeEvaluationSpouseOffshore = yup.array().of(
  yup.object().shape({
    sOfficialJobTitle: yup.string().required('Official job title is required'),
    sJobDescription: yup.string().required('Job description is required'),
    sFullTimePosition: yup.string().required('Full time position is required'),
    sHoursPerWeek: yup.string().required('Hours week is required'),
    sYearsExperience: yup.string().required('Years of experience is required'),
    sJobStartDate: yup.string().required('Job start date is required'),
    sJobEndTime: yup.string().required('Job end time is required'),
    sMonthlyIncome: yup.string().required('Monthly income is required'),
    sMoneyBringToCanada: yup
      .string()
      .required('Money bring to canada is required'),
  }),
)
export const workExperienceMainValid = yup.object().shape({
  numberOfJobOffshore: yup.string().required('Number of jobs is required'),
  workExpOffshoreInfo: workExpeMainOffshoreValid,
  isSpouseOpen: yup.bool(),
  sNumberOfJobOffshore: yup.string().when('isSpouseOpen', {
    is: true,
    then: yup.string().required('Number of jobs is required'),
  }),
  workOffshoreSpouseInfo: yup.array().when('isSpouseOpen', {
    is: true,
    then: workExpeEvaluationSpouseOffshore,
  }),
})
export const evaluationLanguageSchema = yup.object().shape({
  levelOfEnglish: yup.string().required('Level of english is required'),
  levelOfFrench: yup.string(),
  englishProficiencyTest: yup.string(),
  englishTest: yup.object().when('englishProficiencyTest', {
    is: 'Yes',
    then: yup.object().shape({
      englishDateOfTest: yup.string().required('Date of exam is required'),
      englishFinalScore: yup.string().required('Exam score is required'),
    }),
  }),
  frenchProficiencyTest: yup.string(),
  frenchTest: yup.object().when('frenchProficiencyTest', {
    is: 'Yes',
    then: yup.object().shape({
      frenchDateOfTest: yup.string().required('Date of exam is required'),
      frenchFinalScore: yup.string().required('Exam score is required'),
    }),
  }),
})
export const leadFormSchema = yup.object({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  age: yup.string().required("Age is required"),
  phoneNumber: yup.number().required("Phone is required"),
  email: yup
    .string()
    .email("Please enter valid email")
    .required("Email is required"),
    // country: yup.string().required("Country is required"),
    Passport_Holder_score__c: yup.string().required("Passport country is required"),
});
export const refundInvoiceSchema = yup.object().shape({
  paymentMethod: yup.string().required('Invoice type is required'),
  amount: yup.string().required('Amount is required').matches(
    /^[0-9]*[.]?[0-9]{0,2}$/,
    "Amount not in minus"
  ),
  isInstallment:yup.string().required('Installment is required'),
  paymentType:yup.string().required('Payment method is required'),
})
export const purchaseProduct = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup
    .string()
    .email('Please enter valid email')
    .required('Email is required'),
  phone: yup.string().required('Phone number is required'),
  providerId: yup.string().required('Please select payment'),
})
export const travelPurchaseProduct = yup.object().shape({
  firstName: yup.string().max(15,"First name cannot be longer then 15 characters").required('First name is required'),
  lastName: yup.string().max(15,"Last name cannot be longer then 15 characters").required('Last name is required'),
  email: yup
    .string()
    .email('Please enter valid email')
    .required('Email is required'),
  phone: yup.string().required('Phone number is required'),
  billingPhone: yup.string().required('Phone number is required'),
  address: yup.string().required('Address is required'),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
  //providerId: yup.string().required('Please select payment method'),
})
