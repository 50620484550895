import React from 'react'
import { Button } from 'react-bootstrap'
import cancel from '../../assets/images/visa/cancel.svg'
const AknowledgePopup = ({
  setAcknowledgeState,
  activeTabKey,
  setActiveEditable,
  editableTab,
  texts,
  handleUnlockFormForClient,
}) => {
  return (
    <div className="documents-popup">
      <div className="popup-content">
        <p>
          <span>Important!</span> {texts} {activeTabKey}.
        </p>
        <img
          src={cancel}
          alt="cancel"
          onClick={() => {
            setActiveEditable('')
            setAcknowledgeState(false)(
              texts === 'Are you sure you want to unlock for client'
                ? setActiveEditable(editableTab)
                : '',
            )
          }}
        />
      </div>
      <div className="visa-btn">
        <Button
          onClick={() => {
            setActiveEditable(editableTab)
            setAcknowledgeState(false)(
              texts === 'Are you sure you want to unlock for client'
                ? handleUnlockFormForClient(editableTab)
                : '',
            )
          }}
        >
          Ok
        </Button>
      </div>
    </div>
  )
}
export default AknowledgePopup
