import React from 'react'
import { Button } from 'react-bootstrap'
import './pricing.css'
import americanExpress from '../../assets/images/pricing/americanExpress.svg'
import dinersClub from '../../assets/images/pricing/dinersClub.svg'
import masterCard from '../../assets/images/pricing/masterCard.svg'
import SSL from '../../assets/images/pricing/ssl-certificates_banner.png'
import visa from '../../assets/images/pricing/visa.svg'

const PricingBox = ({
  icon,
  product,
  mainPrice,
  discPrice,
  popular,
  onClick,
  data,
}) => {
  return (
    <div className={`pricing-box-sections ${popular ? 'popular' : ''}`}>
      {popular && <p className="popularTxt">Most Popular</p>}
      <div className="pricing-box-inner">
        <div className="pricing-box-icon">
          <img src={icon} alt="icon" />
          <h3>{product}</h3>
        </div>
        <div className="pricing-box-rate-section">
          <h4 className="main-price-rate">
            ${mainPrice}
            {discPrice && <div className="discount-line"></div>}
          </h4>
          <p>One-time fee</p>
          {discPrice && <h4 className="discount-rate">${discPrice}</h4>}
        </div>
        <div className="pricing-btn-section">
          <Button
            className={popular ? 'outline-button' : 'orange-button'}
            onClick={onClick}
          >
            Select
          </Button>
        </div>
        <ul className="list-section">
          {data.map((ele) => {
            return (
              <li>
                {' '}
                <i class="las la-check"></i> {ele}
              </li>
            )
          })}
        </ul>
        <div className="payment-icon-section">
          <div>
            <img src={visa} style={{ height: 12 }} alt="icon" />
          </div>
          <div>
            <img src={masterCard} style={{ height: 15 }} alt="icon" />
          </div>
          <div>
            <img src={SSL} alt="icon" />
          </div>
          <div>
            <img src={dinersClub} alt="icon" />
          </div>
          <div>
            <img src={americanExpress} alt="icon" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PricingBox
