import React from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import PhoneInput from 'react-phone-input-2'
import { serverPath } from '../../config/key'
import { useTranslation } from 'react-i18next'
import checkicon from '../../assets/images/header/success.svg'
import icon1 from '../../assets/images/header/canada.svg'
import facebook from '../../assets/images/header/fb.svg'
import linkedin from '../../assets/images/header/linkedin.svg'
import twitter from '../../assets/images/header/twitter.svg'
import { Form, Button, Spinner } from 'react-bootstrap'
import './modal.css'
import { ConsultForm } from '../../utils/validationSchema'
import { useContext } from 'react'
import UserContext from '../../context/UserContext'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {phone} from 'phone'

const ConsultationPopup = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [succ, setSucc] = useState(false)
  const [loading,setLoading]=useState(false)
  const { userGeoData } = useContext(UserContext)
  const [phoneCountryCode,setPhoneCountryCode]=useState('')
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    setError,
    watch
  } = useForm({ resolver: yupResolver(ConsultForm) })
  const termsCheck=watch("terms")
  const handleChange = (e,country) => {
    setPhoneCountryCode(country?.countryCode)
      setValue('phoneNumber', '+' + e)
      setError('phoneNumber',{message:""})
  }

  const handleSubmitForm = async (values) => {
    try {
      const validate=phone(values.phoneNumber, {country: phoneCountryCode || userGeoData.country_code});
      if(!validate.isValid){
        setError('phoneNumber',{message:"Your phone number is Invalid"})
        return
      } 
     
      setLoading(true)
      const response = await axios.post(`${serverPath}/forms/consult`, {
        ...values,
        ip: userGeoData.ip,
        countryCode: userGeoData.country_code,
      })
      setLoading(false)
      const {
        data: { msg, redirect },
      } = response
      if (response?.data?.success) {
        setSucc(true)
        toast.success(msg, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
        if (redirect) navigate(redirect)
      }
    } catch (error) {
      setLoading(false)
      if (error?.response?.data) {
        toast.error(error.response.data.msg, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
      }
    }
  }
  return (
    <div className="consultation-popup-grid">
      {succ ?
          (
        <div className="consultation-popup-content">
          <div className="thank-you-grid">
            <div className="thank-you-content">
              <img src={icon1} alt="" />
              <h2>{t('consultationPopup.thankyouheading')}</h2>
              <p>{t('consultationPopup.thankyoutext')}</p>
            </div>

            <div className="thank-you-social">
              <p>{t('consultationPopup.connecttext')}</p>
              <ul>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/uis.canada"
                  >
                    <img src={facebook} alt="" />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkedin.com/company/uis-canada-official/"
                  >
                    <img src={linkedin} alt="" />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://twitter.com/uis_canada"
                  >
                    <img src={twitter} alt="" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ) : (
        <div className="consultation-popup-content">
          <h2>{t('consultationPopup.Heading1')}</h2>
          <p>{t('consultationPopup.Paragraph1')}</p>

          <Form onSubmit={handleSubmit(handleSubmitForm)}>
            <Form.Group>
              <Form.Control
                name="firstName"
                type="text"
                placeholder={t('visaContact.firstname')}
                {...register('firstName')}
              />
              {errors?.firstName ? (
                <span className="error-msg">{errors.firstName.message}</span>
              ) : (
                ''
              )}
            </Form.Group>
            
            <Form.Group>
              <Form.Control
                name="lastName"
                type="text"
                placeholder={t('visaContact.lastname')}
                {...register('lastName')}
              />
              {errors?.lastName ? (
                <span className="error-msg">{errors.lastName.message}</span>
              ) : (
                ''
              )}
            </Form.Group>

            <Form.Group>
              <Form.Control
                name="email"
                type="email"
                placeholder={t('visaContact.emailaddress')}
                {...register('email')}
              />
              {errors?.email ? (
                <span className="error-msg">{errors.email.message}</span>
              ) : (
                ''
              )}
            </Form.Group>

            <Form.Group>
              <PhoneInput
                country={userGeoData?.country_code?.toLowerCase()}
                onChange={(e,country)=>handleChange(e,country)}
                inputProps={{
                  name: 'phoneNumber',
                }}
              />

              {errors?.phoneNumber ? (
                <span className="error-msg">{errors.phoneNumber.message}</span>
              ) : (
                ''
              )}
            </Form.Group>

            <Button
              variant="primary"
              type="submit"
              className={`${succ ? `success-btn` : ``}`}
              disabled={(termsCheck ===undefined && !loading)|| (termsCheck && !loading)?false:loading?loading:true}
            >
              {loading && (
                      <Spinner
                        as="span"
                        variant="light"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        animation="border"
                      />
                    )}
                    &nbsp;
             {succ
                ? t('homeNewsletter.successButton')
                : t('consultationPopup.submit')}
              {succ ? <img src={checkicon} alt="" /> : ''}
            </Button>
            <Form.Group controlId="formBasicCheckbox" className="term-link">
              <Form.Check
                type="checkbox"
                name="terms"
                defaultChecked={true}
                //label=""
                {...register("terms")}
              />
              <span>By Clicking On 'Submit', You Are Agreeing To Our <Link to='/termconditions' target="_blank" rel="noopener noreferrer">T&C</Link> And <Link to='/privacypolicy'  target="_blank" rel="noopener noreferrer">Privacy Policy</Link>.</span>
            </Form.Group>
          </Form>
        </div>
      )}

      <div className="consultation-popup-cover"></div>
    </div>
  )
}

export default ConsultationPopup
