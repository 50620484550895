/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useContext } from 'react'
import { Button, Form } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import PhoneInput from 'react-phone-input-2'
import UserContext from '../../context/UserContext'

const MainAccordion = ({
  errors,
  mainApplicanDis,
  personalInformation,
  register,
  changeDatePicker,
  setValue,
  watch,
  expiryDate,
  issueDate,
  lastVisitDate,
  dateOfBirth,
  liveToDate,
  liveFromDate,
  relationFromDate,
  relationToDate,
  addressDetail,
  setAddressDetail,
  intialAddressState,
  organizationDetail,
  setOrganizationDetail,
  intialOrganizationState,
  intialTripState,
  setTripDetail,
  tripsDetail,
}) => {
  const { userGeoData, countries } = useContext(UserContext)

  const watchShowHealthDetail = watch('healthCondition')
  const criminalOffenses = watch('criminalOffense')
  const watchShowCanadaBefore = watch('canadaBefore')
  const watchImmigrationRefugeesCanadaBefore = watch(
    'immigrationRefugeesCanBefore',
  )
  const watchOtherName = watch('otherName')
  const watchPastCitizenshipSixMonth = watch('pastCitizenshipSixMonth')
  const watchCommonLawRelationship = watch('CommonLawRelationship')
  const watchMemberOfOrganization = watch('memberOfOrganization')
  const watchTripsOutSideInside = watch('tripsOutSideInside')

  useEffect(() => {
    if (watchMemberOfOrganization === 'Yes') {
      setValue('memberOfInformation', [{ ...intialOrganizationState }])
    }
  }, [watchMemberOfOrganization])
  useEffect(() => {
    if (watchTripsOutSideInside === 'Yes') {
      setValue('tripInformation', [{ ...intialTripState }])
    }
  }, [watchTripsOutSideInside])
  const handleAddress = () => {
    setValue('isAddress', 'open')
    setValue('addressInformation', [
      ...addressDetail,
      { ...intialAddressState },
    ])
    setAddressDetail([...addressDetail, { ...intialAddressState }])
  }
  const handleAddOrganization = () => {
    setValue('memberOfInformation', [
      ...organizationDetail,
      { ...intialOrganizationState },
    ])
    setOrganizationDetail([
      ...organizationDetail,
      { ...intialOrganizationState },
    ])
  }
  const handleAddTrips = () => {
    setValue('tripInformation', [...tripsDetail, { ...intialTripState }])
    setTripDetail([...tripsDetail, { ...intialTripState }])
  }
  const handleAddressChange = (e, index) => {
    const { name, value } = e.target
    let prevData = [...addressDetail]
    prevData[index][name] = value
    setAddressDetail([...prevData])
    setValue('addressInformation', [...prevData])
  }
  const removeAddress = (index) => {
    const oldAddress = [...addressDetail]
    oldAddress.splice(index, 1)
    setAddressDetail(oldAddress)
    setValue('addressInformation', oldAddress)
  }
  const handleOrganizationChange = (e, index) => {
    const { name, value } = e.target
    let prevData = [...organizationDetail]
    prevData[index][name] = value
    setOrganizationDetail([...prevData])
    setValue('memberOfInformation', [...prevData])
  }
  const handleTripsChange = (e, index) => {
    const { name, value } = e.target
    let prevData = [...tripsDetail]
    prevData[index][name] = value
    setTripDetail([...prevData])
    setValue('tripInformation', [...prevData])
  }
  const removeTrips = (index) => {
    const oldTrips = [...tripsDetail]
    oldTrips.splice(index, 1)
    setTripDetail(oldTrips)
    setValue('tripInformation', oldTrips)
  }

  const removeOrganization = (index) => {
    const oldOrganization = [...organizationDetail]
    oldOrganization.splice(index, 1)
    setOrganizationDetail(oldOrganization)
    setValue('memberOfInformation', oldOrganization)
  }
  const handleAddressPicker = (date, type, index) => {
    let prevData = [...addressDetail]
    prevData[index][type] = date
    setAddressDetail([...prevData])
    setValue('addressInformation', [...prevData])
  }
  const handleOrganizationPicker = (date, type, index) => {
    let prevData = [...organizationDetail]
    prevData[index][type] = date
    setOrganizationDetail([...prevData])
    setValue('memberOfInformation', [...prevData])
  }
  const handleTripsPicker = (date, type, index) => {
    let prevData = [...tripsDetail]
    prevData[index][type] = date
    setTripDetail([...prevData])
    setValue('tripInformation', [...prevData])
  }

  const getCountry=(personalInformation)=>{
   return personalInformation?.mainApplicant?.secondCitizenship
    ? personalInformation?.mainApplicant?.secondCitizenship
    : personalInformation?.sponsoredInformation?.secondCitizenship
    ? personalInformation?.sponsoredInformation?.secondCitizenship
    : ""
  }
  return (
    <>
      <div className="box-grid-three">
        <Form.Group
          className={`form-group ${
            errors?.firstName?.message ? 'error-msg' : ''
          }`}
          controlId="formBasicEmail"
        >
          <Form.Label>First Name*</Form.Label>
          <Form.Control
            type="text"
            name="firstName"
            disabled={mainApplicanDis ? mainApplicanDis : false}
            defaultValue={
              personalInformation?.mainApplicant?.firstName
                ? personalInformation?.mainApplicant?.firstName
                : personalInformation?.sponsoredInformation?.firstName
                ? personalInformation?.sponsoredInformation?.firstName
                : ''
            }
            {...register('firstName')}
          />

          {errors?.firstName ? (
            <span className="error-msg">{errors?.firstName?.message}</span>
          ) : (
            ''
          )}
        </Form.Group>
        {/* <Work control={control} /> */}
        <Form.Group className={`form-group`} controlId="formBasicEmail">
          <Form.Label>Middle Name</Form.Label>
          <Form.Control
            type="text"
            name="midName"
            disabled={mainApplicanDis ? mainApplicanDis : false}
            defaultValue={
              personalInformation?.mainApplicant?.middleName
                ? personalInformation?.mainApplicant?.middleName
                : personalInformation?.sponsoredInformation?.middleName
                ? personalInformation?.sponsoredInformation?.middleName
                : ''
            }
            {...register('midName')}
          />
          {errors?.midName ? (
            <span className="error-msg">{errors?.midName?.message}</span>
          ) : (
            ''
          )}
        </Form.Group>
        <Form.Group
          className={`form-group ${errors?.lName?.message ? 'error-msg' : ''}`}
          controlId="formBasicEmail"
        >
          <Form.Label>Last Name*</Form.Label>
          <Form.Control
            type="text"
            name="lName"
            disabled={mainApplicanDis ? mainApplicanDis : false}
            defaultValue={
              personalInformation?.mainApplicant?.lastName
                ? personalInformation?.mainApplicant?.lastName
                : personalInformation?.sponsoredInformation?.lastName
                ? personalInformation?.sponsoredInformation?.lastName
                : ''
            }
            {...register('lName')}
          />
          {errors?.lName ? (
            <span className="error-msg">{errors?.lName?.message}</span>
          ) : (
            ''
          )}
        </Form.Group>
      </div>
      <div className="box-grid-three">
        <Form.Group
          className={`form-group ${errors?.gender?.message ? 'error-msg' : ''}`}
          controlId="formBasicEmail"
        >
          <Form.Label>Gender*</Form.Label>
          <Form.Select
            aria-label="Default select example"
            name="gender"
            disabled={mainApplicanDis ? mainApplicanDis : false}
           
            defaultValue={
              personalInformation?.mainApplicant
                ? personalInformation?.mainApplicant?.gender
                : personalInformation?.sponsoredInformation?.gender
                ? personalInformation?.sponsoredInformation?.gender
                : ''
            }
            {...register('gender')}
          >
            <option value="" disabled>Select
            </option>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </Form.Select>
          {errors?.gender ? (
            <span className="error-msg">{errors?.gender?.message}</span>
          ) : (
            ''
          )}
        </Form.Group>

        <Form.Group
          className={`form-group ${errors?.birthCountry ? 'error-msg' : ''}`}
          controlId="formBasicEmail"
        >
          <Form.Label>Birth Country*</Form.Label>
          <Form.Select
            name="birthCountry"
            disabled={mainApplicanDis ? mainApplicanDis : false}
            defaultValue={
              personalInformation?.mainApplicant?.birthCountry
                ? personalInformation?.mainApplicant?.birthCountry
                : personalInformation?.sponsoredInformation?.birthCountry
                ? personalInformation?.sponsoredInformation?.birthCountry
                : userGeoData?.country_name
            }
            {...register('birthCountry')}
          >
            <option value={userGeoData?.country_name}>
              {personalInformation?.mainApplicant?.birthCountry
                ? personalInformation?.mainApplicant?.birthCountry
                : personalInformation?.sponsoredInformation?.birthCountry
                ? personalInformation?.sponsoredInformation?.birthCountry
                : userGeoData?.country_name}
            </option>
            {countries &&
              countries?.length > 0 &&
              countries.map((el) => (
                <option value={el?.name}>{el?.name}</option>
              ))}
          </Form.Select>
          {errors?.birthCountry ? (
            <span className="error-msg">{errors?.birthCountry?.message}</span>
          ) : (
            ''
          )}
        </Form.Group>

        <Form.Group
          className={`form-group ${errors?.dateOfBirth ? 'error-msg' : ''}`}
          controlId="formBasicEmail"
        >
          <Form.Label>Date of Birth*</Form.Label>
          <DatePicker
            type="dateOfBirth"
            name="dateOfBirth"
            placeholderText="Select Date"
            disabled={mainApplicanDis ? mainApplicanDis : false}
            selected={dateOfBirth}
            onChange={(date) => changeDatePicker(date, 'dateOfBirth', 'age')}
            className="form-select"
            dateFormat="dd-MM-yyyy"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
          {errors?.dateOfBirth ? (
            <span className="error-msg">{errors?.dateOfBirth?.message}</span>
          ) : (
            ''
          )}
        </Form.Group>
      </div>
      <div className="box-grid-three">
        <Form.Group
          className={`form-group ${errors?.phoneNumber ? 'error-msg' : ''}`}
          controlId="formBasicEmail"
        >
          <Form.Label>Phone*</Form.Label>
          {/* <Form.Control type="email" placeholder="" /> */}

          <Form.Group>
            <PhoneInput
              country={
                personalInformation &&
                personalInformation?.mainApplicant?.phoneNumber
                  ? ''
                  : personalInformation?.sponsoredInformation?.phoneNumber
                  ? ''
                  : userGeoData?.country_code?.toLowerCase()
              }
              enableAreaCodes={false}
              disabled={mainApplicanDis ? mainApplicanDis : false}
              value={
                personalInformation &&
                personalInformation?.mainApplicant?.phoneNumber
                  ? personalInformation?.mainApplicant?.phoneNumber?.substring(
                      1,
                    )
                  : personalInformation?.sponsoredInformation?.phoneNumber
                  ? personalInformation?.sponsoredInformation?.phoneNumber?.substring(
                      1,
                    )
                  : ''
              }
              onChange={(e) => setValue('phoneNumber', '+' + e)}
              inputProps={{
                name: 'phoneNumber',
              }}
            />
            {errors?.phoneNumber ? (
              <span className="error-msg">{errors?.phoneNumber?.message}</span>
            ) : (
              ''
            )}
          </Form.Group>
        </Form.Group>
        <Form.Group className={`form-group`} controlId="formBasicEmail">
          <Form.Label>Mobile</Form.Label>
          <PhoneInput
            country={
              personalInformation && personalInformation?.mainApplicant?.mobile
                ? ''
                : personalInformation?.sponsoredInformation?.mobile
                ? ''
                : userGeoData?.country_code?.toLowerCase()
            }
            disabled={mainApplicanDis ? mainApplicanDis : false}
            value={
              personalInformation?.mainApplicant?.mobile
                ? personalInformation?.mainApplicant?.mobile?.substring(1)
                : personalInformation?.sponsoredInformation?.mobile
                ? personalInformation?.sponsoredInformation?.mobile?.substring(
                    1,
                  )
                : ''
            }
            onChange={(e) => {
              setValue('mobile', '+' + e)
            }}
            inputProps={{
              name: 'mobile',
            }}
          />
          {errors?.mobile ? (
            <span className="error-msg">{errors?.mobile?.message}</span>
          ) : (
            ''
          )}
        </Form.Group>
        <Form.Group className={`form-group`} controlId="formBasicEmail">
          <Form.Label>Office</Form.Label>
          <PhoneInput
            country={
              personalInformation &&
              personalInformation?.mainApplicant?.officePhoneNumber
                ? ''
                : personalInformation?.sponsoredInformation?.officePhoneNumber
                ? ''
                : userGeoData?.country_code?.toLowerCase()
            }
            disabled={mainApplicanDis ? mainApplicanDis : false}
            value={
              personalInformation?.mainApplicant?.officePhoneNumber
                ? personalInformation?.mainApplicant?.officePhoneNumber?.substring(
                    1,
                  )
                : personalInformation?.sponsoredInformation?.officePhoneNumber
                ? personalInformation?.sponsoredInformation?.officePhoneNumber?.substring(
                    1,
                  )
                : ''
            }
            onChange={(e) => setValue('officePhoneNumber', '+' + e)}
            inputProps={{
              name: 'officePhoneNumber',
            }}
          />
        </Form.Group>
      </div>
      <div className="box-grid-two">
        <Form.Group
          className={`form-group ${errors?.primaryEmail ? 'error-msg' : ''}`}
          controlId="formBasicEmail"
        >
          <Form.Label>Primary Email*</Form.Label>
          <Form.Control
            type="email"
            name="primaryEmail"
            disabled={mainApplicanDis ? mainApplicanDis : false}
            defaultValue={
              personalInformation?.mainApplicant?.primaryEmail
                ? personalInformation?.mainApplicant?.primaryEmail
                : personalInformation?.sponsoredInformation?.primaryEmail
                ? personalInformation?.sponsoredInformation?.primaryEmail
                : ''
            }
            {...register('primaryEmail')}
          />

          {errors?.primaryEmail ? (
            <span className="error-msg">
              <i class="las la-exclamation-triangle"></i>
              {errors?.primaryEmail?.message}
            </span>
          ) : (
            ''
          )}
        </Form.Group>

        <Form.Group className={`form-group`} controlId="formBasicEmail">
          <Form.Label>Secondary Email</Form.Label>
          <Form.Control
            type="email"
            name="secondaryEmail"
            disabled={mainApplicanDis ? mainApplicanDis : false}
            defaultValue={
              personalInformation?.mainApplicant?.secondaryEmail
                ? personalInformation?.mainApplicant?.secondaryEmail
                : personalInformation?.sponsoredInformation?.secondaryEmail
                ? personalInformation?.sponsoredInformation?.secondaryEmail
                : ''
            }
            {...register('secondaryEmail')}
          />
          {errors?.secondaryEmail ? (
            <span className="error-msg">{errors?.secondaryEmail?.message}</span>
          ) : (
            ''
          )}
        </Form.Group>
      </div>
      <div className="box-grid-three">
        <Form.Group
          className={`form-group ${
            errors?.countryCitizenship ? 'error-msg' : ''
          }`}
          controlId="formBasicEmail"
        >
          <Form.Label>Country of Citizenship*</Form.Label>

          <Form.Select
            disabled={mainApplicanDis ? mainApplicanDis : false}
            name="countryCitizenship"
            defaultValue={
              personalInformation?.mainApplicant?.countryCitizenship
                ? personalInformation?.mainApplicant?.countryCitizenship
                : personalInformation?.sponsoredInformation?.countryCitizenship
                ? personalInformation?.sponsoredInformation?.countryCitizenship
                : userGeoData?.country_name
            }
            {...register('countryCitizenship')}
          >
            <option value={userGeoData?.country_name}>
              {personalInformation?.mainApplicant?.countryCitizenship
                ? personalInformation?.mainApplicant?.countryCitizenship
                : personalInformation?.sponsoredInformation?.countryCitizenship
                ? personalInformation?.sponsoredInformation?.countryCitizenship
                : userGeoData?.country_name}
            </option>
            {countries &&
              countries?.length > 0 &&
              countries.map((el) => (
                <option value={el?.name}>{el?.name}</option>
              ))}
          </Form.Select>
          {errors?.countryCitizenship ? (
            <span className="error-msg">
              {errors?.countryCitizenship?.message}
            </span>
          ) : (
            ''
          )}
        </Form.Group>
        <Form.Group
          className={`form-group ${
            errors?.secondCitizenship ? 'error-msg' : ''
          }`}
          controlId="formBasicEmail"
        >
          <Form.Label>Second Citizenship</Form.Label>
          <Form.Select
            name="secondCitizenship"
            disabled={mainApplicanDis ? mainApplicanDis : false}
            defaultValue={
              getCountry(personalInformation)
            }
            {...register('secondCitizenship')}
          >
            <option value="">
              Select Country
            </option>
            {countries &&
              countries?.length > 0 &&
              countries.map((el) => (
                <option defaultValue={ getCountry(personalInformation)} value={el?.name}>{el?.name}</option>
              ))}
          </Form.Select>
          {errors?.secondCitizenship ? (
            <span className="error-msg">
              {errors?.secondCitizenship?.message}
            </span>
          ) : (
            ''
          )}
        </Form.Group>

        <Form.Group
          className={`form-group ${
            errors?.countryResidence ? 'error-msg' : ''
          }`}
          controlId="formBasicEmail"
        >
          <Form.Label>Country of Residence*</Form.Label>
          <Form.Select
            disabled={mainApplicanDis ? mainApplicanDis : false}
            name="countryResidence"
            defaultValue={
              personalInformation?.mainApplicant?.countryResidence
                ? personalInformation?.mainApplicant?.countryResidence
                : personalInformation?.sponsoredInformation?.countryResidence
                ? personalInformation?.sponsoredInformation?.countryResidence
                : userGeoData?.country_name
            }
            {...register('countryResidence')}
          >
            <option value={userGeoData?.country_name}>
              {personalInformation?.mainApplicant?.countryResidence
                ? personalInformation?.mainApplicant?.countryResidence
                : personalInformation?.sponsoredInformation?.countryResidence
                ? personalInformation?.sponsoredInformation?.countryResidence
                : userGeoData?.country_name}
            </option>
            {countries &&
              countries?.length > 0 &&
              countries.map((el) => (
                <option value={el?.name}>{el?.name}</option>
              ))}
          </Form.Select>
          {errors?.countryResidence ? (
            <span className="error-msg">
              {errors?.countryResidence?.message}
            </span>
          ) : (
            ''
          )}
        </Form.Group>
      </div>
      <div className="box-grid-three">
        <Form.Group
          className={`form-group ${errors?.city ? 'error-msg' : ''}`}
          controlId="formBasicEmail"
        >
          <Form.Label>City*</Form.Label>
          <Form.Control
            type="text"
            name="city"
            disabled={mainApplicanDis ? mainApplicanDis : false}
            defaultValue={
              personalInformation?.mainApplicant?.city
                ? personalInformation?.mainApplicant?.city
                : personalInformation?.sponsoredInformation?.city
                ? personalInformation?.sponsoredInformation?.city
                : ''
            }
            {...register('city')}
          />
          {errors?.city ? (
            <span className="error-msg">{errors?.city?.message}</span>
          ) : (
            ''
          )}
        </Form.Group>
        <Form.Group
          className={`form-group ${errors?.street ? 'error-msg' : ''}`}
          controlId="formBasicEmail"
        >
          <Form.Label>Street*</Form.Label>
          <Form.Control
            type="text"
            name="street"
            disabled={mainApplicanDis ? mainApplicanDis : false}
            defaultValue={
              personalInformation?.mainApplicant?.street
                ? personalInformation?.mainApplicant?.street
                : personalInformation?.sponsoredInformation?.street
                ? personalInformation?.sponsoredInformation?.street
                : ''
            }
            {...register('street')}
          />
          {errors?.street ? (
            <span className="error-msg">{errors?.street?.message}</span>
          ) : (
            ''
          )}
        </Form.Group>
        <Form.Group
          className={`form-group ${errors?.zipCode ? 'error-msg' : ''}`}
          controlId="formBasicEmail"
        >
          <Form.Label>Zip Code / Postal Code*</Form.Label>
          <Form.Control
            type="number"
            name="zipCode"
            disabled={mainApplicanDis ? mainApplicanDis : false}
            defaultValue={
              personalInformation?.mainApplicant?.zipCode
                ? personalInformation?.mainApplicant?.zipCode
                : personalInformation?.sponsoredInformation?.zipCode
                ? personalInformation?.sponsoredInformation?.zipCode
                : ''
            }
            {...register('zipCode')}
          />
          {errors?.zipCode ? (
            <span className="error-msg">{errors?.zipCode?.message}</span>
          ) : (
            ''
          )}
        </Form.Group>
      </div>
      <div className="box-grid-two">
        <Form.Group
          className={`form-group ${errors?.maritalStatus ? 'error-msg' : ''}`}
          controlId="formBasicEmail"
        >
          <Form.Label>Marital Status*</Form.Label>
          <Form.Select
            disabled={mainApplicanDis ? mainApplicanDis : false}
            defaultValue={
              personalInformation?.mainApplicant?.maritalStatus
                ? personalInformation?.mainApplicant?.maritalStatus
                : personalInformation?.sponsoredInformation?.maritalStatus
                ? personalInformation?.sponsoredInformation?.maritalStatus
                : ''
            }
            name="maritalStatus"
            {...register('maritalStatus')}
          >
            <option value="">
               Select Status
            </option>
            <option value="Single">Single</option>
            <option value="Married">Married</option>
            <option value="Married">
              Common-Law Partnership{' '}
              <span>(you are living together for at least 1 year)</span>
            </option>
            <option value="Legally Separated">Legally Separated</option>
            <option value="Divorced">Divorced</option>
            <option value="Widowed">Widowed</option>
          </Form.Select>
          {errors?.maritalStatus ? (
            <span className="error-msg">{errors?.maritalStatus?.message}</span>
          ) : (
            ''
          )}
        </Form.Group>

        <Form.Group
          className={`form-group ${
            errors?.dependentChildren ? 'error-msg' : ''
          }`}
          controlId="formBasicEmail"
        >
          <Form.Label>Number of dependent children under 22</Form.Label>
          <Form.Control
            type="text"
            disabled={mainApplicanDis ? mainApplicanDis : false}
            defaultValue={
              personalInformation?.mainApplicant?.dependentChildren?.toString()
                ? personalInformation?.mainApplicant?.dependentChildren
                : personalInformation?.sponsoredInformation?.dependentChildren?.toString()
                ? personalInformation?.sponsoredInformation?.dependentChildren
                : ''
            }
            name="dependentChildren"
            {...register('dependentChildren')}
          />

          {errors?.dependentChildren ? (
            <span className="error-msg">
              {errors?.dependentChildren?.message}
            </span>
          ) : (
            ''
          )}
        </Form.Group>
      </div>
      <div className="box-grid-two">
        <Form.Group
          className={`form-group ${errors?.criminalOffense ? 'error-msg' : ''}`}
          controlId="formBasicEmail"
        >
          <Form.Label>
            Have you had any criminal offenses lodged against you?*
          </Form.Label>
          <Form.Select
            aria-label="Default select example"
            name="criminalOffense"
            disabled={mainApplicanDis ? mainApplicanDis : false}
            defaultValue={
              personalInformation?.mainApplicant?.criminalOffense
                ? personalInformation?.mainApplicant?.criminalOffense
                : personalInformation?.sponsoredInformation?.criminalOffense
                ? personalInformation?.sponsoredInformation?.criminalOffense
                : ''
            }
            {...register('criminalOffense')}
          >
            <option value="">
              Select
            </option>
            <option value="No">No</option>
            <option value="Yes">Yes</option>
          </Form.Select>
          {errors?.criminalOffense ? (
            <span className="error-msg">
              {errors?.criminalOffense?.message}
            </span>
          ) : (
            ''
          )}
        </Form.Group>
        {(criminalOffenses && criminalOffenses === 'Yes') ||
        personalInformation?.mainApplicant?.criminalOffense === 'Yes' ||
        personalInformation?.sponsoredInformation?.criminalOffense === 'Yes' ? (
          <Form.Group
            className={`form-group ${
              errors?.offenseTypeDetail ? 'error-msg' : ''
            }`}
            controlId="formBasicEmail"
          >
            <Form.Label>
              Please describe your criminal offenses from the past 10 years
            </Form.Label>
            <Form.Control
              type="text"
              name="offenseTypeDetail"
              disabled={mainApplicanDis ? mainApplicanDis : false}
              defaultValue={
                personalInformation?.mainApplicant?.offenseTypeDetail
                  ? personalInformation?.mainApplicant?.offenseTypeDetail
                  : personalInformation?.sponsoredInformation?.offenseTypeDetail
              }
              {...register('offenseTypeDetail')}
            />
            {errors?.offenseTypeDetail ? (
              <span className="error-msg">
                {errors?.offenseTypeDetail?.message}
              </span>
            ) : (
              ''
            )}
          </Form.Group>
        ) : (
          ''
        )}
      </div>
      <div className="box-grid-two">
        <Form.Group
          className={`form-group ${errors?.healthCondition ? 'error-msg' : ''}`}
          controlId="formBasicEmail"
        >
          <Form.Label>Health Problems*</Form.Label>
          <Form.Select
            aria-label="Default select example"
            name="healthCondition"
            disabled={mainApplicanDis ? mainApplicanDis : false}
            defaultValue={
              personalInformation?.mainApplicant?.healthCondition
                ? personalInformation?.mainApplicant?.healthCondition
                : personalInformation?.sponsoredInformation?.healthCondition
                ? personalInformation?.sponsoredInformation?.healthCondition
                : ''
            }
            {...register('healthCondition')}
          >
            <option value="">
              Select
            </option>
            <option value="No">No</option>
            <option value="Yes">Yes</option>
          </Form.Select>
          {errors?.healthCondition ? (
            <span className="error-msg">
              {errors?.healthCondition?.message}
            </span>
          ) : (
            ''
          )}
        </Form.Group>
        {watchShowHealthDetail === 'Yes' ||
        personalInformation?.mainApplicant?.healthCondition === 'Yes' ||
        personalInformation?.sponsoredInformation?.healthCondition === 'Yes' ? (
          <Form.Group
            className={`form-group ${
              errors?.healthConditionDetail ? 'error-msg' : ''
            }`}
            controlId="formBasicEmail"
          >
            <Form.Label>Please describe your health condition/s</Form.Label>
            <Form.Control
              type="text"
              name="healthConditionDetail"
              disabled={mainApplicanDis ? mainApplicanDis : false}
              defaultValue={
                personalInformation?.mainApplicant?.healthConditionDetail
                  ? personalInformation?.mainApplicant?.healthConditionDetail
                  : personalInformation?.sponsoredInformation
                      ?.healthConditionDetail
              }
              {...register('healthConditionDetail')}
            />
          </Form.Group>
        ) : (
          ''
        )}
      </div>

      <Form.Group
        className={`form-group ${errors?.canadaBefore ? 'error-msg' : ''}`}
        controlId="formBasicEmail"
      >
        <Form.Label>Have you been to canada before?*</Form.Label>
        <Form.Select
          aria-label="Default select example"
          name="canadaBefore"
          disabled={mainApplicanDis ? mainApplicanDis : false}
          defaultValue={
            personalInformation?.mainApplicant?.canadaBefore
              ? personalInformation?.mainApplicant?.canadaBefore
              : personalInformation?.sponsoredInformation?.canadaBefore
              ? personalInformation?.sponsoredInformation?.canadaBefore
              : ''
          }
          {...register('canadaBefore')}
        >
          <option value="">
            Select
          </option>
          <option value="No">No</option>
          <option value="Yes">Yes</option>
        </Form.Select>
        {errors?.canadaBefore ? (
          <span className="error-msg">{errors?.canadaBefore?.message}</span>
        ) : (
          ''
        )}
      </Form.Group>
      {watchShowCanadaBefore === 'Yes' ||
      personalInformation?.mainApplicant?.canadaBefore === 'Yes' ||
      personalInformation?.sponsoredInformation?.canadaBefore === 'Yes' ? (
        <div className="box-grid-two">
          <Form.Group
            className={`form-group ${
              errors?.canadaInfo?.lastVisitDate ? 'error-msg' : ''
            }`}
            controlId="formBasicEmail"
          >
            <Form.Label>When was your last visit?*</Form.Label>
            <DatePicker
              type="lastVisitDate"
              name="lastVisitDate"
              placeholderText="Select Date"
              disabled={mainApplicanDis ? mainApplicanDis : false}
              selected={lastVisitDate}
              onChange={(date) =>
                changeDatePicker(date, 'lastVisitDate', 'age')
              }
              className="form-select"
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            {errors?.canadaInfo?.lastVisitDate ? (
              <span className="error-msg">
                {errors?.canadaInfo?.lastVisitDate?.message}
              </span>
            ) : (
              ''
            )}
          </Form.Group>
          <Form.Group
            className={`form-group ${
              errors?.canadaInfo?.location ? 'error-msg' : ''
            }`}
            controlId="formBasicEmail"
          >
            <Form.Label>Location*</Form.Label>
            <Form.Control
              type="text"
              name="location"
              disabled={mainApplicanDis ? mainApplicanDis : false}
              defaultValue={
                personalInformation?.mainApplicant?.location
                  ? personalInformation?.mainApplicant?.location
                  : personalInformation?.sponsoredInformation?.location
              }
              {...register('canadaInfo.location')}
            />
            {errors?.canadaInfo?.location ? (
              <span className="error-msg">
                {errors?.canadaInfo?.location?.message}
              </span>
            ) : (
              ''
            )}
          </Form.Group>
        </div>
      ) : (
        ''
      )}
      <Form.Group
        className={`form-group ${
          errors?.immigrationRefugeesCanBefore ? 'error-msg' : ''
        }`}
        controlId="formBasicEmail"
      >
        <Form.Label>
          Have you applied to Immigration, Refugees and Citizenship Canada
          before?*
        </Form.Label>
        <Form.Select
          aria-label="Default select example"
          name="immigrationRefugeesCanBefore"
          disabled={mainApplicanDis ? mainApplicanDis : false}
          defaultValue={
            personalInformation?.mainApplicant?.immigrationRefugeesCanBefore
              ? personalInformation?.mainApplicant?.immigrationRefugeesCanBefore
              : personalInformation?.sponsoredInformation
                  ?.immigrationRefugeesCanBefore
              ? personalInformation?.sponsoredInformation
                  ?.immigrationRefugeesCanBefore
              : ''
          }
          {...register('immigrationRefugeesCanBefore')}
        >
          <option value="">
            Select
          </option>
          <option value="No">No</option>
          <option value="Yes">Yes</option>
        </Form.Select>
        {errors?.immigrationRefugeesCanBefore ? (
          <span className="error-msg">
            {errors?.immigrationRefugeesCanBefore?.message}
          </span>
        ) : (
          ''
        )}
      </Form.Group>
      {watchImmigrationRefugeesCanadaBefore === 'Yes' ||
      personalInformation?.mainApplicant?.immigrationRefugeesCanBefore ===
        'Yes' ||
      personalInformation?.sponsoredInformation
        ?.immigrationRefugeesCanBefore === 'Yes' ? (
        <>
          <div className="box-grid-two">
            <Form.Group
              className={`form-group ${
                errors?.immigrationInfo?.passportNumber ? 'error-msg' : ''
              }`}
              controlId="formBasicEmail"
            >
              <Form.Label>Passport Number*</Form.Label>
              <Form.Control
                type="text"
                name="passportNumber"
                disabled={mainApplicanDis ? mainApplicanDis : false}
                defaultValue={
                  personalInformation?.mainApplicant?.passportNumber
                    ? personalInformation?.mainApplicant?.passportNumber
                    : personalInformation?.sponsoredInformation?.passportNumber
                }
                {...register('immigrationInfo.passportNumber')}
              />
              {errors?.immigrationInfo?.passportNumber ? (
                <span className="error-msg">
                  {errors?.immigrationInfo?.passportNumber?.message}
                </span>
              ) : (
                ''
              )}
            </Form.Group>
            <Form.Group
              className={`form-group ${
                errors?.immigrationInfo?.countryOfIssue ? 'error-msg' : ''
              }`}
              controlId="formBasicEmail"
            >
              <Form.Label>Country of Issue*</Form.Label>
              <Form.Select
                disabled={mainApplicanDis ? mainApplicanDis : false}
                name="countryOfIssue"
                defaultValue={
                  personalInformation?.mainApplicant?.countryOfIssue
                    ? personalInformation?.mainApplicant?.countryOfIssue
                    : personalInformation?.sponsoredInformation?.countryOfIssue
                    ? personalInformation?.sponsoredInformation?.countryOfIssue
                    : userGeoData?.country_name
                }
                {...register('immigrationInfo.countryOfIssue')}
              >
                <option value={userGeoData?.country_name}>
                  {personalInformation?.mainApplicant?.countryOfIssue
                    ? personalInformation?.mainApplicant?.countryOfIssue
                    : personalInformation?.sponsoredInformation?.countryOfIssue
                    ? personalInformation?.sponsoredInformation?.countryOfIssue
                    : userGeoData?.country_name}
                </option>
                {countries &&
                  countries?.length > 0 &&
                  countries.map((el) => (
                    <option value={el?.name}>{el?.name}</option>
                  ))}
              </Form.Select>
              {errors?.immigrationInfo?.countryOfIssue ? (
                <span className="error-msg">
                  {errors?.immigrationInfo?.countryOfIssue?.message}
                </span>
              ) : (
                ''
              )}
            </Form.Group>
          </div>
          <div className="box-grid-two">
            <Form.Group
              className={`form-group ${
                errors?.immigrationInfo?.issueDate ? 'error-msg' : ''
              }`}
              controlId="formBasicEmail"
            >
              <Form.Label>Issue Date*</Form.Label>
              <DatePicker
                type="issueDate"
                name="issueDate"
                placeholderText="Select Date"
                disabled={mainApplicanDis ? mainApplicanDis : false}
                selected={issueDate}
                onChange={(date) => changeDatePicker(date, 'issueDate', 'age')}
                className="form-select"
                dateFormat="dd-MM-yyyy"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
              {errors?.immigrationInfo?.issueDate ? (
                <span className="error-msg">
                  {errors?.immigrationInfo?.issueDate?.message}
                </span>
              ) : (
                ''
              )}
            </Form.Group>
            <Form.Group
              className={`form-group ${
                errors?.immigrationInfo?.expiryDate ? 'error-msg' : ''
              }`}
              controlId="formBasicEmail"
            >
              <Form.Label>Expiry Date*</Form.Label>
              <DatePicker
                type="expiryDate"
                name="expiryDate"
                placeholderText="Select Date"
                disabled={mainApplicanDis ? mainApplicanDis : false}
                selected={expiryDate}
                onChange={(date) => changeDatePicker(date, 'expiryDate', 'age')}
                className="form-select"
                dateFormat="dd-MM-yyyy"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
              {errors?.immigrationInfo?.expiryDate ? (
                <span className="error-msg">
                  {errors?.immigrationInfo?.expiryDate?.message}
                </span>
              ) : (
                ''
              )}
            </Form.Group>
          </div>
        </>
      ) : (
        ''
      )}
      <Form.Group
        className={`form-group ${errors?.otherName ? 'error-msg' : ''}`}
        controlId="formBasicEmail"
      >
        <Form.Label>Have you ever used any other name?*</Form.Label>
        <Form.Select
          aria-label="Default select example"
          name="otherName"
          disabled={mainApplicanDis ? mainApplicanDis : false}
          defaultValue={
            personalInformation?.mainApplicant?.otherName
              ? personalInformation?.mainApplicant?.otherName
              : personalInformation?.sponsoredInformation?.otherName
              ? personalInformation?.sponsoredInformation?.otherName
              : ''
          }
          {...register('otherName')}
        >
          <option value="">
            Select
          </option>
          <option value="No">No</option>
          <option value="Yes">Yes</option>
        </Form.Select>
        {errors?.otherName ? (
          <span className="error-msg">{errors?.otherName?.message}</span>
        ) : (
          ''
        )}
      </Form.Group>
      {watchOtherName === 'Yes' ||
      personalInformation?.mainApplicant?.otherName === 'Yes' ||
      personalInformation?.sponsoredInformation?.otherName === 'Yes' ? (
        <div className="box-grid-three">
          <Form.Group
            className={`form-group ${
              errors?.otherNameInfo?.otherFirstName ? 'error-msg' : ''
            }`}
            controlId="formBasicEmail"
          >
            <Form.Label>First Name*</Form.Label>
            <Form.Control
              type="text"
              name="otherFirstName"
              disabled={mainApplicanDis ? mainApplicanDis : false}
              defaultValue={
                personalInformation?.mainApplicant?.otherFirstName
                  ? personalInformation?.mainApplicant?.otherFirstName
                  : personalInformation?.sponsoredInformation?.otherFirstName
              }
              {...register('otherNameInfo.otherFirstName')}
            />
            {errors?.otherNameInfo?.otherFirstName ? (
              <span className="error-msg">
                {errors?.otherNameInfo?.otherFirstName?.message}
              </span>
            ) : (
              ''
            )}
          </Form.Group>
          <Form.Group
            className={`form-group ${
              errors?.otherNameInfo?.otherMidName ? 'error-msg' : ''
            }`}
            controlId="formBasicEmail"
          >
            <Form.Label>Middle Name</Form.Label>
            <Form.Control
              type="text"
              name="otherMidName"
              disabled={mainApplicanDis ? mainApplicanDis : false}
              defaultValue={
                personalInformation?.mainApplicant?.otherMidName
                  ? personalInformation?.mainApplicant?.otherMidName
                  : personalInformation?.sponsoredInformation?.otherMidName
              }
              {...register('otherNameInfo.otherMidName')}
            />
          </Form.Group>
          <Form.Group
            className={`form-group ${
              errors?.otherNameInfo?.otherLastName ? 'error-msg' : ''
            }`}
            controlId="formBasicEmail"
          >
            <Form.Label>last Name*</Form.Label>
            <Form.Control
              type="text"
              name="location"
              disabled={mainApplicanDis ? mainApplicanDis : false}
              defaultValue={
                personalInformation?.mainApplicant?.otherLastName
                  ? personalInformation?.mainApplicant?.otherLastName
                  : personalInformation?.sponsoredInformation?.otherLastName
              }
              {...register('otherNameInfo.otherLastName')}
            />
            {errors?.otherNameInfo?.otherLastName ? (
              <span className="error-msg">
                {errors?.otherNameInfo?.otherLastName?.message}
              </span>
            ) : (
              ''
            )}
          </Form.Group>
        </div>
      ) : (
        ''
      )}
      <Form.Group
        className={`form-group ${
          errors?.pastCitizenshipSixMonth ? 'error-msg' : ''
        }`}
        controlId="formBasicEmail"
      >
        <Form.Label>
          In the past 5 years, have you lived in any country other than your
          country of citizenship for more than six months?*
        </Form.Label>
        <Form.Select
          aria-label="Default select example"
          name="pastCitizenshipSixMonth"
          disabled={mainApplicanDis ? mainApplicanDis : false}
          defaultValue={
            personalInformation?.mainApplicant?.pastCitizenshipSixMonth
              ? personalInformation?.mainApplicant?.pastCitizenshipSixMonth
              : personalInformation?.sponsoredInformation
                  ?.pastCitizenshipSixMonth
              ? personalInformation?.sponsoredInformation
                  ?.pastCitizenshipSixMonth
              : ''
          }
          {...register('pastCitizenshipSixMonth')}
        >
          <option value="">
            Select
          </option>
          <option value="No">No</option>
          <option value="Yes">Yes</option>
        </Form.Select>
        {errors?.pastCitizenshipSixMonth ? (
          <span className="error-msg">
            {errors?.pastCitizenshipSixMonth?.message}
          </span>
        ) : (
          ''
        )}
      </Form.Group>
      {watchPastCitizenshipSixMonth === 'Yes' ||
      personalInformation?.mainApplicant?.pastCitizenshipSixMonth === 'Yes' ||
      personalInformation?.sponsoredInformation?.pastCitizenshipSixMonth ===
        'Yes' ? (
        <>
          <div className="box-grid-two">
            <Form.Group
              className={`form-group ${
                errors?.pastCitizenshipInfo?.liveCountry ? 'error-msg' : ''
              }`}
              controlId="formBasicEmail"
            >
              <Form.Label>Country*</Form.Label>
              <Form.Select
                disabled={mainApplicanDis ? mainApplicanDis : false}
                name="liveCountry"
                defaultValue={
                  personalInformation?.mainApplicant?.liveCountry
                    ? personalInformation?.mainApplicant?.liveCountry
                    : personalInformation?.sponsoredInformation?.liveCountry
                    ? personalInformation?.sponsoredInformation?.liveCountry
                    : userGeoData?.country_name
                }
                {...register('pastCitizenshipInfo.liveCountry')}
              >
                <option value={userGeoData?.country_name}>
                  {personalInformation?.mainApplicant?.liveCountry
                    ? personalInformation?.mainApplicant?.liveCountry
                    : personalInformation?.sponsoredInformation?.liveCountry
                    ? personalInformation?.sponsoredInformation?.liveCountry
                    : userGeoData?.country_name}
                </option>
                {countries &&
                  countries?.length > 0 &&
                  countries.map((el) => (
                    <option value={el?.name}>{el?.name}</option>
                  ))}
              </Form.Select>
              {errors?.pastCitizenshipInfo?.liveCountry ? (
                <span className="error-msg">
                  {errors?.pastCitizenshipInfo?.liveCountry?.message}
                </span>
              ) : (
                ''
              )}
            </Form.Group>
            <Form.Group
              className={`form-group ${
                errors?.pastCitizenshipInfo?.liveStatus ? 'error-msg' : ''
              }`}
              controlId="formBasicEmail"
            >
              <Form.Label>Status*</Form.Label>
              <Form.Control
                type="text"
                name="liveStatus"
                disabled={mainApplicanDis ? mainApplicanDis : false}
                defaultValue={
                  personalInformation?.mainApplicant?.liveStatus
                    ? personalInformation?.mainApplicant?.liveStatus
                    : personalInformation?.sponsoredInformation?.liveStatus
                }
                {...register('pastCitizenshipInfo.liveStatus')}
              />
              {errors?.pastCitizenshipInfo?.liveStatus ? (
                <span className="error-msg">
                  {errors?.pastCitizenshipInfo?.liveStatus?.message}
                </span>
              ) : (
                ''
              )}
            </Form.Group>
          </div>
          <div className="box-grid-two">
            <Form.Group
              className={`form-group ${
                errors?.pastCitizenshipInfo?.liveFromDate ? 'error-msg' : ''
              }`}
              controlId="formBasicEmail"
            >
              <Form.Label>From date*</Form.Label>
              <DatePicker
                type="liveFromDate"
                name="liveFromDate"
                placeholderText="Select Date"
                disabled={mainApplicanDis ? mainApplicanDis : false}
                selected={liveFromDate}
                onChange={(date) =>
                  changeDatePicker(date, 'liveFromDate', 'age')
                }
                className="form-select"
                dateFormat="dd-MM-yyyy"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
              {errors?.pastCitizenshipInfo?.liveFromDate ? (
                <span className="error-msg">
                  {errors?.pastCitizenshipInfo?.liveFromDate?.message}
                </span>
              ) : (
                ''
              )}
            </Form.Group>
            <Form.Group
              className={`form-group ${
                errors?.pastCitizenshipInfo?.liveFromDate ? 'error-msg' : ''
              }`}
              controlId="formBasicEmail"
            >
              <Form.Label>To date*</Form.Label>
              <DatePicker
                type="liveToDate"
                name="liveToDate"
                placeholderText="Select Date"
                disabled={mainApplicanDis ? mainApplicanDis : false}
                selected={liveToDate}
                onChange={(date) => changeDatePicker(date, 'liveToDate', 'age')}
                className="form-select"
                dateFormat="dd-MM-yyyy"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
              {errors?.pastCitizenshipInfo?.liveToDate ? (
                <span className="error-msg">
                  {errors?.pastCitizenshipInfo?.liveToDate?.message}
                </span>
              ) : (
                ''
              )}
            </Form.Group>
          </div>
        </>
      ) : (
        ''
      )}
      <Form.Group
        className={`form-group ${
          errors?.CommonLawRelationship ? 'error-msg' : ''
        }`}
        controlId="formBasicEmail"
      >
        <Form.Label>
          Have you been previously married or in a common-law relationship?*
        </Form.Label>
        <Form.Select
          aria-label="Default select example"
          name="CommonLawRelationship"
          disabled={mainApplicanDis ? mainApplicanDis : false}
          defaultValue={
            personalInformation?.mainApplicant?.CommonLawRelationship
              ? personalInformation?.mainApplicant?.CommonLawRelationship
              : personalInformation?.sponsoredInformation?.CommonLawRelationship
              ? personalInformation?.sponsoredInformation?.CommonLawRelationship
              : ''
          }
          {...register('CommonLawRelationship')}
        >
          <option value="">
            Select
          </option>
          <option value="No">No</option>
          <option value="Yes">Yes</option>
        </Form.Select>
        {errors?.CommonLawRelationship ? (
          <span className="error-msg">
            {errors?.CommonLawRelationship?.message}
          </span>
        ) : (
          ''
        )}
      </Form.Group>
      {watchCommonLawRelationship === 'Yes' ||
      personalInformation?.mainApplicant?.CommonLawRelationship === 'Yes' ||
      personalInformation?.sponsoredInformation?.CommonLawRelationship ===
        'Yes' ? (
        <>
          <div className="box-grid-three">
            <Form.Group
              className={`form-group ${
                errors?.relationshipInfo?.relationFirstName ? 'error-msg' : ''
              }`}
              controlId="formBasicEmail"
            >
              <Form.Label>First Name*</Form.Label>
              <Form.Control
                type="text"
                name="relationFirstName"
                disabled={mainApplicanDis ? mainApplicanDis : false}
                defaultValue={
                  personalInformation?.mainApplicant?.relationFirstName
                    ? personalInformation?.mainApplicant?.relationFirstName
                    : personalInformation?.sponsoredInformation
                        ?.relationFirstName
                }
                {...register('relationshipInfo.relationFirstName')}
              />
              {errors?.relationshipInfo?.relationFirstName ? (
                <span className="error-msg">
                  {errors?.relationshipInfo?.relationFirstName?.message}
                </span>
              ) : (
                ''
              )}
            </Form.Group>
            <Form.Group
              className={`form-group ${
                errors?.relationshipInfo?.relationFamilyName ? 'error-msg' : ''
              }`}
              controlId="formBasicEmail"
            >
              <Form.Label>Family Name*</Form.Label>
              <Form.Control
                type="text"
                name="relationFamilyName"
                disabled={mainApplicanDis ? mainApplicanDis : false}
                defaultValue={
                  personalInformation?.mainApplicant?.relationFamilyName
                    ? personalInformation?.mainApplicant?.relationFamilyName
                    : personalInformation?.sponsoredInformation
                        ?.relationFamilyName
                }
                {...register('relationshipInfo.relationFamilyName')}
              />
              {errors?.relationshipInfo?.relationFamilyName ? (
                <span className="error-msg">
                  {errors?.relationshipInfo?.relationFamilyName?.message}
                </span>
              ) : (
                ''
              )}
            </Form.Group>
            <Form.Group
              className={`form-group ${
                errors?.relationshipInfo?.relationType ? 'error-msg' : ''
              }`}
              controlId="formBasicEmail"
            >
              <Form.Label>Relation Type*</Form.Label>
              <Form.Control
                type="text"
                name="relationType"
                disabled={mainApplicanDis ? mainApplicanDis : false}
                defaultValue={
                  personalInformation?.mainApplicant?.relationType
                    ? personalInformation?.mainApplicant?.relationType
                    : personalInformation?.sponsoredInformation?.relationType
                }
                {...register('relationshipInfo.relationType')}
              />
              {errors?.relationshipInfo?.relationType ? (
                <span className="error-msg">
                  {errors?.relationshipInfo?.relationType?.message}
                </span>
              ) : (
                ''
              )}
            </Form.Group>
          </div>
          <div className="box-grid-two">
            <Form.Group
              className={`form-group ${
                errors?.relationshipInfo?.relationFromDate ? 'error-msg' : ''
              }`}
              controlId="formBasicEmail"
            >
              <Form.Label>From date*</Form.Label>
              <DatePicker
                type="relationFromDate"
                name="relationFromDate"
                placeholderText="Select Date"
                disabled={mainApplicanDis ? mainApplicanDis : false}
                selected={relationFromDate}
                onChange={(date) =>
                  changeDatePicker(date, 'relationFromDate', 'age')
                }
                className="form-select"
                dateFormat="dd-MM-yyyy"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
              {errors?.relationshipInfo?.relationFromDate ? (
                <span className="error-msg">
                  {errors?.relationshipInfo?.relationFromDate?.message}
                </span>
              ) : (
                ''
              )}
            </Form.Group>
            <Form.Group
              className={`form-group ${
                errors?.relationshipInfo?.relationToDate ? 'error-msg' : ''
              }`}
              controlId="formBasicEmail"
            >
              <Form.Label>To date*</Form.Label>
              <DatePicker
                type="relationToDate"
                name="relationToDate"
                placeholderText="Select Date"
                disabled={mainApplicanDis ? mainApplicanDis : false}
                selected={relationToDate}
                onChange={(date) =>
                  changeDatePicker(date, 'relationToDate', 'age')
                }
                className="form-select"
                dateFormat="dd-MM-yyyy"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
              {errors?.relationshipInfo?.relationToDate ? (
                <span className="error-msg">
                  {errors?.relationshipInfo?.relationToDate?.message}
                </span>
              ) : (
                ''
              )}
            </Form.Group>
          </div>
        </>
      ) : (
        ''
      )}
      <span className="address-subheading">
        Please list all the addresses you lived at since you were 18 years old.
        Do not use P.O box addresses*
      </span>

      {/* <div className="address-heading">
        <h5>Address #1</h5>
      </div> */}
      {addressDetail?.length === 0 ? (
        <div className="add-btn">
          <Button
            onClick={handleAddress}
            disabled={mainApplicanDis ? mainApplicanDis : false}
          >
            Add Address <AddIcon />
          </Button>
        </div>
      ) : (
        ''
      )}
      {addressDetail &&
        addressDetail?.length > 0 &&
        addressDetail.map((addr, i) => (
          <>
            <div className="address-heading">
              <h5>Address #{i + 1}</h5>
            </div>
            <div className="education-info">
              <div className="box-grid-two">
                <Form.Group
                  className={`form-group mb-3 ${
                    errors?.addressInformation &&
                    errors?.addressInformation[`${i}`]?.addrCountry
                      ? 'error-msg'
                      : ''
                  }`}
                  controlId="formBasicEmail"
                >
                  <Form.Label>Country*</Form.Label>
                  <Form.Select
                    name="addrCountry"
                    defaultValue={addr.addrCountry ? addr.addrCountry : ''}
                    disabled={mainApplicanDis ? mainApplicanDis : false}
                    onChange={(e) => handleAddressChange(e, i)}
                  >
                    <option value="">
                      {addr?.addrCountry ? addr?.addrCountry : 'Select'}
                    </option>
                    {countries &&
                      countries?.length > 0 &&
                      countries.map((el) => (
                        <option value={el?.name}>{el?.name}</option>
                      ))}
                  </Form.Select>
                  {errors?.addressInformation &&
                  errors?.addressInformation[`${i}`]?.addrCountry ? (
                    <span className="error-msg">
                      {errors?.addressInformation[`${i}`]?.addrCountry?.message}
                    </span>
                  ) : (
                    ''
                  )}
                </Form.Group>
                <Form.Group
                  className={`form-group mb-3 ${
                    errors?.addressInformation &&
                    errors?.addressInformation[`${i}`]?.addrStateDist
                      ? 'error-msg'
                      : ''
                  }`}
                  controlId="formBasicEmail"
                >
                  <Form.Label>State/province/District*</Form.Label>
                  <Form.Control
                    type="text"
                    name="addrStateDist"
                    disabled={mainApplicanDis ? mainApplicanDis : false}
                    defaultValue={addr.addrStateDist}
                    onChange={(e) => handleAddressChange(e, i)}
                  />
                  {errors?.addressInformation &&
                  errors?.addressInformation[`${i}`]?.addrStateDist ? (
                    <span className="error-msg">
                      {
                        errors?.addressInformation[`${i}`]?.addrStateDist
                          ?.message
                      }
                    </span>
                  ) : (
                    ''
                  )}
                </Form.Group>
              </div>
              <div className="box-grid-three">
                <Form.Group
                  className={`form-group mb-3 ${
                    errors?.addressInformation &&
                    errors?.addressInformation[`${i}`]?.addrCityTown
                      ? 'error-msg'
                      : ''
                  }`}
                  controlId="formBasicEmail"
                >
                  <Form.Label>City/Town*</Form.Label>
                  <Form.Control
                    type="text"
                    name="addrCityTown"
                    disabled={mainApplicanDis ? mainApplicanDis : false}
                    defaultValue={addr?.addrCityTown}
                    onChange={(e) => handleAddressChange(e, i)}
                  />
                  {errors?.addressInformation &&
                  errors?.addressInformation[`${i}`]?.addrCityTown ? (
                    <span className="error-msg">
                      {
                        errors?.addressInformation[`${i}`]?.addrCityTown
                          ?.message
                      }
                    </span>
                  ) : (
                    ''
                  )}
                </Form.Group>
                <Form.Group
                  className={`form-group mb-3 ${
                    errors?.addressInformation &&
                    errors?.addressInformation[`${i}`]?.addrFullStreet
                      ? 'error-msg'
                      : ''
                  }`}
                  controlId="formBasicEmail"
                >
                  <Form.Label>Full Street Address*</Form.Label>
                  <Form.Control
                    type="text"
                    name="addrFullStreet"
                    disabled={mainApplicanDis ? mainApplicanDis : false}
                    defaultValue={addr.addrFullStreet}
                    onChange={(e) => handleAddressChange(e, i)}
                  />
                  {errors?.addressInformation &&
                  errors?.addressInformation[`${i}`]?.addrFullStreet ? (
                    <span className="error-msg">
                      {
                        errors?.addressInformation[`${i}`]?.addrFullStreet
                          ?.message
                      }
                    </span>
                  ) : (
                    ''
                  )}
                </Form.Group>
                <Form.Group
                  className={`form-group mb-3 ${
                    errors?.addressInformation &&
                    errors?.addressInformation[`${i}`]?.addrZipCode
                      ? 'error-msg'
                      : ''
                  }`}
                  controlId="formBasicEmail"
                >
                  <Form.Label>Zip Code / Postal Code*</Form.Label>
                  <Form.Control
                    type="number"
                    name="addrZipCode"
                    disabled={mainApplicanDis ? mainApplicanDis : false}
                    defaultValue={addr.addrZipCode}
                    onChange={(e) => handleAddressChange(e, i)}
                  />
                  {errors?.addressInformation &&
                  errors?.addressInformation[`${i}`]?.addrZipCode ? (
                    <span className="error-msg">
                      {errors?.addressInformation[`${i}`]?.addrZipCode?.message}
                    </span>
                  ) : (
                    ''
                  )}
                </Form.Group>
              </div>
              <div className="box-grid-two">
                <Form.Group
                  className={`form-group mb-3 ${
                    errors?.addressInformation &&
                    errors?.addressInformation[`${i}`]?.addrFromDate
                      ? 'error-msg'
                      : ''
                  }`}
                  controlId="formBasicEmail"
                >
                  <Form.Label>From date*</Form.Label>
                  <DatePicker
                    type="addrFromDate"
                    name="addrFromDate"
                    placeholderText="Select Date"
                    disabled={mainApplicanDis ? mainApplicanDis : false}
                    selected={
                      addr?.addrFromDate
                        ? new Date(addr?.addrFromDate)
                        : new Date()
                    }
                    onChange={(date) =>
                      handleAddressPicker(date, 'addrFromDate', i)
                    }
                    className="form-select"
                    dateFormat="dd-MM-yyyy"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                  {errors?.addressInformation ? (
                    <span className="error-msg">
                      {
                        errors?.addressInformation[`${i}`]?.addrFromDate
                          ?.message
                      }
                    </span>
                  ) : (
                    ''
                  )}
                </Form.Group>
                <Form.Group
                  className={`form-group mb-3 ${
                    errors?.addressInformation &&
                    errors?.addressInformation[`${i}`]?.addrToDate
                      ? 'error-msg'
                      : ''
                  }`}
                  controlId="formBasicEmail"
                >
                  <Form.Label>To date*</Form.Label>
                  <DatePicker
                    type="addrToDate"
                    name="addrToDate"
                    placeholderText="Select Date"
                    disabled={mainApplicanDis ? mainApplicanDis : false}
                    selected={
                      addr?.addrToDate ? new Date(addr?.addrToDate) : new Date()
                    }
                    onChange={(date) =>
                      handleAddressPicker(date, 'addrToDate', i)
                    }
                    className="form-select"
                    dateFormat="dd-MM-yyyy"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                  {errors?.addressInformation ? (
                    <span className="error-msg">
                      {errors?.addressInformation[`${i}`]?.addrToDate?.message}
                    </span>
                  ) : (
                    ''
                  )}
                </Form.Group>
              </div>
              <div className="add-btn">
                {i >= 1 && !mainApplicanDis && (
                  <Button
                    className="delete-btn"
                    disabled={mainApplicanDis ? mainApplicanDis : false}
                    onClick={() => removeAddress(i)}
                  >
                    Delete #{i + 1} <i class="las la-minus"></i>
                  </Button>
                )}
                <Button
                  onClick={handleAddress}
                  disabled={mainApplicanDis ? mainApplicanDis : false}
                >
                  Add More <AddIcon />
                </Button>
              </div>
            </div>
          </>
        ))}
      <Form.Group
        className={`form-group ${
          errors?.memberOfOrganization ? 'error-msg' : ''
        }`}
        controlId="formBasicEmail"
      >
        <Form.Label>
          Are you currently a member of any organization, or have you been in
          the past?*
        </Form.Label>
        <Form.Select
          aria-label="Default select example"
          name="memberOfOrganization"
          disabled={mainApplicanDis ? mainApplicanDis : false}
          defaultValue={
            personalInformation?.mainApplicant?.memberOfOrganization
              ? personalInformation?.mainApplicant?.memberOfOrganization
              : personalInformation?.sponsoredInformation?.memberOfOrganization
              ? personalInformation?.sponsoredInformation?.memberOfOrganization
              : ''
          }
          {...register('memberOfOrganization')}
        >
          <option value="">
            Select
          </option>
          <option value="No">No</option>
          <option value="Yes">Yes</option>
        </Form.Select>
        {errors?.memberOfOrganization ? (
          <span className="error-msg">
            {errors?.memberOfOrganization?.message}
          </span>
        ) : (
          ''
        )}
      </Form.Group>
      {watchMemberOfOrganization === 'Yes' ||
      personalInformation?.mainApplicant?.memberOfOrganization === 'Yes' ||
      personalInformation?.sponsoredInformation?.memberOfOrganization === 'Yes'
        ? organizationDetail &&
          organizationDetail?.length > 0 &&
          organizationDetail.map((org, i) => (
            <>
              <div className="education-info">
                <div className="box-grid-three">
                  <Form.Group
                    className={`form-group mb-3 ${
                      errors?.memberOfInformation &&
                      errors?.memberOfInformation[`${i}`]?.organizationName
                        ? 'error-msg'
                        : ''
                    }`}
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Organization Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="organizationName"
                      disabled={mainApplicanDis ? mainApplicanDis : false}
                      defaultValue={org.organizationName}
                      onChange={(e) => handleOrganizationChange(e, i)}
                    />
                    {errors?.memberOfInformation &&
                    errors?.memberOfInformation[`${i}`]?.organizationName ? (
                      <span className="error-msg">
                        {
                          errors?.memberOfInformation[`${i}`]?.organizationName
                            ?.message
                        }
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Group>
                  <Form.Group
                    className={`form-group mb-3 ${
                      errors?.memberOfInformation &&
                      errors?.memberOfInformation[`${i}`]?.organizationType
                        ? 'error-msg'
                        : ''
                    }`}
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Organization Type*</Form.Label>
                    <Form.Control
                      type="text"
                      name="organizationType"
                      disabled={mainApplicanDis ? mainApplicanDis : false}
                      defaultValue={org.organizationType}
                      onChange={(e) => handleOrganizationChange(e, i)}
                    />
                    {errors?.memberOfInformation &&
                    errors?.memberOfInformation[`${i}`]?.organizationType ? (
                      <span className="error-msg">
                        {
                          errors?.memberOfInformation[`${i}`]?.organizationType
                            ?.message
                        }
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Group>
                  <Form.Group
                    className={`form-group mb-3 ${
                      errors?.memberOfInformation &&
                      errors?.memberOfInformation[`${i}`]?.organizationRole
                        ? 'error-msg'
                        : ''
                    }`}
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Activities/Role*</Form.Label>
                    <Form.Control
                      type="text"
                      name="organizationRole"
                      disabled={mainApplicanDis ? mainApplicanDis : false}
                      defaultValue={org.organizationRole}
                      onChange={(e) => handleOrganizationChange(e, i)}
                    />
                    {errors?.memberOfInformation &&
                    errors?.memberOfInformation[`${i}`]?.organizationRole ? (
                      <span className="error-msg">
                        {
                          errors?.memberOfInformation[`${i}`]?.organizationRole
                            ?.message
                        }
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Group>
                </div>
                <div className="box-grid-two">
                  <Form.Group
                    className={`form-group mb-3 ${
                      errors?.memberOfInformation &&
                      errors?.memberOfInformation[`${i}`]?.organizationCountry
                        ? 'error-msg'
                        : ''
                    }`}
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Country*</Form.Label>
                    <Form.Select
                      name="organizationCountry"
                      disabled={mainApplicanDis ? mainApplicanDis : false}
                      defaultValue={org.organizationCountry
                        ? org.organizationCountry
                        : ''}
                      onChange={(e) => handleOrganizationChange(e, i)}
                    >
                      <option value="">
                        Select
                      </option>
                      {countries &&
                        countries?.length > 0 &&
                        countries.map((el) => (
                          <option value={el?.name}>{el?.name}</option>
                        ))}
                    </Form.Select>
                    {errors?.memberOfInformation &&
                    errors?.memberOfInformation[`${i}`]?.organizationCountry ? (
                      <span className="error-msg">
                        {
                          errors?.memberOfInformation[`${i}`]
                            ?.organizationCountry?.message
                        }
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Group>
                  <Form.Group
                    className={`form-group mb-3 ${
                      errors?.memberOfInformation &&
                      errors?.memberOfInformation[`${i}`]?.organizationCity
                        ? 'error-msg'
                        : ''
                    }`}
                    controlId="formBasicEmail"
                  >
                    <Form.Label>City*</Form.Label>
                    <Form.Control
                      type="text"
                      name="organizationCity"
                      disabled={mainApplicanDis ? mainApplicanDis : false}
                      defaultValue={org.organizationCity}
                      onChange={(e) => handleOrganizationChange(e, i)}
                    />
                    {errors?.memberOfInformation &&
                    errors?.memberOfInformation[`${i}`]?.organizationCity ? (
                      <span className="error-msg">
                        {
                          errors?.memberOfInformation[`${i}`]?.organizationCity
                            ?.message
                        }
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Group>
                </div>

                <div className="box-grid-two">
                  <Form.Group
                    className={`form-group mb-3 ${
                      errors?.memberOfInformation &&
                      errors?.memberOfInformation[`${i}`]?.organizationFromDate
                        ? 'error-msg'
                        : ''
                    }`}
                    controlId="formBasicEmail"
                  >
                    <Form.Label>From date*</Form.Label>
                    <DatePicker
                      type="organizationFromDate"
                      name="organizationFromDate"
                      placeholderText="Select Date"
                      disabled={mainApplicanDis ? mainApplicanDis : false}
                      selected={
                        org?.organizationFromDate
                          ? new Date(org?.organizationFromDate)
                          : new Date()
                      }
                      onChange={(date) =>
                        handleOrganizationPicker(
                          date,
                          'organizationFromDate',
                          i,
                        )
                      }
                      className="form-select"
                      dateFormat="dd-MM-yyyy"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                    {errors?.memberOfInformation &&
                    errors?.memberOfInformation[`${i}`]
                      ?.organizationFromDate ? (
                      <span className="error-msg">
                        {
                          errors?.memberOfInformation[`${i}`]
                            ?.organizationFromDate?.message
                        }
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Group>
                  <Form.Group
                    className={`form-group mb-3 ${
                      errors?.memberOfInformation &&
                      errors?.memberOfInformation[`${i}`]?.organizationToDate
                        ? 'error-msg'
                        : ''
                    }`}
                    controlId="formBasicEmail"
                  >
                    <Form.Label>To date*</Form.Label>
                    <DatePicker
                      type="organizationToDate"
                      name="organizationToDate"
                      placeholderText="Select Date"
                      disabled={mainApplicanDis ? mainApplicanDis : false}
                      selected={
                        org?.organizationToDate
                          ? new Date(org?.organizationToDate)
                          : new Date()
                      }
                      onChange={(date) =>
                        handleOrganizationPicker(date, 'organizationToDate', i)
                      }
                      className="form-select"
                      dateFormat="dd-MM-yyyy"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                    {errors?.memberOfInformation ? (
                      <span className="error-msg">
                        {
                          errors?.memberOfInformation[`${i}`]
                            ?.organizationToDate?.message
                        }
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Group>
                </div>
                <div className="add-btn">
                  {i >= 1 && !mainApplicanDis && (
                    <Button
                      className="delete-btn"
                      disabled={mainApplicanDis ? mainApplicanDis : false}
                      onClick={() => removeOrganization(i)}
                    >
                      Delete #{i + 1} <i class="las la-minus"></i>
                    </Button>
                  )}
                  <Button
                    onClick={handleAddOrganization}
                    disabled={mainApplicanDis ? mainApplicanDis : false}
                  >
                    Add More <AddIcon />
                  </Button>
                </div>
              </div>
            </>
          ))
        : ''}
      <Form.Group
        className={`form-group ${
          errors?.tripsOutSideInside ? 'error-msg' : ''
        }`}
        controlId="formBasicEmail"
      >
        <Form.Label>
          Trips - outside and inside your country (please start with your most
          recent trip)?*
        </Form.Label>
        <Form.Select
          aria-label="Default select example"
          name="tripsOutSideInside"
          disabled={mainApplicanDis ? mainApplicanDis : false}
          defaultValue={
            personalInformation?.mainApplicant?.tripsOutSideInside
              ? personalInformation?.mainApplicant?.tripsOutSideInside
              : personalInformation?.sponsoredInformation?.tripsOutSideInside
              ? personalInformation?.sponsoredInformation?.tripsOutSideInside
              : ''
          }
          {...register('tripsOutSideInside')}
        >
          <option value="">
            Select
          </option>
          <option value="No">No</option>
          <option value="Yes">Yes</option>
        </Form.Select>
        {errors?.tripsOutSideInside ? (
          <span className="error-msg">
            {errors?.tripsOutSideInside?.message}
          </span>
        ) : (
          ''
        )}
      </Form.Group>
      {watchTripsOutSideInside === 'Yes' ||
      personalInformation?.mainApplicant?.tripsOutSideInside === 'Yes' ||
      personalInformation?.sponsoredInformation?.tripsOutSideInside === 'Yes'
        ? tripsDetail &&
          tripsDetail?.length > 0 &&
          tripsDetail.map((trip, i) => (
            <>
              <div className="education-info">
                <div className="box-grid-two">
                  <Form.Group
                    className={`form-group mb-3 ${
                      errors?.tripInformation &&
                      errors?.tripInformation[`${i}`]?.tripsCountry
                        ? 'error-msg'
                        : ''
                    }`}
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Country*</Form.Label>
                    <Form.Select
                      name="tripsCountry"
                      disabled={mainApplicanDis ? mainApplicanDis : false}
                      defaultValue={trip.tripsCountry ? trip.tripsCountry : ''}
                      onChange={(e) => handleTripsChange(e, i)}
                    >
                      <option value="">
                        Select
                      </option>
                      {countries &&
                        countries?.length > 0 &&
                        countries.map((el) => (
                          <option value={el?.name}>{el?.name}</option>
                        ))}
                    </Form.Select>
                    {errors?.tripInformation &&
                    errors?.tripInformation[`${i}`]?.tripsCountry ? (
                      <span className="error-msg">
                        {errors?.tripInformation[`${i}`]?.tripsCountry?.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Group>
                  <Form.Group
                    className={`form-group mb-3 ${
                      errors?.tripInformation &&
                      errors?.tripInformation[`${i}`]?.tripsCity
                        ? 'error-msg'
                        : ''
                    }`}
                    controlId="formBasicEmail"
                  >
                    <Form.Label>City*</Form.Label>
                    <Form.Control
                      type="text"
                      name="tripsCity"
                      disabled={mainApplicanDis ? mainApplicanDis : false}
                      defaultValue={trip.tripsCity}
                      onChange={(e) => handleTripsChange(e, i)}
                    />
                    {errors?.tripInformation &&
                    errors?.tripInformation[`${i}`]?.tripsCity ? (
                      <span className="error-msg">
                        {errors?.tripInformation[`${i}`]?.tripsCity?.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Group>
                </div>
                <div className="box-grid-two">
                  <Form.Group
                    className={`form-group mb-3 ${
                      errors?.tripInformation &&
                      errors?.tripInformation[`${i}`]?.tripsCountryStatus
                        ? 'error-msg'
                        : ''
                    }`}
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Status in that Country*</Form.Label>
                    <Form.Control
                      type="text"
                      name="tripsCountryStatus"
                      disabled={mainApplicanDis ? mainApplicanDis : false}
                      defaultValue={trip.tripsCountryStatus}
                      onChange={(e) => handleTripsChange(e, i)}
                    />
                    {errors?.tripInformation &&
                    errors?.tripInformation[`${i}`]?.tripsCountryStatus ? (
                      <span className="error-msg">
                        {
                          errors?.tripInformation[`${i}`]?.tripsCountryStatus
                            ?.message
                        }
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Group>
                  <Form.Group
                    className={`form-group mb-3 ${
                      errors?.tripInformation &&
                      errors?.tripInformation[`${i}`]?.tripsReason
                        ? 'error-msg'
                        : ''
                    }`}
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Reason*</Form.Label>
                    <Form.Control
                      type="text"
                      name="tripsReason"
                      disabled={mainApplicanDis ? mainApplicanDis : false}
                      defaultValue={trip.tripsReason}
                      onChange={(e) => handleTripsChange(e, i)}
                    />
                    {errors?.tripInformation &&
                    errors?.tripInformation[`${i}`]?.tripsReason ? (
                      <span className="error-msg">
                        {errors?.tripInformation[`${i}`]?.tripsReason?.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Group>
                </div>

                <div className="box-grid-two">
                  <Form.Group
                    className={`form-group mb-3 ${
                      errors?.tripInformation &&
                      errors?.tripInformation[`${i}`]?.tripsFromDate
                        ? 'error-msg'
                        : ''
                    }`}
                    controlId="formBasicEmail"
                  >
                    <Form.Label>From date*</Form.Label>
                    <DatePicker
                      type="tripsFromDate"
                      name="tripsFromDate"
                      placeholderText="Select Date"
                      disabled={mainApplicanDis ? mainApplicanDis : false}
                      selected={
                        trip?.tripsFromDate
                          ? new Date(trip?.tripsFromDate)
                          : new Date()
                      }
                      onChange={(date) =>
                        handleTripsPicker(date, 'tripsFromDate', i)
                      }
                      className="form-select"
                      dateFormat="dd-MM-yyyy"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                    {errors?.tripInformation ? (
                      <span className="error-msg">
                        {
                          errors?.tripInformation[`${i}`]?.tripsFromDate
                            ?.message
                        }
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Group>
                  <Form.Group
                    className={`form-group mb-3 ${
                      errors?.tripInformation &&
                      errors?.tripInformation[`${i}`]?.tripsToDate
                        ? 'error-msg'
                        : ''
                    }`}
                    controlId="formBasicEmail"
                  >
                    <Form.Label>To date*</Form.Label>
                    <DatePicker
                      type="tripsToDate"
                      name="tripsToDate"
                      placeholderText="Select Date"
                      disabled={mainApplicanDis ? mainApplicanDis : false}
                      selected={
                        trip?.tripsToDate
                          ? new Date(trip?.tripsToDate)
                          : new Date()
                      }
                      onChange={(date) =>
                        handleTripsPicker(date, 'tripsToDate', i)
                      }
                      className="form-select"
                      dateFormat="dd-MM-yyyy"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                    {errors?.tripInformation ? (
                      <span className="error-msg">
                        {errors?.tripInformation[`${i}`]?.tripsToDate?.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Group>
                </div>
                <div className="add-btn">
                  {i >= 1 && !mainApplicanDis && (
                    <Button
                      className="delete-btn"
                      disabled={mainApplicanDis ? mainApplicanDis : false}
                      onClick={() => removeTrips(i)}
                    >
                      Delete #{i + 1} <i class="las la-minus"></i>
                    </Button>
                  )}
                  <Button
                    onClick={handleAddTrips}
                    disabled={mainApplicanDis ? mainApplicanDis : false}
                  >
                    Add More <AddIcon />
                  </Button>
                </div>
              </div>
            </>
          ))
        : ''}
    </>
  )
}
export default MainAccordion

const AddIcon = () => {
  return (
    <svg
      width="9"
      height="10"
      viewBox="0 0 9 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.37278 0.0449985V3.69935H8.83996V5.33043H5.37278V9.00261H3.59017V5.33043H0.140825V3.69935H3.59017V0.0449985H5.37278Z"
        fill="#F35C5C"
      />
    </svg>
  )
}
