/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import logo from '../../assets/images/application/logo.svg'
// import logouticon from "../../assets/images/application/logout.svg";
import { Navbar, Nav } from 'react-bootstrap'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { serverPath } from '../../config/key'
import { useEffect, useRef } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import UserContext from '../../context/UserContext'
import profile from '../../assets/images/visa/avatar.svg'
import arrow from '../../assets/images/visa/arrow.svg'
import caseicon from '../../assets/images/application/case.svg'
import closeIconMob from '../../assets/images/application/mobCloseIcon.svg'

const ApplicationSidebar = () => {
  const navigate = useNavigate()
  const wrapperRef = useRef(null)
  const [visaType, setVisaType] = useState([])
  const { setSelectedVisa, selectedVisa, setAgreementVisa } = useContext(
    UserContext,
  )
  const [dropdown, setDropdown] = useState(false)
  const allVisaType = async () => {
    const res = await axios.get(`${serverPath}/product/allVisaTypes`, {
      headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
    })
    const { visaData } = res?.data || {}
    const { userVisaType = [] } = visaData
    let type = []
    if (userVisaType?.length) {
      userVisaType?.forEach((el) => {
        if (el?.caseIds || el) {
          el?.caseIds?.forEach((visa) => {
            type.push({
              name: visa?.productId?.visaType?.name
                ? `${visa?.productId?.visaType?.name}-${el.invoiceNumber}`
                : `${visa?.productId?.name}-${el.invoiceNumber}`,
              visaName: visa?.productId?.visaType?.name
                ? visa?.productId?.visaType?.name
                : visa?.productId?.name,
              _id: visa?.productId?.visaType?._id,
              productId: visa?.productId?._id,
              invoiceNumber: el?.invoiceNumber,
              invoiceId: el?._id,
              caseId: visa?.caseNumber,
              isEvaluation: visa?.productId?.evaluation
                ? visa?.productId?.evaluation
                : false,
              formsSponsor: visa?.productId?.visaType?.formsSponsor?.length
                ? visa?.productId?.visaType?.formsSponsor
                : [],
              forms: visa?.productId?.visaType?.forms?.length
                ? visa?.productId?.visaType?.forms
                : [],
              agreements: visa.productId?.visaType?.agreement
                ? visa.productId?.visaType?.agreement
                : [],
            })
          })
        }
      })
      setVisaType(type)
    }
  }
  const selectChange = (
    visaId,
    invoiceId,
    caseId,
    agreement,
    name,
    invoiceNumber,
    formsSponsor,
    forms,
    productId,
    isEvaluation,
  ) => {
    setSelectedVisa({
      visaId: visaId ? visaId : '',
      invoiceId: invoiceId ? invoiceId : '',
      caseId: caseId ? caseId : '',
      visaName: name ? name : '',
      invoiceNumber: invoiceNumber ? invoiceNumber : '',
      sponsorForms: formsSponsor ? formsSponsor : [],
      forms: forms ? forms : [],
      productId: productId ? productId : '',
      isEvaluation: isEvaluation,
    })
    setAgreementVisa(agreement)
    setDropdown(false)
  }
  useEffect(() => {
    allVisaType()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // useEffect(() => {
  //   if (selectedVisa?.productId && selectedVisa?.forms?.length > 0) {
  //     navigate('/evaluation')
  //   }
  // }, [selectedVisa])
  const handleLogout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    setSelectedVisa({
      visaId: '',
      invoiceId: '',
      caseId: '',
      visaName: '',
      invoiceNumber: '',
    })
    navigate('/login')
  }

  const handleDropdown = () => {
    setDropdown(true)
  }

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setDropdown(false)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  useOutsideAlerter(wrapperRef)
  return (
    <div className={`application-sidebar`}>
      <div className="application-logo">
        <Link to="/">
          <img src={logo} alt="" />
        </Link>
      </div>
      <div className="custom-dropdown" ref={wrapperRef}>
        <h6 onClick={handleDropdown}>
          <img className="dropdown-avatar" src={profile} alt="profile" />{' '}
          <span>
            {(visaType &&
              visaType?.length > 0 &&
              visaType.find(
                (el) =>
                  el?._id === selectedVisa?.visaId &&
                  el?.invoiceNumber === selectedVisa?.invoiceNumber,
              )?.name) ||
              selectedVisa?.visaName ||
              'Select Visa Type'}
          </span>
          <img src={arrow} alt="profile" />
        </h6>
        {dropdown && (
          <ul>
            {visaType &&
              visaType.length > 0 &&
              visaType.map((el) => (
                <li
                  onClick={() =>
                    selectChange(
                      el?._id,
                      el?.invoiceId,
                      el?.caseId,
                      el?.agreements,
                      el?.visaName,
                      el?.invoiceNumber,
                      el?.formsSponsor,
                      el?.forms,
                      el?.productId,
                      el?.isEvaluation,
                    )
                  }
                >
                  {el.name}
                </li>
              ))}
          </ul>
        )}
      </div>
      <div className="application-menu">
        <div className="close-icon-mob">
          <img src={closeIconMob} alt="" />
        </div>
        <div className="application-case-mob">
          <div className="application-case-icon">
            <span>Carlos Martines</span>
            <div className="application-case-id">
              <img src={caseicon} alt="" /> case:{' '}
              {selectedVisa?.caseId ? selectedVisa?.caseId : ''}
            </div>
          </div>
        </div>
        <Navbar>
          <Nav>
            <NavLink to="/guideline">
              <span>
                <GuidelineIcon />
              </span>{' '}
              Guidelines
            </NavLink>
            <NavLink to="/agreement">
              <span>
                <GuidelineIcon />
              </span>{' '}
              Agreements
            </NavLink>

            {!selectedVisa?.isEvaluation ? (
              <NavLink to="/application/main">
                <span>
                  <ApplicationIcon />
                </span>{' '}
                Application
              </NavLink>
            ) : (
              <NavLink to="/evaluation">
                <span>
                  <EvaluationStartIcon />
                </span>{' '}
                Evaluation
              </NavLink>
            )}
            <NavLink to="/invoices">
              <span>
                <InvoiceIcon />
              </span>{' '}
              Invoices
            </NavLink>
          </Nav>

          <Nav className="settings-mob-view">
            <div className="application-logout" onClick={handleLogout}>
              <span>
                <LogoutIcon /> Log Out
              </span>
            </div>
          </Nav>
        </Navbar>
      </div>
      <div className="application-logout" onClick={handleLogout}>
        <span>
          <LogoutIcon /> Log Out
        </span>
      </div>
    </div>
  )
}

export default ApplicationSidebar

const GuidelineIcon = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16 2H11.82C11.4 0.84 10.3 0 9 0C7.7 0 6.6 0.84 6.18 2H2C0.9 2 0 2.9 0 4V18C0 19.1 0.9 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM9 2C9.55 2 10 2.45 10 3C10 3.55 9.55 4 9 4C8.45 4 8 3.55 8 3C8 2.45 8.45 2 9 2ZM4 16H11V14H4V16ZM14 12H4V10H14V12ZM4 8H14V6H4V8Z"
        fill="#F35C5C"
      />
    </svg>
  )
}

const ApplicationIcon = () => {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 4H10L16 10V22C16 23.1 15.1 24 14 24H1.99C0.89 24 0 23.1 0 22V6C0 4.9 0.9 4 2 4ZM4 20H12V18H4V20ZM12 16H4V14H12V16ZM9 5.5V11H14.5L9 5.5Z"
        fill="#6243CE"
      />
      {/* <path
        d="M19.008 3.98014L18.6 4.69214L17.272 3.92414C17.1813 3.86548 17.096 3.80681 17.016 3.74814C16.936 3.68948 16.8667 3.62281 16.808 3.54814C16.8827 3.71348 16.92 3.90548 16.92 4.12414V5.65214H16.088V4.14014C16.088 3.91081 16.1173 3.71881 16.176 3.56414C16.0747 3.69748 15.928 3.81748 15.736 3.92414L14.416 4.68414L14.008 3.97214L15.32 3.21214C15.512 3.10014 15.7013 3.02814 15.888 2.99614C15.7013 2.97481 15.512 2.90281 15.32 2.78014L14 2.01214L14.408 1.30014L15.736 2.06814C15.8267 2.12681 15.9093 2.18814 15.984 2.25214C16.064 2.31081 16.1333 2.37748 16.192 2.45214C16.1227 2.28148 16.088 2.08681 16.088 1.86814V0.348145H16.92V1.86014C16.92 1.96681 16.912 2.06814 16.896 2.16414C16.8853 2.26014 16.8613 2.35081 16.824 2.43614C16.9467 2.29214 17.096 2.16948 17.272 2.06814L18.592 1.30814L19 2.02014L17.688 2.78014C17.5973 2.83348 17.5067 2.88148 17.416 2.92414C17.3253 2.96148 17.2347 2.98548 17.144 2.99614C17.2347 3.01214 17.3253 3.03881 17.416 3.07614C17.5067 3.10814 17.5973 3.15348 17.688 3.21214L19.008 3.98014Z"
        fill="#6243CE"
      /> */}
    </svg>
  )
}

// const ApplicationStarIconNew = () => {
//   return (
//     <svg
//       width="20"
//       height="24"
//       viewBox="0 0 20 24"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         fill-rule="evenodd"
//         clip-rule="evenodd"
//         d="M2 4H10L16 10V22C16 23.1 15.1 24 14 24H1.99C0.89 24 0 23.1 0 22V6C0 4.9 0.9 4 2 4ZM4 20H12V18H4V20ZM12 16H4V14H12V16ZM9 5.5V11H14.5L9 5.5Z"
//         fill="#6243CE"
//       />
//       <path
//         d="M19.008 3.98014L18.6 4.69214L17.272 3.92414C17.1813 3.86548 17.096 3.80681 17.016 3.74814C16.936 3.68948 16.8667 3.62281 16.808 3.54814C16.8827 3.71348 16.92 3.90548 16.92 4.12414V5.65214H16.088V4.14014C16.088 3.91081 16.1173 3.71881 16.176 3.56414C16.0747 3.69748 15.928 3.81748 15.736 3.92414L14.416 4.68414L14.008 3.97214L15.32 3.21214C15.512 3.10014 15.7013 3.02814 15.888 2.99614C15.7013 2.97481 15.512 2.90281 15.32 2.78014L14 2.01214L14.408 1.30014L15.736 2.06814C15.8267 2.12681 15.9093 2.18814 15.984 2.25214C16.064 2.31081 16.1333 2.37748 16.192 2.45214C16.1227 2.28148 16.088 2.08681 16.088 1.86814V0.348145H16.92V1.86014C16.92 1.96681 16.912 2.06814 16.896 2.16414C16.8853 2.26014 16.8613 2.35081 16.824 2.43614C16.9467 2.29214 17.096 2.16948 17.272 2.06814L18.592 1.30814L19 2.02014L17.688 2.78014C17.5973 2.83348 17.5067 2.88148 17.416 2.92414C17.3253 2.96148 17.2347 2.98548 17.144 2.99614C17.2347 3.01214 17.3253 3.03881 17.416 3.07614C17.5067 3.10814 17.5973 3.15348 17.688 3.21214L19.008 3.98014Z"
//         fill="#6243CE"
//       />
//     </svg>
//   )
// }

const InvoiceIcon = () => {
  return (
    <svg
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0528 12.5001V13.3335C17.0528 14.2502 16.2002 15.0002 15.1581 15.0002H1.89476C0.843167 15.0002 0 14.2502 0 13.3335V1.66668C0 0.750008 0.843167 0 1.89476 0H15.1581C16.2002 0 17.0528 0.750008 17.0528 1.66668V2.50003H8.52641C7.47482 2.50003 6.63165 3.25003 6.63165 4.16671V10.8334C6.63165 11.7501 7.47482 12.5001 8.52641 12.5001H17.0528ZM8.5264 10.8335H18.0002V4.16672H8.5264V10.8335ZM10.8949 7.50016C10.8949 8.19183 11.5296 8.75017 12.316 8.75017C13.1023 8.75017 13.737 8.19183 13.737 7.50016C13.737 6.80848 13.1023 6.25014 12.316 6.25014C11.5296 6.25014 10.8949 6.80848 10.8949 7.50016Z"
        fill="#3A416F"
      />
    </svg>
  )
}
// const ProductIcon = () => {
//   return (
//     <svg
//       width="24"
//       height="24"
//       viewBox="0 0 24 24"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M20 8H4V6H20V8ZM18 2H6V4H18V2ZM22 12V20C22 20.5304 21.7893 21.0391 21.4142 21.4142C21.0391 21.7893 20.5304 22 20 22H4C3.47005 21.9984 2.96227 21.7872 2.58753 21.4125C2.2128 21.0377 2.00158 20.5299 2 20V12C2.00158 11.4701 2.2128 10.9623 2.58753 10.5875C2.96227 10.2128 3.47005 10.0016 4 10H20C20.5299 10.0016 21.0377 10.2128 21.4125 10.5875C21.7872 10.9623 21.9984 11.4701 22 12ZM13.927 17.042L16.25 15.056L13.191 14.8L12 12L10.809 14.8L7.75 15.056L10.073 17.042L9.373 20L12 18.428L14.627 20L13.927 17.042Z"
//         fill="#6243CE"
//       />
//     </svg>
//   );
// };

// const SettingsIcon = () => {
//   return (
//     <svg
//       width="25"
//       height="25"
//       viewBox="0 0 25 25"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         fillRule="evenodd"
//         clipRule="evenodd"
//         d="M14.7337 1.77639C14.1587 -0.592131 10.7902 -0.592131 10.2152 1.77639C9.84376 3.30644 8.0908 4.03254 6.74623 3.21329C4.66484 1.94506 2.28295 4.32695 3.55118 6.40834C4.37043 7.75291 3.64433 9.50585 2.11428 9.87731C-0.254241 10.4523 -0.254241 13.8208 2.11428 14.3958C3.64433 14.7673 4.37043 16.5203 3.55118 17.8647C2.28295 19.9461 4.66484 22.3281 6.74625 21.0598C8.0908 20.2406 9.84376 20.9667 10.2152 22.4968C10.7902 24.8652 14.1587 24.8652 14.7337 22.4968C15.1052 20.9667 16.8582 20.2406 18.2026 21.0598C20.284 22.3281 22.666 19.9461 21.3977 17.8647C20.5785 16.5203 21.3046 14.7673 22.8347 14.3958C25.2031 13.8208 25.2031 10.4523 22.8347 9.87731C21.3046 9.50585 20.5785 7.75291 21.3977 6.40834C22.666 4.32695 20.284 1.94506 18.2026 3.21329C16.8582 4.03254 15.1052 3.30644 14.7337 1.77639ZM12.4745 16.6879C14.9881 16.6879 17.0257 14.6503 17.0257 12.1367C17.0257 9.62312 14.9881 7.58547 12.4745 7.58547C9.96092 7.58547 7.92327 9.62312 7.92327 12.1367C7.92327 14.6503 9.96092 16.6879 12.4745 16.6879Z"
//         fill="#AFB7C4"
//       />
//     </svg>
//   );
// };
const EvaluationStartIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.86 8.49159L22.91 10.5416H22.92C23.2925 10.9163 23.5016 11.4232 23.5016 11.9516C23.5016 12.48 23.2925 12.9869 22.92 13.3616L20.87 15.4116V18.3116C20.87 18.842 20.6593 19.3507 20.2842 19.7258C19.9091 20.1009 19.4004 20.3116 18.87 20.3116H15.96L13.91 22.3716C13.535 22.7435 13.0281 22.952 12.5 22.9516C11.9719 22.952 11.465 22.7435 11.09 22.3716L9.04 20.3216H6.14C5.60957 20.3216 5.10086 20.1109 4.72579 19.7358C4.35071 19.3607 4.14 18.852 4.14 18.3216V15.4116L2.08 13.3616C1.70808 12.9866 1.49958 12.4797 1.5 11.9516C1.50631 11.6865 1.56526 11.4254 1.67342 11.1833C1.78157 10.9413 1.93678 10.7231 2.13 10.5416L4.13 8.49159V5.59159C4.13 5.06115 4.34072 4.55244 4.71579 4.17737C5.09086 3.8023 5.59957 3.59159 6.13 3.59159H9.04L11.09 1.58159C11.4647 1.20908 11.9716 1 12.5 1C13.0284 1 13.5353 1.20908 13.91 1.58159L15.96 3.58159H18.86C19.3904 3.58159 19.8991 3.7923 20.2742 4.16737C20.6493 4.54244 20.86 5.05115 20.86 5.58159V8.49159ZM9.20906 10.3916L11.4991 12.6816L15.7891 8.3916L17.2091 9.8016L11.4991 15.5116L7.78906 11.8016L9.20906 10.3916Z"
        fill="#F35C5C"
      />
    </svg>
  )
}

const LogoutIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.08 12.59L13.67 10H4V8H13.67L11.08 5.41L12.5 4L17.5 9L12.5 14L11.08 12.59ZM16 0C16.5304 0 17.0391 0.210714 17.4142 0.585786C17.7893 0.960859 18 1.46957 18 2V6.67L16 4.67V2H2V16H16V13.33L18 11.33V16C18 16.5304 17.7893 17.0391 17.4142 17.4142C17.0391 17.7893 16.5304 18 16 18H2C0.89 18 0 17.1 0 16V2C0 0.89 0.89 0 2 0H16Z"
        fill="#6243CE"
        // fill-opacity="0.2"
      />
    </svg>
  )
}
