/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
//import { useLocation } from 'react-router-dom'
import Headroom from "react-headroom";
import envelope from "../../assets/images/header/envelope.svg";
import envelopewhite from "../../assets/images/header/envelope-white.svg";
import envelopewhitedark from "../../assets/images/header/envelope-white-dark.svg";
import call from "../../assets/images/header/call.svg";
import callwhite from "../../assets/images/header/call-white.svg";
import callwhitedark from "../../assets/images/header/call-white-dark.svg";
import searchicon from "../../assets/images/header/search.svg";
import searchiconlight from "../../assets/images/header/search-light.svg";
import loginicon from "../../assets/images/header/login.svg";
import loginiconwhite from "../../assets/images/header/login-white.svg";
import logo from "../../assets/images/header/logo.svg";
import logowhite from "../../assets/images/header/logo-white.svg";
import menuicon from "../../assets/images/header/menu.svg";
import menuiconwhite from "../../assets/images/header/menu-white.svg";
import searchmbl from "../../assets/images/header/search-mbl.svg";
import searchmblwhite from "../../assets/images/header/search-mbl-white.svg";
import loginmbl from "../../assets/images/header/login-mbl.svg";
import loginmblwhite from "../../assets/images/header/login-mbl-white.svg";
import headBannerImg from "../../assets/images/header/headBannerImg.png";
import bannerMobile from "../../assets/images/header/bannerMobile.png";
import forwardArrow from "../../assets/images/header/forwardArrow.svg";
import Cancel from "../../assets/images/header/Cancel.svg";
import { Button, Dropdown, Nav } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import "./header.css";
import { useContext } from "react";
import UserContext from "../../context/UserContext";
// import GetUserDetailForPayment from '../Payments/getUserDetail'
import profile from "../../assets/images/visa/avatar.svg";
// import UserContext from "../../context/UserContext";

const Header = ({
  head,
  scrollToElement,
  handleSearch,
  scrollToElementVisa,
  bannerView,
  setBannerView,
}) => {
  // const location = useLocation();
  const navigate = useNavigate();
  const { token, setUserGeoData, user } = useContext(UserContext);
  // const [open, setOpen] = useState(false)
  const { t, i18n } = useTranslation();
  const changeLanguage = (event) => {
    i18n.changeLanguage(event);
  };

  const [pageScroll, setPageScroll] = useState(0);

  const [menuopen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuopen);
  };
  const handleCloseBanner = () => {
    setBannerView(!bannerView);
  };
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  const isSticky = (e) => {
    const header = document.querySelector(".header");
    const scrollTop = window.scrollY;
    scrollTop >= 50
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
  };

  const originData = async () => {
    const res = await axios.get(
      "https://geolocation-db.com/json/8dd79c70-0801-11ec-a29f-e381a788c2c0"
    );
    setUserGeoData(res.data);
  };

  useEffect(() => {
    originData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // useEffect(() => {
  //   const getDetail = new URLSearchParams(search).get('user')
  //   if (getDetail === 'get') {
  //     setOpen(true)
  //   }
  // }, [])
  // const handleLogout = () => {
  //   localStorage.removeItem('token')
  //   setToken('')
  //   navigate('/login')
  // }

  const hrefStyle = {
    color: "inherit",
  };
  const initialScreen = window.outerWidth;

  const [screenSize, setScreenSize] = useState(initialScreen);

  useEffect(() => {
    setScreenSize(initialScreen);
  }, [initialScreen]);

  const handleResize = (e) => {
    const currentScreenSize = e.target.outerWidth;
    setScreenSize(currentScreenSize);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleLearn = () => {
    navigate("/WorldTravel");
  };
  useEffect(() => {
    window.addEventListener("scroll", function () {
      setPageScroll(window.scrollY);
    });
    return () => {
      window.removeEventListener("scroll", console.log("unload"));
    };
  }, []);
  return (
    <div
      className={`header ${bannerView ? "extra-padding" : ""} ${
        menuopen && bannerView ? "menu-open" : ""
      } ${head ? `white-header` : ``}`}
    >
      {/* <GetUserDetailForPayment open={open} /> */}
      <div className={`top-header-banner ${bannerView ? "show" : "close"} `}>
        <div className='container'>
          <div className='headerBanner-container' onClick={handleLearn}>
            <div className='headerBanner-img'>
              {screenSize < 767 ? (
                <img src={bannerMobile} alt='' />
              ) : (
                <img src={headBannerImg} alt='' />
              )}
            </div>
            <div className='headerBanner-title'>
              <div className='kutumText'>Ready for an Adventure?</div>
              <div className='Ralewaytext'>
                Working Holiday Packages Available Now!
              </div>
            </div>
            <div className='headerBanner-learnBtn'>
              <Button>
                <p>Learn More</p>
                <img src={forwardArrow} alt='' />
              </Button>
            </div>
          </div>
          <div className='headerBanner-cancelBtn'>
            <img src={Cancel} alt='' onClick={handleCloseBanner} />
          </div>
        </div>
      </div>
      <div className={`top-header ${bannerView ? "" : "headerTopChange"}`}>
        <div className='container'>
          <div className='top-header-grid'>
            <div className='contact-info'>
              <span>
                {head ? (
                  <img src={envelopewhite} alt='' />
                ) : (
                  <img src={envelope} alt='' />
                )}
                {/* {t('contactEmail')} */}
                <a href={"mailto:" + t("contactEmail")} style={hrefStyle}>
                  {t("contactEmail")}
                </a>
              </span>
              <span>
                {head ? (
                  <img src={callwhite} alt='' />
                ) : (
                  <img src={call} alt='' />
                )}
                {t("contactPhone")}
              </span>
            </div>

            <div className='auth-info'>
              <div
                className='search-icon'
                onClick={handleSearch}
                role='presentation'
              >
                {head ? (
                  <img src={searchiconlight} alt='' />
                ) : (
                  <img src={searchicon} alt='' />
                )}
              </div>
              <div className='language-dropdown'>
                <Dropdown>
                  <Dropdown.Toggle variant='success' id='dropdown-basic'>
                    {head ? (
                      <span className='white-lang'>{i18n.language}</span>
                    ) : (
                      <span>{i18n.language}</span>
                    )}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => changeLanguage("en")}>
                      English
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => changeLanguage("fr")}>
                      French
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              {
                <div className='auth-login'>
                  {token ? (
                    <div className='language-dropdown my-app'>
                      {/* <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          {user?.name || user?.firstName}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {user && user?.role === 'admin' ? (
                            <>
                              <Link className="dropdown-item" to="/allinvoices">
                                All Invoices
                              </Link>
                              <Link
                                className="dropdown-item"
                                to="/manualinvoice"
                              >
                                Manual Invoice
                              </Link>
                              <Link
                                className="dropdown-item"
                                to="/invoiceReport"
                              >
                                Invoice Report
                              </Link>
                              <Link className="dropdown-item" to="/users">
                                Manage Users
                              </Link>
                              <Link
                                className="dropdown-item"
                                to="/viewPayments"
                              >
                                View Payments
                              </Link>
                            </>
                          ) : (
                            <Link className="dropdown-item" to="/welcome">
                              My Application
                            </Link>
                          )}

                          <Dropdown.Item onClick={() => handleLogout()}>
                            Logout
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown> */}
                      {user && user?.role?.includes("client") ? (
                        <Link to='/application/main'>
                          My App <img src={profile} alt='profile' />
                        </Link>
                      ) : (
                        <Link to='/admin'>
                          My App <img src={profile} alt='profile' />
                        </Link>
                      )}
                    </div>
                  ) : (
                    //https://www.uiscanada.com
                    <a href='/login/'>
                      <span>{t("topHeader.signin")}</span>{" "}
                      {head ? (
                        <img src={loginiconwhite} alt='' />
                      ) : (
                        <img src={loginicon} alt='' />
                      )}
                    </a>
                  )}
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <Headroom>
        <div
          className={`main-navigation ${menuopen ? `open-menu` : ``} ${
            bannerView ? "navigation-with-banner" : "topChange"
          }`}
        >
          <div className='container'>
            <div className='main-navigation-grid'>
              <div className='logo'>
                <Link to='/'>
                  {head ? (
                    <img src={logowhite} alt='' />
                  ) : (
                    <img src={pageScroll >= 10 ? logowhite : logo} alt='' />
                  )}
                </Link>
              </div>

              <div className='mobile-icons'>
                <span onClick={handleSearch} role='presentation'>
                  {head ? (
                    <img src={searchmblwhite} alt='' />
                  ) : (
                    <img src={searchmbl} alt='' />
                  )}
                </span>
                <span>
                  {token ? (
                    <Link to='welcome'>My Application</Link>
                  ) : (
                    <a href='https://www.uiscanada.com/login/'>
                      {head ? (
                        <img src={loginmblwhite} alt='' />
                      ) : (
                        <img src={loginmbl} alt='' />
                      )}
                    </a>
                  )}
                </span>
              </div>

              <div className='menu-icon' onClick={toggleMenu}>
                <span>{t("header.menu")}</span>
                {head ? (
                  <img src={menuiconwhite} alt='' />
                ) : (
                  <img src={menuicon} alt='' />
                )}
              </div>

              <div className='menu-grid'>
                <Nav>
                  <Nav.Item>
                    <span onClick={scrollToElementVisa}>
                      {t("header.visa")}
                    </span>
                  </Nav.Item>
                  <Nav.Item>
                    <Link to='/about'>{t("header.about")}</Link>
                  </Nav.Item>
                  <Nav.Item>
                    <span onClick={scrollToElement}>{t("header.faq")}</span>
                  </Nav.Item>
                  {/* <Nav.Item>
                            <a href="https://www.uiscanada.com/blog/">Blog</a>
                          </Nav.Item> */}
                  <Nav.Item>
                    <Link to='/reviews'>{t("header.review")}</Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Link to='/contact'>{t("header.contact")}</Link>
                  </Nav.Item>
                </Nav>

                <div className='language-mobile'>
                  <div className='contact-info'>
                    <span>
                      <img src={envelopewhitedark} alt='' />
                      {t("contactEmail")}
                    </span>
                    <span>
                      <img src={callwhitedark} alt='' />
                      {t("contactPhone")}
                    </span>
                  </div>
                  <div className='language-dropdown'>
                    <Dropdown>
                      <Dropdown.Toggle variant='success' id='dropdown-basic'>
                        <span className='white-lang'>{i18n.language}</span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => changeLanguage("en")}>
                          English
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => changeLanguage("fr")}>
                          French
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Headroom>
    </div>
  );
};

export default Header;
