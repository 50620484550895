import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { serverPath } from '../../config/key'
import { worldCardScriptBaseUrl } from '../../config/key'
import Footer from '../footer/footer'
const WorldCardForm = () => {
  const { checkoutId } = useParams()
  useEffect(() => {
    const appendSdkScript = () => {
      const script = document.createElement('script')
      script.src = `${worldCardScriptBaseUrl}/v1/paymentWidgets.js?checkoutId=${checkoutId}`
      document.body.append(script)
    }

    appendSdkScript()
  }, [checkoutId])

  return (
    <div className="paymentWidgetsPage">
      <div className="paymentWidgetsFormWrapper">
      <form
        action={serverPath + '/payment-provider/worldcardCallback'}
        className="paymentWidgets"
        data-brands="VISA MASTER"
      ></form>
      <div className="charge-text">
        <p>
          The charge on your card statement will appear as{' '}
          <Link
            to="https://www.uiscanada.com/"
            style={{ textDecoration: 'none' }}
          >
            uiscanada.com
          </Link>
        </p>
      </div>
      </div>
      <Footer />
    </div>
  )
}

export default WorldCardForm
