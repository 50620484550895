export const selEmpVisaAdaptabilityMain = [
  {
    name: 'spouseCommonLawPartnerEducationCred',
    label:
      'Proof of Spouse or Common-Law Partner Earned Educational Credential(s) ',
    secondLabel: '',
  },
  {
    name: 'spouseCommonLawPartnerEquivalentStud',
    label: 'Proof of Spouse or Common-Law Partner Full Time Equivalent Studies',
    secondLabel: '',
  },
  {
    name: 'spouseCommonLawPartnerStudiesCanada',
    label:
      'Proof of Spouse or Common-Law Partner Studies in Canada (Full Time Studies Under Study Permit)',
    secondLabel: '',
  },
  {
    name: 'spouseCommonLawPartnerWorkExpCanada',
    label:
      'Proof of Spouse or Common-Law Partner Work Experience in Canada (Full Time Under Work Permit)',
    secondLabel: '',
  },
  {
    name: 'accompanyingSpouseCommonLawPartner',
    label: `Proof of Your Accompanying Spouse or Common-Law Partner's Familial Relationship to a Canadian Citizen or Permanent Resident (Living in Canada)`,
    secondLabel: '',
  },
]
