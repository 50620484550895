import React from "react";
import Layout from "../layout/layout";
import { useTranslation } from "react-i18next";
import holidayicon from "../../assets/images/visa/holiday-icon.svg";
import holidayicon1 from "../../assets/images/visa/holiday-icon-1.svg";
import holidayicon2 from "../../assets/images/visa/holiday-icon-2.svg";
import holidayicon3 from "../../assets/images/visa/holiday-icon-3.svg";
import holidayicon4 from "../../assets/images/visa/holiday-icon-4.svg";
import tick from "../../assets/images/visa/tick-icon.svg";
import holiday from "../../assets/images/visa/holiday-new.png";
import "./visa.css";
import PlansSection from "../WorldTravel/planSection";
import bg2 from "../../assets/images/worldTravel/Union2.png";

const HolidayVisa = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <div className="worker-visa-page holiday-visa">
        <div className="worker-visa-banner">
          <div className="container">
            <div className="holiday-title">
              <div className="worker-visa-banner-heading">
                <img src={holidayicon} alt="" />
                <div className="worker-visa-banner-heading-title">
                  <h5>{t("visaHoliday.holidayHeading1")}</h5>
                  <h2>{t("visaHoliday.holidayHeading2")}</h2>
                </div>
              </div>
              <div className="holiday-subtitle">
                <p>{t("visaHoliday.holidaySubTitle")}</p>
              </div>
            </div>
            <div className="worker-grid">
              <div className="worker-content">
                <h3>{t("visaHoliday.holidayHeading3")}</h3>
                <p>{t("visaHoliday.holidayParagraph5")}</p>
                <ol>
                  <li>{t("visaHoliday.holidayParagraph6")}</li>
                  <li>{t("visaHoliday.holidayParagraph7")}</li>
                  <li>{t("visaHoliday.holidayParagraph8")}</li>
                </ol>
                <p>{t("visaHoliday.holidayParagraph9")}</p>
              </div>

              <div className="worker-cover">
                <img src={holiday} alt="" />
              </div>
            </div>
          </div>
          <div className="curvebg holiday-first">
            <img src={bg2} alt="" />
          </div>
          <div className="holiday-work-section">
            <div className="container">
              <div className="how-works-section">
                <h2>{t("visaHoliday.holidayHeading4")}</h2>
                <div className="how-works-list">
                  <div className="how-works-box">
                    <div className="how-works-box-img">
                      <img src={holidayicon1} alt="icon" />
                    </div>
                    <div className="how-works-box-content">
                      <p>
                        <span>{t("visaHoliday.holidayParagraph10")}</span>{" "}
                        {t("visaHoliday.holidayParagraph11")}{" "}
                        <a
                            rel="noreferrer"
                          href="https://www.cic.gc.ca/english/work/iec/selections.asp"
                          target="_blank"
                        >
                          {t("visaHoliday.holidayParagraph12")}
                        </a>{" "}
                        , {t("visaHoliday.holidayParagraph13")}
                      </p>
                    </div>
                  </div>
                  <div className="how-works-box">
                    <div className="how-works-box-img">
                      <img src={holidayicon2} alt="icon" />
                    </div>
                    <div className="how-works-box-content">
                      <p>
                        <span>{t("visaHoliday.holidayParagraph14")}</span>{" "}
                        {t("visaHoliday.holidayParagraph15")}
                      </p>
                    </div>
                  </div>
                  <div className="how-works-box">
                    <div className="how-works-box-img">
                      <img src={holidayicon4} alt="icon" />
                    </div>
                    <div className="how-works-box-content">
                      <p>
                        <span>{t("visaHoliday.holidayParagraph16")}</span>{" "}
                        {t("visaHoliday.holidayParagraph17")}
                      </p>
                    </div>
                  </div>
                  <div className="how-works-box">
                    <div className="how-works-box-img">
                      <img src={holidayicon3} alt="icon" />
                    </div>
                    <div className="how-works-box-content">
                      <p>
                        <span>{t("visaHoliday.holidayParagraph18")}</span>{" "}
                        {t("visaHoliday.holidayParagraph19")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="eligibilty-requirments">
              <div className="eligibilty-requirments-title">
                <h2>{t("visaHoliday.holidayHeading5")}</h2>
                <p>{t("visaHoliday.holidayParagraph20")}</p>
              </div>
              <div className="eligibilty-requirments-list">
                <p>
                  <img src={tick} alt="icon" />{" "}
                  {t("visaHoliday.holidayParagraph21")}
                </p>
                <p>
                  <img src={tick} alt="icon" />{" "}
                  {t("visaHoliday.holidayParagraph22")}
                </p>
                <p>
                  <img src={tick} alt="icon" />{" "}
                  {t("visaHoliday.holidayParagraph23")}
                </p>
                <p>
                  <img src={tick} alt="icon" />{" "}
                  {t("visaHoliday.holidayParagraph24")}
                </p>{" "}
                <p>
                  <img src={tick} alt="icon" />{" "}
                  {t("visaHoliday.holidayParagraph25")}
                </p>{" "}
                <p>
                  <img src={tick} alt="icon" />{" "}
                  {t("visaHoliday.holidayParagraph26")}
                </p>{" "}
                <p>
                  <img src={tick} alt="icon" />{" "}
                  {t("visaHoliday.holidayParagraph27")}
                </p>{" "}
              </div>
              <div className="warning-note">
                <p>{t("visaHoliday.holidayParagraph28")}</p>
              </div>
            </div>
          </div>
        </div>
        <PlansSection />
      </div>
    </Layout>
  );
};

export default HolidayVisa;
