import React from "react";
import img from "../../assets/images/worldTravel/image.png";
import img1 from "../../assets/images/worldTravel/image1.png";

const TravelSection = () => {
  return (
    <div>
      <div className='WorldTravel-descp WorldTravel-plans'>
        <div className='container'>
          <div className='WorldTravel-transform'>
            {/* <div className="worldTravelTransform-content">
                <h2>
                  How a Working Holiday in Canada <br />
                  Can <span>Transform You</span>
                </h2>
                <p>
                  There are many ways in which a Working Holiday in Canada can
                  transform your life. Along with gaining new skills and forming
                  valuable professional connections, you'll also become more
                  self-confident, open-minded, and globally aware by immersing
                  yourself in a different culture. Invest in your growth and
                  development by booking your Working Holiday in Canada today!
                </p>
              </div> */}
            <div className='WorldTravel-descp-content'>
              <div className='WorldTravel-transform WorldTravel-transform-card1'>
                <div className='worldTravelTransform-content'>
                  <h2>
                    How a Working Holiday in Canada <br />
                    Can <span>Transform You</span>
                  </h2>
                  <p>
                    There are many ways in which a Working Holiday in Canada can
                    transform your life. Along with gaining new skills and
                    forming valuable professional connections, you'll also
                    become more self-confident, open-minded, and globally aware
                    by immersing yourself in a different culture. Invest in your
                    growth and development by booking your Working Holiday in
                    Canada today!
                  </p>
                </div>
                <div className='worldTravelTransform-image'>
                  <img src={img} alt='' />
                </div>
              </div>
              <div className='WorldTravel-transform '>
                <div className='worldTravelTransform-image'>
                  <img src={img1} alt='' />
                </div>
                <div className='worldTravelTransform-content'>
                  <h2>
                    <span>The Benefits</span> of UIS Canada’s Working <br />{" "}
                    Holiday Packages
                  </h2>
                  <p>
                    There are many ways in which a Working Holiday in Canada can
                    transform your life. Along with gaining new skills and
                    forming valuable professional connections, you'll also
                    become more self-confident, open-minded, and globally aware
                    by immersing yourself in a different culture. Invest in your
                    growth and development by booking your Working Holiday in
                    Canada today!
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="WorldTravel-transform">
              <div className="worldTravelTransform-image">
                <img src={img1} alt="" />
              </div>
              <div className="worldTravelTransform-content">
                <h2>
                  <span>The Benefits</span> of UIS Canada’s Working <br />{" "}
                  Holiday Packages
                </h2>
                <p>
                  There are many ways in which a Working Holiday in Canada can
                  transform your life. Along with gaining new skills and forming
                  valuable professional connections, you'll also become more
                  self-confident, open-minded, and globally aware by immersing
                  yourself in a different culture. Invest in your growth and
                  development by booking your Working Holiday in Canada today!
                </p>
              </div>
            </div> */}
        </div>
      </div>
    </div>
  );
};

export default TravelSection;
