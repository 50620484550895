/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useState } from 'react'
import { Accordion } from 'react-bootstrap'
import GeneralInfo from './generalDocInfo'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import axios from 'axios'
import { serverPath } from '../../config/key'
import { useContext } from 'react'
import UserContext from '../../context/UserContext'
import approved from '../../assets/images/header/approved.svg'

import ErrorModal from '../modals/errorModal'
import {
  doubleEvaluationGenMain,
  doubleEvaluationSpouse,
  singleEvaluationGenMain,
} from '../../utils/visaDocuments/main/evaluationGenMain'
import GeneralSpouseInfo from './spouseGenralInfo'
import DocumentPopup from '../visaForm/ducumentPopup'
const Documents = ({
  documentInfo,
  setDocumentInfo,
  updFormType,
  adminFormDisable,
}) => {
  const urlParams = new URLSearchParams(window.location.search)
  const token = urlParams.get('t')
  const { selectedVisa, setSelectedVisa } = useContext(UserContext)
  const [documentPopup, setDocumentPopup] = useState(true)
  const [documentDis, setDocumentDis] = useState(false)
  const [generalDocumentMainInfo, setGeneralDocumentMainInfo] = useState([])
  const [spouseGeneralInfo, setSpouseGeneralInfo] = useState([])
  // const [customAccordBtn, setCustomAccordBtn] = useState('')
  const [requiredDocument, setRequiredDocument] = useState([])
  const [errors, setErrors] = useState([])
  const [showErrorModal, setShowErrorModal] = useState(false)
  useEffect(() => {
    if (selectedVisa && selectedVisa?.visaName === 'Single Evaluation') {
      setGeneralDocumentMainInfo(singleEvaluationGenMain)
      setRequiredDocument([...singleEvaluationGenMain])
    }
    if (selectedVisa && selectedVisa?.visaName === 'Double Evaluation') {
      setGeneralDocumentMainInfo(doubleEvaluationGenMain)
      setSpouseGeneralInfo(doubleEvaluationSpouse)
      setRequiredDocument([
        ...doubleEvaluationGenMain,
        ...doubleEvaluationSpouse,
      ])
    }
  }, [selectedVisa])
  useEffect(() => {
    if (
      documentInfo &&
      Object.keys(documentInfo)?.length !== 0 &&
      documentInfo?.isLocked
    ) {
      setDocumentDis(token ? adminFormDisable : true)
    }
  }, [documentInfo, adminFormDisable])
  const handleShowErrorModal = () => {
    setShowErrorModal(false)
  }
  useEffect(() => {
    if (errors.length > 0) {
      setShowErrorModal(true)
    } else {
      setShowErrorModal(false)
    }
  }, [errors])
  const updateDocumentStatus = async () => {
    try {
      let error = []
      if (!documentInfo && requiredDocument?.length > 0) {
        requiredDocument.forEach((el) => {
          if (el.isRequired) {
            error.push({ name: el.name, message: 'This document is required' })
          }
        })
        if (error?.length) {
          return setErrors(error)
        }
      }
      if (documentInfo && requiredDocument?.length > 0) {
        requiredDocument?.forEach((el) => {
          if (!documentInfo[`${el?.name?.toString()}`] && el?.isRequired) {
            error.push({ name: el.name, message: 'This document is required' })
          }
        })
        if (error?.length > 0) {
          return setErrors(error)
        }
      }
      if (error?.length === 0) {
        const res = await axios.post(
          `${serverPath}/evaluation/updateDocumentStatus`,
          {
            productId: selectedVisa?.productId,
            invoiceId: selectedVisa?.invoiceId,
          },
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          },
        )
        if (res?.data?.updatedDoc) {
          const data = res?.data?.updatedDoc
          setDocumentInfo(data)
          setSelectedVisa({
            invoiceId: data?.invoiceId,
            productId: data?.productId,
            caseId: selectedVisa.caseId,
            visaName: selectedVisa?.visaName,
            isEvaluation: selectedVisa.isEvaluation,
          })
          toast.success('Document Save successfully', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          })
        }
      }
    } catch (err) {
      if (err?.response?.data) {
        toast.error(err.response.data.msg, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
      }
    }
  }
  // const changeAccordionselect = (key) => {
  //   console.log('Key==>', key)
  //   if (key === '1') {
  //     setSpouseDocuments('open')
  //   }
  //   if (key === '2') {
  //     setChildrenDocuments('open')
  //   }
  // }
  // const handleDelete = (value, id) => {
  //   if (value === 'spouseDocuments') {
  //     setSpouseDocuments('plus')
  //   }
  //   if (value === 'childrenDocuments') {
  //     setChildrenDocuments('plus')
  //   }
  //   document.getElementById(id).click()
  // }
  //custom accordian toggle
  // function CustomToggle({ children, eventKey }) {
  //   const decoratedOnClick = useAccordionButton(eventKey, () => {
  //     if (customAccordBtn === eventKey) {
  //       setCustomAccordBtn('')
  //     } else setCustomAccordBtn(eventKey)
  //   })
  //   return (
  //     <Button id={`doc-${eventKey}`} onClick={decoratedOnClick}>
  //       {children}
  //     </Button>
  //   )
  // }
  return (
    <div className="personal-info document-info">
      {documentPopup && (
        <>
          <div className="bg-shadow" />
          <DocumentPopup setDocumentPopup={setDocumentPopup} />
        </>
      )}
      <Accordion
        defaultActiveKey="0"
        // onSelect={(key) => changeAccordionselect(key)}
      >
        <div className={`accordion-list ${'change-accordion-icon'}`}>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Main Applicant</Accordion.Header>
            <Accordion.Body>
              <Accordion className="inner-accordion">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>General</Accordion.Header>
                  <Accordion.Body>
                    <GeneralInfo
                      documentDis={documentDis}
                      documentInfo={documentInfo}
                      setDocumentInfo={setDocumentInfo}
                      generalDocumentMainInfo={generalDocumentMainInfo}
                      errors={errors}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <div className="disclaimer-text">
                <p>
                  <span>Disclaimer:</span> This checklist contains the basic,
                  mandatory documentation based on the type of visa you're
                  applying for.Nevertheless, it's common for the Canadian
                  immigration authorities to request supplementary information /
                  documentation during advanced stages of the application
                  process. You will be informed about these requirements if
                  needed, as soon as we are notified by the immigration
                  authorities.
                </p>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </div>
        {spouseGeneralInfo?.length > 0 ? (
          <div className={`accordion-list ${'change-accordion-icon'}`}>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Spouse (Partner) Applicant</Accordion.Header>
              <Accordion.Body>
                <Accordion className="inner-accordion">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>General</Accordion.Header>
                    <Accordion.Body>
                      <GeneralSpouseInfo
                        documentDis={documentDis}
                        documentInfo={documentInfo}
                        setDocumentInfo={setDocumentInfo}
                        updFormType={updFormType}
                        spouseGeneralInfo={spouseGeneralInfo}
                        errors={errors}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <div className="disclaimer-text">
                  <p>
                    <span>Disclaimer:</span> This checklist contains the basic,
                    mandatory documentation based on the type of visa you're
                    applying for.Nevertheless, it's common for the Canadian
                    immigration authorities to request supplementary information
                    / documentation during advanced stages of the application
                    process. You will be informed about these requirements if
                    needed, as soon as we are notified by the immigration
                    authorities.
                  </p>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </div>
        ) : (
          ''
        )}
      </Accordion>

      <div className="visa-btn">
        {!documentDis ? (
          <>
            <Button
              type="submit"
              disabled={documentDis ? documentDis : false}
              onClick={updateDocumentStatus}
              className={`${documentDis ? 'success-btn' : ''}`}
            >
              Save {documentDis && <img src={approved} alt="approved" />}
            </Button>
            <div className="visa-desc-text">
              <p>
                Once your form is completed, you will be able to make new
                changes by contacting our Support team.
              </p>
              <p>When you're ready, click "Submit"!</p>
            </div>
          </>
        ) : (
          <div className="for-new-changes">
            <p>
              <span>Important! </span> Please contact our Support team if you
              would like to make new changes .
            </p>
          </div>
        )}
      </div>
      <ErrorModal show={showErrorModal} handleClose={handleShowErrorModal} />
    </div>
  )
}
export default Documents
