export const workingHolydayVisaRelationMain = [
  {
    name: 'commonLawDeclaration',
    label: 'Common-Law Declaration',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'additionalFamilyInformation',
    label: 'Additional Family Information IMM 5406',
    secondLabel: '',
    isRequired: false,
  },
  {
    name: 'statutoryCommonLawDeclaration',
    label: 'Statutory Declaration of Common-Law Union IMM 5409 - Original',
    secondLabel: '',
    isRequired: false,
  },
]
export const investorVisaRelation = [
  {
    name: 'commonLawDeclaration',
    label: 'Common-Law Declaration',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'additionalFamilyInformation',
    label: 'Additional Family Information IMM 5406',
    secondLabel: '',
    isRequired: false,
  },
  {
    name: 'statutoryCommonLawDeclaration',
    label: 'Statutory Declaration of Common-Law Union IMM 5409 - Original',
    secondLabel: '',
    isRequired: false,
  },
]
export const selfEmpVisaRelation = [
  {
    name: 'commonLawDeclaration',
    label: 'Common-Law Declaration',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'additionalFamilyInformation',
    label: 'Additional Family Information IMM 5406',
    secondLabel: '',
    isRequired: false,
  },
  {
    name: 'statutoryCommonLawDeclaration',
    label: 'Statutory Declaration of Common-Law Union IMM 5409 - Original',
    secondLabel: '',
    isRequired: false,
  },
]
