/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, useEffect } from 'react'
// import ApplicationLayout from '../myapplication/layout'
import { Tab, Tabs, Button, Spinner } from 'react-bootstrap'
import PersonalInfo from './personalInfo'
import './index.css'
import EducationInfo from './educationInfo'
import WorkExperience from './workExperience'
import LanguagesSection from './languagesSection'
import Documents from './documents'
// import BusinessInfo from './businessInfo'
// import AdaptabilityInfo from './adaptabilityInfo'
import UserContext from '../../context/UserContext'
import { serverPath } from '../../config/key'
import axios from 'axios'
import lock from '../../assets/images/visa/lock.svg'
import AdaptabilityInfo from './adaptabilityInfo'
import BusinessInfo from './businessInfo'
import './index.css'
import AknowledgePopup from './acknowledgePopup'
import { toast } from 'react-toastify'
import Loader from '../loader/loader'
import {useNavigate} from "react-router-dom";

const SupportVisaForm = () => {
  // const { formType } = useParams()
  const urlParams = new URLSearchParams(window.location.search)
  const token = urlParams.get('t')

  const [openVisaApplication, setOpenVisaApplication] = useState(false)
  ///const [visaDetail, setVisaDetail] = useState({})
  const { user, selectedVisa, setSavePercentage, setSelectedVisa } = useContext(
    UserContext,
  )
  const [openForm, setOpenForm] = useState([])
  const [showSelectedMessage, setShowSelectMessage] = useState(false)
  const [personalInformation, setPersonalInfo] = useState()
  const [educationInfo, setEducationInfo] = useState()
  const [experienceInfo, setExperienceInfo] = useState()
  const [LanguageInfo, setLanguageInfo] = useState()
  const [documentInfo, setDocumentInfo] = useState()
  const [saveCount, setSaveCount] = useState(0)
  const [formCount, setFormCount] = useState(0)
  const [sponsorForms, setSponsorForms] = useState([])
  const [showFormTab, setShowFormTab] = useState([])
  const [adaptabilityInfo, setAdaptabilityInfo] = useState()
  const [businessInfo, setBusinessInfo] = useState()
  const [visaFormData, setVisaFormData] = useState()
  const [activeTabKey, setActiveTabKey] = useState('personalInfo')
  const [formType, setFormType] = useState('forms')

  const [loading, setLoading] = useState(false)
  const [mainLoading, setMainLoading] = useState(false)
  const [aknowledgeState, setAcknowledgeState] = useState(false)
  const [activeEditable, setActiveEditable] = useState('')
  const [texts, setTexts] = useState('')
  const [productName /* setProductName */] = useState('')
  const [unlockClient,setUnlockClient]=useState(false)
  const navigate = useNavigate()

  const checkProVisaType = async () => {
    setPersonalInfo()
    setExperienceInfo()
    setOpenForm([])
    setShowFormTab([])
    setSponsorForms([])
    setMainLoading(true)
    const res = await axios.get(`${serverPath}/support/application`, {
      headers: { authorization: `Bearer ${token}` },
    })
    setMainLoading(false)
    const { visaData } = res?.data?.visaData || {}
    setVisaFormData(res.data.visaData)
    const { visaType } = visaData?.productIds[0] || {}
    if (visaData?.productIds?.length && visaType?.forms?.length) {
      setSelectedVisa({
        ...selectedVisa,
        visaName: visaType?.name
          ? visaType?.name
          : visaData?.productIds[0].name,
      })
      setFormCount(visaType?.forms?.length)
      setOpenForm(visaType?.forms)
      setFormType('main')
      // navigate('/application/main')
    } else {
      navigate('/support/evaluation?t=' + token);
    }
    if (visaData?.productIds?.length && visaType?.formsSponsor?.length) {
      setSponsorForms(visaType?.formsSponsor)
      setFormType('main')
      // setFormType('sponsored')
      // if (formType === 'sponsored') {
      //   setFormType('sponsored')
      // }
      // if (formType === 'sponsoring') {
      //   setFormType('sponsoring')
      // }
      // if (formType === 'main') {
      //   setFormType('sponsored')
      // }
    }
  }
  useEffect(() => {
    if (formType === 'sponsoring') {
      setShowFormTab(sponsorForms)
      let count = []
      if (visaFormData?.personalSponsoringInfo?.isLocked) {
        count.push(1)
      }
      setPersonalInfo(visaFormData?.personalSponsoringInfo)
      if (visaFormData?.workExperienceSponsoringInfo?.isLocked) {
        count.push(2)
      }
      if (visaFormData?.documentSponsoringInfo?.isLocked) {
        count.push(2)
      }
      setDocumentInfo(visaFormData?.documentSponsoringInfo)
      setExperienceInfo(visaFormData?.workExperienceSponsoringInfo)
      setSaveCount(count?.length)
    } else if (formType === 'sponsored') {
      setShowFormTab(openForm)
      let count = []
      if (visaFormData?.personalSponsoredInfo?.isLocked) {
        count.push(1)
      }
      setPersonalInfo(visaFormData?.personalSponsoredInfo)
      if (visaFormData?.workExperienceSponsoredInfo?.isLocked) {
        count.push(2)
      }
      setExperienceInfo(visaFormData?.workExperienceSponsoredInfo)
      if (visaFormData?.documentSponsoredInfo?.isLocked) {
        count.push(3)
      }
      setDocumentInfo(visaFormData?.documentSponsoredInfo)
    } else {
      if(visaFormData){
        setShowFormTab(openForm)
        let count = []
        if (visaFormData?.personal?.isLocked) {
          count.push(1)
        }
        let personal = visaFormData.personal&&Object.keys(visaFormData?.personal)?.length>0 ? visaFormData?.personal : {}
        setPersonalInfo(personal)
        if (visaFormData?.education?.isLocked) {
          count.push(2)
        }
        let education = visaFormData.education&&Object.keys(visaFormData?.education)?.length>0 ? visaFormData?.education: {}
        setEducationInfo(education)
        if (visaFormData?.Experience?.isLocked) {
          count.push(3)
        }
        let experience=visaFormData?.Experience&&Object.keys(visaFormData?.Experience)?.length>0?visaFormData?.Experience:{}
        setExperienceInfo(experience)
  
        if (visaFormData?.language?.isLocked) {
          count.push(4)
        }
        let language=visaFormData?.language&&Object.keys(visaFormData?.language)?.length>0?visaFormData?.language:{}
        setLanguageInfo(language)
        if (visaFormData?.document?.isLocked) {
          count.push(5)
        }
        setDocumentInfo(visaFormData?.document)
        if (visaFormData?.adaptability?.isLocked) {
          count.push(6)
        }
        let adaptability=visaFormData?.adaptability&&Object.keys(visaFormData?.adaptability)?.length>0?visaFormData?.adaptability:{}
        setAdaptabilityInfo(adaptability)
        if (visaFormData?.business?.isLocked) {
          count.push(6)
        }
        let business=visaFormData?.business&&Object.keys(visaFormData?.business)?.length>0?visaFormData?.business:{}
        setBusinessInfo(business)
      }
      
    }
    setActiveTabKey(activeTabKey)
  }, [visaFormData])
  useEffect(() => {
    if (saveCount !== 0 && formCount !== 0) {
      const per = (Number(saveCount) / Number(formCount)) * 100
      setSavePercentage(per)
    }
  }, [
    selectedVisa,
    openForm,
    personalInformation,
    educationInfo,
    experienceInfo,
    LanguageInfo,
    documentInfo,
  ])
  useEffect(() => {
    if (token) {
      setOpenVisaApplication(false)
      setShowSelectMessage(false)
      setPersonalInfo()
      checkProVisaType()
    } else setShowSelectMessage(true)
  }, [token, formType,unlockClient])
  const purchasePackage = async () => {
    const providerId = '62e9117ccc0c0af330f7c40c'
    const res1 = await axios.get(`${serverPath}/product/visaProductByVisaId`, {
      headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
      params: { visaId: selectedVisa.visaId },
    })
    const { productData } = res1?.data || {}
    const res = await axios.get(
      `${serverPath}/payment-provider/supportPayment/${productData._id}/${user._id}/${providerId}`,
    )
    if (res?.data) {
      window.location.href = res?.data?.url
    }
  }

  const capitalize = (strg) => {
    let str2 = ''
    strg.split('').forEach((el, index) => {
      if (index !== 0 && el === el.toUpperCase()) {
        str2 += ` ${el}`
      } else {
        str2 += el
      }
    })
    return str2.charAt(0).toUpperCase() + str2.slice(1)
  }

  const getFromTab = (tab) => {
    switch (tab) {
      case 'personalInfo':
        return (
          <PersonalInfo
            personalInformation={personalInformation}
            setPersonalInfo={setPersonalInfo}
            updFormType={formType}
            adminFormDisable={activeEditable !== 'personalInfo'}
            productName={productName}
          />
        )
      case 'education':
        return (
          <EducationInfo
            educationInfo={educationInfo}
            setEducationInfo={setEducationInfo}
            adminFormDisable={activeEditable !== 'education'}
            updFormType={formType}
            productName={productName}
          />
        )
      case 'workExperience':
        return (
          <WorkExperience
            experienceInfo={experienceInfo}
            setExperienceInfo={setExperienceInfo}
            updFormType={formType}
            adminFormDisable={activeEditable !== 'workExperience'}
            productName={productName}
          />
        )
      case 'language':
        return (
          <LanguagesSection
            LanguageInfo={LanguageInfo}
            setLanguageInfo={setLanguageInfo}
            updFormType={formType}
            adminFormDisable={activeEditable !== 'language'}
            productName={productName}
          />
        )
      case 'documents':
        return (
          <Documents
            documentInfo={documentInfo}
            setDocumentInfo={setDocumentInfo}
            showButton={true}
            updFormType={formType}
            adminFormDisable={activeEditable !== 'documents'}
            productName={productName}
           
          />
        )
      case 'adaptability':
        return (
          <AdaptabilityInfo
            adaptabilityInfo={adaptabilityInfo}
            setAdaptabilityInfo={setAdaptabilityInfo}
            updFormType={formType}
            adminFormDisable={activeEditable !== 'adaptability'}
            productName={productName}
          />
        )
      case 'business':
        return (
          <BusinessInfo
            businessInfo={businessInfo}
            setBusinessInfo={setBusinessInfo}
            updFormType={formType}
            adminFormDisable={activeEditable !== 'business'}
            productName={productName}
          />
        )
      default:
        return ''
    }
  }

  const getDynamicClass = (tab) => {
    switch (tab) {
      case 'personalInfo':
        return personalInformation && personalInformation?.isLocked
          ? true
          : personalInformation?.sponsoredInformation &&
            personalInformation?.formsType === formType &&
            personalInformation?.isLocked
          ? true
          : false
      case 'education':
        return educationInfo && educationInfo?.isLocked ? true : false
      case 'workExperience':
        return experienceInfo && experienceInfo?.isLocked ? true : false
      case 'language':
        return LanguageInfo && LanguageInfo?.isLocked ? true : false
      case 'documents':
        return documentInfo && documentInfo?.isLocked ? true : false
      case 'adaptability':
        return adaptabilityInfo && adaptabilityInfo?.isLocked ? true : false
      case 'business':
        return businessInfo && businessInfo?.isLocked ? true : false
      default:
        return ''
    }
  }

  const handleFormDisable = (tab) => {
    if (tab !== activeEditable) {
      //setActiveEditable(tab)
      setAcknowledgeState(true)
      setTexts('Are you sure you want to unlock for client')
    } else {
      setActiveEditable('')
    }
  }
  const handleTabChange = (e) => {
    if (e !== activeTabKey && !activeEditable) {
      setActiveTabKey(e)
    } else if (activeEditable && e !== activeEditable) {
      setAcknowledgeState(true)
      setTexts('Please save the changes of')
    }
  }
  const handleGenerateDocument = async (values) => {
    try {
      setLoading(true)
      const response = await axios.post(
        `${serverPath}/visa/documentWithZipFile`,
        {
          invoiceId: visaFormData?.visaData?._id,
        },
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          responseType: 'blob',
        },
      )
      setLoading(false)
      const { status } = response
      if (status === 201) {
        var blob = new Blob([response.data], {
          type: response.headers['content-type'],
        })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'visa_documents.zip'
        link.click()
      }
    } catch (err) {}
  }
  const handleDownloadAgreement = async () => {
    try {
      setLoading(true)
      const response = await axios.post(
        `${serverPath}/visa/downloadAgreementForSupport`,
        {
          invoiceId: visaFormData?.visaData?._id,
        },
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          responseType: 'blob',
        },
      )
      setLoading(false)
      const { status } = response
      if (status === 201) {
        var blob = new Blob([response.data], {
          type: response.headers['content-type'],
        })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'visa_documents.zip'
        link.click()
      }
    } catch (err) {}
  }
  const handleUnlockFormForClient = async (tab) => {
    try {
      const res = await axios.post(
        `${serverPath}/support/unlockFormForClient`,
        {
          tab: tab,
          formType: formType,
        },
        {
          headers: {
            authorization: `Bearer ${
              token ? token : localStorage.getItem('token')
            }`,
          },
        },
      )
      const { code } = res.data
      if (code === 200) {
        setUnlockClient(!unlockClient)
        toast.success('Client Form unlock', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
      }
    } catch (err) {
      toast.error(err.data.message, { toastId: 'Eror' })
    }
  }
  return (
    <div className="support-visa-form">
      {aknowledgeState && (
        <AknowledgePopup
          setAcknowledgeState={setAcknowledgeState}
          activeTabKey={capitalize(activeTabKey)}
          setActiveEditable={setActiveEditable}
          editableTab={activeTabKey}
          texts={texts}
          handleUnlockFormForClient={handleUnlockFormForClient}
        />
      )}
      {openVisaApplication ? (
        <>
          <h2>
            In order to start your visa application please purchase a package
            first
          </h2>
          <Button onClick={purchasePackage}>Buy</Button>
        </>
      ) : showSelectedMessage ? (
        <div className="select-visatype">
          <h2>Please Select visa type</h2>
        </div>
      ) : (
        <div className="visa-form-page">
          <div className="container">
            {mainLoading ? (
              <Loader />
            ) : (
              <div className="home-visa-tab">
                {/* {sponsorForms && sponsorForms?.length > 0 ? (
                  <div className="form-sponsor">
                    <Button
                      onClick={() => setFormType('sponsored')}
                      className={formType === 'sponsored' ? 'active' : ''}
                    >
                      Sponsored App
                    </Button>
                    <Button
                      onClick={() => setFormType('sponsoring')}
                      className={formType === 'sponsoring' ? 'active' : ''}
                    >
                      Sponsoring App
                    </Button>
                  </div>
                ) : (
                  ''
                )} */}
                {activeTabKey === 'documents' ? (
                  <div className="download-btn">
                    <Button
                      onClick={() => {
                        handleGenerateDocument()
                      }}
                    >
                      {loading && (
                        <Spinner
                          as="span"
                          variant="light"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          animation="border"
                        />
                      )}
                      &nbsp;Download All Documents
                    </Button>
                  </div>
                ) : (
                  ''
                )}
                <Tabs
                  className=""
                  defaultActiveKey={'personalInfo'}
                  activeKey={activeTabKey}
                  onSelect={handleTabChange}
                >
                  {showFormTab &&
                    showFormTab?.length > 0 &&
                    showFormTab?.map((el) => (
                      <Tab
                        eventKey={el}
                        title={
                          <span>
                            {capitalize(el)}
                            {getDynamicClass(el) && (
                              <img
                                src={lock}
                                alt="lock"
                                onClick={() => handleFormDisable(el)}
                                role="presentation"
                              />
                            )}
                          </span>
                        }
                      >
                        {getFromTab(el)}
                      </Tab>
                    ))}
                  <Tab eventKey="Agreement Document" title="Agreement Document">
                    <div className="download-btn">
                      <Button
                        onClick={() => {
                          handleDownloadAgreement()
                        }}
                      >
                        {loading && (
                          <Spinner
                            as="span"
                            variant="light"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            animation="border"
                          />
                        )}
                        &nbsp;Download Agreement Document
                      </Button>
                    </div>
                  </Tab>
                  {/* <Tab eventKey="Personal Info" title="Personal Info">
                <PersonalInfo />
              </Tab>
              <Tab eventKey="Education" title="Education">
                <EducationInfo />
              </Tab>
              <Tab eventKey="Work Experience" title="Work Experience">
                <WorkExperience />
              </Tab>
              <Tab eventKey="Languages" title="Languages">
                <LanguagesSection />
              </Tab>
              <Tab eventKey="Business" title="Business">
                <BusinessInfo />
              </Tab>
              <Tab eventKey="Adaptability" title="Adaptability">
                <AdaptabilityInfo />
              </Tab>
              <Tab eventKey="Documents" title="Documents">
                <Documents />
              </Tab> */}
                </Tabs>

                {/* <div className="visa-btn">
              <Button>Save</Button>
              <div className="visa-desc-text">
                <p>
                  Once your form is completed, you will be able to make new
                  changes by contacting our Support team.
                </p>
                <p>When you're ready, click "Submit"!</p>
              </div>
            </div> */}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
export default SupportVisaForm
