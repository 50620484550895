import React, { useState, useEffect } from 'react'
import {
  Button,
  Tabs,
  Tab,
  Accordion,
  // Form,
  // FormControl,
  Modal,
} from 'react-bootstrap'
import axios from 'axios'
// import { toast } from 'react-toastify'
// import { useForm } from 'react-hook-form'
// import { yupResolver } from '@hookform/resolvers/yup'
import { serverPath } from '../../config/key'
import Layout from '../layout/layout'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import ReactPlayer from 'react-player'
import ConsultationPopup from '../modals/consultation'
import star from '../../assets/images/header/star.svg'
import starhalf from '../../assets/images/home/star-half.svg'
// import aboutcover from '../../assets/images/home/about.png'
// import homevideo from "../../assets/images/home/home-video.svg";
import video1 from '../../assets/images/home/videothumb1.png'
import video2 from '../../assets/images/home/videothumb2.png'
import video3 from '../../assets/images/home/videothumb3.png'
import video4 from '../../assets/images/home/videothumb4.png'
import video5 from '../../assets/images/home/videothumb5.png'
import video6 from '../../assets/images/home/videothumb6.png'
import video7 from '../../assets/images/home/videothumb7.png'
import permanent from '../../assets/images/home/permanent-visa.png'
import temporary from '../../assets/images/home/temporary-visa.png'
import permanent1 from '../../assets/images/home/permanent-icon-1.svg'
import permanent2 from '../../assets/images/home/permanent-icon-2.svg'
import permanent3 from '../../assets/images/home/permanent-icon-3.svg'
import permanent4 from '../../assets/images/home/permanent-icon-4.svg'
import temporary1 from '../../assets/images/home/temporary-icon-1.svg'
//import temporary2 from '../../assets/images/home/temporary-icon-2.svg'
import temporary3 from '../../assets/images/home/temporary-icon-3.svg'
import temporary4 from '../../assets/images/home/temporary-icon-4.svg'
import successcover from '../../assets/images/home/success-cover.png'
import playicon from '../../assets/images/home/playicon.svg'
import goal1 from '../../assets/images/home/goal1.svg'
import goal2 from '../../assets/images/home/goal2.svg'
import goal3 from '../../assets/images/home/goal3.svg'
import businessknow from '../../assets/images/home/business-know.svg'
import businessknowcover from '../../assets/images/home/business-visa.png'
import rcic from '../../assets/images/reviews/rcic.svg'
// import trustpilot from '../../assets/images/home/trustpilot.svg'
import reviews from '../../assets/images/home/reviews.svg'
import sitejabber from '../../assets/images/reviews/sitejabber.svg'
import blog1 from '../../assets/images/home/blog-1.jpg'
import blog2 from '../../assets/images/home/blog-2.jpg'
import blog3 from '../../assets/images/home/blog-3.jpg'
//import newslettericon from '../../assets/images/home/newsletter-icon.svg'
//import newslettercircle from '../../assets/images/home/newsletter-circle.svg'
import accessiblity from '../../assets/images/header/accessibility.svg'
import chatbot from '../../assets/images/header/chat-bot.svg'
import arrowshape from '../../assets/images/home/shape-arrow.svg'
//import checkicon from '../../assets/images/header/success.svg'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useTranslation } from 'react-i18next'
import './home.css'
// import { NewsLetterSchema } from '../../utils/validationSchema'
// import { useContext } from 'react'
// import UserContext from '../../context/UserContext'

const Home = () => {
  const { t } = useTranslation()
  // const [succ, setSucc] = useState(false)
  // const { userGeoData } = useContext(UserContext)
  // const {
  //   handleSubmit,
  //   register,
  //   formState: { errors },
  // } = useForm({ resolver: yupResolver(NewsLetterSchema) })
  const [key, setKey] = useState(localStorage.getItem('key') || 'permanent')

  useEffect(() => {
    localStorage.setItem('key', key)
  }, [key])

  const [show, setShow] = useState(false)
  const [showvideo, setShowVideo] = useState(false)

  const [playvideo, setPlayVideo] = useState('')

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const handleVideoClose = () => setShowVideo(false)

  const handleVideoShow = (url) => {
    setShowVideo(true)
    setPlayVideo(url)
  }

  // const handleSubmitForm = async (values) => {
  //   try {
  //     // make axios post request
  //     const response = await axios.post(`${serverPath}/forms/newsletter`, {
  //       ...values,
  //       ip: userGeoData.ip,
  //       countryCode: userGeoData.country_code,
  //     })
  //     const {
  //       data: { msg, success },
  //       status,
  //     } = response
  //     if (success || status === 200 || status === 201) {
  //       setSucc(true)
  //       toast.success(msg, {
  //         position: 'top-right',
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //       })
  //     }
  //   } catch (error) {
  //     if (error?.response?.data) {
  //       toast.error(error.response.data.msg, {
  //         position: 'top-right',
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //       })
  //     }
  //   }
  // }

  var settings = {
    autoplay: true,
    dots: true,
    arrow: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '0px',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          className: 'center',
          centerMode: true,
          centerPadding: '30px',
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          className: 'center',
          centerMode: true,
          centerPadding: '20px',
        },
      },
    ],
  }

  var blogslider = {
    dots: false,
    arrow: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 1,
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '0px',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          className: 'center',
          centerMode: true,
          centerPadding: '30px',
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          className: 'center',
          centerMode: true,
          centerPadding: '20px',
        },
      },
    ],
  }

  const [postcontent, setPostContent] = useState([])

  const productData = () => {
    axios
      .get(`${serverPath}/social/uiscanada/feed`, {
        params: {
          limit: 3,
        },
      })
      .then((res) => {
        const data = res.data.data
        setPostContent(data)
      })
  }

  useEffect(() => {
    productData()
  }, [])

  return (
    <Layout>
      <div className="home-page">
        <div className="home-hero-banner">
          <div className="container">
            <div className="home-hero-content">
              <div className="floating-menus">
                <Link to="">
                  <img src={accessiblity} alt="" />
                </Link>
                <Link to="">
                  <img src={chatbot} alt="" />
                </Link>
              </div>

              <h2>{t('homeBanner.heading')}</h2>
              <p>{t('homeBanner.paragraph')}</p>

              <div className="rating-box">
                <Link to="/reviews">
                  <div className="stars">
                    <img src={star} alt="" />
                    <img src={star} alt="" />
                    <img src={star} alt="" />
                    <img src={star} alt="" />
                    <img src={starhalf} alt="" />
                  </div>
                  <span>
                    <b>4.7</b> {t('homeBanner.review')}
                  </span>
                </Link>
              </div>

              <div className="home-hero-button">
                <Button
                  className="orange-button"
                  onClick={() =>
                    (window.location.href = t('freeEligibilityUrl'))
                  }
                >
                  {t('homeBanner.orangebutton')}
                </Button>
                <Button className="white-button" onClick={handleShow}>
                  {t('homeBanner.whitebutton')}
                </Button>
              </div>

              <div className="home-video">
                <div className="home-video-grid thumb1" role="presentation">
                  <img src={video1} alt="" />
                  {/* <span>Play Video <img src={playicon} alt="" /></span> */}
                </div>

                <div
                  className="home-video-grid thumb2"
                  role="presentation"
                  onClick={() =>
                    handleVideoShow(
                      'https://www.youtube.com/watch?v=mwOXJq160lk&ab_channel=UISCanada',
                    )
                  }
                >
                  <img src={video2} alt="" />
                  <span>
                    {t('homeBanner.homevideo')}
                    <img src={playicon} alt="" />
                  </span>
                </div>

                <div
                  className="home-video-grid thumb3"
                  role="presentation"
                  onClick={() =>
                    handleVideoShow(
                      'https://www.youtube.com/shorts/bl_X6nkhE_8?&ab_channel=UISCanada',
                    )
                  }
                >
                  <img src={video3} alt="" />
                  <span>
                    {t('homeBanner.homevideo')}
                    <img src={playicon} alt="" />
                  </span>
                </div>

                <div
                  className="home-video-grid thumb4"
                  role="presentation"
                  onClick={() =>
                    handleVideoShow(
                      'https://www.youtube.com/watch?v=E7F9ModRVO8&ab_channel=UISCanada',
                    )
                  }
                >
                  <img src={video4} alt="" />
                  <span>
                    {t('homeBanner.homevideo')}
                    <img src={playicon} alt="" />
                  </span>
                </div>

                <div className="home-video-grid thumb5" role="presentation">
                  <img src={video5} alt="" />
                  {/* <span>Play Video <img src={playicon} alt="" /></span> */}
                </div>

                <div className="home-video-grid thumb6" role="presentation">
                  <img src={video6} alt="" />
                  {/* <span>Play Video <img src={playicon} alt="" /></span> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="home-about">
          <div className="container">
            <h2>{t('homeAbout.heading')}</h2>
            <div className="home-about-grid">
             
                <div
                  className="home-video-grid thumb7 wow animate__animated animate__fadeInUp"
                  role="presentation"
                  onClick={() =>
                    handleVideoShow(
                      "https://www.youtube.com/shorts/wvxdDPxHTbc?&ab_channel=UISCanada"
                    )
                  }
                >
                  <img src={video7} alt="" />
                  {/* <span>
                    {t("homeBanner.homevideo")}
                    <img src={playicon} alt="" />
                  </span> */}
                  
                </div>
             
              <div className="home-about-content">
                <span className="cursive-font">
                  {t('homeAbout.cursivefont')}
                </span>
                <p>{t('homeAbout.paragraph1')}</p>
                <p>{t('homeAbout.paragraph2')}</p>
                <Link className="link-hover" to="/about">
                  {t('homeAbout.readmore')}
                </Link>
              </div>
              {/* <div className="home-about-cover">
                <img
                  src={aboutcover}
                  alt=""
                  className="wow animate__animated animate__fadeInUp"
                />
              </div> */}
            </div>
          </div>
        </div>

        <div className="home-visa-type" id="visa-type">
          <div className="container">
            <div className="home-visa-heading">
              <h2>{t('homeVisa.heading')}</h2>
              <p>{t('homeVisa.paragraph')}</p>
            </div>

            <div className="home-visa-tab">
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
              >
                <Tab eventKey="permanent" title={t('homeVisa.permanent')}>
                  <div className="home-visa-grid">
                    <div className="home-visa-content">
                      <div className="home-visa-box">
                        <div className="home-visa-icon">
                          <img src={permanent1} alt="" />
                        </div>
                        <div className="home-visa-info">
                          <h4>{t('homevisabox1.heading')}</h4>
                          <p>{t('homevisabox1.paragraph')}</p>
                          <Link className="link-hover" to="/visa/express">
                            {t('homevisabox1.learnmore')}
                          </Link>
                        </div>
                      </div>

                      <div className="home-visa-box">
                        <div className="home-visa-icon">
                          <img src={permanent2} alt="" />
                        </div>
                        <div className="home-visa-info">
                          <h4>{t('homevisabox2.heading')}</h4>
                          <p>{t('homevisabox2.paragraph')}</p>
                          <Link className="link-hover" to="/visa/worker">
                            {t('homevisabox2.learnmore')}
                          </Link>
                        </div>
                      </div>

                      <div className="home-visa-box">
                        <div className="home-visa-icon">
                          <img src={permanent3} alt="" />
                        </div>
                        <div className="home-visa-info">
                          <h4>{t('homevisabox3.heading')}</h4>
                          <p>{t('homevisabox3.paragraph')}</p>
                          <Link className="link-hover" to="/visa/business">
                            {t('homevisabox3.learnmore')}
                          </Link>
                        </div>
                      </div>

                      <div className="home-visa-box">
                        <div className="home-visa-icon">
                          <img src={permanent4} alt="" />
                        </div>
                        <div className="home-visa-info">
                          <h4>{t('homevisabox4.heading')}</h4>
                          <p>{t('homevisabox4.paragraph')}</p>
                          <Link className="link-hover" to="/visa/family">
                            {t('homevisabox4.learnmore')}
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="home-visa-cover">
                      <img
                        src={permanent}
                        alt=""
                        className="wow animate__animated animate__fadeInUp"
                      />
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="temporary" title={t('homeVisa.temporary')}>
                  <div className="home-visa-grid">
                    <div className="home-visa-content">
                      <div className="home-visa-box">
                        <div className="home-visa-icon">
                          <img src={temporary1} alt="" />
                        </div>
                        <div className="home-visa-info">
                          <h4>{t('homevisabox5.heading')}</h4>
                          <p>{t('homevisabox5.paragraph')}</p>
                          <Link className="link-hover" to="/visa/holiday">
                            {t('homevisabox5.learnmore')}
                          </Link>
                        </div>
                      </div>

                      {/* <div className="home-visa-box">
                        <div className="home-visa-icon">
                          <img src={temporary2} alt="" />
                        </div>
                        <div className="home-visa-info">
                          <h4>{t('homevisabox6.heading')}</h4>
                          <p>{t('homevisabox6.paragraph')}</p>
                          <h1>hhhh</h1>
                          <Link className="link-hover" to="/visa/tourist">
                            {t('homevisabox6.learnmore')}
                          </Link>
                        </div>
                      </div> */}

                      <div className="home-visa-box">
                        <div className="home-visa-icon">
                          <img src={temporary3} alt="" />
                        </div>
                        <div className="home-visa-info">
                          <h4>{t('homevisabox7.heading')}</h4>
                          <p>{t('homevisabox7.paragraph')}</p>
                          <Link className="link-hover" to="/visa/student">
                            {t('homevisabox7.learnmore')}
                          </Link>
                        </div>
                      </div>

                      <div className="home-visa-box">
                        <div className="home-visa-icon">
                          <img src={temporary4} alt="" />
                        </div>
                        <div className="home-visa-info">
                          <h4>{t('homevisabox8.heading')}</h4>
                          <p>{t('homevisabox8.paragraph')}</p>
                          <Link className="link-hover" to="/visa/temporary">
                            {t('homevisabox8.learnmore')}
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="home-visa-cover">
                      <img
                        src={temporary}
                        alt=""
                        className="wow animate__animated animate__fadeInUp"
                      />
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>

        <div className="home-success-story">
          <div className="container">
            <h2>{t('homeSuccess.heading')}</h2>

            <div className="home-success-story-grid">
              <div className="home-success-story-cover">
                <div
                  className="home-success-video wow animate__animated animate__fadeInUp"
                  role="presentation"
                  onClick={() =>
                    handleVideoShow(
                      'https://www.youtube.com/watch?v=E7F9ModRVO8&ab_channel=UISCanada',
                    )
                  }
                >
                  <img src={successcover} alt="" />
                  <span>
                    Play Video <img src={playicon} alt="" />
                  </span>
                </div>
              </div>

              <div className="home-success-story-content">
                <h4>Jack Carolan</h4>
                <h6>24, Ireland</h6>

                <div className="success-stars">
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={starhalf} alt="" />
                </div>

                <p>
                  Jack Carolan started his Working Holiday Visa process with us
                  in May 2021. We submitted his application in July 2021 and by
                  August he has already received his ITA. Even with Covid-19
                  restrictions he received a visa approval in October 2021. It
                  was a challenging and exciting journey but we made it through!
                </p>

                <span>Approved Nov 2021</span>
              </div>
            </div>

            <div className="home-success-story-grid flip-grid">
              <div className="home-success-story-cover">
                <div
                  className="home-success-video wow animate__animated animate__fadeInUp"
                  role="presentation"
                  onClick={() =>
                    handleVideoShow(
                      'https://www.youtube.com/watch?v=E7F9ModRVO8&ab_channel=UISCanada',
                    )
                  }
                >
                  <img src={successcover} alt="" />
                  <span>
                    Play Video <img src={playicon} alt="" />
                  </span>
                </div>
              </div>

              <div className="home-success-story-content">
                <h4>Jack Carolan</h4>
                <h6>24, Ireland</h6>

                <div className="success-stars">
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={starhalf} alt="" />
                </div>

                <p>
                  Jack Carolan started his Working Holiday Visa process with us
                  in May 2021. We submitted his application in July 2021 and by
                  August he has already received his ITA. Even with Covid-19
                  restrictions he received a visa approval in October 2021. It
                  was a challenging and exciting journey but we made it through!
                </p>

                <span>Approved Nov 2021</span>
              </div>
            </div>

            <div className="home-success-story-grid">
              <div className="home-success-story-cover">
                <div
                  className="home-success-video wow animate__animated animate__fadeInUp"
                  role="presentation"
                  onClick={() =>
                    handleVideoShow(
                      'https://www.youtube.com/watch?v=E7F9ModRVO8&ab_channel=UISCanada',
                    )
                  }
                >
                  <img src={successcover} alt="" />
                  <span>
                    Play Video <img src={playicon} alt="" />
                  </span>
                </div>
              </div>

              <div className="home-success-story-content">
                <h4>Jack Carolan</h4>
                <h6>24, Ireland</h6>

                <div className="success-stars">
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={starhalf} alt="" />
                </div>

                <p>
                  Jack Carolan started his Working Holiday Visa process with us
                  in May 2021. We submitted his application in July 2021 and by
                  August he has already received his ITA. Even with Covid-19
                  restrictions he received a visa approval in October 2021. It
                  was a challenging and exciting journey but we made it through!
                </p>

                <span>Approved Nov 2021</span>
              </div>
            </div>
          </div>
        </div>

        <div className="home-goal">
          <div className="container">
            <h2>{t('homeGoal.heading')}</h2>

            <div className="home-goal-grid">
              <div className="home-goal-box wow animate__animated animate__fadeInUp">
                <img src={goal1} alt="" />
                <h4>{t('homeGoalgrid1.heading')}</h4>
                <p>{t('homeGoalgrid1.paragraph')}</p>
              </div>

              <div className="home-goal-box wow animate__animated animate__fadeInUp">
                <img src={goal2} alt="" />
                <h4>{t('homeGoalgrid2.heading')}</h4>
                <p>{t('homeGoalgrid2.paragraph')}</p>
              </div>

              <div className="home-goal-box wow animate__animated animate__fadeInUp">
                <img src={goal3} alt="" />
                <h4>{t('homeGoalgrid3.heading')}</h4>
                <p>{t('homeGoalgrid3.paragraph')}</p>
              </div>
            </div>

            <div className="home-goal-button">
              <p>{t('homeGoalbutton.paragraph')}</p>
              <Button
                className="orange-button"
                onClick={() =>
                (window.location.href =
                  'https://www.uiscanada.com/online-eligibility-assessment/3/')
                }
              >
                {t('homeGoalorangebutton.paragraph')}
              </Button>
            </div>
          </div>
        </div>

        <div className="home-business-know">
          <div className="container">
            <div className="home-business-know-grid">
              <div className="home-business-know-content">
                <h2>{t('homeBusiness.heading')}</h2>
                <div className="home-business-know-info">
                  <img src={businessknow} alt="" />
                  <p>{t('homeBusiness.paragraph1')}</p>

                  <p>{t('homeBusiness.paragraph2')}</p>

                  <Link className="link-hover" to="/visa/business">
                    {t('homeBusiness.readmore')}
                  </Link>
                </div>
              </div>

              <div className="home-business-know-cover">
                <img
                  src={businessknowcover}
                  alt=""
                  className="wow animate__animated animate__fadeInUp"
                />
              </div>
            </div>

            <div className="home-testimonial">
              <h2> {t('homeTestimonial.heading')}</h2>

              <div className="home-testimonial-box">
                <Slider {...settings}>
                  <div>
                    <Link to="/reviews">
                      <div className="home-testimonial-content">
                        <img src={reviews} alt="" />
                        <div className="testimonial-review">
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                        </div>
                        <h4> {t('homeTestimonialbox1.heading')}</h4>
                        <p>{t('homeTestimonialbox1.paragraph')}</p>
                      </div>
                    </Link>
                  </div>

                  <div>
                    <Link to="/reviews">
                      <div className="home-testimonial-content">
                        {/* <img src={trustpilot} alt="" /> */}
                        <div className="testimonial-review">
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                        </div>
                        <h4>{t('homeTestimonialbox2.heading')}</h4>
                        <p>{t('homeTestimonialbox2.paragraph')}</p>
                      </div>
                    </Link>
                  </div>

                  <div>
                    <Link to="/reviews">
                      <div className="home-testimonial-content">
                        <img src={rcic} alt="" />
                        <div className="testimonial-review">
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                        </div>
                        <h4>{t('homeTestimonialbox3.heading')}</h4>
                        <p>{t('homeTestimonialbox3.paragraph')}</p>
                      </div>
                    </Link>
                  </div>

                  <div>
                    <Link to="/reviews">
                      <div className="home-testimonial-content">
                        <img src={sitejabber} alt="" />
                        <div className="testimonial-review">
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                        </div>
                        <h4>{t('homeTestimonialbox4.heading')}</h4>
                        <p>{t('homeTestimonialbox4.paragraph')}</p>
                      </div>
                    </Link>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>

        <div className="home-faq" id="faq">
          <div className="container">
            <h2>{t('homeFaq.heading')}</h2>

            <div className="home-faq-grid">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    {t('homeFaq0.accordionheading')}
                  </Accordion.Header>
                  <Accordion.Body>
                    {t('homeFaq0.accordionparagraph')}
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    {t('homeFaq1.accordionheading')}
                  </Accordion.Header>
                  <Accordion.Body>
                    {t('homeFaq1.accordionparagraph')}
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    {t('homeFaq2.accordionheading')}
                  </Accordion.Header>
                  <Accordion.Body>
                    {t('homeFaq2.accordionparagraph')}
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    {t('homeFaq3.accordionheading')}
                  </Accordion.Header>
                  <Accordion.Body>
                    {t('homeFaq3.accordionparagraph')}
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    {t('homeFaq4.accordionheading')}
                  </Accordion.Header>
                  <Accordion.Body>
                    {t('homeFaq4.accordionparagraph')}
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    {t('homeFaq5.accordionheading')}
                  </Accordion.Header>
                  <Accordion.Body>
                    {t('homeFaq5.accordionparagraph')}
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="6">
                  <Accordion.Header>
                    {t('homeFaq6.accordionheading')}
                  </Accordion.Header>
                  <Accordion.Body>
                    {t('homeFaq6.accordionparagraph')}
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="7">
                  <Accordion.Header>
                    {t('homeFaq7.accordionheading')}
                  </Accordion.Header>
                  <Accordion.Body>
                    {t('homeFaq7.accordionparagraph')}
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="8">
                  <Accordion.Header>
                    {t('homeFaq8.accordionheading')}
                  </Accordion.Header>
                  <Accordion.Body>
                    {t('homeFaq8.accordionparagraph')}
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="9">
                  <Accordion.Header>
                    {t('homeFaq9.accordionheading')}
                  </Accordion.Header>
                  <Accordion.Body>
                    {t('homeFaq9.accordionparagraph')}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>

            <div className="home-faq-button">
              <Link to="/contact">
                <Button
                  className="outline-button"
                  onClick={() => (window.location.href = '/contact')}
                >
                  {t('homeFaqbutton.outlineButton')}
                </Button>
              </Link>
            </div>
          </div>
        </div>

        <div className="home-blog">
          <div className="container">
            <div className="home-facebook">
              <h2>{t('homeBlog.homeFacebookheading')}</h2>

              <div className="home-facebook-grid">
                {postcontent.map((post, i) => (
                  <div className="home-facebook-box" key={i}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={post.permalink_url}
                    >
                      <div className="home-facebook-cover">
                        <img src={post.full_picture} alt="" />
                      </div>
                      <div className="home-facebook-content">
                        <p>{post.message}</p>
                      </div>
                    </a>
                  </div>
                ))}
              </div>

              <a
                className="fb-btn"
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/uis.canada"
              >
                {t('FollowUs')}{' '}
                <img src={arrowshape} alt="" className="hover-fb" />{' '}
                <svg
                  width="26"
                  height="27"
                  viewBox="0 0 26 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M25.7132 13.5001C25.7132 6.48243 20.0177 0.786926 13 0.786926C5.98237 0.786926 0.286865 6.48243 0.286865 13.5001C0.286865 19.6533 4.6602 24.7767 10.4574 25.959V17.3141H7.91477V13.5001H10.4574V10.3218C10.4574 7.86817 12.4534 5.8722 14.907 5.8722H18.0853V9.68615H15.5427C14.8435 9.68615 14.2714 10.2582 14.2714 10.9575V13.5001H18.0853V17.3141H14.2714V26.1497C20.6915 25.5141 25.7132 20.0982 25.7132 13.5001Z"
                    fill="#F35C5C"
                  />
                </svg>
              </a>
            </div>

            <h2>{t('homeBlog.homeBlogheading')}</h2>

            <div className="home-blog-grid">
              <Slider {...blogslider}>
                <div className="home-blog-box">
                  <a href="https://www.uiscanada.com/blog/the-4-best-apps-to-learn-english-before-moving-to-canada/">
                    <div className="home-blog-gutter">
                      <div className="home-blog-cover">
                        <img src={blog1} alt="" />
                      </div>

                      <div className="home-blog-content">
                        <span>04/27/2022</span>
                        <h4>{t('homeBlogcontent1.homeBlogheading')}</h4>
                        <p>{t('homeBlogcontent1.homeBlogparagraph')}</p>
                        <a
                          className="link-hover"
                          href="https://www.uiscanada.com/blog/the-4-best-apps-to-learn-english-before-moving-to-canada/"
                        >
                          {t('homeBlogcontent1.readmore')}
                        </a>
                      </div>
                    </div>
                  </a>
                </div>

                <div className="home-blog-box">
                  <a href="https://www.uiscanada.com/blog/7-things-youll-wish-someone-told-you-before-moving-to-canada/">
                    <div className="home-blog-gutter">
                      <div className="home-blog-cover">
                        <img src={blog2} alt="" />
                      </div>

                      <div className="home-blog-content">
                        <span>04/13/2022</span>
                        <h4>{t('homeBlogcontent2.homeBlogheading')}</h4>
                        <p>{t('homeBlogcontent2.homeBlogparagraph')}</p>
                        <a
                          className="link-hover"
                          href="https://www.uiscanada.com/blog/7-things-youll-wish-someone-told-you-before-moving-to-canada/"
                        >
                          {t('homeBlogcontent2.readmore')}
                        </a>
                      </div>
                    </div>
                  </a>
                </div>

                <div className="home-blog-box">
                  <a href="https://www.uiscanada.com/blog/managing-tax-as-a-backpacker-in-canada-a-quick-guide/">
                    <div className="home-blog-gutter">
                      <div className="home-blog-cover">
                        <img src={blog3} alt="" />
                      </div>

                      <div className="home-blog-content">
                        <span>03/27/2022</span>
                        <h4>{t('homeBlogcontent3.homeBlogheading')}</h4>
                        <p>{t('homeBlogcontent3.homeBlogparagraph')}</p>
                        <a
                          className="link-hover"
                          href="https://www.uiscanada.com/blog/managing-tax-as-a-backpacker-in-canada-a-quick-guide/"
                        >
                          {t('homeBlogcontent3.readmore')}
                        </a>
                      </div>
                    </div>
                  </a>
                </div>
              </Slider>
            </div>

            <div className="home-blog-button">
              <a href="https://www.uiscanada.com/blog/">
                <Button className="outline-button">
                  {' '}
                  {t('homeBlogbutton.outlineButton')}
                </Button>
              </a>
            </div>
          </div>
        </div>

        {/* <div className="home-newsletter">
          <div className="container">
            <div className="newletter-grid">
              <img
                className="newsletter-circle"
                src={newslettercircle}
                alt=""
              />
              <div className="newletter-content">
                <img src={newslettericon} alt="" />
                <h2>{t('homeNewsletter.homeNewsheading')}</h2>
                <Form
                  className="d-flex"
                  onSubmit={handleSubmit(handleSubmitForm)}
                >
                  <FormControl
                    className={`${errors?.email ? `error-brd` : ``}`}
                    type="text"
                    name="email"
                    placeholder={
                      errors?.email
                        ? errors.email.message
                        : t('visaContact.emailaddress')
                    }
                    {...register('email')}
                  />

                  <Button
                    type="submit"
                    className={`orange-button ${succ ? `success-btn` : ``}`}
                  >
                    {succ
                      ? t('homeNewsletter.successButton')
                      : t('homeNewsletter.orangeButton')}
                    {succ ? <img src={checkicon} alt="" /> : ''}
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div> */}
      </div>

      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        centered
        className="consultation-popup"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <ConsultationPopup />
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={showvideo}
        onHide={handleVideoClose}
        centered
        className="video-popup"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <ReactPlayer
            width="100%"
            height="575px"
            controls={true}
            url={playvideo}
          />
        </Modal.Body>
      </Modal>
    </Layout>
  )
}

export default Home
