import React from "react";
import Layout from "../layout/layout";
import aboutbanner from "../../assets/images/about/about-banner.png";
import whoweare from "../../assets/images/about/who-we-are.png";
import value from "../../assets/images/about/value.png";
import rcic from "../../assets/images/about/rcic.svg";
// import team from "../../assets/images/about/team.png";
import "./about.css";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <div className='about-page'>
        <div className='about-banner'>
          <div className='container'>
            <div className='about-banner-grid'>
              <div className='about-banner-cover'>
                <img src={aboutbanner} alt='' />

                <div className='about-bubble cursive-font'>
                  <span>{t("aboutpage.cursiveFont")}</span>
                </div>
              </div>

              <div className='about-banner-content'>
                <h2>{t("aboutBanner.aboutHeading")}</h2>

                <p>{t("aboutBanner.aboutParagraph1")}</p>

                <p>{t("aboutBanner.aboutParagraph2")}</p>

                <p>{t("aboutBanner.aboutParagraph3")}</p>
              </div>
            </div>
          </div>
        </div>

        <div className='about-experience-section'>
          <div className='container'>
            <div className='about-experience'>
              <div className='about-experience-content'>
                <h2>{t("aboutExperience.aboutHeading")}</h2>

                <p>{t("aboutExperience.aboutParagraph1")}</p>

                <p>{t("aboutExperience.aboutParagraph2")}</p>
              </div>

              <div className='about-experience-cover'>
                <img src={whoweare} alt='' />
              </div>
            </div>

            <div className='about-who-we-are'>
              <div className='about-who-we-are-cover'>
                <img src={value} alt='' />
              </div>

              <div className='about-who-we-are-content'>
                <h2>{t("aboutWho.aboutHeading")}</h2>

                <p>{t("aboutWho.aboutParagraph1")}</p>

                <p>{t("aboutWho.aboutParagraph2")}.</p>
              </div>
            </div>
          </div>
        </div>

        <div className='about-team'>
          <div className='container'>
            <div className='about-about-help-content'>
              <h2>{t("aboutTeam.aboutHeading")}</h2>

              <p>{t("aboutTeam.aboutParagraph1")}</p>

              <p>{t("aboutTeam.aboutParagraph2")}</p>

              {/* <div className="team-cover">
                            <img src={team} alt="" />
                        </div> */}
            </div>
          </div>
        </div>

        <div className='about-rcic'>
          <div className='container'>
            <div className='about-rcic-grid'>
              <div className='about-rcic-content'>
             
           
            <div className="about-rcic-headng">
            <h3>{t("aboutRciccover.aboutHeading")}</h3>
            <div className='about-rcic-cover'>
              <img src={rcic} alt='' />
            </div>
            </div>
          

            <p>{t("aboutRciccover.aboutParagraph1")}</p>

            <p>{t("aboutRciccover.aboutParagraph2")}</p>

            <ul>
              <li>{t("aboutRciccover.aboutList1")}</li>
              <li>{t("aboutRciccover.aboutList2")}</li>
              <li>{t("aboutRciccover.aboutList3")}</li>
            </ul>

            <p>{t("aboutRciccover.aboutParagraph3")}</p>

            <ul>
              <li>{t("aboutRciccover.aboutList4")}</li>
              <li>{t("aboutRciccover.aboutList5")}</li>
              <li>{t("aboutRciccover.aboutList6")}</li>
              <li>{t("aboutRciccover.aboutParagraph4")}</li>
            </ul>

            {/* <p>{t("aboutRciccover.aboutParagraph4")}</p> */}

            <p>{t("aboutRciccover.aboutParagraph5")}</p>

            </div>

</div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default About;
