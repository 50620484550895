export const spouseStudentVisaGenMain = [
  {
    name: 'spousePassportCopy',
    label: 'Passport Copy',
    secondLabel: '(front page + stamp / visa pages if any)',
  },
  {
    name: 'spouseDigitalPhoto',
    label: 'Digital Photo',
    secondLabel: '',
  },
  {
    name: 'spouseBirthCertificate',
    label: 'Copy of Birth Certificate',
    secondLabel: '',
  },
]
export const spouseTouristVisaGenMain = [
  {
    name: 'spousePassportCopy',
    label: 'Passport Copy',
    secondLabel: '(front page + stamp / visa pages if any)',
  },
  {
    name: 'spouseDigitalPhoto',
    label: 'Digital Photo',
    secondLabel: '',
  },
  {
    name: 'spouseBirthCertificate',
    label: 'Copy of Birth Certificate',
    secondLabel: '',
  },
]
export const spousePnpExpressEntryVisaGenMain = [
  {
    name: 'spousePassportCopy',
    label: 'Passport Copy',
    secondLabel: '(front page + stamp / visa pages if any)',
  },
  {
    name: 'spouseDigitalPhoto',
    label: 'Digital Photo',
    secondLabel: '',
  },
  {
    name: 'spouseTwoDigitalPhoto',
    label: 'Two Digital Photos',
    secondLabel: '',
  },
  {
    name: 'spouseBirthCertificate',
    label: 'Copy of Birth Certificate',
    secondLabel: '',
  },
]
