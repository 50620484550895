import React from "react";
import icon1 from "../../assets/images/header/canada.svg";
import facebook from "../../assets/images/header/fb.svg";
import linkedin from "../../assets/images/header/linkedin.svg";
import twitter from "../../assets/images/header/twitter.svg";
import "./thankyou.css";
import { useLocation } from "react-router-dom";

const ThankyouIneligible = () => {
  const { search } = useLocation();
  const name = new URLSearchParams(search).get("name");
  return (
    <div>
      <div className='landing-thank-you'>
        <div className='thank-you-grid'>
          <div className='thank-you-content'>
            <img src={icon1} alt='' />
            <h2>Thank You!</h2>
            {/* <p>
              A company representative will call you shortly to discuss your
              immigration goals.
            </p> */}

            <p>
              Hi {name}, Unfortunately, <br />
              you are not eligible to enter the Working Holiday Visa Program.
            </p>
            <p>
              For more information or to learn about alternative options,
              <br />
              contact our team at:{" "}
              <a href='mailto:support@uiscanada.com'>
                support@uiscanada.com
              </a>{" "}
              today!
            </p>
          </div>

          <div className='thank-you-social'>
            <p>Let’s Connect!</p>
            <ul>
              <li>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://www.facebook.com/uis.canada'
                >
                  <img src={facebook} alt='' />
                </a>
              </li>
              <li>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://www.linkedin.com/company/uis-canada-official/'
                >
                  <img src={linkedin} alt='' />
                </a>
              </li>
              <li>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://twitter.com/uis_canada'
                >
                  <img src={twitter} alt='' />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankyouIneligible;
