import React, { useState, useEffect, useContext } from 'react'
import { Button } from 'react-bootstrap'
// import image2 from "../../assets/images/worldTravel/Rectangle7.png";
// import image3 from "../../assets/images/worldTravel/Rectangle8.png";
// import image1 from "../../assets/images/worldTravel/Rectangle9.png";
import star from '../../assets/images/worldTravel/star.svg'
import arrow from '../../assets/images/worldTravel/Arrow.svg'
import tick from '../../assets/images/worldTravel/tick.svg'
import './WorldTravel.css'
import axios from 'axios'
import { serverPath } from '../../config/key'
//import Loader from "../loader/loader";
import UserContext from '../../context/UserContext'
import { useLocation, useNavigate } from 'react-router-dom'

const PlansSection = () => {
  // const initialScreen = window.outerWidth;
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const navigate = useNavigate()
  const { setSelfProduct } = useContext(UserContext)
  // const [screenSize, setScreenSize] = useState(initialScreen);

  // useEffect(() => {
  //   setScreenSize(initialScreen);
  // }, [initialScreen]);

  // const handleResize = (e) => {
  //   const currentScreenSize = e.target.outerWidth;
  //   setScreenSize(currentScreenSize);
  // };

  // useEffect(() => {
  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);
  const [products, setProducts] = useState([])
  //const [loading, setLoading] = useState(false);
  // const [showPayment, setShowPayment] = useState(false);

  const allProducts = async () => {
    //setLoading(true);
    const res = await axios.get(`${serverPath}/product/allPublicProduct`)
    const {
      data: { products },
    } = res
    //setLoading(false);
    if (products?.length > 0) {
      setProducts(products)
    }
  }
  useEffect(() => {
    allProducts()
  }, [])
  const redirectOnPaymentPage = (pro) => {
    setSelfProduct(pro)
    navigate(
      `/Payment/?pId=${pro?._id}&?utm_medium=${queryParams.get(
        'utm_medium',
      )}&UTM1=${queryParams.get('UTM1')}&UTM2=${queryParams.get(
        'UTM2',
      )}&UTM3=${queryParams.get('UTM3')}&UTM8=${queryParams.get(
        'UTM8',
      )}&utm_campaign=${queryParams.get(
        'utm_campaign',
      )}&Campaign_source=${queryParams.get(
        'Campaign_source',
      )}&lId=${queryParams.get('lId')}`,
    )
  }
  // const handleRedirectOnShedule = () => {
  //   window.open(
  //     "https://calendly.com/uis_canada_team/30-minute-consultation-call",
  //     "_blank"
  //   );
  // };
  return (
    <React.Fragment>
      <div className="WorldTravels-plans" id="getplansection">
        <div className="container">
          <div className="world-travel-plans-wrapper">
            <div className="WorldTravel-plansTitle">
              <h2>Experience the Ultimate Travel Adventure</h2>
              <p>
                Are you ready to live life to the fullest? Choose from one of
                our travel packages for the ultimate adventure:
              </p>
            </div>
            {/* {loading ? (
              <Loader />
            ) : ( */}
            <div className="WorldTravel-plansCards">
              {products &&
                products?.length > 0 &&
                products.map((el, i) => (
                  <div className="WorldTravel-plansCard" key={el._id}>
                    <img src={el.image} className="plansCardBanner" alt="" />
                    <div className="plansCard-content">
                      <div className="plansCard-star">
                        {i === 0 && <img src={star} alt="" />}
                        {i === 1 && (
                          <>
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                          </>
                        )}
                        {i === 2 && (
                          <>
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                            <img src={star} alt="" />
                          </>
                        )}
                      </div>
                      <h2>{el?.name}</h2>
                      {/* <h6
                          dangerouslySetInnerHTML={{
                            __html: el?.description,
                          }}
                        /> */}
                      <div className="plansCard-contentPrice">
                        <h4>$ {el?.price}</h4>
                        <Button onClick={() => redirectOnPaymentPage(el)}>
                          <img src={arrow} alt="" />
                        </Button>
                      </div>
                      <div className="plansCard-packagelist">
                        {el?.publicDescription &&
                          el?.publicDescription?.length > 0 &&
                          el?.publicDescription.map((desc) => (
                            <li>
                              <img src={tick} alt="" />
                              <p>{desc}</p>
                            </li>
                          ))}
                      </div>
                    </div>
                  </div>
                ))}
            </div>

            <div className="WorldTravel-Plannote">
              Our Work & Travel and Work & Travel Premium packages are offered
              to you in collaboration with a leading travel agency
              "Travel-For-Work", located in Vancouver, Canada. This travel
              agency is led by Mr. Mario Moscovici.
            </div>
            {/* <div className='WorldTravel-primaryCta'>
              <p>
                Want to go on a Working Holiday to Canada, but unsure where to
                start?
              </p>
              <h1>
                We can help! <br /> Schedule a free consultation with our team
                today and we'll show you the way.
              </h1>
              {screenSize < 767 ? (
                <p>Leave your details and our team will contact you shortly.</p>
              ) : (
                ""
              )}
              <Button onClick={handleRedirectOnShedule}>
                <p>Schedule Now</p>
                <div>
                  <Date />
                </div>
              </Button>
            </div> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PlansSection

// const Date = () => {
//   return (
//     <svg
//       width='28'
//       height='28'
//       viewBox='0 0 28 28'
//       fill='none'
//       xmlns='http://www.w3.org/2000/svg'
//     >
//       <path
//         d='M19.8333 11.6667H8.16667V14.0001H19.8333V11.6667ZM22.1667 3.50008H21V1.16675H18.6667V3.50008H9.33333V1.16675H7V3.50008H5.83333C4.53833 3.50008 3.51167 4.55008 3.51167 5.83342L3.5 22.1667C3.5 23.4501 4.53833 24.5001 5.83333 24.5001H22.1667C23.45 24.5001 24.5 23.4501 24.5 22.1667V5.83342C24.5 4.55008 23.45 3.50008 22.1667 3.50008ZM22.1667 22.1667H5.83333V9.33342H22.1667V22.1667ZM16.3333 16.3334H8.16667V18.6667H16.3333V16.3334Z'
//         fill='white'
//       />
//     </svg>
//   );
// };
