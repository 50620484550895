import Layout from '../layout/layout'
import 'react-toastify/dist/ReactToastify.css'
import axios from 'axios'
import { Button, Dropdown, Form, Table } from 'react-bootstrap'
import { serverPath } from '../../config/key'
import React, { useEffect, useState } from 'react'
//import "./user.css";
import { toast } from 'react-toastify'
import Pagination from '../pagination'
import Loader from '../loader/loader'
const ViewPayments = () => {
  const [allPaymants, setAllPayments] = useState([])
  const [invoices, setInvoices] = useState([])
  const [paymentCount, setPaymentCount] = useState(0)
  const [skip, setSkip] = useState(0)
  const [pageCount, setPageCount] = useState()
  const [selectDate, setSelectDate] = useState({
    fromDate: '',
    toDate: '',
  })
  const [loading, setLoading] = useState(false)
  const pageLimit = 10
  const getAllViewPayment = async () => {
    try {
      setLoading(true)
      const response = await axios.get(
        `${serverPath}/transaction/viewAllPayments/${skip}`,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      setLoading(false)
      const { data, status } = response || []
      if (status === 200) {
        setAllPayments(data?.allPayment)
        setPaymentCount(data?.count)
        setInvoices(data?.invoices)
      }
    } catch (err) {
      toast.error(err.message, { toastId: 'errorId' })
    }
  }
  useEffect(() => {
    getAllViewPayment()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip])
  useEffect(() => {
    setPageCount(Math.ceil(paymentCount / pageLimit))
  }, [paymentCount])
  const handlePageClick = (selected) => {
    setSkip(selected * pageLimit)
    // setSelected(selected);
  }
  const handleDateChange = (e) => {
    const { name, value } = e.target
    setSelectDate({ ...selectDate, [name]: value })
  }
  const searchPayment = async () => {
    try {
      const response = await axios.get(
        `${serverPath}/transaction/viewAllPayments/${skip}`,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          params: selectDate,
        },
      )
      const { data, status } = response || []
      if (status === 200) {
        setAllPayments(data?.allPayment)
        setPaymentCount(data?.count)
      }
    } catch (err) {
      toast.error(err.message, { toastId: 'errorId' })
    }
  }

  const handleRedirect = async (id) => {
    const response = await axios.post(
      `${serverPath}/invoice/generateSignedUrl`,
      { pdfId: id },
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      },
    )
    const { data, status } = response
    if (status === 200) {
      window.open(data?.signedUrl, '_blank')
    }
  }
  return (
    <Layout>
      <div className="users-list">
        <div className="container">
          <h3>Payments</h3>
          <Form>
            <Form.Group>
              <Form.Control
                type="date"
                name="fromDate"
                placeholder="Select Date"
                onChange={handleDateChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="date"
                name="toDate"
                placeholder="Select Date"
                onChange={handleDateChange}
              />
            </Form.Group>
            <Button type="button" onClick={searchPayment}>
              Search
            </Button>
          </Form>
          <div className="users-page-content">
            <Table responsive>
              <thead>
                <tr>
                  <th>Transaction Id</th>
                  <th>Invoices</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Address</th>
                  <th>Product Name</th>
                  <th>Amount</th>
                  <th>Company</th>
                  <th>Brand</th>
                  <th>Transaction Type</th>
                  <th>Date/Time</th>
                </tr>
              </thead>
              {loading ? (
                <Loader />
              ) : (
                <tbody>
                  {allPaymants &&
                    allPaymants?.length > 0 &&
                    allPaymants.map((el, i) => (
                      <tr>
                        <td>{el._id}</td>
                        <td className="invoice-pdf-menu">
                          <Dropdown>
                            <Dropdown.Toggle>
                              <i className="las la-ellipsis-v"></i>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {invoices
                                .find(
                                  (invoice) =>
                                    invoice.transactionId === el?._id,
                                )
                                ?.invoicePdf.map((ele) => (
                                  <>
                                    {ele.type === 'Proforma' && (
                                      <Dropdown.Item
                                        onClick={() => handleRedirect(ele._id)}
                                      >
                                        {ele.type}
                                      </Dropdown.Item>
                                    )}
                                    {ele?.companyId && (
                                      <Dropdown.Item
                                        onClick={() => handleRedirect(ele._id)}
                                      >
                                        {ele?.companyId?.name}
                                      </Dropdown.Item>
                                    )}
                                  </>
                                ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                        <td>{el?.userId?.name}</td>
                        <td>{el?.userId?.email}</td>
                        <td>{el?.userId?.phoneNumber}</td>
                        <td>{el?.userId?.address}</td>
                        <td>{el?.productId?.name}</td>
                        <td>{el.price} USD</td>
                        <td>{el?.companyId?.name}</td>
                        <td>{el?.brandId?.name}</td>
                        <td>{el.transactionType}</td>
                        <td>{new Date(el.date).toUTCString()}</td>
                      </tr>
                    ))}
                </tbody>
              )}
            </Table>
            {allPaymants && allPaymants?.length > 0 && (
              <Pagination
                pageCount={pageCount}
                handlePageClick={handlePageClick}
                setSkip={setSkip}
                skip={skip}
                paymentCount={paymentCount}
              />
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ViewPayments
