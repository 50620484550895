import React from "react";
import cancel from '../../assets/images/visa/cancel.svg'

const DocumentPopup = ({setDocumentPopup}) => {
  return (
    <div className="documents-popup">
      <p>
        <span>Important!</span> Every document must be written in one of
        Canada's official languages: English or French. If you don't have your
        documents in one of these languages, you must have them translated by a
        translator who is authorized to perform this kind of work by the
        corresponding GOV body who regulates this profession in your country.
      </p>
      <img src={cancel} alt="cancel" onClick={() => setDocumentPopup(false)} />
    </div>
  );
};
export default DocumentPopup;
