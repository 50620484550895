// import Layout from '../layout/layout'
import 'react-toastify/dist/ReactToastify.css'
import axios from 'axios'
import { Table, Dropdown, Button, FormGroup, Form } from 'react-bootstrap'
import dot from '../../assets/images/header/dot.svg'
import { serverPath } from '../../config/key'
import { useEffect, useState } from 'react'
//import { useNavigate } from "react-router-dom";
import UpdateUser from './updateUser'
import { Modal } from 'react-bootstrap'
import './user.css'
import { toast } from 'react-toastify'
import Loader from '../loader/loader'
import AdminLayout from '../admin/adminLayout/adminLayout'
import AddUserModal from './addUserModel'
import Pagination from '../pagination/index'
import '../modals/modal.css'
import UpdateUserPassword from './updatePassword'

const Users = () => {
  //const navigate=useNavigate()
  const [allUsers, setAllUSers] = useState([])
  const [activeId, setActiveId] = useState('')
  const [editPage, setEditPage] = useState(false)
  const [block, setBlock] = useState(false)
  const [blockStatus, setBockStatus] = useState()
  const [loading, setLoading] = useState(false)
  const [showAddUserModal, setShowAddUserModal] = useState(false)
  const [userCount, setUserCount] = useState(0)
  const [pageCount, setPageCount] = useState()
  const [skip, setSkip] = useState(0)
  const [searchKeyword, setSearchKeyword] = useState('')
  const [changePass, setPassChange] = useState(false)
  const[user,setUser]=useState()
  const pageLimit = 10
  const getUsers = async () => {
    setLoading(true)
    const response = await axios.get(
      `${serverPath}/users/getAllUsers`,
      {
        params: { skip, keyword: searchKeyword },
        headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
      },
      
    )
    setLoading(false)
    const { users } = response?.data || []
    if (users?.user?.length) {
      setAllUSers(users?.user)
      setUserCount(users?.count)
      setActiveId('')
    }
  }
  useEffect(() => {
    getUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip,searchKeyword])
  useEffect(() => {
    setPageCount(Math.ceil(userCount / pageLimit))
  }, [userCount])
  const handlePageClick = (selected) => {
    setSkip(selected * pageLimit)
    // setSelected(selected);
  }
  const handleEdit = (user) => {
    setActiveId(user?._id)
    setUser(user)
    setEditPage(true)
    // navigate(`/users/create-user?id=${id}`);
  }
  const handleChangePassword = (id) => {
    setActiveId(id)
    setPassChange(true)
    // navigate(`/users/create-user?id=${id}`);
  }
  const handleClose = () => {
    setEditPage(false)
    setPassChange(false)
  }

  const handleBlockShow = (id, status) => {
    setActiveId(id)
    setBlock(true)
    setBockStatus(status)
  }

  const handleBlockClose = () => {
    setBlock(false)
  }
  const handleBlockUser = async () => {
    try {
      const response = await axios.put(
        `${serverPath}/users/blockUsers/${activeId}`,
        {},
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      const { data } = response
      const { msg, status } = data.upd
      if (status === 200) {
        setBlock(false)
          setActiveId('')
          toast.success(`user ${msg} successfully`)
          getUsers()
      }
    } catch (err) {
      toast.error('Something went wrong!', { toastId: 'payId' })
    }
  }
  const handleShowUserModal = () => {
    setShowAddUserModal(!showAddUserModal)
  }
  const handleCloseUserModal = () => {
    setShowAddUserModal(false)
  }
  const handleSearch = (e) => {
    setSkip(0)
    setSearchKeyword(e.target.value)
  }
  return (
    <AdminLayout>
      <div className="users-list">
        <div className="container">
          <div className="user-list-head">
            <h3>Users</h3>
            <Button className="orange-button" onClick={handleShowUserModal}>
              Add User
            </Button>
          </div>
          <div className="users-page-content-input">

          <FormGroup>
            
              <Form.Control
              type="text"
                value={searchKeyword}
                placeholder="Enter search keyword"
                onChange={handleSearch}
              />
            </FormGroup>
            </div>
          <div className="users-page-content">

            <Table responsive>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              {loading ? (
                <Loader />
              ) : (
                <tbody>
                  {allUsers &&
                    allUsers?.length > 0 &&
                    allUsers.map((el, i) => (
                      <tr>
                        <td>{el.name}</td>
                        <td>{el.email}</td>
                        <td>{el.phoneNumber}</td>
                        <td>
                          {el.isBlock ? (
                            <span className="text-danger">Block</span>
                          ) : (
                            <span className="text-success">Active</span>
                          )}
                        </td>
                        <td>
                          <div className="user_btn_dot">
                            <Dropdown>
                              <Dropdown.Toggle id="dropdown-basic">
                                <img src={dot} alt="" />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => handleEdit(el)}
                                >
                                  Edit
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() =>
                                    handleBlockShow(el._id, el.isBlock)
                                  }
                                >
                                  Block
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => handleChangePassword(el._id)}
                                >
                                  Change Password
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              )}
            </Table>
            {allUsers && allUsers?.length > 0 && (
              <Pagination
                pageCount={pageCount}
                handlePageClick={handlePageClick}
                setSkip={setSkip}
                skip={skip}
              />
            )}
          </div>
        </div>
      </div>

      <Modal
        size="lg"
        show={editPage}
        onHide={handleClose}
        centered
        className="consultation-popup user-update-popup"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <UpdateUser
            activeId={activeId}
            setEditPage={setEditPage}
            getUsers={getUsers}
            user={user}
          />
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={changePass}
        onHide={handleClose}
        centered
        className="consultation-popup user-update-popup"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <UpdateUserPassword
            activeId={activeId}
            setPassChange={setPassChange}
          />
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={block}
        onHide={handleBlockClose}
        centered
        className="user-popup"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="user-block">
            <h3>
              Are you sure to {blockStatus ? 'active' : 'block'} this user?
            </h3>
            <div className="user-block-btn">
              <Button onClick={handleBlockClose}>No</Button>
              <Button onClick={handleBlockUser}>Yes</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        size="s"
        show={showAddUserModal}
        onHide={handleCloseUserModal}
        centered
        className="add-user-modal consultation-popup"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <AddUserModal />
        </Modal.Body>
      </Modal>
    </AdminLayout>
  )
}

export default Users
