import ApplicationFooter from "../../myapplication/footer";
import AdminSidebar from "../sidebar/adminSidebar";
import "../admin.css";

const AdminLayout = ({ children }) => {
  return (
    <div className="admin-page">
      <AdminSidebar />
      <div className="my-application-grid">
        <div className="admin-page-content my-application-content">
          {children}
        </div>
        <ApplicationFooter />
      </div>
    </div>
  );
};

export default AdminLayout;
