import React from "react";
import Layout from "../layout/layout";
import { useTranslation } from "react-i18next";
import parse from 'html-react-parser';
import "./legal.css";


const TermConditions = () => {
    const { t } = useTranslation();
  
    return (

    <Layout>


        <div className="legal-page">


            <div className="container">


                <div className="legal-content">

                    <h2>{t("terms.Heading")}</h2>

                    <div>{parse(t("terms.content"))}</div>


                </div>


                
               

                   

            </div>

           
        </div>


        <div className="legal-disclaimer">
            <div className="container">

YOU EXPRESSLY ACKNOWLEDGE THAT YOU HAVE READ THE TERMS OF USE AND UNDERSTAND THE RIGHTS, OBLIGATIONS, TERMS AND CONDITIONS SET FORTH HEREIN. BY USING ANY OF UIS CANADA’S WEBSITES OR OFFERED SERVICES OR SOFTWARE, YOU EXPRESSLY CONSENT TO BE BOUND BY ITS TERMS AND CONDITIONS AND GRANT TO UIS CANADA THE RIGHTS SET FORTH HEREIN.

</div>
</div>

    </Layout>

    );
  }
  
export default TermConditions;