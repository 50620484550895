import React from "react";
import ApplicationFooter from "./footer";
import ApplicationHeader from "./header";
import ApplicationSidebar from "./sidebar";
import { Helmet } from "react-helmet";
import "./myapplication.css";
import { useState } from "react";


const ApplicationLayout = ({ children }) => {
  const [opensidebar, setOpensidebar] = useState(false);

  const handleSideBar = () => {
    setOpensidebar(!opensidebar)
  }
  return (
    <div className={`my-application ${opensidebar ? 'open-sidebar' : ''}`}>
      <Helmet>
        <body className="my-application-page" />
      </Helmet>
      <ApplicationSidebar />
      <div className="my-application-grid">
        <ApplicationHeader handleSideBar={handleSideBar} />
        <div className="my-application-content">{children}</div>
        <ApplicationFooter />
      </div>
    </div>
  );
};

export default ApplicationLayout;
