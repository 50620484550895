import React from "react";
import Layout from "../layout/layout";
import VisaContact from "./visacontact";
import { useTranslation } from "react-i18next";
import studenticon from "../../assets/images/visa/student-icon.svg";
import student from "../../assets/images/visa/student.png";
import "./visa.css";

const StudentVisa = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <div className='express-visa-page'>
        <div className='express-visa-banner'>
          <div className='container'>
            <div className='express-visa-banner-heading'>
              <img src={studenticon} alt='' />
              <div className='express-visa-banner-heading-title'>
                <h5>{t("visaStaudent.studentHeading1")}</h5>
                <h2>{t("visaStaudent.studentHeading2")}</h2>
              </div>
            </div>

            <p>{t("visaStaudent.studentParagraph1")}</p>

            <p>{t("visaStaudent.studentParagraph2")}</p>

            <p>{t("visaStaudent.studentParagraph3")}</p>

            <p>{t("visaStaudent.studentParagraph4")}</p>

            <p>{t("visaStaudent.studentParagraph5")}</p>
          </div>
        </div>

        <div className='express-program student-program'>
          <div className='container'>
            <div className='express-program-grid'>
              <div className='express-program-content'>
                <h3>{t("studentProgram.Heading1")}</h3>
                <h4>{t("studentProgram.Heading2")}</h4>

                <ul>
                  <li>{t("studentProgram.List1")}</li>
                  <li>{t("studentProgram.List2")}</li>
                  <li>{t("studentProgram.List3")}</li>
                  <li>{t("studentProgram.List4")}</li>
                  <li>{t("studentProgram.List5")}</li>
                </ul>

                <p>{t("studentProgram.Paragraph1")}</p>

               
              </div>

              <div className='express-program-cover'>
                <img src={student} alt='' />
              </div>
            </div>
          </div>
        </div>

        <VisaContact />
      </div>
    </Layout>
  );
};

export default StudentVisa;
