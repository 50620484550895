import { useState, useContext } from 'react'
import { Navbar, Nav, Offcanvas } from 'react-bootstrap'
import { serverPath } from '../../config/key'
import axios from 'axios'
import UserContext from '../../context/UserContext'
import { NavLink, useNavigate } from 'react-router-dom'
import logouticon from '../../assets/images/application/logout.svg'
import caseicon from '../../assets/images/application/case.svg'
import menuClose from '../../assets/images/application/mobCloseIcon.svg'
import { useEffect } from 'react'

const SubMenu = ({ show, handleClose }) => {
  const navigate = useNavigate()
  const [visaType, setVisaType] = useState([])
  const { user, selectedVisa, setSelectedVisa } = useContext(UserContext)

  const handleLogout = () => {
    localStorage.removeItem('token')
    setSelectedVisa({
      visaId: '',
      invoiceId: '',
      caseId: '',
      visaName: '',
      invoiceNumber: '',
    })
    navigate('/login')
  }

  const allVisaType = async () => {
    const res = await axios.get(`${serverPath}/product/allVisaTypes`, {
      headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
    })
    const { visaData } = res?.data || {}
    const { userVisaType = [] } = visaData
    let type = []
    if (userVisaType?.length) {
      userVisaType?.forEach((el) => {
        if (el?.productId?.visaType || el) {
          type.push({
            name: `${el?.productId?.visaType?.name}-${el.invoiceNumber}`,
            _id: el?.productId?.visaType?._id,
            invoiceNumber: el?.invoiceNumber,
            invoiceId: el?._id,
            formsSponsor: el?.productId?.visaType?.formsSponsor
              ? el?.productId?.visaType?.formsSponsor
              : [],
          })
        }
      })
      setVisaType(type)
    }
  }
  useEffect(() => {
    allVisaType()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className="">
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="left"
        className="mob-sidebar"
        backdrop="static"
      >
        <Offcanvas.Body>
          <div className="application-menu">
            <div
              className="menu-close-btn"
              onClick={handleClose}
              role="presentation"
            >
              <img src={menuClose} alt="" />
            </div>
            <div className="application-case-mob">
              <div className="application-case-icon">
                <span>{user?.name}</span>
                <div className="application-case-id">
                  <img src={caseicon} alt="" /> case:{' '}
                  {selectedVisa?.caseId ? selectedVisa?.caseId : ''}
                </div>
              </div>
            </div>
            <Navbar>
              <Nav>
                <NavLink to="/guideline">
                  <span>
                    <GuidelineIcon />
                  </span>{' '}
                  Guidelines
                </NavLink>
                {visaType?.find(
                  (el) => el?.invoiceId === selectedVisa?.invoiceId,
                )?.formsSponsor?.length > 0 ? (
                  <>
                    <NavLink to="/application/sponsoring">
                      <span>
                        <ApplicationIcon />
                      </span>{' '}
                      Sponsoring(App)
                    </NavLink>
                    <NavLink to="/application/sponsored">
                      <span>
                        <ApplicationStarIconNew />
                      </span>{' '}
                      Sponsored(App)
                    </NavLink>
                  </>
                ) : (
                  <NavLink to="/application/0">
                    <span>
                      <ApplicationIcon />
                    </span>{' '}
                    Application
                  </NavLink>
                )}

                <NavLink to="/products">
                  <span>
                    <ProductIcon />
                  </span>{' '}
                  Product
                </NavLink>
                <NavLink to="/invoices">
                  <span>
                    <InvoiceIcon />
                  </span>{' '}
                  Invoices
                </NavLink>
              </Nav>
            </Navbar>
            <div className="application-logout" onClick={handleLogout}>
              <span>
                <img src={logouticon} alt="" /> Log Out
              </span>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  )
}

export default SubMenu

const ApplicationStarIconNew = () => {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 4H10L16 10V22C16 23.1 15.1 24 14 24H1.99C0.89 24 0 23.1 0 22V6C0 4.9 0.9 4 2 4ZM4 20H12V18H4V20ZM12 16H4V14H12V16ZM9 5.5V11H14.5L9 5.5Z"
        fill="#6243CE"
      />
      <path
        d="M19.008 3.98014L18.6 4.69214L17.272 3.92414C17.1813 3.86548 17.096 3.80681 17.016 3.74814C16.936 3.68948 16.8667 3.62281 16.808 3.54814C16.8827 3.71348 16.92 3.90548 16.92 4.12414V5.65214H16.088V4.14014C16.088 3.91081 16.1173 3.71881 16.176 3.56414C16.0747 3.69748 15.928 3.81748 15.736 3.92414L14.416 4.68414L14.008 3.97214L15.32 3.21214C15.512 3.10014 15.7013 3.02814 15.888 2.99614C15.7013 2.97481 15.512 2.90281 15.32 2.78014L14 2.01214L14.408 1.30014L15.736 2.06814C15.8267 2.12681 15.9093 2.18814 15.984 2.25214C16.064 2.31081 16.1333 2.37748 16.192 2.45214C16.1227 2.28148 16.088 2.08681 16.088 1.86814V0.348145H16.92V1.86014C16.92 1.96681 16.912 2.06814 16.896 2.16414C16.8853 2.26014 16.8613 2.35081 16.824 2.43614C16.9467 2.29214 17.096 2.16948 17.272 2.06814L18.592 1.30814L19 2.02014L17.688 2.78014C17.5973 2.83348 17.5067 2.88148 17.416 2.92414C17.3253 2.96148 17.2347 2.98548 17.144 2.99614C17.2347 3.01214 17.3253 3.03881 17.416 3.07614C17.5067 3.10814 17.5973 3.15348 17.688 3.21214L19.008 3.98014Z"
        fill="#6243CE"
      />
    </svg>
  )
}
// const ApplicationStarIcon = () => {
//   return (
//     <svg
//       width="20"
//       height="24"
//       viewBox="0 0 20 24"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         fill-rule="evenodd"
//         clip-rule="evenodd"
//         d="M2 4H10L16 10V22C16 23.1 15.1 24 14 24H1.99C0.89 24 0 23.1 0 22V6C0 4.9 0.9 4 2 4ZM4 20H12V18H4V20ZM12 16H4V14H12V16ZM9 5.5V11H14.5L9 5.5Z"
//         fill="#6243CE"
//       />
//       <path
//         d="M19.008 3.98014L18.6 4.69214L17.272 3.92414C17.1813 3.86548 17.096 3.80681 17.016 3.74814C16.936 3.68948 16.8667 3.62281 16.808 3.54814C16.8827 3.71348 16.92 3.90548 16.92 4.12414V5.65214H16.088V4.14014C16.088 3.91081 16.1173 3.71881 16.176 3.56414C16.0747 3.69748 15.928 3.81748 15.736 3.92414L14.416 4.68414L14.008 3.97214L15.32 3.21214C15.512 3.10014 15.7013 3.02814 15.888 2.99614C15.7013 2.97481 15.512 2.90281 15.32 2.78014L14 2.01214L14.408 1.30014L15.736 2.06814C15.8267 2.12681 15.9093 2.18814 15.984 2.25214C16.064 2.31081 16.1333 2.37748 16.192 2.45214C16.1227 2.28148 16.088 2.08681 16.088 1.86814V0.348145H16.92V1.86014C16.92 1.96681 16.912 2.06814 16.896 2.16414C16.8853 2.26014 16.8613 2.35081 16.824 2.43614C16.9467 2.29214 17.096 2.16948 17.272 2.06814L18.592 1.30814L19 2.02014L17.688 2.78014C17.5973 2.83348 17.5067 2.88148 17.416 2.92414C17.3253 2.96148 17.2347 2.98548 17.144 2.99614C17.2347 3.01214 17.3253 3.03881 17.416 3.07614C17.5067 3.10814 17.5973 3.15348 17.688 3.21214L19.008 3.98014Z"
//         fill="#6243CE"
//       />
//     </svg>
//   )
// }
const GuidelineIcon = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16 2H11.82C11.4 0.84 10.3 0 9 0C7.7 0 6.6 0.84 6.18 2H2C0.9 2 0 2.9 0 4V18C0 19.1 0.9 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM9 2C9.55 2 10 2.45 10 3C10 3.55 9.55 4 9 4C8.45 4 8 3.55 8 3C8 2.45 8.45 2 9 2ZM4 16H11V14H4V16ZM14 12H4V10H14V12ZM4 8H14V6H4V8Z"
        fill="#F35C5C"
      />
    </svg>
  )
}

const ApplicationIcon = () => {
  return (
    <svg
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.9541 0H10.9541L16.9541 6V18C16.9541 19.1 16.0541 20 14.9541 20H2.9441C1.8441 20 0.954102 19.1 0.954102 18V2C0.954102 0.9 1.8541 0 2.9541 0ZM4.9541 16H12.9541V14H4.9541V16ZM12.9541 12H4.9541V10H12.9541V12ZM9.9541 1.5V7H15.4541L9.9541 1.5Z"
        fill="#3A416F"
      />
    </svg>
  )
}

const InvoiceIcon = () => {
  return (
    <svg
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0528 12.5001V13.3335C17.0528 14.2502 16.2002 15.0002 15.1581 15.0002H1.89476C0.843167 15.0002 0 14.2502 0 13.3335V1.66668C0 0.750008 0.843167 0 1.89476 0H15.1581C16.2002 0 17.0528 0.750008 17.0528 1.66668V2.50003H8.52641C7.47482 2.50003 6.63165 3.25003 6.63165 4.16671V10.8334C6.63165 11.7501 7.47482 12.5001 8.52641 12.5001H17.0528ZM8.5264 10.8335H18.0002V4.16672H8.5264V10.8335ZM10.8949 7.50016C10.8949 8.19183 11.5296 8.75017 12.316 8.75017C13.1023 8.75017 13.737 8.19183 13.737 7.50016C13.737 6.80848 13.1023 6.25014 12.316 6.25014C11.5296 6.25014 10.8949 6.80848 10.8949 7.50016Z"
        fill="#3A416F"
      />
    </svg>
  )
}
const ProductIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 8H4V6H20V8ZM18 2H6V4H18V2ZM22 12V20C22 20.5304 21.7893 21.0391 21.4142 21.4142C21.0391 21.7893 20.5304 22 20 22H4C3.47005 21.9984 2.96227 21.7872 2.58753 21.4125C2.2128 21.0377 2.00158 20.5299 2 20V12C2.00158 11.4701 2.2128 10.9623 2.58753 10.5875C2.96227 10.2128 3.47005 10.0016 4 10H20C20.5299 10.0016 21.0377 10.2128 21.4125 10.5875C21.7872 10.9623 21.9984 11.4701 22 12ZM13.927 17.042L16.25 15.056L13.191 14.8L12 12L10.809 14.8L7.75 15.056L10.073 17.042L9.373 20L12 18.428L14.627 20L13.927 17.042Z"
        fill="#6243CE"
      />
    </svg>
  )
}
