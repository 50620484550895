import React from 'react'
import { Button } from 'react-bootstrap'
import icon1 from '../../assets/images/header/canada.svg'
import facebook from '../../assets/images/header/fb.svg'
import linkedin from '../../assets/images/header/linkedin.svg'
import twitter from '../../assets/images/header/twitter.svg'
import { useLocation, useNavigate } from 'react-router-dom'

const ThankYouPage2 = () => {
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const navigate = useNavigate()
  const handleRedirect = (e) => {
    e.preventDefault()
    navigate(
      `/WorldTravel/?utm_medium=${queryParams.get(
        'utm_medium',
      )}&UTM1=${queryParams.get('UTM1')}&UTM2=${queryParams.get(
        'UTM2',
      )}&UTM3=${queryParams.get('UTM3')}&UTM8=${queryParams.get(
        'UTM8',
      )}&utm_campaign=${queryParams.get(
        'utm_campaign',
      )}&Campaign_source=${queryParams.get(
        'Campaign_source',
      )}&lId=${queryParams.get('lId')}`,
    )
  }
  return (
    <>
      <div className="landing-thank-you">
        <div className="thank-you-grid">
          <div className="thank-you-content">
            <img src={icon1} alt="" />
            <h2>Thank You!</h2>
            <p>We’ve received your details and will contact you shortly.</p>
          </div>
          <div className="thankyou-cta">
            <p>
              Want to get more details about our working holiday opportunities?
            </p>
            <span>
              Check out our all-inclusive Work & Travel packages below!
            </span>
            <Button
              className="orange-button"
              onClick={(e) => {
                handleRedirect(e)
              }}
            >
              {' '}
              Discover Our Selection
            </Button>
          </div>
          <div className="thank-you-social">
            <p>Let’s Connect!</p>
            <ul>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/uis.canada"
                >
                  <img src={facebook} alt="" />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.linkedin.com/company/uis-canada-official/"
                >
                  <img src={linkedin} alt="" />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://twitter.com/uis_canada"
                >
                  <img src={twitter} alt="" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default ThankYouPage2
