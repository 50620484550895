/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Accordion, Button } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { toast } from 'react-toastify'
import { serverPath } from '../../config/key'
import { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import DatePicker from 'react-datepicker'
import UserContext from '../../context/UserContext'
import { yupResolver } from '@hookform/resolvers/yup'
import { languageExperienceSchema } from '../../utils/validationSchema'
import approved from '../../assets/images/header/approved.svg'
import { useParams } from 'react-router-dom'
import ErrorModal from '../modals/errorModal'
const LanguagesSection = ({
  LanguageInfo,
  setLanguageInfo,
  adminFormDisable,
}) => {
  const urlParams = new URLSearchParams(window.location.search)
  const { formType } = useParams()
  const token = urlParams.get('t')
  const [langDis, setLangDis] = useState(false)
  const { selectedVisa, setSelectedVisa } = useContext(UserContext)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(languageExperienceSchema),
    shouldUnregister: true,
  })
  const [frenchDateOfTest, setFrenchDateOfTest] = useState(
    LanguageInfo?.frenchDateOfTest
      ? new Date(LanguageInfo?.frenchDateOfTest)
      : new Date(),
  )
  const [englishDateOfTest, setEnglishDateOfTest] = useState(
    LanguageInfo?.englishDateOfTest
      ? new Date(LanguageInfo?.englishDateOfTest)
      : new Date(),
  )
  const watchEnglishLevel = watch('levelOfEnglish')
  const watchFrenchLevel = watch('levelOfFrench')
  const watchEnglishProfciencyTest = watch('englishProficiencyTest')
  const watchFrenchProfciencyTest = watch('frenchProficiencyTest')
  useEffect(() => {
    if (LanguageInfo && LanguageInfo?.isLocked) {
      setLangDis(token ? adminFormDisable : true)
    }
    if (LanguageInfo) {
      setValue(
        'levelOfEnglish',
        LanguageInfo && LanguageInfo?.levelOfEnglish
          ? LanguageInfo?.levelOfEnglish
          : '',
      )
      setValue(
        'levelOfFrench',
        LanguageInfo && LanguageInfo?.levelOfFrench
          ? LanguageInfo?.levelOfFrench
          : '',
      )
      setValue(
        'englishProficiencyTest',
        LanguageInfo && LanguageInfo?.englishProficiencyTest
          ? LanguageInfo?.englishProficiencyTest
          : '',
      )
      setValue(
        'frenchProficiencyTest',
        LanguageInfo && LanguageInfo?.frenchProficiencyTest
          ? LanguageInfo?.frenchProficiencyTest
          : '',
      )
    }
    if (token) {
      setValue(
        'levelOfEnglish',
        LanguageInfo && LanguageInfo?.levelOfEnglish
          ? LanguageInfo?.levelOfEnglish
          : '',
      )
      setValue(
        'levelOfFrench',
        LanguageInfo && LanguageInfo?.levelOfFrench
          ? LanguageInfo?.levelOfFrench
          : '',
      )
    }
  }, [LanguageInfo, token, adminFormDisable])
  const handleTestDatePicker = (date, type) => {
    if (type === 'englishDateOfTest') {
      setEnglishDateOfTest(date)
      setValue('englishTest.englishDateOfTest', date)
    }
    if (type === 'frenchDateOfTest') {
      setFrenchDateOfTest(date)
      setValue('frenchTest.frenchDateOfTest', date)
    }
  }
  const handleShowErrorModal = () => {
    setShowErrorModal(false)
  }
  useEffect(() => {
    if (errors && Object.keys(errors).length !== 0) {
      setShowErrorModal(true)
    } else {
      setShowErrorModal(false)
    }
  }, [errors])
  const submitLanguageInfo = async (values) => {
    let apiPath = token
      ? 'support/visaUpdateLanguageSkill'
      : 'visa/visaLanguageSkill'
    const res = await axios.post(
      `${serverPath}/${apiPath}`,
      {
        ...values,
        visaTypeId: selectedVisa.visaId,
        invoiceId: selectedVisa.invoiceId,
        formType: formType,
      },
      {
        headers: {
          authorization: `Bearer ${
            token ? token : localStorage.getItem('token')
          }`,
        },
      },
    )
    const { code, create } = res.data
    if (code === 200) {
      reset()
      setLanguageInfo(create)
      setSelectedVisa({
        invoiceId: create?.invoiceId,
        visaId: create?.visaTypeId,
        caseId: selectedVisa?.caseId,
        invoiceNumber: selectedVisa?.invoiceNumber,
        visaName: selectedVisa?.visaName,
        isEvaluation: selectedVisa?.isEvaluation,
      })
      toast.success('Your information saved successfully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      })
    }
  }

  return (
    <div className="personal-info education-info-section">
      <Form onSubmit={handleSubmit(submitLanguageInfo)}>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Main Applicant</Accordion.Header>

            <Accordion.Body>
              <div className="child-info">
                <div className="box-grid-two">
                  <Form.Group
                    className={`form-group ${
                      errors?.levelOfEnglish?.message ? 'error-msg' : ''
                    }`}
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Level of English*</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="levelOfEnglish"
                      defaultValue={LanguageInfo?.levelOfEnglish
                        ? LanguageInfo?.levelOfEnglish
                        : ''}
                      {...register('levelOfEnglish')}
                      disabled={langDis ? langDis : false}
                    >
                      <option value="">
                        {LanguageInfo?.levelOfEnglish
                          ? LanguageInfo?.levelOfEnglish
                          : 'Select'}
                      </option>
                      <option value="Basic">Basic</option>
                      <option value="Intermediate">Intermediate</option>
                      <option value="Fluent">Fluent</option>
                      <option value="Mother Tongue">Mother Tongue</option>
                    </Form.Select>
                    {errors?.levelOfEnglish ? (
                      <span className="error-msg">
                        {errors?.levelOfEnglish?.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Group>
                  <Form.Group className="form-group" controlId="formBasicEmail">
                    <Form.Label>Level of French</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="levelOfFrench"
                      defaultValue={LanguageInfo?.levelOfFrench
                        ? LanguageInfo?.levelOfFrench
                        : ''}
                      disabled={langDis ? langDis : false}
                      {...register('levelOfFrench')}
                    >
                      <option value="">
                        {LanguageInfo?.levelOfFrench
                          ? LanguageInfo?.levelOfFrench
                          : 'Select'}
                      </option>
                      <option value="Basic">Basic</option>
                      <option value="Intermediate">Intermediate</option>
                      <option value="Fluent">Fluent</option>
                      <option value="Mother Tongue">Mother Tongue</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="box-grid-two">
                  {watchEnglishLevel !== '' ||
                  (LanguageInfo &&
                    LanguageInfo?.englishProficiencyTest !== '') ? (
                    <Form.Group
                      // className={`form-group ${
                      //   errors?.levelOfEnglish?.message ? 'error-msg' : ''
                      // }`}
                      className="form-group"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>
                        Did you take an English proficiency test?
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="englishProficiencyTest"
                        defaultValue={LanguageInfo?.englishProficiencyTest
                          ? LanguageInfo?.englishProficiencyTest
                          : ''}
                        {...register('englishProficiencyTest')}
                        disabled={langDis ? langDis : false}
                      >
                        <option value="">
                          {LanguageInfo?.englishProficiencyTest
                            ? LanguageInfo?.englishProficiencyTest
                            : 'Select'}
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </Form.Select>
                      {/* {errors?.levelOfEnglish ? (
                        <span className="error-msg">
                          {errors?.levelOfEnglish?.message}
                        </span>
                      ) : (
                        ''
                      )} */}
                    </Form.Group>
                  ) : (
                    ''
                  )}
                  {watchFrenchLevel !== '' ||
                  (LanguageInfo &&
                    LanguageInfo?.frenchProficiencyTest !== '') ? (
                    <Form.Group
                      // className={`form-group ${
                      //   errors?.levelOfEnglish?.message ? 'error-msg' : ''
                      // }`}
                      className="form-group"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>
                        Did you take an French proficiency test?
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="frenchProficiencyTest"
                        defaultValue={LanguageInfo?.frenchProficiencyTest
                          ? LanguageInfo?.frenchProficiencyTest
                          : ''}
                        {...register('frenchProficiencyTest')}
                        disabled={langDis ? langDis : false}
                      >
                        <option value="">
                          {LanguageInfo?.frenchProficiencyTest
                            ? LanguageInfo?.frenchProficiencyTest
                            : 'Select'}
                        </option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </Form.Select>
                      {/* {errors?.levelOfEnglish ? (
                        <span className="error-msg">
                          {errors?.levelOfEnglish?.message}
                        </span>
                      ) : (
                        ''
                      )} */}
                    </Form.Group>
                  ) : (
                    ''
                  )}
                </div>

                {watchEnglishProfciencyTest === 'Yes' ||
                LanguageInfo?.englishProficiencyTest === 'Yes' ? (
                  <>
                    <h4 className="language-heading">
                      English Proficiency Test
                    </h4>
                    <div className="box-grid-two">
                      <Form.Group
                        className={`form-group ${
                          errors?.englishTest?.englishDateOfTest?.message
                            ? 'error-msg'
                            : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Date of test*</Form.Label>
                        <DatePicker
                          type="englishDateOfTest"
                          name="englishDateOfTest"
                          disabled={langDis ? langDis : false}
                          placeholderText="Select Date"
                          selected={englishDateOfTest}
                          onChange={(date) =>
                            handleTestDatePicker(date, 'englishDateOfTest')
                          }
                          className="form-select"
                          dateFormat="dd-MM-yyyy"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                        />
                        {errors?.englishTest?.englishDateOfTest?.message ? (
                          <span className="error-msg">
                            {errors?.englishTest?.englishDateOfTest?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>

                      <Form.Group
                        className={`form-group ${
                          errors?.englishTest?.englishFinalScore?.message
                            ? 'error-msg'
                            : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Final score*</Form.Label>
                        <Form.Control
                          aria-label="Default select example"
                          name="englishFinalScore"
                          defaultValue={LanguageInfo?.englishFinalScore}
                          {...register('englishTest.englishFinalScore')}
                          disabled={langDis ? langDis : false}
                        />
                        {errors?.englishTest?.englishFinalScore?.message ? (
                          <span className="error-msg">
                            {errors?.englishTest?.englishFinalScore?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                    </div>
                    <div className="box-grid-two">
                      <Form.Group
                        className={`form-group ${
                          errors?.englishTest?.englishReadingScore?.message
                            ? 'error-msg'
                            : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Reading score*</Form.Label>
                        <Form.Control
                          aria-label="Default select example"
                          name="englishReadingScore"
                          defaultValue={LanguageInfo?.englishReadingScore}
                          {...register('englishTest.englishReadingScore')}
                          disabled={langDis ? langDis : false}
                        />
                        {errors?.englishTest?.englishReadingScore ? (
                          <span className="error-msg">
                            {errors?.englishTest?.englishReadingScore?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                      <Form.Group
                        className={`form-group ${
                          errors?.englishTest?.englishWritingScore?.message
                            ? 'error-msg'
                            : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Writing score*</Form.Label>
                        <Form.Control
                          aria-label="Default select example"
                          name="englishWritingScore"
                          defaultValue={LanguageInfo?.englishWritingScore}
                          {...register('englishTest.englishWritingScore')}
                          disabled={langDis ? langDis : false}
                        />
                        {errors?.englishTest?.englishWritingScore ? (
                          <span className="error-msg">
                            {errors?.englishTest?.englishWritingScore?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                    </div>
                    <div className="box-grid-two">
                      <Form.Group
                        className={`form-group ${
                          errors?.englishTest?.englishListeningScore?.message
                            ? 'error-msg'
                            : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Listening score*</Form.Label>
                        <Form.Control
                          aria-label="Default select example"
                          name="englishListeningScore"
                          defaultValue={LanguageInfo?.englishListeningScore}
                          {...register('englishTest.englishListeningScore')}
                          disabled={langDis ? langDis : false}
                        />
                        {errors?.englishTest?.englishListeningScore ? (
                          <span className="error-msg">
                            {
                              errors?.englishTest?.englishListeningScore
                                ?.message
                            }
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                      <Form.Group
                        className={`form-group ${
                          errors?.englishTest?.englishSpeakingScore &&
                          errors?.englishTest?.englishSpeakingScore
                            ? 'error-msg'
                            : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Speaking Score*</Form.Label>
                        <Form.Control
                          aria-label="Default select example"
                          name="englishSpeakingScore"
                          defaultValue={LanguageInfo?.englishSpeakingScore}
                          {...register('englishTest.englishSpeakingScore')}
                          disabled={langDis ? langDis : false}
                        />
                        {errors?.englishTest?.englishSpeakingScore?.message ? (
                          <span className="error-msg">
                            {errors?.englishTest?.englishSpeakingScore?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                    </div>
                  </>
                ) : (
                  ''
                )}

                {watchFrenchProfciencyTest === 'Yes' ||
                LanguageInfo?.frenchProficiencyTest === 'Yes' ? (
                  <>
                    <h4 className="language-heading">
                      French Proficiency Test
                    </h4>
                    <div className="box-grid-two">
                      <Form.Group
                        className={`form-group ${
                          errors?.frenchTest?.frenchDateOfTest
                            ? 'error-msg'
                            : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Date of test*</Form.Label>
                        <DatePicker
                          type="frenchDateOfTest"
                          name="frenchDateOfTest"
                          disabled={langDis ? langDis : false}
                          placeholderText="Select Date"
                          selected={frenchDateOfTest}
                          onChange={(date) =>
                            handleTestDatePicker(date, 'frenchDateOfTest')
                          }
                          className="form-select"
                          dateFormat="dd-MM-yyyy"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                        />
                        {errors?.frenchTest?.frenchDateOfTest?.message ? (
                          <span className="error-msg">
                            {errors?.frenchTest?.frenchDateOfTest?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                      <Form.Group
                        className={`form-group ${
                          errors?.frenchTest?.frenchFinalScore?.message
                            ? 'error-msg'
                            : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Final score*</Form.Label>
                        <Form.Control
                          aria-label="Default select example"
                          name="frenchFinalScore"
                          defaultValue={LanguageInfo?.frenchFinalScore}
                          {...register('frenchTest.frenchFinalScore')}
                          disabled={langDis ? langDis : false}
                        />
                        {errors?.frenchTest?.frenchFinalScore?.message ? (
                          <span className="error-msg">
                            {errors?.frenchTest?.frenchFinalScore?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                    </div>
                    <div className="box-grid-two">
                      <Form.Group
                        className={`form-group ${
                          errors?.frenchTest?.frenchReadingScore?.message
                            ? 'error-msg'
                            : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Reading score*</Form.Label>
                        <Form.Control
                          aria-label="Default select example"
                          name="frenchReadingScore"
                          defaultValue={LanguageInfo?.frenchReadingScore}
                          {...register('frenchTest.frenchReadingScore')}
                          disabled={langDis ? langDis : false}
                        />
                        {errors?.frenchTest?.frenchReadingScore?.message ? (
                          <span className="error-msg">
                            {errors?.frenchTest?.frenchReadingScore?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                      <Form.Group
                        className={`form-group ${
                          errors?.frenchTest?.frenchWritingScore
                            ? 'error-msg'
                            : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Writing score*</Form.Label>
                        <Form.Control
                          aria-label="Default select example"
                          name="frenchWritingScore"
                          defaultValue={LanguageInfo?.frenchWritingScore}
                          {...register('frenchTest.frenchWritingScore')}
                          disabled={langDis ? langDis : false}
                        />
                        {errors?.frenchTest?.frenchWritingScore?.message ? (
                          <span className="error-msg">
                            {errors?.frenchTest?.frenchWritingScore?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                    </div>
                    <div className="box-grid-two">
                      <Form.Group
                        className={`form-group ${
                          errors?.frenchTest?.frenchListeningScore
                            ? 'error-msg'
                            : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Listening score*</Form.Label>
                        <Form.Control
                          aria-label="Default select example"
                          name="frenchListeningScore"
                          defaultValue={LanguageInfo?.frenchListeningScore}
                          {...register('frenchTest.frenchListeningScore')}
                          disabled={langDis ? langDis : false}
                        />
                        {errors?.frenchTest?.frenchListeningScore ? (
                          <span className="error-msg">
                            {errors?.frenchTest?.frenchWritingScore?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                      <Form.Group
                        className={`form-group ${
                          errors?.frenchTest?.frenchSpeakingScore
                            ? 'error-msg'
                            : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Speaking Score*</Form.Label>
                        <Form.Control
                          aria-label="Default select example"
                          name="frenchSpeakingScore"
                          defaultValue={LanguageInfo?.frenchSpeakingScore}
                          {...register('frenchTest.frenchSpeakingScore')}
                          disabled={langDis ? langDis : false}
                        />
                        {errors?.frenchTest?.frenchSpeakingScore ? (
                          <span className="error-msg">
                            {errors?.frenchTest?.frenchSpeakingScore?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                    </div>
                  </>
                ) : (
                  ''
                )}
                <Form.Group className="form-group" controlId="formBasicEmail">
                  <Form.Label>
                    If your native language isn't French or English, which
                    language do you use most frequently?
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="nativeLanguage"
                    defaultValue={LanguageInfo?.nativeLanguage
                      ? LanguageInfo?.nativeLanguage
                      : ''}
                    disabled={langDis ? langDis : false}
                    {...register('nativeLanguage')}
                  >
                    <option value="">
                      {LanguageInfo?.nativeLanguage
                        ? LanguageInfo?.nativeLanguage
                        : 'Select'}
                    </option>
                    <option value="Hindi">Hindi</option>
                    <option value="Dutch">Dutch</option>
                    <option value="Spanish">Spanish</option>
                    <option value="Arabic">Arabic</option>
                  </Form.Select>
                </Form.Group>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <div className="visa-btn">
          {!langDis ? (
            <>
              <Button
                type="submit"
                disabled={langDis ? langDis : false}
                className={`${langDis ? 'success-btn' : ''}`}
              >
                Save {langDis && <img src={approved} alt="approved" />}
              </Button>
              <div className="visa-desc-text">
                <p>
                  Once your form is completed, you will be able to make new
                  changes by contacting our Support team.
                </p>
                <p>When you're ready, click "Submit"!</p>
              </div>
            </>
          ) : (
            <div className="for-new-changes">
              <p>
                <span>Important! </span> Please contact our Support team if you
                would like to make new changes .
              </p>
            </div>
          )}
        </div>
      </Form>
      <ErrorModal show={showErrorModal} handleClose={handleShowErrorModal} />
    </div>
  )
}
export default LanguagesSection
