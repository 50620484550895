import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import PhoneInput from 'react-phone-input-2'
import close from '../../assets/images/pricing/closeIcon.svg'
import { serverPath } from '../../config/key'
import UserContext from '../../context/UserContext'
import { purchaseProduct } from '../../utils/validationSchema'

const PricingModal = ({ show, setShow, product }) => {
  const { userGeoData } = useContext(UserContext)
  const [provider, setProvider] = useState([])
  // const [succ, setSucc] = useState(false)
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
  } = useForm({ resolver: yupResolver(purchaseProduct) })
  const handlePhoneChange = (e) => {
    setValue('phone', '+' + e)
  }
  const getProvider = async () => {
    const res = await axios.get(
      `${serverPath}/payment-provider/getPaymentProvider`,
    )
    const { provider, status } = res?.data
    if (status === 200) {
      setProvider(provider)
    }
  }
  useEffect(() => {
    getProvider()
  }, [])
  const handleMakePurchase = async (values) => {
    try {
      const response = await axios.post(
        `${serverPath}/payment-provider/purchasePublicProduct`,
        {
          ...values,
          ip: userGeoData.ip,
          countryCode: userGeoData.country_code,
          amount: product.price,
          currency: 'USD',
          productName: product.name,
          productId: product.salesForceId,
        },
      )
      const {
        data: { paymentId, providerId },
        status,
      } = response
      if (status === 200 || status === 201) {
        window.location.href = `${serverPath}/payment-provider/pay/${paymentId}/${providerId}`
      }
    } catch (error) {
      if (error?.response?.data) {
        toast.error(error.response.data.msg, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
      }
    }
  }
  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false)
        clearErrors()
        reset()
      }}
      centered
      className="pricing-modal"
    >
      <Modal.Header>
        <Modal.Title>
          Great Choice, We Just Need a Little More Data To Continue.
        </Modal.Title>
        <Button
          className="modal-close-button"
          onClick={() => {
            setShow(false)
            clearErrors()
            reset()
          }}
        >
          <img src={close} alt="icon" />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Form
          onSubmit={handleSubmit(handleMakePurchase)}
          className="pricing-form"
        >
          <div className="pricing-form-section">
            <Form.Group className="form-group">
              <Form.Control
                placeholder="Full Name"
                name="name"
                {...register('name')}
              />
              {errors?.name ? (
                <span className="error-msg">{errors?.name?.message}</span>
              ) : (
                ''
              )}
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Control
                placeholder="Email"
                name="email"
                {...register('email')}
              />
              {errors?.email ? (
                <span className="error-msg">{errors?.email?.message}</span>
              ) : (
                ''
              )}
            </Form.Group>
            <Form.Group>
              <PhoneInput
                country={userGeoData?.country_code?.toLowerCase()}
                onChange={handlePhoneChange}
                inputProps={{
                  name: 'phone',
                }}
              />

              {errors?.phone ? (
                <span className="error-msg">{errors?.phone?.message}</span>
              ) : (
                ''
              )}
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Select
                name="providerId"
                aria-label="Default select example"
                {...register('providerId')}
              >
                <option value="">Select Company</option>
                {provider &&
                  provider?.length > 0 &&
                  provider.map((el) => (
                    <React.Fragment key={el?._id}>
                      <option value={el?._id}>{el?.paymentProviderType}</option>
                    </React.Fragment>
                  ))}
              </Form.Select>
              {errors?.providerId ? (
                <span className="error-msg">{errors?.providerId?.message}</span>
              ) : (
                ''
              )}
            </Form.Group>
            <Button type="submit" className="orange-button">
              Final Step
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default PricingModal
