import React from 'react'
import { useLocation } from 'react-router-dom'
import Helmet from 'react-helmet'
import Layout from '../layout/layout'
import facebook from '../../assets/images/thankyou/fb.svg'
import linkedin from '../../assets/images/thankyou/linkedin.svg'
import twitter from '../../assets/images/thankyou/twitter.svg'
import bg1 from '../../assets/images/thankyou/bg1.jpg'
import bg2 from '../../assets/images/thankyou/bg1.jpg'
import bg3 from '../../assets/images/thankyou/bg1.jpg'
import bg4 from '../../assets/images/thankyou/bg1.jpg'
import './thankyou.css'

const bg = { bg1, bg2, bg3, bg4 }

const FailedPage = ({ paymentError }) => {
  const { state } = useLocation()
  const search = useLocation().search
  return (
    <Layout>
      <Helmet>
        <body className="hide-layout" />
      </Helmet>

      <div className="thank-you-page">
        <div
          className="thank-you-bg"
          style={{
            backgroundImage: 'url(' + bg[state?.bg] + ')',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        ></div>

        <div className="thank-you-grid">
          <div className="thank-you-content">
            <img src="" alt="" />
            <h3>We have a problem...</h3>
            <p>
              {state?.description ||
                new URLSearchParams(search).get('err') || { paymentError }}
            </p>
            <p>
              Please provide the above error message to your support
              representative.
            </p>
          </div>

          <div className="thank-you-social">
            <p>Let’s Connect!</p>
            <ul>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/uis.canada"
                >
                  <img src={facebook} alt="" />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.linkedin.com/company/uis-canada-official/"
                >
                  <img src={linkedin} alt="" />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://twitter.com/uis_canada"
                >
                  <img src={twitter} alt="" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default FailedPage
