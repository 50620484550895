import { Modal } from 'react-bootstrap'
import ThankYouPage from './thankyou'
const PaymentSuccess = ({ openThankYou, handleClose }) => {
  return (
    <>
      <Modal
        centered
        show={openThankYou}
        onHide={handleClose}
        className="payment-success"
      >
        <Modal.Body>
          <ThankYouPage />
        </Modal.Body>
      </Modal>
    </>
  )
}
export default PaymentSuccess
