export const studentVisaGuidLine = [
  {
    title: 'Submitting Documents',
    description: 'All documentation must be scanned in a PDF format.',
  },
  {
    title: 'Translating Documents',
    description: `Every document must be written in one of Canada's official languages: English or French.If you don't have your documents in one of these languages, you must have them translated by a translator who is authorized to perform this kind of work by the corresponding GOV body who regulates this profession in your country.`,
  },
  {
    title: 'Digital Photo',
    description: '',
    links: {
      linkTitle: 'Photo Example - View / Download',
      link:
        'https://www.google.com/search?q=canada+immigration+digital+photo+measurements&rlz=1C1WPZC_en__912__912&sxsrf=AOaemvLZxFcPxRi5YkxCGl78A7DaMvPF2g:1639645915178&tbm=isch&source=iu&ictx=1&fir=G2jcJY85PYh0_M%252CuDyGUw4xsRyAQM%252C_&vet=1&usg=AI4_-kQWyiqrsoZCKqX9uw99Vj-v6xRryg&sa=X&ved=2ahUKEwi62dKC_ef0AhVUhv0HHd-CCXsQ9QF6BAgNEAE&biw=1920&bih=937&dpr=1#imgrc=XDMIG2DDN1ICuM',
      linkDescription: '',
    },
    multipleDesc: [
      'Frame size: 35mm X 45mm.',
      'For scanned photos, the minimum resolution must be 600 pixels per inch.',
      'Full frontal view of the face and head, and top of the shoulders.',
      'Size of the head, from chin to crown, must be between 31mm and 36mm.',
      'The physical dimensions in pixels must be at least 420 X 540 (digital dimensions are often expressed in pixels or DPI (Dots Per Inch).',
    ],
  },
  {
    title: 'Police Clearance',
    description: `Your police record should include all criminal offenses from the past <b>10 years</b>, including from countries that you visited for <b>6 months or more.</b>`,
  },
  {
    title: 'Proof of Medical Exam From A Canadian Approved Physician',
    description: `You must book your medical exam with a medical professional from <a class='guideline_desc_link'href='https://secure.cic.gc.ca/pp-md/pp-list.aspx'>Panel Physicians</a>. 
    <i>Please enquire with your physician about what to bring to the exam!</i> 
    If your application is in progress and you can’t complete your IME due to COVID-19, 
    please provide proof that a medical appointment is scheduled, including the name of the panel physician 
    and the date and time of the appointment, 
    or a letter of explanation of your efforts to have the IME done.`,
    // links: {
    //   linkTitle:
    //     'You must book your medical exam with a medical professional from Panel Physician',
    //   link: 'https://secure.cic.gc.ca/pp-md/pp-list.aspx',
    //   linkDescription:
    //     'please enquire with your physician about what to bring to the exam',
    // },
  },
  {
    title: 'Letter of Motives',
    description: `This letter helps the visa officer to understand you and your goals. It explains why you want to study in Canada and your responsibilities as a student. 
    <i>You should include this letter even if you don't need a study permit for your program!</i>
    <br/>Your letter must clearly state that you have a solid intention to return to your country of origin upon the expiry of your visa.`,
  },

  {
    title: 'Financial Support Letter (signed by the sponsor)',
    description: `This letter must be written by the sponsoring parent of the applicant. The letter must include clear confirmation that the parent of the applicant will provide financial support during their stay in Canada.`,
    links: {
      linkTitle: 'Example',
      link:
        'https://acrobat.adobe.com/link/track?uri=urn:aaid:scds:US:3f0cb2d2-90c5-3490-831b-bcdb4203f80f',
      linkDescription: '',
    },
  },
  {
    title: 'Recent Bank Statement',
    description: `Your bank statement must reflect an account balance of at least $10,000 CAD + 1st year tuition fees. It must be dated within the last 4 months.`,
  },
  {
    title: 'Letter of acceptance',
    description: `Students can establish acceptance to a course or program of study by showing an original letter of acceptance from the DLI that they will be attending.`,
    links: {
      linkTitle: 'Example',
      link:
        'https://acrobat.adobe.com/link/track?uri=urn:aaid:scds:US:d8a44168-14ac-319b-83ba-34badaced6f2',
      linkDescription: '',
    },
  },
]
export const evaluationGuideline = [
  {
    title: 'Submitting Documents',
    description: 'All documentation must be scanned in a PDF format.',
  },
  {
    title: 'Translating Documents',
    description: `Every document must be written in one of Canada's official languages: English or French. If you don't have your documents in one of these languages, you must have them translated by a translator who is authorized to perform this kind of work by the corresponding GOV body who regulates this profession in your country.`,
  },
  {
    title: 'The Evaluation Process',
    description: `Our immigration team will thoroughly evaluate your and/or your family’s profile, 
    analyzing where in Canada your skills are most needed, 
    and matching your portfolio against all the relevant visa programs in Canada to determine 
    which you should apply for.<br/>You will receive your evaluation results within <b>21 working days,</b> after you have completed your evaluation payment (if you paid for priority processing, you will receive your results within <b>9 business days</b>).
    <br/>Please upload a clear copy of your passport. If you do not have a valid passport; please upload your driver’s license in its place.
    <br/>Please upload an updated CV/resume in English.<br/>Please upload a utility bill (dated within the last 3 months), or other form of proof of address.`,
  },
]
export const touristVisaGuidLine = [
  {
    title: 'Submitting Documents',
    description: 'All documentation must be scanned in a PDF format.',
  },
  {
    title: 'Translating Documents',
    description: `Every document must be written in one of Canada's official languages: English or French.If you don't have your documents in one of these languages, you must have them translated by a translator who is authorized to perform this kind of work by the corresponding GOV body who regulates this profession in your country.`,
  },
  {
    title: 'Digital Photo',
    description: '',
    links: {
      linkTitle: 'Photo Example - View / Download',
      link:
        'https://www.google.com/search?q=canada+immigration+digital+photo+measurements&rlz=1C1WPZC_en__912__912&sxsrf=AOaemvLZxFcPxRi5YkxCGl78A7DaMvPF2g:1639645915178&tbm=isch&source=iu&ictx=1&fir=G2jcJY85PYh0_M%252CuDyGUw4xsRyAQM%252C_&vet=1&usg=AI4_-kQWyiqrsoZCKqX9uw99Vj-v6xRryg&sa=X&ved=2ahUKEwi62dKC_ef0AhVUhv0HHd-CCXsQ9QF6BAgNEAE&biw=1920&bih=937&dpr=1#imgrc=XDMIG2DDN1ICuM',
      linkDescription: '',
    },
    multipleDesc: [
      'Frame size: 35mm X 45mm.',
      'For scanned photos, the minimum resolution must be 600 pixels per inch.',
      'Full frontal view of the face and head, and top of the shoulders.',
      'Size of the head, from chin to crown, must be between 31mm and 36mm.',
      'The physical dimensions in pixels must be at least 420 X 540 (digital dimensions are often expressed in pixels or DPI (Dots Per Inch).',
    ],
  },
  {
    title: 'Police Clearance',
    description: `Your police record should include all criminal offenses from the past <b>10 years</b>, including from countries that you visited for <b>6 months or more.</b>`,
  },
  {
    title: 'Proof of Medical Exam From A Canadian Approved Physician',
    description: `You must book your medical exam with a medical professional from <a class='guideline_desc_link'href='https://secure.cic.gc.ca/pp-md/pp-list.aspx'>Panel Physicians</a>. 
    <i>Please enquire with your physician about what to bring to the exam!</i> 
    If your application is in progress and you can’t complete your IME due to COVID-19, 
    please provide proof that a medical appointment is scheduled, including the name of the panel physician 
    and the date and time of the appointment, 
    or a letter of explanation of your efforts to have the IME done.`,
  },
  {
    title: 'Letter of Motives',
    description: `This letter helps the visa officer to understand you and your goals. 
    It explains why you want to visit Canada and your responsibilities as a student.
    <br/>Your letter must clearly state that you have a solid intention to return to your country of origin upon the expiry of your visa.`,
  },

  {
    title: 'Financial Support Letter (signed by the sponsor)',
    description: `This letter must be written by the sponsoring parent of the applicant. The letter must include clear confirmation that the parent of the applicant will provide financial support during their stay in Canada.`,
    links: {
      linkTitle: 'Example',
      link:
        'https://acrobat.adobe.com/link/review?uri=urn:aaid:scds:US:49971224-2fc0-3eb5-87a2-bdbfd4bf62c5',
      linkDescription: '',
    },
  },
  {
    title: 'Recent Bank Statement',
    description: `Your bank statement must reflect an account balance of CAD$1,500 per person, for each month you visit.`,
  },
]
export const workingHolidayVisaGuidLine = [
  {
    title: 'Submitting Documents',
    description: 'All documentation must be scanned in a PDF format.',
  },
  {
    title: 'Translating Documents',
    description: `Every document must be written in one of Canada's official languages: English or French.If you don't have your documents in one of these languages, you must have them translated by a translator who is authorized to perform this kind of work by the corresponding GOV body who regulates this profession in your country.`,
  },
  {
    title: 'Digital Photo',
    description: '',
    links: {
      linkTitle: 'Photo Example - View / Download',
      link:
        'https://www.google.com/search?q=canada+immigration+digital+photo+measurements&rlz=1C1WPZC_en__912__912&sxsrf=AOaemvLZxFcPxRi5YkxCGl78A7DaMvPF2g:1639645915178&tbm=isch&source=iu&ictx=1&fir=G2jcJY85PYh0_M%252CuDyGUw4xsRyAQM%252C_&vet=1&usg=AI4_-kQWyiqrsoZCKqX9uw99Vj-v6xRryg&sa=X&ved=2ahUKEwi62dKC_ef0AhVUhv0HHd-CCXsQ9QF6BAgNEAE&biw=1920&bih=937&dpr=1#imgrc=XDMIG2DDN1ICuM',
      linkDescription: '',
    },
    multipleDesc: [
      'Frame size: 35mm X 45mm.',
      'For scanned photos, the minimum resolution must be 600 pixels per inch.',
      'Full frontal view of the face and head, and top of the shoulders.',
      'Size of the head, from chin to crown, must be between 31mm and 36mm.',
      'The physical dimensions in pixels must be at least 420 X 540 (digital dimensions are often expressed in pixels or DPI (Dots Per Inch).',
    ],
  },
  {
    title: 'Police Clearance',
    description: `Your police record should include all criminal offenses from the past <b>10 years</b>, including from countries that you visited for <b>6 months or more.</b>`,
  },
  {
    title: 'Proof of Medical Exam From A Canadian Approved Physician',
    description: `You must book your medical exam with a medical professional from <a class='guideline_desc_link'href='https://secure.cic.gc.ca/pp-md/pp-list.aspx'>Panel Physicians</a>. 
    <i>Please enquire with your physician about what to bring to the exam!</i> 
    If your application is in progress and you can’t complete your IME due to COVID-19, 
    please provide proof that a medical appointment is scheduled, including the name of the panel physician 
    and the date and time of the appointment, 
    or a letter of explanation of your efforts to have the IME done.`,
  },
  {
    title: 'Recent Bank Statement',
    description: `Your bank statement must reflect an account balance of at least $2,500-$3,000 CAD.`,
  },
]
