import { useState } from 'react'
import { useContext } from 'react'
import { Form } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import UserContext from '../../context/UserContext'

const ExperienceExtraFields = ({
  onshoreDis,
  experienceInfo,
  register,
  watch,
  errors,
  setValue,
}) => {
  const { userGeoData, countries } = useContext(UserContext)
  const [positionFromDate, setPositionFromDate] = useState(
    experienceInfo?.positionFromDate
      ? new Date(experienceInfo?.positionFromDate)
      : new Date(),
  )
  const [positionToDate, setPositionToDate] = useState(
    experienceInfo?.positionToDate
      ? new Date(experienceInfo?.positionToDate)
      : new Date(),
  )
  const [militaryFromDate, setMilitaryFromDate] = useState(
    experienceInfo?.militaryFromDate
      ? new Date(experienceInfo?.militaryFromDate)
      : new Date(),
  )
  const [militaryToDate, setMilitaryToDate] = useState(
    experienceInfo?.militaryToDate
      ? new Date(experienceInfo?.militaryToDate)
      : new Date(),
  )
  const [unemploymentFromDate, setUnemploymentFromDate] = useState(
    experienceInfo?.unemploymentFromDate
      ? new Date(experienceInfo?.unemploymentFromDate)
      : new Date(),
  )
  const [unemploymentToDate, setUnemploymentToDate] = useState(
    experienceInfo?.unemploymentToDate
      ? new Date(experienceInfo?.unemploymentToDate)
      : new Date(),
  )
  const watchGovernmentalPosition = watch('governMentalPosition')
  const watchMilitaryExperience = watch('militaryExperience')
  const watchUnemploymentPeriod = watch('unemploymentPeriod')
  const changeDatePicker = (date, type) => {
    if (type === 'positionFromDate') {
      setPositionFromDate(date)
      setValue('govPositionInfo.positionFromDate', date)
    }
    if (type === 'positionToDate') {
      setPositionToDate(date)
      setValue('govPositionInfo.positionToDate', date)
    }
    if (type === 'militaryFromDate') {
      setMilitaryFromDate(date)
      setValue('militaryInfo.militaryFromDate', date)
    }
    if (type === 'militaryToDate') {
      setMilitaryToDate(date)
      setValue('militaryInfo.militaryToDate', date)
    }
    if (type === 'unemploymentFromDate') {
      setUnemploymentFromDate(date)
      setValue('unemploymentInfo.unemploymentFromDate', date)
    }
    if (type === 'unemploymentToDate') {
      setUnemploymentToDate(date)
      setValue('unemploymentInfo.unemploymentToDate', date)
    }
  }
  return (
    <>
      <div className="inside-country">
        <Form.Group className="form-group" controlId="formBasicEmail">
          <Form.Label>Do you hold any governmental position ?</Form.Label>
          <Form.Select
            aria-label="Default select example"
            name="governMentalPosition"
            disabled={onshoreDis ? onshoreDis : false}
            defaultValue={
              experienceInfo?.governMentalPosition
                ? experienceInfo?.governMentalPosition
                : ''
            }
            {...register('governMentalPosition')}
          >
            <option value="">
              {experienceInfo?.governMentalPosition
                ? experienceInfo?.governMentalPosition
                : 'Select'}
            </option>
            <option value="No">No</option>
            <option value="Yes">Yes</option>
          </Form.Select>
        </Form.Group>
        {watchGovernmentalPosition === 'Yes' ||
        experienceInfo?.governMentalPosition === 'Yes' ? (
          <>
            <div className="box-grid-two">
              <Form.Group
                className={`form-group ${
                  errors?.govPositionInfo?.positionCountry ? 'error-msg' : ''
                }`}
                controlId="formBasicEmail"
              >
                <Form.Label>Country*</Form.Label>
                <Form.Select
                  disabled={onshoreDis ? onshoreDis : false}
                  name="positionCountry"
                  defaultValue={
                    experienceInfo?.positionCountry
                      ? experienceInfo?.positionCountry
                      : userGeoData?.country_name
                  }
                  {...register('govPositionInfo.positionCountry')}
                >
                  <option value={userGeoData?.country_name}>
                    {experienceInfo?.positionCountry
                      ? experienceInfo?.positionCountry
                      : userGeoData?.country_name}
                  </option>
                  {countries &&
                    countries?.length > 0 &&
                    countries.map((el) => (
                      <option value={el?.name}>{el?.name}</option>
                    ))}
                </Form.Select>
                {errors?.govPositionInfo?.positionCountry ? (
                  <span className="error-msg">
                    {errors?.govPositionInfo?.positionCountry?.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Group>
              <Form.Group
                className={`form-group ${
                  errors?.govPositionInfo?.jurisdictionLevel ? 'error-msg' : ''
                }`}
                controlId="formBasicEmail"
              >
                <Form.Label>Jurisdiction Level*</Form.Label>
                <Form.Control
                  type="text"
                  name="jurisdictionLevel"
                  disabled={onshoreDis ? onshoreDis : false}
                  defaultValue={
                    experienceInfo?.jurisdictionLevel
                      ? experienceInfo?.jurisdictionLevel
                      : ''
                  }
                  {...register('govPositionInfo.jurisdictionLevel')}
                />
                {errors?.govPositionInfo?.jurisdictionLevel ? (
                  <span className="error-msg">
                    {errors?.govPositionInfo?.jurisdictionLevel?.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Group>
            </div>
            <div className="box-grid-two">
              <Form.Group
                className={`form-group ${
                  errors?.govPositionInfo?.departBranch ? 'error-msg' : ''
                }`}
                controlId="formBasicEmail"
              >
                <Form.Label>Department/Branch*</Form.Label>
                <Form.Control
                  type="text"
                  name="departBranch"
                  disabled={onshoreDis ? onshoreDis : false}
                  defaultValue={
                    experienceInfo?.departBranch
                      ? experienceInfo?.departBranch
                      : ''
                  }
                  {...register('govPositionInfo.departBranch')}
                />
                {errors?.govPositionInfo?.departBranch ? (
                  <span className="error-msg">
                    {errors?.govPositionInfo?.departBranch?.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Group>
              <Form.Group
                className={`form-group ${
                  errors?.govPositionInfo?.activitiesRole ? 'error-msg' : ''
                }`}
                controlId="formBasicEmail"
              >
                <Form.Label>Activities/Roles*</Form.Label>
                <Form.Control
                  type="text"
                  name="activitiesRole"
                  disabled={onshoreDis ? onshoreDis : false}
                  defaultValue={
                    experienceInfo?.activitiesRole
                      ? experienceInfo?.activitiesRole
                      : ''
                  }
                  {...register('govPositionInfo.activitiesRole')}
                />
                {errors?.govPositionInfo?.activitiesRole ? (
                  <span className="error-msg">
                    {errors?.govPositionInfo?.activitiesRole?.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Group>
            </div>
            <div className="box-grid-two">
              <Form.Group
                className={`form-group ${
                  errors?.govPositionInfo?.positionFromDate ? 'error-msg' : ''
                }`}
                controlId="formBasicEmail"
              >
                <Form.Label>From date*</Form.Label>
                <DatePicker
                  type="positionFromDate"
                  name="positionFromDate"
                  placeholderText="Select Date"
                  disabled={onshoreDis ? onshoreDis : false}
                  selected={positionFromDate}
                  onChange={(date) =>
                    changeDatePicker(date, 'positionFromDate', 'age')
                  }
                  className="form-select"
                  dateFormat="dd-MM-yyyy"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
                {errors?.govPositionInfo?.positionFromDate ? (
                  <span className="error-msg">
                    {errors?.govPositionInfo?.positionFromDate?.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Group>
              <Form.Group
                className={`form-group ${
                  errors?.govPositionInfo?.positionToDate ? 'error-msg' : ''
                }`}
                controlId="formBasicEmail"
              >
                <Form.Label>To date*</Form.Label>
                <DatePicker
                  type="positionToDate"
                  name="positionToDate"
                  placeholderText="Select Date"
                  disabled={onshoreDis ? onshoreDis : false}
                  selected={positionToDate}
                  onChange={(date) =>
                    changeDatePicker(date, 'positionToDate', 'age')
                  }
                  className="form-select"
                  dateFormat="dd-MM-yyyy"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
                {errors?.govPositionInfo?.positionToDate ? (
                  <span className="error-msg">
                    {errors?.govPositionInfo?.positionToDate?.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Group>
            </div>
          </>
        ) : (
          ''
        )}
        <Form.Group className="form-group" controlId="formBasicEmail">
          <Form.Label>Do you have any military experience ?</Form.Label>
          <Form.Select
            aria-label="Default select example"
            name="militaryExperience"
            disabled={onshoreDis ? onshoreDis : false}
            defaultValue={
              experienceInfo?.militaryExperience
                ? experienceInfo?.militaryExperience
                : ''
            }
            {...register('militaryExperience')}
          >
            <option value="">
              {experienceInfo?.militaryExperience
                ? experienceInfo?.militaryExperience
                : 'Select'}
            </option>
            <option value="No">No</option>
            <option value="Yes">Yes</option>
          </Form.Select>
        </Form.Group>
        {watchMilitaryExperience === 'Yes' ||
        experienceInfo?.militaryExperience === 'Yes' ? (
          <>
            <div className="box-grid-three">
              <Form.Group
                className={`form-group ${
                  errors?.militaryInfo?.militaryCountry ? 'error-msg' : ''
                }`}
                controlId="formBasicEmail"
              >
                <Form.Label>Country*</Form.Label>
                <Form.Select
                  disabled={onshoreDis ? onshoreDis : false}
                  name="militaryCountry"
                  defaultValue={
                    experienceInfo?.militaryCountry
                      ? experienceInfo?.militaryCountry
                      : userGeoData?.country_name
                  }
                  {...register('militaryInfo.militaryCountry')}
                >
                  <option value={userGeoData?.country_name}>
                    {experienceInfo?.militaryCountry
                      ? experienceInfo?.militaryCountry
                      : userGeoData?.country_name}
                  </option>
                  {countries &&
                    countries?.length > 0 &&
                    countries.map((el) => (
                      <option value={el?.name}>{el?.name}</option>
                    ))}
                </Form.Select>
                {errors?.militaryInfo?.militaryCountry ? (
                  <span className="error-msg">
                    {errors?.militaryInfo?.militaryCountry?.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Group>
              <Form.Group
                className={`form-group ${
                  errors?.militaryInfo?.militaryRank ? 'error-msg' : ''
                }`}
                controlId="formBasicEmail"
              >
                <Form.Label>Rank*</Form.Label>
                <Form.Control
                  type="text"
                  name="militaryRank"
                  disabled={onshoreDis ? onshoreDis : false}
                  defaultValue={
                    experienceInfo?.militaryRank
                      ? experienceInfo?.militaryRank
                      : ''
                  }
                  {...register('militaryInfo.militaryRank')}
                />
                {errors?.militaryInfo?.militaryRank ? (
                  <span className="error-msg">
                    {errors?.militaryInfo?.militaryRank?.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Group>
              <Form.Group
                className={`form-group ${
                  errors?.militaryInfo?.branchUnit ? 'error-msg' : ''
                }`}
                controlId="formBasicEmail"
              >
                <Form.Label>Branch/Unit number*</Form.Label>
                <Form.Control
                  type="text"
                  name="branchUnit"
                  disabled={onshoreDis ? onshoreDis : false}
                  defaultValue={
                    experienceInfo?.branchUnit ? experienceInfo?.branchUnit : ''
                  }
                  {...register('militaryInfo.branchUnit')}
                />
                {errors?.militaryInfo?.branchUnit ? (
                  <span className="error-msg">
                    {errors?.militaryInfo?.branchUnit?.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Group>
            </div>
            <div className="box-grid-two">
              <Form.Group
                className={`form-group ${
                  errors?.militaryInfo?.commandingOfficer ? 'error-msg' : ''
                }`}
                controlId="formBasicEmail"
              >
                <Form.Label>Name(s) of Your Commanding Officers*</Form.Label>
                <Form.Control
                  type="text"
                  name="commandingOfficer"
                  disabled={onshoreDis ? onshoreDis : false}
                  defaultValue={
                    experienceInfo?.commandingOfficer
                      ? experienceInfo?.commandingOfficer
                      : ''
                  }
                  {...register('militaryInfo.commandingOfficer')}
                />
                {errors?.militaryInfo?.commandingOfficer ? (
                  <span className="error-msg">
                    {errors?.militaryInfo?.commandingOfficer?.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Group>
              <Form.Group
                className={`form-group ${
                  errors?.militaryInfo?.activeCombat ? 'error-msg' : ''
                }`}
                controlId="formBasicEmail"
              >
                <Form.Label>Places of Any Active Combat*</Form.Label>
                <Form.Control
                  type="text"
                  name="activeCombat"
                  disabled={onshoreDis ? onshoreDis : false}
                  defaultValue={
                    experienceInfo?.activeCombat
                      ? experienceInfo?.activeCombat
                      : ''
                  }
                  {...register('militaryInfo.activeCombat')}
                />
                {errors?.militaryInfo?.activeCombat ? (
                  <span className="error-msg">
                    {errors?.militaryInfo?.activeCombat?.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Group>
            </div>
            <div className="box-grid-two">
              <Form.Group
                className={`form-group ${
                  errors?.militaryInfo?.militaryFromDate ? 'error-msg' : ''
                }`}
                controlId="formBasicEmail"
              >
                <Form.Label>From date*</Form.Label>
                <DatePicker
                  type="militaryFromDate"
                  name="militaryFromDate"
                  placeholderText="Select Date"
                  disabled={onshoreDis ? onshoreDis : false}
                  selected={militaryFromDate}
                  onChange={(date) =>
                    changeDatePicker(date, 'militaryFromDate')
                  }
                  className="form-select"
                  dateFormat="dd-MM-yyyy"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
                {errors?.militaryInfo?.militaryFromDate ? (
                  <span className="error-msg">
                    {errors?.militaryInfo?.militaryFromDate?.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Group>
              <Form.Group
                className={`form-group ${
                  errors?.militaryInfo?.militaryToDate ? 'error-msg' : ''
                }`}
                controlId="formBasicEmail"
              >
                <Form.Label>To date*</Form.Label>
                <DatePicker
                  type="militaryToDate"
                  name="militaryToDate"
                  placeholderText="Select Date"
                  disabled={onshoreDis ? onshoreDis : false}
                  selected={militaryToDate}
                  onChange={(date) => changeDatePicker(date, 'militaryToDate')}
                  className="form-select"
                  dateFormat="dd-MM-yyyy"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
                {errors?.militaryInfo?.militaryToDate ? (
                  <span className="error-msg">
                    {errors?.militaryInfo?.militaryToDate?.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Group>
            </div>
          </>
        ) : (
          ''
        )}
        <Form.Group className="form-group" controlId="formBasicEmail">
          <Form.Label>
            Your unemployment period in the past 10 years (or since you were 18
            years old if you are currently 28 years old).
          </Form.Label>
          <Form.Select
            aria-label="Default select example"
            name="unemploymentPeriod"
            disabled={onshoreDis ? onshoreDis : false}
            defaultValue={
              experienceInfo?.unemploymentPeriod
                ? experienceInfo?.unemploymentPeriod
                : ''
            }
            {...register('unemploymentPeriod')}
          >
            <option value="">
              {experienceInfo?.unemploymentPeriod
                ? experienceInfo?.unemploymentPeriod
                : 'Select'}
            </option>
            <option value="No">No</option>
            <option value="Yes">Yes</option>
          </Form.Select>
        </Form.Group>
        {watchUnemploymentPeriod === 'Yes' ||
        experienceInfo?.unemploymentPeriod === 'Yes' ? (
          <>
            <div className="box-grid-three">
              <Form.Group
                className={`form-group ${
                  errors?.unemploymentInfo?.unemploymentCountry
                    ? 'error-msg'
                    : ''
                }`}
                controlId="formBasicEmail"
              >
                <Form.Label>Country*</Form.Label>
                <Form.Select
                  disabled={onshoreDis ? onshoreDis : false}
                  name="unemploymentCountry"
                  defaultValue={
                    experienceInfo?.unemploymentCountry
                      ? experienceInfo?.unemploymentCountry
                      : userGeoData?.country_name
                  }
                  {...register('unemploymentInfo.unemploymentCountry')}
                >
                  <option value={userGeoData?.country_name}>
                    {experienceInfo?.unemploymentCountry
                      ? experienceInfo?.unemploymentCountry
                      : userGeoData?.country_name}
                  </option>
                  {countries &&
                    countries?.length > 0 &&
                    countries.map((el) => (
                      <option value={el?.name}>{el?.name}</option>
                    ))}
                </Form.Select>
                {errors?.unemploymentInfo?.unemploymentCountry ? (
                  <span className="error-msg">
                    {errors?.unemploymentInfo?.unemploymentCountry?.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Group>
              <Form.Group
                className={`form-group ${
                  errors?.unemploymentInfo?.unemploymentCity ? 'error-msg' : ''
                }`}
                controlId="formBasicEmail"
              >
                <Form.Label>City*</Form.Label>
                <Form.Control
                  type="text"
                  name="unemploymentCity"
                  disabled={onshoreDis ? onshoreDis : false}
                  defaultValue={
                    experienceInfo?.unemploymentCity
                      ? experienceInfo?.unemploymentCity
                      : ''
                  }
                  {...register('unemploymentInfo.unemploymentCity')}
                />
                {errors?.unemploymentInfo?.unemploymentCity ? (
                  <span className="error-msg">
                    {errors?.unemploymentInfo?.unemploymentCity?.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Group>
              <Form.Group
                className={`form-group ${
                  errors?.unemploymentInfo?.unemploymentStatus
                    ? 'error-msg'
                    : ''
                }`}
                controlId="formBasicEmail"
              >
                <Form.Label>Status in that country*</Form.Label>
                <Form.Control
                  type="text"
                  name="unemploymentStatus"
                  disabled={onshoreDis ? onshoreDis : false}
                  defaultValue={
                    experienceInfo?.unemploymentStatus
                      ? experienceInfo?.unemploymentStatus
                      : ''
                  }
                  {...register('unemploymentInfo.unemploymentStatus')}
                />
                {errors?.unemploymentInfo?.unemploymentStatus ? (
                  <span className="error-msg">
                    {errors?.unemploymentInfo?.unemploymentStatus?.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Group>
            </div>
            <div className="box-grid-two">
              <Form.Group
                className={`form-group ${
                  errors?.unemploymentInfo?.unemploymentFromDate
                    ? 'error-msg'
                    : ''
                }`}
                controlId="formBasicEmail"
              >
                <Form.Label>From date*</Form.Label>
                <DatePicker
                  type="unemploymentFromDate"
                  name="unemploymentFromDate"
                  placeholderText="Select Date"
                  disabled={onshoreDis ? onshoreDis : false}
                  selected={unemploymentFromDate}
                  onChange={(date) =>
                    changeDatePicker(date, 'unemploymentFromDate')
                  }
                  className="form-select"
                  dateFormat="dd-MM-yyyy"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
                {errors?.unemploymentInfo?.unemploymentFromDate ? (
                  <span className="error-msg">
                    {errors?.unemploymentInfo?.unemploymentFromDate?.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Group>
              <Form.Group
                className={`form-group ${
                  errors?.unemploymentInfo?.unemploymentToDate
                    ? 'error-msg'
                    : ''
                }`}
                controlId="formBasicEmail"
              >
                <Form.Label>To date*</Form.Label>
                <DatePicker
                  type="unemploymentToDate"
                  name="unemploymentToDate"
                  placeholderText="Select Date"
                  disabled={onshoreDis ? onshoreDis : false}
                  selected={unemploymentToDate}
                  onChange={(date) =>
                    changeDatePicker(date, 'unemploymentToDate')
                  }
                  className="form-select"
                  dateFormat="dd-MM-yyyy"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
                {errors?.unemploymentInfo?.unemploymentToDate ? (
                  <span className="error-msg">
                    {errors?.unemploymentInfo?.unemploymentToDate?.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Group>
            </div>
          </>
        ) : (
          ''
        )}
      </div>
    </>
  )
}
export default ExperienceExtraFields
