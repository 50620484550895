import React from "react";
import Layout from "../layout/layout";
import VisaContact from "./visacontact";
import { useTranslation } from "react-i18next";
import workericon from "../../assets/images/visa/worker-icon.svg";
import worker from "../../assets/images/visa/worker.png";
import "./visa.css";

const WorkerVisa = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <div className='worker-visa-page'>
        <div className='worker-visa-banner'>
          <div className='container'>
            <div className='worker-visa-banner-heading'>
              <img src={workericon} alt='' />
              <div className='worker-visa-banner-heading-title'>
                <h5>{t("workerVisa.workerHeading1")}</h5>
                <h2>{t("workerVisa.workerHeading2")}</h2>
              </div>
            </div>

            <p>{t("workerVisa.workerParagraph1")}</p>

            <div className='worker-grid'>
              <div className='worker-content'>
                <p>{t("workerGrid.workerParagraph1")}</p>

                <p>{t("workerGrid.workerParagraph2")}</p>

                <p>
                  <b>{t("workerGrid.workerParagraph3")}</b>
                </p>
              </div>

              <div className='worker-cover'>
                <img src={worker} alt='' />
              </div>
            </div>
          </div>
        </div>

        <VisaContact />
      </div>
    </Layout>
  );
};

export default WorkerVisa;
