import { Button } from "react-bootstrap";
import icon1 from "../../assets/images/header/canada.svg";
import facebook from "../../assets/images/header/fb.svg";
import linkedin from "../../assets/images/header/linkedin.svg";
import twitter from "../../assets/images/header/twitter.svg";
const LandingThankYou = () => {
  window.dataLayer.push({
    event: "page_view",
    page: {
      url: window.location.href,
      title: window.location.href.split("/").slice(-1).pop(),
    },
  });
  return (
    <div className='landing-thank-you'>
      <div className='thank-you-grid'>
        <div className='thank-you-content'>
          <img src={icon1} alt='' />
          <h2>Thank You!</h2>
          {/* <p>
            A company representative will call you shortly to discuss your
            immigration goals.
          </p> */}
          <p>
            One of our representatives will call you to discuss your Work &
            Travel Bundle shortly.
          </p>
        </div>

        <div className='thankyou-cta'>
          <p>Want to explore our working holiday packages on your own?</p>
          <Button
            className='orange-button'
            onClick={(e) => {
              e.preventDefault();
              window.location.href =
                "https://develop.uiscanada.com/WorldTravel";
            }}
          >
            {" "}
            Discover Our Selection
          </Button>
        </div>

        <div className='thank-you-social'>
          <p>Let’s Connect!</p>
          <ul>
            <li>
              <a
                target='_blank'
                rel='noreferrer'
                href='https://www.facebook.com/uis.canada'
              >
                <img src={facebook} alt='' />
              </a>
            </li>
            <li>
              <a
                target='_blank'
                rel='noreferrer'
                href='https://www.linkedin.com/company/uis-canada-official/'
              >
                <img src={linkedin} alt='' />
              </a>
            </li>
            <li>
              <a
                target='_blank'
                rel='noreferrer'
                href='https://twitter.com/uis_canada'
              >
                <img src={twitter} alt='' />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default LandingThankYou;
