import React from "react";
import "./style.css";
import mokeData from "./mokeData";
import   {useParams} from "react-router-dom";
const Header = React.lazy(() => import('./header'));
const TopBanner = React.lazy(() => import('./topBanner'));
const TellMore = React.lazy(() => import('./tellMore'));
const WorkingHoliday = React.lazy(() => import('./workingHoliday'));
const LandingForm = React.lazy(() => import('./form'));
const Gallery = React.lazy(() => import('./gallery'));
const Testimonial = React.lazy(() => import('./testimonial'));
const About = React.lazy(() => import('./about'));
const BottomBanner = React.lazy(() => import('./bottomBanner'));
const Footer = React.lazy(() => import('./footer'));
const TopBannerV3 = React.lazy(() => import('../landing-new/topBanner_v3'));
const AboutV3 = React.lazy(() => import('../landing-new/about_v3'));
const TestimonialV3 = React.lazy(() => import('../landing-new/testimonial_v3'));
const ServiceV3 = React.lazy(() => import('../landing-new/services_v3'));
const WorkingHolidayV3 = React.lazy(() => import('./workingHoliday_v3'));
const Landing = () => {
  // const {search} = useLocation();
  // const lpPageV=new URLSearchParams(search).get("lp");
  let { lp } = useParams();
  const sectionsType = {
    'topHeader':Header,
    'topBanner': TopBanner,
    "topBannerV2":TopBannerV3,
    "aboutV2":AboutV3,
    'tellMore': TellMore,
    "workingHoliday":WorkingHoliday,
    "workingHolidayV2":WorkingHolidayV3,
    "form":LandingForm,
    "gallery":Gallery,
    "testimonial":Testimonial,
    "testimonialV2":TestimonialV3,
    "serviceV2":ServiceV3,
    "about":About,
    "bottomBanner":BottomBanner,
    "footer":Footer
  };
const sections = mokeData.find(page => page.page === lp);
  return (
    <div className={`${(lp==='lv1' || lp==='lv2')?"landing-page-v2":"landing-page"}`}>
      {sections['sections'].map(section =>{
          let Component = sectionsType[section['type']];
          return (section?.isShow?<Component content={section.content} />:"")
        })}
    </div>
  );
};

export default Landing;
