/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Button, Spinner } from 'react-bootstrap'
//import PhoneInput from 'react-phone-input-2'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import { toast } from 'react-toastify'
import { serverPath } from '../../config/key'
import React, { useState } from 'react'
import { refundInvoiceSchema } from '../../utils/validationSchema'
// import showicon from '../../assets/images/login/show-icon.svg'
// import hideicon from '../../assets/images/login/hide-icon.svg'
const RefundInvoicePopup = ({refundDetail,setShow,getAllInvoices}) => {
  const [isLoading, setIsLoading] = useState(false)
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(refundInvoiceSchema),
  })
  const updateUserDetail = async (values) => {
  
    const payload = {
      companyId: refundDetail?.companyId,
      clientEmail: refundDetail?.clientEmail,
      clientName: refundDetail?.clientName,
      clientAddress: refundDetail?.clientAddress,
      clientCity: refundDetail?.clientCity,
      clientCountry: refundDetail?.clientCountry ,
      clientPhone: refundDetail?.clientPhone,
      paymentMethod: values?.paymentMethod,
      amount:values?.amount,
      invoiceNumber: refundDetail?.invoiceNumber,
      currency:refundDetail?.transaction?.currency,
      userId:refundDetail?.userId,
      brandId:refundDetail?.brandId,
      invoiceId:refundDetail?._id,
      transactionId:refundDetail?.transaction?._id,
      isInstallment:values?.isInstallment ==='true'?true:false,
      sfPaymentId:refundDetail?.transaction?.sfPaymentId?refundDetail?.transaction?.sfPaymentId:null,
      paymentType:values?.paymentType
    }
    try {
      setIsLoading(true)
      const response = await axios.post(
        `${serverPath}/invoice/createInvoice`,
        payload,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      setIsLoading(false)
        const { status } = response
        if (status === 200) {
            getAllInvoices()
            setShow(false)
            toast.success('Invoice generated successfully', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          })
        }
    } catch (error) {
        setIsLoading(false)
      if (error?.response?.data) {
        toast.error(error.response.data.msg||"Something went wrong", {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
      }
    }
  }

  return (
    <div className="payment-user-detail login-form">
        {/* <div className="login-form"> */}
          <div className="login-popup-content">
            <h4>Refund Invoice</h4>
            <Form onSubmit={handleSubmit(updateUserDetail)}>
                <Form.Group className="form-group">
                  <Form.Select
                    aria-label="Choose Invoice Type"
                    name="paymentMethod"
                    {...register("paymentMethod")}
                  >
                    <option value="">Choose Invoice Type</option>
                    <option value="Full Refund">Refund</option>
                    <option value="Partial Refund">Partial Refund</option>
                    <option value="Chargeback">Chargeback</option>
                  </Form.Select>
                  {errors?.paymentMethod && (
                    <span className="error-msg">{errors.paymentMethod.message}</span>
                  )}
                </Form.Group>
                  <Form.Group className="form-group">
                      {/* <Form.Label>Amount</Form.Label> */}
                      <Form.Control
                        type="text"
                        placeholder="Amount"
                        name="amount"
                        {...register("amount")}
                      />
                      {errors?.amount && (
                        <span className="error-msg">{errors.amount.message}</span>
                      )}
                    </Form.Group>
                   
                <Form.Group className="form-group">
                  {/* <Form.Label></Form.Label> */}
                  <Form.Select
                    name="paymentType"
                    {...register("paymentType")}
                  >
                    <option value="">Payment Method</option>
                    <option value='Wire'>Wire</option>
                    <option value='Credit Card'>Credit Card</option>
                  </Form.Select>
                  {errors?.paymentType && (
                    <span className="error-msg">{errors.paymentType.message}</span>
                  )}
                </Form.Group>
                <Form.Group className="form-group">
                  {/* <Form.Label></Form.Label> */}
                  <Form.Select
                    name="isInstallment"
                    {...register("isInstallment")}
                  >
                    <option value="">Pay In Installment</option>
                    <option value='true'>Yes</option>
                    <option value='false'>No</option>
                  </Form.Select>
                  {errors?.isInstallment && (
                    <span className="error-msg">{errors.isInstallment.message}</span>
                  )}
                </Form.Group>
              <Button type="submit" disabled={isLoading}>
                {isLoading ? <Spinner animation="border" /> : 'Refund'}
              </Button>
            </Form>
          </div>
        {/* </div> */}
    </div>
  )
}
export default RefundInvoicePopup
