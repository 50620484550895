/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, useEffect } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import PersonalInfo from './personalInfo'
import '../visaForm/index.css'
import EducationInfo from './educationInfo'
import WorkExperience from './workExperience'
import LanguagesSection from './languageSection'
import Documents from './document'
import UserContext from '../../context/UserContext'
import { serverPath } from '../../config/key'
import axios from 'axios'
import lock from '../../assets/images/visa/lock.svg'
import Loader from '../loader/loader'
import AknowledgePopup from '../visaForm/acknowledgePopup'
import { toast } from 'react-toastify'

const SupportEvaluationVisaForm = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const token = urlParams.get('t')
  const { selectedVisa, setSavePercentage,setSelectedVisa } = useContext(UserContext)
  const [personalInformation, setPersonalInfo] = useState()
  const [educationInfo, setEducationInfo] = useState()
  const [experienceInfo, setExperienceInfo] = useState()
  const [LanguageInfo, setLanguageInfo] = useState()
  const [documentInfo, setDocumentInfo] = useState()
  const [saveCount, setSaveCount] = useState(0)
  const [visaFormData, setVisaFormData] = useState()
  const [activeTabKey, setActiveTabKey] = useState('personalInfo')
  const [loading, setLoading] = useState(false)
  const [activeEditable, setActiveEditable] = useState('')
  const [aknowledgeState, setAcknowledgeState] = useState(false)
  const [texts, setTexts] = useState('')
  const checkProVisaType = async () => {
    setPersonalInfo()
    setExperienceInfo()
    setVisaFormData()
    setSaveCount(0)
    setSavePercentage(0)
    setLoading(true)
    const res = await axios.get(`${serverPath}/supportEvaluation/evaluation`, {
      headers: { authorization: `Bearer ${token}` },
    })
    setLoading(false)
    let data = Object.keys(res?.data?.visaData)?.length >0 ? res?.data?.visaData :{}
    if(Object.keys(res?.data?.visaData)?.length >0){
      setVisaFormData(data)     
    }
    const {productDetail}=res?.data?.visaData
    setSelectedVisa({
      ...selectedVisa,
      visaName: productDetail?.name
        ? productDetail?.name
        : '',
    })
  }
  useEffect(() => {
      checkProVisaType()
  }, [token])
  useEffect(() => {
    if(visaFormData){
      let count = []
      if (visaFormData?.education?.isLocked) {
        count.push(2)
      }
      let education = visaFormData.education&&Object.keys(visaFormData?.education)?.length>0 ? visaFormData?.education: {}
      setEducationInfo(education)
      if (visaFormData?.personal?.isLocked) {
        count.push(1)
      }
      let personal = visaFormData.personal&&Object.keys(visaFormData?.personal)?.length>0 ? visaFormData?.personal : {}
      setPersonalInfo(personal)
      if (visaFormData?.Experience?.isLocked) {
        count.push(3)
      }
      let experience=visaFormData?.Experience&&Object.keys(visaFormData?.Experience)?.length>0?visaFormData?.Experience:{}
      setExperienceInfo(experience)
      if (visaFormData?.language?.isLocked) {
        count.push(4)
      }
      let language=visaFormData?.language&&Object.keys(visaFormData?.language)?.length>0?visaFormData?.language:{}
      setLanguageInfo(language)
      if (visaFormData?.document?.isLocked) {
        count.push(5)
      }
      setDocumentInfo(visaFormData?.document)
      setSaveCount(count?.length)
      setActiveTabKey(activeTabKey)
    }
  }, [visaFormData])
  useEffect(() => {
    if (saveCount !== 0) {
      const per = (Number(saveCount) / 5) * 100
      setSavePercentage(per)
    }
  }, [
    selectedVisa,
    personalInformation,
    educationInfo,
    experienceInfo,
    LanguageInfo,
    documentInfo,
    saveCount,
  ])
  const handleFormDisable = (tab) => {
    if (tab !== activeEditable) {
      //setActiveEditable(tab)
      setAcknowledgeState(true)
      setTexts('Are you sure you want to unlock for client')
    } else {
      setActiveEditable('')
    }
  }
  const handleTabChange = (e) => {
    if (e !== activeTabKey && !activeEditable) {
      setActiveTabKey(e)
    } else if (activeEditable && e !== activeEditable) {
      setAcknowledgeState(true)
      setTexts('Please save the changes of')
    }
  }
  const handleUnlockFormForClient = async (tab) => {
    try {
      const res = await axios.post(
        `${serverPath}/supportEvaluation/unlockFormForClient`,
        {
          tab: tab,
        },
        {
          headers: {
            authorization: `Bearer ${
              token ? token : localStorage.getItem('token')
            }`,
          },
        },
      )
      const { code } = res.data
      if (code === 200) {
        toast.success('Client Form unlock', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
      }
    } catch (err) {
      toast.error(err.data.message, { toastId: 'Eror' })
    }
  }
  return (
    <div className="support-visa-form">
      {aknowledgeState && (
        <AknowledgePopup
          setAcknowledgeState={setAcknowledgeState}
          activeTabKey={activeTabKey}
          setActiveEditable={setActiveEditable}
          editableTab={activeTabKey}
          texts={texts}
          handleUnlockFormForClient={handleUnlockFormForClient}
        />
      )}
      <div className="visa-form-page">
        <div className="container">
          {loading ? (
            <Loader />
          ) : (
            <div className="home-visa-tab">
              <Tabs
                className=""
                defaultActiveKey={'personalInfo'}
                activeKey={activeTabKey}
                onSelect={handleTabChange}
              >
                <Tab
                  eventKey="personalInfo"
                  title={
                    <span>
                      {'Personal Info'}
                      {personalInformation?.isLocked && (
                        <img
                          src={lock}
                          alt="lock"
                          onClick={() => handleFormDisable('personalInfo')}
                          role="presentation"
                        />
                      )}
                    </span>
                  }
                >
{personalInformation?<PersonalInfo
                    personalInformation={personalInformation}
                    setPersonalInfo={setPersonalInfo}
                    adminFormDisable={activeEditable !== 'personalInfo'}
                   
                  />:null}               
                 
                </Tab>
                <Tab
                  eventKey="Education"
                  title={
                    <span>
                      {'Education'}
                      {educationInfo && educationInfo?.isLocked && (
                        <img
                          src={lock}
                          alt="lock"
                          onClick={() => handleFormDisable('Education')}
                          role="presentation"
                        />
                      )}
                    </span>
                  }
                >
                  {educationInfo ? <EducationInfo
                    educationInfo={educationInfo}
                    setEducationInfo={setEducationInfo}
                    adminFormDisable={activeEditable !== 'Education'}
                  />:null}
                </Tab>
                <Tab
                  eventKey="Work Experience"
                  title={
                    <span>
                      {'Work Experience'}
                      {experienceInfo && experienceInfo?.isLocked && (
                        <img
                          src={lock}
                          alt="lock"
                          onClick={() => handleFormDisable('Work Experience')}
                          role="presentation"
                        />
                      )}
                    </span>
                  }
                >
                 {experienceInfo? <WorkExperience
                    experienceInfo={experienceInfo}
                    setExperienceInfo={setExperienceInfo}
                    adminFormDisable={activeEditable !== 'Work Experience'}
                  />:null}
                </Tab>
                <Tab
                  eventKey="Languages"
                  title={
                    <span>
                      {'Language'}
                      {LanguageInfo && LanguageInfo?.isLocked && (
                        <img
                          src={lock}
                          alt="lock"
                          onClick={() => handleFormDisable('Languages')}
                          role="presentation"
                        />
                      )}
                    </span>
                  }
                >
                 {LanguageInfo? <LanguagesSection
                    LanguageInfo={LanguageInfo}
                    setLanguageInfo={setLanguageInfo}
                    adminFormDisable={activeEditable !== 'Languages'}
                  />
                 :null}
                </Tab>

                <Tab
                  eventKey="Documents"
                  title={
                    <span>
                      {'Documents'}
                      {documentInfo && documentInfo?.isLocked && (
                        <img
                          src={lock}
                          alt="lock"
                          onClick={() => handleFormDisable('Documents')}
                          role="presentation"
                        />
                      )}
                    </span>
                  }
                >
                  <Documents
                    documentInfo={documentInfo}
                    setDocumentInfo={setDocumentInfo}
                    showButton={true}
                    adminFormDisable={activeEditable !== 'Documents'}
                  />
                </Tab>
              </Tabs>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default SupportEvaluationVisaForm
