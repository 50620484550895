export const spouseTouristVisaLetterClearance = [
  {
    name: 'spouseVisaResident',
    label: 'Copy of Visa or Resident Visa ',
    secondLabel: '(if you are a resident of another country)',
  },
]
export const spousePnpExpressEntryVisaLetterClearanceMain = [
  {
    name: 'spousePoliceClearance',
    label: 'Police Clearance (see Guidelines)',
    secondLabel: '(if applicable)',
  },
  {
    name: 'spouseCanadianMedicalExamProof',
    label:
      'Proof of Medical Exam From A Canadian Approved Physician (see Guidelines)',
    secondLabel: '',
  },
  {
    name: 'spouseLangTestResult',
    label: 'Language Test Results',
    secondLabel: '',
  },
  {
    name: 'spouseEducationCredentialAssessment',
    label: 'Educational Credential Assessment (ECA)(WES)',
    secondLabel: '(if applicable)',
  },

  {
    name: 'spouseProvincialNomination',
    label: 'Provincial Nomination (if any)',
    secondLabel: '',
  },
  {
    name: 'spouseWrittenJobOffer',
    label: 'Written Job Offer (if any)',
    secondLabel: '',
  },
  {
    name: 'spouseVisaResident',
    label: 'Copy of Visa or Resident Visa ',
    secondLabel: '(if you are a resident of another country)',
  },
  {
    name: 'spouseQualificationCertificate',
    label: 'Certificate of Qualification',
    secondLabel: '',
  },
]
