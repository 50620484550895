/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef, useContext, useEffect } from 'react'
import PhoneInput from 'react-phone-input-2'
import {
  Accordion,
  Button,
  Card,
  Form,
  useAccordionButton,
} from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import 'react-datepicker/dist/react-datepicker.css'
import {
  PersonalSponsoredInfoSchema,
  PersonalMainApplicantInfoSchema,
} from '../../utils/validationSchema'
import UserContext from '../../context/UserContext'
import { serverPath } from '../../config/key'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import approved from '../../assets/images/header/approved.svg'
// import { findFlagUrlByNationality, countries } from 'country-flags-svg'
import addIcon from '../../assets/images/visa/add-icon.svg'
import activeArrow from '../../assets/images/visa/active-arrow.svg'
import closeArrow from '../../assets/images/visa/close-arrow.svg'
import ErrorModal from '../modals/errorModal'
import MainAccordion from '../visaAccordion/mainAccordion'

const PersonalInfo = ({
  personalInformation,
  setPersonalInfo,
  updFormType,
  adminFormDisable,
}) => {
  const { formType: type } = useParams()
  const urlParams = new URLSearchParams(window.location.search)
  const { selectedVisa, countries, setSelectedVisa, userGeoData } = useContext(
    UserContext,
  )
  const token = urlParams.get('t')
  let intialState = {
    childFirstName: '',
    childMiddleName: '',
    childLastName: '',
    childGender: '',
    childBirthCity: '',
    childBirthCountry: userGeoData?.country_name,
    childCitizenship: userGeoData?.country_name,
    childSecondCitizenship: '',
    childDateOfBirth: '',
    childMaritalStatus: '',
    childOccupation: '',
    childResidentialAddress: '',
    childEmailAddress: '',
  }
  let intialParentState = {
    parentFullName: '',
    parentDateOfBirth: '',
    parentBirthCountry: userGeoData?.country_name,
    parentBirthCity: '',
    parentOccupation: '',
    parentResidentialAddress: '',
    parentMaritalStatus: '',
    parentEmailAddress: '',
    parentDeceased: '',
    parentRelation: '',
    parentGender: '',
  }
  let intialSiblingState = {
    siblingFullName: '',
    siblingDateOfBirth: '',
    siblingBirthCountry: userGeoData?.country_name,
    siblingBirthCity: '',
    siblingOccupation: '',
    siblingResidentialAddress: '',
    siblingMaritalStatus: '',
    siblingEmailAddress: '',
    siblingDeceased: '',
    siblingRelation: '',
    siblingGender: '',
  }
  let intialAddressState = {
    addrCountry: userGeoData?.country_name,
    addrStateDist: '',
    addrCityTown: '',
    addrFullStreet: '',
    addrZipCode: '',
    addrFromDate: '',
    addrToDate: '',
  }
  let intialOrganizationState = {
    organizationName: '',
    organizationType: '',
    organizationRole: '',
    organizationCountry: userGeoData?.country_name,
    organizationCity: '',
    organizationFromDate: '',
    organizationToDate: '',
  }
  let intialTripState = {
    tripsCountry: userGeoData?.country_name,
    tripsCity: '',
    tripsCountryStatus: '',
    tripsReason: '',
    tripsFromDate: '',
    tripsToDate: '',
  }
  const [sponsoredShow, setSponsoredShow] = useState(false)
  const [sponsoredSpouseShow, setSponsoredSpouseShow] = useState(true)
  const [textMainAccordion, setTextMainAccordion] = useState('')
  const [textSponsoringAccordion, setTextSponsoringAccordion] = useState('')
  const [textChildInfo, setTextChild] = useState('')
  const [showChildrenTab, setShowChildrenTab] = useState(true)
  const [sponsorApplicant, setSponsorApplicant] = useState(false)
  const [showDeleteIcon, setShowDeleteIcon] = useState(true)

  let schema = sponsoredShow
    ? PersonalSponsoredInfoSchema
    : PersonalMainApplicantInfoSchema
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: true,
  })
  const inputRef = useRef()
  const [error, setErrors] = useState()
  const [dateOfBirth, setDateOfBirth] = useState(
    personalInformation?.mainApplicant?.dateOfBirth
      ? new Date(personalInformation?.mainApplicant?.dateOfBirth)
      : personalInformation?.sponsoredInformation?.dateOfBirth
      ? new Date(personalInformation?.sponsoredInformation?.dateOfBirth)
      : new Date(),
  )
  const [lastVisitDate, setLastVisitDate] = useState(
    personalInformation?.mainApplicant?.lastVisitDate
      ? new Date(personalInformation?.mainApplicant?.lastVisitDate)
      : personalInformation?.sponsoredInformation?.lastVisitDate
      ? new Date(personalInformation?.sponsoredInformation?.lastVisitDate)
      : new Date(),
  )
  const [issueDate, setIssueDate] = useState(
    personalInformation?.mainApplicant?.issueDate
      ? new Date(personalInformation?.mainApplicant?.issueDate)
      : personalInformation?.sponsoredInformation?.issueDate
      ? new Date(personalInformation?.sponsoredInformation?.issueDate)
      : new Date(),
  )
  const [expiryDate, setExpiryDate] = useState(
    personalInformation?.mainApplicant?.expiryDate
      ? new Date(personalInformation?.mainApplicant?.expiryDate)
      : personalInformation?.sponsoredInformation?.expiryDate
      ? new Date(personalInformation?.sponsoredInformation?.expiryDate)
      : new Date(),
  )
  const [liveFromDate, setLiveFromDate] = useState(
    personalInformation?.mainApplicant?.liveFromDate
      ? new Date(personalInformation?.mainApplicant?.liveFromDate)
      : personalInformation?.sponsoredInformation?.liveFromDate
      ? new Date(personalInformation?.sponsoredInformation?.liveFromDate)
      : new Date(),
  )
  const [liveToDate, setLiveToDate] = useState(
    personalInformation?.mainApplicant?.liveToDate
      ? new Date(personalInformation?.mainApplicant?.liveToDate)
      : personalInformation?.sponsoredInformation?.liveToDate
      ? new Date(personalInformation?.sponsoredInformation?.liveToDate)
      : new Date(),
  )
  const [relationToDate, setRelationToDate] = useState(
    personalInformation?.mainApplicant?.relationToDate
      ? new Date(personalInformation?.mainApplicant?.relationToDate)
      : personalInformation?.sponsoredInformation?.relationToDate
      ? new Date(personalInformation?.sponsoredInformation?.relationToDate)
      : new Date(),
  )
  const [relationFromDate, setRelationFromDate] = useState(
    personalInformation?.mainApplicant?.relationFromDate
      ? new Date(personalInformation?.mainApplicant?.relationFromDate)
      : personalInformation?.sponsoredInformation?.relationFromDate
      ? new Date(personalInformation?.sponsoredInformation?.relationFromDate)
      : new Date(),
  )
  const [sDateOfBirth, setSDateOfBirth] = useState(
    personalInformation?.spouseInfo?.dateOfBirth
      ? new Date(personalInformation?.spouseInfo?.dateOfBirth)
      : personalInformation?.sponsoredSpouseInfo?.dateOfBirth
      ? new Date(personalInformation?.sponsoredSpouseInfo?.dateOfBirth)
      : new Date(),
  )
  const [sponsoredDateBirth, setSponsoredDateOfBirth] = useState(
    personalInformation?.sponsoringInformation?.dateOfBirth
      ? new Date(personalInformation?.sponsoringInformation?.dateOfBirth)
      : new Date(),
  )
  const [childs, setChilds] = useState(
    personalInformation?.sponsoredChildrenInfo?.length > 0
      ? personalInformation?.sponsoredChildrenInfo
      : personalInformation?.childInformation?.length > 0
      ? personalInformation?.childInformation
      : [{ ...intialState }],
  )
  const [sponsorChilds, setSponsorChilds] = useState([{ ...intialParentState }])
  const [mainApplicanDis, setMainApplicaDis] = useState(false)
  const [sponsorDis, setSponsorDis] = useState(false)
  const [spouseDis, setSpouseDis] = useState(false)
  const [childrenInfoDis, setChildrenInfo] = useState(false)

  const [spouseFormState, setSpouseformstate] = useState('plus')
  const [childrenFormState, setChildrenFormState] = useState('plus')
  const [sponsorChildrenFormState, setSponsorChildrenFormState] = useState(
    'plus',
  )
  const [customAccordBtn, setCustomAccordBtn] = useState(true)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [sponsoringFormState, setSponsoringFormState] = useState('plus')
  const [parentFormState, setParentFormState] = useState('plus')
  const [siblingFormState, setSiblingFormState] = useState('plus')
  const [parentDetail, setParentDetail] = useState([{ ...intialParentState }])
  const [siblingDetail, setSiblingDetail] = useState([
    { ...intialSiblingState },
  ])
  const [addressDetail, setAddressDetail] = useState([])
  const [organizationDetail, setOrganizationDetail] = useState([
    { ...intialOrganizationState },
  ])
  const [tripsDetail, setTripDetail] = useState([{ ...intialTripState }])
  const [parentDis, setParentDis] = useState(false)
  const [siblingDis, setSiblingDis] = useState(false)
  const [isParentShow, setIsParentShow] = useState(true)
  const [isSiblingShow, setIsSiblingShow] = useState(true)
  //for show the field with conditionaly

  const sposeCriminalOffense = watch('spouseInformation.sCriminalOffense')
  const sponsorCriminalOffense = watch(
    'sponsorInformation.sponsorCriminalOffense',
  )
  const showHelthCondetionDetail = watch(
    'sponsorInformation.sponsorHealthCondition',
  )
  const spouseHealthCondition = watch('spouseInformation.sHealthCondition')
  useEffect(() => {
    if (siblingFormState === 'open') {
      setValue('isSibling', true)
      setValue('siblingInformation', [{ ...intialSiblingState }])
    }
  }, [ siblingFormState])
  useEffect(() => {
    if (parentFormState === 'open') {
      setValue('isParent', true)
      setValue('parentInformation', [{ ...intialParentState }])
    }
  }, [parentFormState])
  useEffect(() => {
    if (childrenFormState === 'open') {
      setValue("isChildren",true)
      setValue('childrenInfo', childs)
    }
  }, [childrenFormState, childs])
  useEffect(() => {
    if (selectedVisa?.visaName === 'Partner / Spouse Visa') {
      setSponsoredSpouseShow(false)
      setValue('isSponsor', true)
      setSponsoredShow(true)
      setSponsorApplicant(true)
      setTextSponsoringAccordion('Sponsor (Partner/Spouse) Information')
      setSponsoringFormState('open')
      setShowDeleteIcon(false)
    }
    if (selectedVisa?.visaName === 'Parent Sponsorship Visa' && !sponsorDis) {
      setTextMainAccordion('Applicant (Parent)')
      setSponsoredSpouseShow(false)
      setSponsoredShow(true)
      setTextSponsoringAccordion('Add Applicant (Parent #2)')
      setTextChild('Sponsor (Child) Information')
      setSponsorChildrenFormState('open')
      setValue('isSponsorChild', true)
      setValue('sponsorChildrenInfo', sponsorChilds)
      setSponsorApplicant(true)
      setIsSiblingShow(false)
      setIsParentShow(false)
    }
    if (
      selectedVisa.visaName ===
      'Employer-Specific Work Permit' 
    ) {
      setTextSponsoringAccordion('Sponsor (Employer) Information')
      setValue('isSponsor', true)
      setShowChildrenTab(false)
      setSponsoringFormState('open')
      setSponsoredSpouseShow(true)
      setSponsorApplicant(true)
      setSponsoredShow(true)
      setShowDeleteIcon(false)
    }
    if (selectedVisa.visaName === 'Employer-Specific Work Permit') {
      setTextSponsoringAccordion('Sponsor (Employer) Information')
      setValue('isSponsor', true)
      setShowChildrenTab(false)
      setSponsoringFormState('open')
      setSponsoredSpouseShow(true)
      setSponsorApplicant(true)
      setSponsoredShow(true)
      setShowDeleteIcon(false)
    }
  }, [selectedVisa])
  useEffect(() => {
    if (sponsorChildrenFormState === 'open') {
      setValue('sponsorChildrenInfo', sponsorChilds)
    }
  }, [sponsorChildrenFormState, sponsorChilds])
  useEffect(() => {
    if (selectedVisa?.visaName === 'Parent Sponsorship Visa' && sponsorDis) {
      setTextSponsoringAccordion('Applicant (Parent #2)')
    }
    if (
      (selectedVisa?.visaName === 'Parent Sponsorship Visa' && sponsorDis) ||
      (selectedVisa?.visaName === 'Parent Sponsorship Visa' &&
        sponsoringFormState === 'open')
    ) {
      setTextSponsoringAccordion('Applicant (Parent #2)')
    }
    if (
      (selectedVisa?.visaName === 'Parent Sponsorship Visa' && sponsorDis) ||
      (selectedVisa?.visaName === 'Parent Sponsorship Visa' &&
        sponsoringFormState === 'plus')
    ) {
      setTextSponsoringAccordion('Add Applicant (Parent #2)')
    }
  }, [selectedVisa, sponsoringFormState, sponsorDis])
  useEffect(() => {
    if (sponsorChildrenFormState === 'open') {
      setValue('sponsorChildrenInfo', sponsorChilds)
    }
  }, [sponsorChildrenFormState, sponsorChilds])
  useEffect(() => {
    if (
      personalInformation?.sponsoredInformation &&
      Object.keys(personalInformation?.sponsoredInformation)?.length !== 0 &&
      personalInformation?.isLocked
    ) {
      setMainApplicaDis(token ? adminFormDisable : true)
      setSpouseDis(token ? adminFormDisable : true)
      setChildrenInfo(token ? adminFormDisable : true)
      setParentDis(token ? adminFormDisable : true)
    }
    if (
      personalInformation?.sponsoredInformation &&
      Object.keys(personalInformation?.sponsoredInformation)?.length !== 0 &&
      personalInformation?.sponsoredChildrenInfo?.length > 0
    ) {
      setChilds(personalInformation?.sponsoredChildrenInfo)
    }
    if (
      personalInformation?.sponsoringInformation &&
      Object.keys(personalInformation?.sponsoringInformation)?.length !== 0 &&
      personalInformation?.isLocked
    ) {
      setSponsorDis(token ? adminFormDisable : true)
    }
    if (
      personalInformation?.sponsoringInformation &&
      personalInformation?.parentInfo?.length !== 0
    ) {
      setSponsorChilds(personalInformation?.parentInfo)
    }
    if (
      personalInformation?.mainApplicant &&
      Object.keys(personalInformation?.mainApplicant)?.length !== 0 &&
      personalInformation?.isLocked
    ) {
      setMainApplicaDis(token ? adminFormDisable : true)
      setSpouseDis(token ? adminFormDisable : true)
      setChildrenInfo(token ? adminFormDisable : true)
      setParentDis(token ? adminFormDisable : true)
      setSiblingDis(token ? adminFormDisable : true)
    }
    if (
      personalInformation?.mainApplicant &&
      Object.keys(personalInformation?.mainApplicant)?.length !== 0 &&
      personalInformation?.childInformation?.length > 0
    ) {
      //setChildrenInfo(token ? adminFormDisable : true)
      setChilds(personalInformation?.childInformation)
    }
    if (
      personalInformation?.mainApplicant &&
      Object.keys(personalInformation?.mainApplicant)?.length !== 0 &&
      personalInformation?.childInformation?.length > 0
    ) {
      //setChildrenInfo(token ? adminFormDisable : true)
      setChilds(personalInformation?.childInformation)
    }
    if (
      personalInformation?.mainApplicant &&
      Object.keys(personalInformation?.mainApplicant)?.length !== 0 &&
      personalInformation?.addressInfo?.length > 0
    ) {
      //setChildrenInfo(token ? adminFormDisable : true)
      setAddressDetail(personalInformation?.addressInfo)
    }
    if (
      personalInformation?.mainApplicant &&
      Object.keys(personalInformation?.mainApplicant)?.length !== 0 &&
      personalInformation?.organizationInfo?.length > 0
    ) {
      //setChildrenInfo(token ? adminFormDisable : true)
      setOrganizationDetail(personalInformation?.organizationInfo)
    }
    if (
      personalInformation?.mainApplicant &&
      Object.keys(personalInformation?.mainApplicant)?.length !== 0 &&
      personalInformation?.tripsInfo?.length > 0
    ) {
      //setChildrenInfo(token ? adminFormDisable : true)
      setTripDetail(personalInformation?.tripsInfo)
    }
    if (
      personalInformation?.mainApplicant &&
      Object.keys(personalInformation?.mainApplicant)?.length !== 0 &&
      personalInformation?.parentInfo?.length > 0
    ) {
      //setChildrenInfo(token ? adminFormDisable : true)
      setParentDetail(personalInformation?.parentInfo)
    }
    if (token) {
      setValue(
        'birthCountry',
        personalInformation?.mainApplicant?.birthCountry
          ? personalInformation?.mainApplicant?.birthCountry
          : personalInformation?.sponsoredInformation?.birthCountry
          ? personalInformation?.sponsoredInformation?.birthCountry
          : userGeoData?.country_name,
      )
      setValue(
        'countryCitizenship',
        personalInformation?.mainApplicant?.countryCitizenship
          ? personalInformation?.mainApplicant?.countryCitizenship
          : personalInformation?.sponsoredInformation?.countryCitizenship
          ? personalInformation?.sponsoredInformation?.countryCitizenship
          : userGeoData?.country_name,
      )
      setValue(
        'secondCitizenship',
        personalInformation?.mainApplicant?.secondCitizenship
                ? personalInformation?.mainApplicant?.secondCitizenship
                : personalInformation?.sponsoredInformation?.secondCitizenship
                ? personalInformation?.sponsoredInformation?.secondCitizenship
                : "",
      )
      setValue(
        'countryResidence',
        personalInformation?.mainApplicant?.countryResidence
          ? personalInformation?.mainApplicant?.countryResidence
          : personalInformation?.sponsoredInformation?.countryResidence
          ? personalInformation?.sponsoredInformation?.countryResidence
          : userGeoData?.country_name,
      )
      setValue(
        'spouseInformation.sBirthCountry',
        personalInformation?.spouseInfo?.birthCountry
          ? personalInformation?.spouseInfo?.birthCountry
          : personalInformation?.sponsoredSpouseInfo?.birthCountry
          ? personalInformation?.sponsoredSpouseInfo?.birthCountry
          : userGeoData?.country_name,
      )
      setValue(
        'spouseInformation.sCountryCitizenship',
        personalInformation?.spouseInfo?.countryCitizenship
          ? personalInformation?.spouseInfo?.countryCitizenship
          : personalInformation?.sponsoredSpouseInfo?.countryCitizenship
          ? personalInformation?.sponsoredSpouseInfo?.countryCitizenship
          : userGeoData?.country_name,
      )
      setValue(
        'spouseInformation.sCountryResidence',
        personalInformation?.spouseInfo?.countryResidence
          ? personalInformation?.spouseInfo?.countryResidence
          : personalInformation?.sponsoredSpouseInfo?.countryResidence
          ? personalInformation?.sponsoredSpouseInfo?.countryResidence
          : userGeoData?.country_name,
      )
      setValue(
        'sponsorInformation.sponsorGender',
        personalInformation?.sponsoringInformation?.gender || '',
      )
      setValue(
        'sponsorInformation.sponsorBirthCountry',
        personalInformation?.sponsoringInformation?.birthCountry
          ? personalInformation?.sponsoringInformation?.birthCountry
          : userGeoData?.country_name,
      )
      setValue(
        'sponsorInformation.sponsorCountryCitizenship',
        personalInformation?.sponsoringInformation?.countryCitizenship
          ? personalInformation?.sponsoringInformation?.countryCitizenship
          : userGeoData?.country_name,
      )
      setValue(
        'sponsorInformation.sponsorCountryResidence',
        personalInformation?.sponsoringInformation?.countryResidence
          ? personalInformation?.sponsoringInformation?.countryResidence
          : userGeoData?.country_name,
      )
      setValue(
        'sponsorInformation.sponsorMaritalStatus',
        personalInformation?.sponsoringInformation?.maritalStatus || '',
      )
      setValue(
        'sponsorInformation.sponsorCriminalOffense',
        personalInformation?.sponsoringInformation?.criminalOffense || '',
      )
      setValue(
        'sponsorInformation.sponsorHealthCondition',
        personalInformation?.sponsoringInformation?.healthCondition || '',
      )
      setValue(
        'sponsorSpouseInformation.sGender',
        personalInformation?.sponsoringSpouseInfo?.gender || '',
      )
      setValue(
        'sponsorSpouseInformation.sBirthCountry',
        personalInformation?.sponsoringSpouseInfo?.birthCountry
          ? personalInformation?.sponsoringSpouseInfo?.birthCountry
          : userGeoData?.country_name,
      )
      setValue(
        'sponsorSpouseInformation.sCountryCitizenship',
        personalInformation?.sponsoringSpouseInfo?.countryCitizenship
          ? personalInformation?.sponsoringSpouseInfo?.countryCitizenship
          : userGeoData?.country_name,
      )
      setValue(
        'sponsorSpouseInformation.sCountryResidence',
        personalInformation?.sponsoringSpouseInfo?.countryResidence
          ? personalInformation?.sponsoringSpouseInfo?.countryResidence
          : userGeoData?.country_name,
      )
      setValue(
        'spouseInformation.sSecondCitizenship',
        personalInformation?.spouseInfo
        ? personalInformation?.spouseInfo
            ?.secondCitizenship
        : personalInformation?.sponsoredSpouseInfo
            ?.secondCitizenship
        ? personalInformation?.sponsoredSpouseInfo
            ?.secondCitizenship
        : "",
      )
      setValue(
        'sponsorSpouseInformation.sMaritalStatus',
        personalInformation?.sponsoringSpouseInfo?.maritalStatus || '',
      )
      setValue(
        'sponsorSpouseInformation.sCriminalOffense',
        personalInformation?.sponsoringSpouseInfo?.criminalOffense || '',
      )
      setValue(
        'sponsorSpouseInformation.sHealthCondition',
        personalInformation?.sponsoringSpouseInfo?.healthCondition || '',
      )
      setValue(
        'sponsorInformation.sponsorDateOfBirth',
        personalInformation?.sponsoringInformation?.dateOfBirth
          ? new Date(personalInformation?.sponsoringInformation?.dateOfBirth)
          : new Date(),
      )
      setValue(
        'phoneNumber',
        personalInformation?.mainApplicant?.phoneNumber ||
          personalInformation?.sponsoredInformation?.phoneNumber ||
          '',
      )
      setValue(
        'mobile',
        personalInformation?.mainApplicant?.mobile
          ? personalInformation?.mainApplicant?.mobile
          : personalInformation?.sponsoredInformation?.mobile
          ? personalInformation?.sponsoredInformation?.mobile
          : '',
      )
      setValue(
        'officePhoneNumber',
        personalInformation?.mainApplicant?.officePhoneNumber
          ? personalInformation?.mainApplicant?.officePhoneNumber
          : personalInformation?.sponsoredInformation?.officePhoneNumber
          ? personalInformation?.sponsoredInformation?.officePhoneNumber
          : '',
      )
      setValue(
        'spouseInformation.sPhoneNumber',
        personalInformation?.spouseInfo?.phoneNumber ||
          personalInformation?.sponsoredSpouseInfo?.phoneNumber ||
          '',
      )
      setValue(
        'spouseInformation.sMobile',
        personalInformation?.spouseInfo?.mobile
          ? personalInformation?.spouseInfo?.mobile
          : personalInformation?.sponsoredSpouseInfo?.mobile,
      )
      setValue(
        'spouseInformation.sOfficePhoneNumber',
        personalInformation?.spouseInfo?.officePhoneNumber
          ? personalInformation?.spouseInfo?.officePhoneNumber
          : personalInformation?.sponsoredSpouseInfo?.officePhoneNumber,
      )
      setValue(
        'spouseInformation.sDateOfBirth',
        personalInformation?.spouseInfo?.dateOfBirth
          ? new Date(personalInformation?.spouseInfo?.dateOfBirth)
          : personalInformation?.sponsoredSpouseInfo?.dateOfBirth
          ? new Date(personalInformation?.sponsoredSpouseInfo?.dateOfBirth)
          : new Date(),
      )
      setValue(
        'sponsorPhoneNumber',
        personalInformation?.sponsoringInformation?.phoneNumber
          ? personalInformation?.sponsoringInformation?.phoneNumber
          : '',
      )
      setValue(
        'sponsorMobile',
        personalInformation?.sponsoringInformation?.mobile
          ? personalInformation?.sponsoringInformation?.mobile
          : '',
      )
      setValue(
        'sponsorOfficePhoneNumber',
        personalInformation?.sponsoringInformation?.mobile
          ? personalInformation?.sponsoringInformation?.mobile
          : '',
      )
      setValue(
        'sponsorSpouseInformation.sPhoneNumber',
        personalInformation?.sponsoringSpouseInfo?.phoneNumber || '',
      )
      setValue(
        'sponsorSpouseInformation.sMobile',
        personalInformation?.sponsoringSpouseInfo?.mobile || '',
      )
      setValue(
        'sponsorSpouseInformation.sPhoneNumber',
        personalInformation?.sponsoringSpouseInfo?.mobile || '',
      )
      setValue(
       "dateOfBirth", personalInformation?.mainApplicant?.dateOfBirth
      ? new Date(personalInformation?.mainApplicant?.dateOfBirth)
      : personalInformation?.sponsoredInformation?.dateOfBirth
      ? new Date(personalInformation?.sponsoredInformation?.dateOfBirth)
      : new Date()
      )
    }
  }, [personalInformation, token, adminFormDisable])

  const changeAccordionselect = (key) => {
    if (key === '1') {
      setSpouseformstate('open')
      setValue('isSpouseOpen', true)
    } else if (key === '2') {
      setChildrenFormState('open')
      setChilds(
        personalInformation?.childInformation?.length > 0
          ? personalInformation?.childInformation
          : [{ ...intialState }],
      )
    } else if (key === '3') {
      setValue('isSponsor', true)
      setSponsoringFormState('open')
    } else if (key === '5') {
      setValue('isSponsorChild', true)

      setSponsorChildrenFormState('open')
      setSponsorChilds(
        personalInformation?.parentInfo?.length > 0
          ? personalInformation?.parentInfo
          : [{ ...intialParentState }],
      )
      setValue('sponsorChildrenInfo', sponsorChilds)
    } else if (key === '6') {
      setParentFormState('open')
    } else if (key === '7') {
      setSiblingFormState('open')
    }
  }
  
  const handleAddChild = () => {
    setChilds([...childs, { ...intialState }])
  }
  const handleAddParent = () => {
    setValue('parentInformation', [...parentDetail, { ...intialParentState }])
    setParentDetail([...parentDetail, { ...intialParentState }])
  }
  const handleAddsibling = () => {
    setValue('siblingInformation', [
      ...siblingDetail,
      { ...intialSiblingState },
    ])
    setSiblingDetail([...siblingDetail, { ...intialSiblingState }])
  }
  const handleAddSponsorChild = () => {
    setValue('sponsorChildrenInfo', [
      ...sponsorChilds,
      { ...intialParentState },
    ])
    setSponsorChilds([...sponsorChilds, { ...intialParentState }])
  }

  const handleChange = (e, index) => {
    const { name, value } = e.target
    let prevData = [...childs]
    prevData[index][name] = value
    setChilds([...prevData])
  }
  const removeChild = (index) => {
    const oldChild = [...childs]
    oldChild.splice(index, 1)
    setChilds(oldChild)
  }

  const handleParentChange = (e, index) => {
    const { name, value } = e.target
    let prevData = [...parentDetail]
    prevData[index][name] = value
    setParentDetail([...prevData])
    setValue('parentInformation', [...prevData])
  }
  const removeParent = (index) => {
    const oldChild = [...parentDetail]
    oldChild.splice(index, 1)
    setParentDetail(oldChild)
    setValue('parentInformation', [...oldChild])
  }
  const handleParentDatePicker = (date, type, index) => {
    let prevData = [...parentDetail]
    prevData[index][type] = date
    setParentDetail([...prevData])
    setValue('parentInformation', [...prevData])
  }
  const handleSiblingChange = (e, index) => {
    const { name, value } = e.target
    let prevData = [...siblingDetail]
    prevData[index][name] = value
    setSiblingDetail([...prevData])
    setValue('siblingInformation', [...prevData])
  }
  const removeSibling = (index) => {
    const oldChild = [...siblingDetail]
    oldChild.splice(index, 1)
    setSiblingDetail(oldChild)
    setValue('siblingInformation', oldChild)
  }
  const handleSiblingDatePicker = (date, type, index) => {
    let prevData = [...siblingDetail]
    prevData[index][type] = date
    setSiblingDetail([...prevData])
    setValue('siblingInformation', [...prevData])
  }
  const handleSponsoredChange = (e, index) => {
    const { name, value } = e.target
    let prevData = [...sponsorChilds]
    prevData[index][name] = value
    setSponsorChilds([...prevData])
    setValue('sponsorChildrenInfo', [...prevData])
  }
  const removeSponsoredChild = (index) => {
    const oldChild = [...sponsorChilds]
    oldChild.splice(index, 1)
    setSponsorChilds(oldChild)
    setValue('sponsorChildrenInfo', oldChild)
  }
  const handleChildDatePicker = (date, index) => {
    let prevData = [...childs]
    prevData[index].childDateOfBirth = date
    setChilds([...prevData])
  }
  const handleSponsorChildDatePicker = (date, type, index) => {
    let prevData = [...sponsorChilds]
    prevData[index][type] = date
    setSponsorChilds([...prevData])
    setValue('sponsorChildrenInfo', [...prevData])
  }
  const changeDatePicker = (date, type, ageType) => {
    if (type === 'dateOfBirth') {
      setDateOfBirth(date)
      setValue('dateOfBirth', date)
    }
    if (type === 'lastVisitDate') {
      setLastVisitDate(date)
      setValue('canadaInfo.lastVisitDate', date)
    }
    if (type === 'liveFromDate') {
      setLiveFromDate(date)
      setValue('pastCitizenshipInfo.liveFromDate', date)
    }
    if (type === 'liveToDate') {
      setLiveToDate(date)
      setValue('pastCitizenshipInfo.liveToDate', date)
    }
    if (type === 'issueDate') {
      setIssueDate(date)
      setValue('immigrationInfo.issueDate', date)
    }
    if (type === 'expiryDate') {
      setExpiryDate(date)
      setValue('immigrationInfo.expiryDate', date)
    }
    if (type === 'relationFromDate') {
      setRelationFromDate(date)
      setValue('relationshipInfo.relationFromDate', date)
    }
    if (type === 'relationToDate') {
      setRelationToDate(date)
      setValue('relationshipInfo.relationToDate', date)
    }
    if (type === 'sponsorDateOfBirth') {
      setSponsoredDateOfBirth(date)
      setValue('sponsorInformation.sponsorDateOfBirth', date)
    }
    // if (type === 'sponsorSDateOfBirth') {
    //   setSponsoredSDateOfBirth(date)
    //   setValue('spouseInformation.sDateOfBirth')
    // }
    if (type === 'sDateOfBirth') {
      setSDateOfBirth(date)
      setValue('spouseInformation.sDateOfBirth', date)
    }
    setValue(type, date)
    var dob = new Date(date)
    //calculate month difference from current date in time
    var month_diff = Date.now() - dob.getTime()
    //convert the calculated difference in date format
    var age_dt = new Date(month_diff)
    //extract year from date
    var year = age_dt.getUTCFullYear()
    //now calculate the age of the user
    var age = Math.abs(year - 1970)
    if (ageType === 'age') {
      setValue(ageType, age)
    }
    if (ageType === 'sAge') {
      setValue('spouseInformation.sAge', age)
    }
    if (ageType === 'sponsorAge') {
      setValue('spouseInformation.sAge', age)
    }
    if (ageType === 'sponsoredAge') {
      setValue('sponsorInformation.sponsorAge', age)
    }
  }
  const submitPersonalInfo = async (values) => {
    try {
      // let validate
      // let valid = true
      let apiPath = token
        ? 'support/updatePersonalInfo'
        : 'visa/visaPersonalInfo'
      // if (childrenFormState === 'open') {
      //   validate = await childValidation.validate(childs, {
      //     abortEarly: false,
      //   })
      //   valid = childrenFormState === 'open' ? validate : true
      // }
      // if (valid) {
      //   setErrors({})
        const res = await axios.post(
          `${serverPath}/${apiPath}`,
          {
            ...values,
            childData: childs,
            parentInfo:
              parentDetail &&
              parentDetail &&
              parentDetail[0]?.parentFullName &&
              parentDetail[0]?.parentRelation
                ? parentDetail
                : sponsorChilds,
            siblingInfo: siblingDetail,
            addressDetail: addressDetail,
            organizationDetail: organizationDetail,
            tripsDetail: tripsDetail,
            visaTypeId: selectedVisa.visaId,
            invoiceId: selectedVisa.invoiceId,
            formType: type || updFormType,
          },
          {
            headers: {
              authorization: `Bearer ${
                token ? token : localStorage.getItem('token')
              }`,
            },
          },
        )
        const { code, create } = res?.data

        if (code === 200) {
          reset()
          setPersonalInfo(create)
          setSelectedVisa({
            invoiceId: create?.invoiceId,
            visaId: create?.visaTypeId,
            caseId: selectedVisa?.caseId,
            visaName: selectedVisa?.visaName,
            forms: selectedVisa?.forms,
            sponsorForms: selectedVisa?.sponsorForms,
            isEvaluation: selectedVisa?.isEvaluation,
          })
          toast.success('Your information is saved successfully', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          })
        }
      // }
    } catch (err) {
      if (err?.response?.data) {
        toast.error(error.response.data.msg, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
      }
      let er = {}
      err?.inner?.forEach((el) => {
        er[el.path] = el.message
      })
      setErrors({ ...er })
    }
  }
  const handleDelete = (value, id) => {
    if (value === 'childrenFormState') {
      setChildrenFormState('plus')
      setErrors({})
      setValue("isChildren",false)
      setValue('childrenInfo', [])
    } else if (value === 'spouseFormState') {
      setSpouseformstate('plus')
      setValue('isSpouseOpen', false)
      setCustomAccordBtn(false)
    } else if (value === 'sponsoringFormState') {
      setSponsoringFormState('plus')
      setValue('isSponsor', false)
    } else if (value === 'sponsorChildrenFormState') {
      setSponsorChildrenFormState('plus')
      setValue('isSponsorChild', false)
      setErrors({})
      setCustomAccordBtn(false)
    } else if (value === 'parentFormState') {
      setValue('isParent', false)
      setValue('parentInformation', [])
      setParentFormState('plus')
    } else if (value === 'siblingFormState') {
      setSiblingFormState('plus')
      setValue('isSibling', false)
      setValue('siblingInformation', [])
    }
    document.getElementById(id).click()
  }
  const handleClose = (value, id) => {
    document.getElementById(id).click()
  }
  //custom accordian toggle
  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      if (customAccordBtn === eventKey) {
        setCustomAccordBtn('')
      } else setCustomAccordBtn(eventKey)
    })
    return (
      <Button id={`person-${eventKey}`} onClick={decoratedOnClick}>
        {children}
      </Button>
    )
  }
  //For Error popup
  const handleShowErrorModal = () => {
    setShowErrorModal(false)
  }
  useEffect(() => {
    if (
      (errors && Object.keys(errors).length !== 0) ||
      (error && Object.keys(error).length !== 0)
    ) {
      setShowErrorModal(true)
    } else {
      setShowErrorModal(false)
    }
  }, [errors, error])
  return (
    <div className="personal-info">
      <Form onSubmit={handleSubmit(submitPersonalInfo)}>
        <Accordion
          defaultActiveKey="0"
          onSelect={(key) => changeAccordionselect(key)}
        >
          <div className={`accordion-list ${'change-accordion-icon'}`}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {textMainAccordion ? textMainAccordion : 'Main Applicant'}
              </Accordion.Header>

              <Accordion.Body>
                <MainAccordion
                  errors={errors}
                  mainApplicanDis={mainApplicanDis}
                  personalInformation={personalInformation}
                  register={register}
                  changeDatePicker={changeDatePicker}
                  setValue={setValue}
                  watch={watch}
                  expiryDate={expiryDate}
                  issueDate={issueDate}
                  lastVisitDate={lastVisitDate}
                  dateOfBirth={dateOfBirth}
                  liveToDate={liveToDate}
                  liveFromDate={liveFromDate}
                  relationFromDate={relationFromDate}
                  relationToDate={relationToDate}
                  addressDetail={addressDetail}
                  setAddressDetail={setAddressDetail}
                  intialAddressState={intialAddressState}
                  organizationDetail={organizationDetail}
                  setOrganizationDetail={setOrganizationDetail}
                  intialOrganizationState={intialOrganizationState}
                  intialTripState={intialTripState}
                  setTripDetail={setTripDetail}
                  tripsDetail={tripsDetail}
                />
              </Accordion.Body>
            </Accordion.Item>
          </div>
          {sponsoredSpouseShow ? (
            <div
              className={`accordion-list ${
                spouseFormState === 'open' ? 'change-accordion-icon' : ''
              }`}
            >
              <Card>
                <Card.Header
                  className={customAccordBtn === '1' ? 'accord-open' : ''}
                  onClick={() =>
                    spouseFormState === 'open' || spouseDis
                      ? handleClose('spouseFormState', 'person-1')
                      : ''
                  }
                >
                  <div className="accordian-title">
                    {spouseFormState === 'open' || spouseDis
                      ? 'Spouse Information'
                      : 'Add Spouse Information'}
                    {spouseFormState === 'open' && !spouseDis && (
                      <div className="delete-btn">
                        <Button
                          onClick={() =>
                            handleDelete('spouseFormState', 'person-1')
                          }
                        >
                          Delete <i className="las la-minus"></i>
                        </Button>
                      </div>
                    )}
                  </div>
                  <CustomToggle eventKey="1">
                    {spouseFormState === 'plus' && !spouseDis ? (
                      <img src={addIcon} alt="Add Icon" />
                    ) : (spouseFormState === 'open' && !spouseDis) ||
                      (spouseFormState === 'open' && spouseDis) ||
                      (spouseFormState === 'plus' && !spouseDis) ||
                      (spouseFormState === 'plus' && spouseDis) ? (
                      <img
                        className="arrow-icon"
                        src={customAccordBtn !== '1' ? closeArrow : activeArrow}
                        alt="Icon"
                      />
                    ) : (
                      ''
                    )}
                  </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <div className="box-grid-three">
                      <Form.Group
                        className={`form-group ${
                          errors?.spouseInformation?.sFirstName
                            ? 'error-msg'
                            : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>First Name*</Form.Label>
                        <Form.Control
                          type="text"
                          name="sFirstName"
                          {...register('spouseInformation.sFirstName')}
                          disabled={spouseDis ? spouseDis : false}
                          defaultValue={
                            personalInformation?.spouseInfo?.firstName
                              ? personalInformation?.spouseInfo?.firstName
                              : personalInformation?.sponsoredSpouseInfo
                                  ?.firstName
                          }
                        />
                        {errors?.spouseInformation?.sFirstName ? (
                          <span className="error-msg">
                            {errors?.spouseInformation?.sFirstName?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                      <Form.Group
                        className={`form-group`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Middle Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="sMidName"
                          disabled={spouseDis ? spouseDis : false}
                          defaultValue={
                            personalInformation?.spouseInfo?.middleName
                              ? personalInformation?.spouseInfo?.middleName
                              : personalInformation?.sponsoredSpouseInfo
                                  ?.middleName
                          }
                          {...register('spouseInformation.sMidName')}
                        />
                        {errors?.spouseInformation?.sMidName ? (
                          <span className="error-msg">
                            {errors?.spouseInformation?.sMidName?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                      <Form.Group
                        className={`form-group ${
                          errors?.spouseInformation?.sLastName
                            ? 'error-msg'
                            : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Last Name*</Form.Label>
                        <Form.Control
                          type="text"
                          name="sLastName"
                          disabled={spouseDis ? spouseDis : false}
                          defaultValue={
                            personalInformation?.spouseInfo?.lastName
                              ? personalInformation?.spouseInfo?.lastName
                              : personalInformation?.sponsoredSpouseInfo
                                  ?.lastName
                          }
                          {...register('spouseInformation.sLastName')}
                        />
                        {errors?.spouseInformation?.sLastName ? (
                          <span className="error-msg">
                            {errors?.spouseInformation?.sLastName?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                    </div>
                    <div className="box-grid-three">
                      <Form.Group
                        className={`form-group ${
                          errors?.spouseInformation?.sGender ? 'error-msg' : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Gender*</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="sGender"
                          disabled={spouseDis ? spouseDis : false}
                          defaultValue={
                            personalInformation?.spouseInfo
                              ? personalInformation?.spouseInfo?.gender
                              : personalInformation?.sponsoredSpouseInfo?.gender
                              ? personalInformation?.sponsoredSpouseInfo?.gender
                              : ''
                          }
                          {...register('spouseInformation.sGender')}
                        >
                          <option value="">
                            select
                          </option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </Form.Select>
                        {errors?.spouseInformation?.sGender ? (
                          <span className="error-msg">
                            {errors?.spouseInformation?.sGender?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>

                      <Form.Group
                        className={`form-group ${
                          errors?.spouseInformation?.sBirthCountry?.message
                            ? 'error-msg'
                            : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Birth Country*</Form.Label>
                        <Form.Select
                          disabled={spouseDis ? spouseDis : false}
                          name="sBirthCountry"
                          defaultValue={
                            personalInformation?.spouseInfo
                              ? personalInformation?.spouseInfo?.birthCountry
                              : personalInformation?.sponsoredSpouseInfo
                                  ?.birthCountry
                              ? personalInformation?.sponsoredSpouseInfo
                                  ?.birthCountry
                              : userGeoData?.country_name
                          }
                          {...register('spouseInformation.sBirthCountry')}
                        >
                          <option value={userGeoData?.country_name}>
                            {personalInformation?.spouseInfo
                              ? personalInformation?.spouseInfo?.birthCountry
                              : personalInformation?.sponsoredSpouseInfo
                                  ?.birthCountry
                              ? personalInformation?.sponsoredSpouseInfo
                                  ?.birthCountry
                              : userGeoData?.country_name}
                          </option>
                          {countries &&
                            countries?.length > 0 &&
                            countries.map((el) => (
                              <option value={el?.name}>{el?.name}</option>
                            ))}
                        </Form.Select>
                        {errors?.spouseInformation?.sBirthCountry ? (
                          <span className="error-msg">
                            {errors?.spouseInformation?.sBirthCountry?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>

                      <Form.Group
                        className={`form-group ${
                          error?.sDateOfBirth?.message ? 'error-msg' : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Date of Birth*</Form.Label>
                        <DatePicker
                          type="sDateOfBirth"
                          name="sDateOfBirth"
                          placeholderText="Select Date"
                          // selected={sDateOfBirth}
                          selected={sDateOfBirth}
                          disabled={spouseDis ? spouseDis : false}
                          onChange={(date) =>
                            changeDatePicker(date, 'sDateOfBirth', 'sAge')
                          }
                          className="form-select"
                          dateFormat="dd-MM-yyyy"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                        />
                        <input
                          ref={inputRef}
                          value={sDateOfBirth}
                          type="hidden"
                          {...register('spouseInformation.sDateOfBirth')}
                        />
                        {errors?.spouseInformation?.sDateOfBirth ? (
                          <span className="error-msg">
                            {errors?.spouseInformation?.sDateOfBirth?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                    </div>
                    <div className="box-grid-three">
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Phone*</Form.Label>
                        {/* <Form.Control type="email" placeholder="" /> */}
                        <Form.Group>
                          <PhoneInput
                            country={
                              personalInformation?.spouseInfo?.phoneNumber
                                ? ''
                                : personalInformation?.sponsoredSpouseInfo
                                    ?.phoneNumber
                                ? ''
                                : userGeoData?.country_code?.toLowerCase()
                            }
                            value={
                              personalInformation?.spouseInfo?.phoneNumber
                                ? personalInformation?.spouseInfo?.phoneNumber?.substring(
                                    1,
                                  )
                                : personalInformation?.sponsoredSpouseInfo?.phoneNumber?.substring(
                                    1,
                                  )
                            }
                            disabled={spouseDis ? spouseDis : false}
                            onChange={(e) =>
                              setValue(
                                'spouseInformation.sPhoneNumber',
                                '+' + e,
                              )
                            }
                            inputProps={{
                              name: 'sPhoneNumber',
                            }}
                          />
                        </Form.Group>
                      </Form.Group>
                      <Form.Group
                        className={`form-group`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Mobile</Form.Label>
                        <PhoneInput
                          country={
                            personalInformation?.spouseInfo?.mobile
                              ? ''
                              : personalInformation?.sponsoredSpouseInfo?.mobile
                              ? ''
                              : userGeoData?.country_code?.toLowerCase()
                          }
                          value={
                            personalInformation?.spouseInfo?.mobile
                              ? personalInformation?.spouseInfo?.mobile?.substring(
                                  1,
                                )
                              : personalInformation?.sponsoredSpouseInfo?.mobile?.substring(
                                  1,
                                )
                          }
                          disabled={spouseDis ? spouseDis : false}
                          onChange={(e) =>
                            setValue('spouseInformation.sMobile', '+' + e)
                          }
                          inputProps={{
                            name: 'sMobile',
                          }}
                        />
                      </Form.Group>
                      <Form.Group
                        className={`form-group`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Office</Form.Label>
                        <PhoneInput
                          country={
                            personalInformation?.spouseInfo?.officePhoneNumber
                              ? ''
                              : personalInformation?.sponsoredSpouseInfo
                                  ?.officePhoneNumber
                              ? ''
                              : userGeoData?.country_code?.toLowerCase()
                          }
                          value={
                            personalInformation?.spouseInfo?.officePhoneNumber
                              ? personalInformation?.spouseInfo?.officePhoneNumber?.substring(
                                  1,
                                )
                              : personalInformation?.sponsoredSpouseInfo?.officePhoneNumber?.substring(
                                  1,
                                )
                          }
                          disabled={spouseDis ? spouseDis : false}
                          onChange={(e) =>
                            setValue(
                              'spouseInformation.sOfficePhoneNumber',
                              '+' + e,
                            )
                          }
                          inputProps={{
                            name: 'sOfficePhoneNumber',
                          }}
                        />
                      </Form.Group>
                    </div>
                    <div className="box-grid-two">
                      <Form.Group
                        className={`form-group ${
                          errors?.spouseInformation?.sPrimaryEmail?.message
                            ? 'error-msg'
                            : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Primary Email*</Form.Label>
                        <Form.Control
                          type="email"
                          name="sPrimaryEmail"
                          disabled={spouseDis ? spouseDis : false}
                          defaultValue={
                            personalInformation?.spouseInfo?.primaryEmail
                              ? personalInformation?.spouseInfo?.primaryEmail
                              : personalInformation?.sponsoredSpouseInfo
                                  ?.primaryEmail
                          }
                          {...register('spouseInformation.sPrimaryEmail')}
                        />
                        {errors?.spouseInformation?.sPrimaryEmail ? (
                          <span className="error-msg">
                            {errors?.spouseInformation?.sPrimaryEmail?.message}
                          </span>
                        ) : (
                          ''
                        )}
                        {/* <p>
                    <i class="las la-exclamation-triangle"></i>Please enter an
                    email address in the format{' '}
                    <span> username@domain.com</span>
                  </p> */}
                      </Form.Group>

                      <Form.Group
                        className={`form-group`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Secondary Email</Form.Label>
                        <Form.Control
                          type="email"
                          name="sSecondaryEmail"
                          disabled={spouseDis ? spouseDis : false}
                          defaultValue={
                            personalInformation?.spouseInfo?.secondaryEmail
                              ? personalInformation?.spouseInfo?.secondaryEmail
                              : personalInformation?.sponsoredSpouseInfo
                                  ?.secondaryEmail
                          }
                          {...register('spouseInformation.sSecondaryEmail')}
                        />
                        {errors?.spouseInformation?.sSecondaryEmail ? (
                          <span className="error-msg">
                            {
                              errors?.spouseInformation?.sSecondaryEmail
                                ?.message
                            }
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                    </div>
                    <div className="box-grid-three">
                      <Form.Group
                        className={`form-group ${
                          errors?.spouseInformation?.sCountryCitizenship
                            ?.message
                            ? 'error-msg'
                            : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Country of Citizenship*</Form.Label>
                        <Form.Select
                          //onChange={handleSelectChange}
                          disabled={spouseDis ? spouseDis : false}
                          name="sCountryCitizenship"
                          defaultValue={
                            personalInformation?.spouseInfo
                              ? personalInformation?.spouseInfo
                                  ?.countryCitizenship
                              : personalInformation?.sponsoredSpouseInfo
                                  ?.countryCitizenship
                              ? personalInformation?.sponsoredSpouseInfo
                                  ?.countryCitizenship
                              : userGeoData?.country_name
                          }
                          {...register('spouseInformation.sCountryCitizenship')}
                        >
                          <option value={userGeoData?.country_name}>
                            {personalInformation?.spouseInfo
                              ? personalInformation?.spouseInfo
                                  ?.countryCitizenship
                              : personalInformation?.sponsoredSpouseInfo
                                  ?.countryCitizenship
                              ? personalInformation?.sponsoredSpouseInfo
                                  ?.countryCitizenship
                              : userGeoData?.country_name}
                          </option>
                          {countries &&
                            countries?.length > 0 &&
                            countries.map((el) => (
                              <option value={el?.name}>{el?.name}</option>
                            ))}
                        </Form.Select>
                        {errors?.spouseInformation?.sCountryCitizenship ? (
                          <span className="error-msg">
                            {
                              errors?.spouseInformation?.sCountryCitizenship
                                ?.message
                            }
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                      <Form.Group
                        className={`form-group ${
                          errors?.sSecondCitizenship ? 'error-msg' : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Second Citizenship</Form.Label>
                        <Form.Select
                          name="sSecondCitizenship"
                          disabled={mainApplicanDis ? mainApplicanDis : false}
                          defaultValue={
                            personalInformation?.spouseInfo
                              ? personalInformation?.spouseInfo
                                  ?.secondCitizenship
                              : personalInformation?.sponsoredSpouseInfo
                                  ?.secondCitizenship
                              ? personalInformation?.sponsoredSpouseInfo
                                  ?.secondCitizenship
                              : ""
                          }
                          {...register('spouseInformation.sSecondCitizenship')}
                        >
                          <option value="">
                            Select country
                          </option>
                          {countries &&
                            countries?.length > 0 &&
                            countries.map((el) => (
                              <option value={el?.name}>{el?.name}</option>
                            ))}
                        </Form.Select>
                        {errors?.sSecondCitizenship ? (
                          <span className="error-msg">
                            {errors?.sSecondCitizenship?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>

                      <Form.Group
                        className={`form-group ${
                          errors?.spouseInformation?.sCountryResidence?.message
                            ? 'error-msg'
                            : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Country of Residence*</Form.Label>
                        <Form.Select
                          name="sCountryResidence"
                          disabled={spouseDis ? spouseDis : false}
                          defaultValue={
                            personalInformation?.spouseInfo
                              ? personalInformation?.spouseInfo
                                  ?.countryResidence
                              : personalInformation?.sponsoredSpouseInfo
                                  ?.countryResidence
                              ? personalInformation?.sponsoredSpouseInfo
                                  ?.countryResidence
                              : userGeoData?.country_name
                          }
                          {...register('spouseInformation.sCountryResidence')}
                        >
                          <option value={userGeoData?.country_name}>
                            {personalInformation?.spouseInfo
                              ? personalInformation?.spouseInfo
                                  ?.countryResidence
                              : personalInformation?.sponsoredSpouseInfo
                                  ?.countryResidence
                              ? personalInformation?.sponsoredSpouseInfo
                                  ?.countryResidence
                              : userGeoData?.country_name}
                          </option>
                          {countries &&
                            countries?.length > 0 &&
                            countries.map((el) => (
                              <option value={el?.name}>{el?.name}</option>
                            ))}
                        </Form.Select>
                        {errors?.spouseInformation?.sCountryResidence ? (
                          <span className="error-msg">
                            {
                              errors?.spouseInformation.sCountryResidence
                                .message
                            }
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                    </div>
                    <div className="box-grid-three">
                      <Form.Group
                        className={`form-group ${
                          errors?.spouseInformation?.sCity?.message
                            ? 'error-msg'
                            : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>City*</Form.Label>
                        <Form.Control
                          type="text"
                          name="sCity"
                          disabled={spouseDis ? spouseDis : false}
                          defaultValue={
                            personalInformation?.spouseInfo?.city
                              ? personalInformation?.spouseInfo?.city
                              : personalInformation?.sponsoredSpouseInfo?.city
                          }
                          {...register('spouseInformation.sCity')}
                        />
                        {errors?.spouseInformation?.sCity ? (
                          <span className="error-msg">
                            {errors?.spouseInformation?.sCity?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                      <Form.Group
                        className={`form-group ${
                          errors?.spouseInformation?.sStreet?.message
                            ? 'error-msg'
                            : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Street*</Form.Label>
                        <Form.Control
                          type="text"
                          name="sStreet"
                          disabled={spouseDis ? spouseDis : false}
                          defaultValue={
                            personalInformation?.spouseInfo?.street
                              ? personalInformation?.spouseInfo?.street
                              : personalInformation?.sponsoredSpouseInfo?.street
                          }
                          {...register('spouseInformation.sStreet')}
                        />
                        {errors?.spouseInformation?.sStreet ? (
                          <span className="error-msg">
                            {errors?.spouseInformation.sStreet.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                      <Form.Group
                        className={`form-group ${
                          errors?.spouseInformation?.sZipCode?.message
                            ? 'error-msg'
                            : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Zip Code / Postal Code*</Form.Label>
                        <Form.Control
                          type="number"
                          name="sZipCode"
                          disabled={spouseDis ? spouseDis : false}
                          defaultValue={
                            personalInformation?.spouseInfo?.zipCode
                              ? personalInformation?.spouseInfo?.zipCode
                              : personalInformation?.sponsoredSpouseInfo
                                  ?.zipCode
                          }
                          {...register('spouseInformation.sZipCode')}
                        />
                        {errors?.spouseInformation?.sZipCode ? (
                          <span className="error-msg">
                            {errors?.spouseInformation?.sZipCode?.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                    </div>
                    <div className="box-grid-two">
                      <Form.Group
                        className={`form-group ${
                          errors?.spouseInformation?.sMaritalStatus?.message
                            ? 'error-msg'
                            : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Marital Status*</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="sMaritalStatus"
                          disabled={spouseDis ? spouseDis : false}
                          defaultValue={
                            personalInformation?.spouseInfo?.maritalStatus
                              ? personalInformation?.spouseInfo?.maritalStatus
                              : personalInformation?.sponsoredSpouseInfo
                                  ?.maritalStatus
                              ? personalInformation?.sponsoredSpouseInfo
                                  ?.maritalStatus
                              : ''
                          }
                          {...register('spouseInformation.sMaritalStatus')}
                        >
                          <option value="">
                            Select
                          </option>
                          <option value="Single">Single</option>
                          <option value="2">Married</option>
                          <option value="Married">
                            Common-Law Partnership{' '}
                            <span>
                              (you are living together for at least 1 year)
                            </span>
                          </option>
                          <option value="Legally Separated">
                            Legally Separated
                          </option>
                          <option value="Divorced">Divorced</option>
                          <option value="Widowed">Widowed</option>
                        </Form.Select>
                        {errors?.spouseInformation?.sMaritalStatus ? (
                          <span className="error-msg">
                            {errors?.spouseInformation.sMaritalStatus.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>

                      <Form.Group
                        className={`form-group ${
                          errors?.spouseInformation?.sDependentChildren?.message
                            ? 'error-msg'
                            : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>
                          Number of dependent children under 22
                        </Form.Label>
                        <Form.Control
                          aria-label="Default select example"
                          type="text"
                          name="sDependentChildren"
                          disabled={spouseDis ? spouseDis : false}
                          defaultValue={
                            personalInformation?.spouseInfo?.dependentChildren?.toString()
                              ? personalInformation?.spouseInfo?.dependentChildren?.toString()
                              : personalInformation?.sponsoredSpouseInfo?.dependentChildren?.toString()
                              ? personalInformation?.sponsoredSpouseInfo?.dependentChildren?.toString()
                              : ''
                          }
                          {...register('spouseInformation.sDependentChildren')}
                        />
                        {/* <option value="">
                            {personalInformation?.spouseInfo?.dependentChildren?.toString()
                              ? personalInformation?.spouseInfo?.dependentChildren?.toString()
                              : personalInformation?.sponsoredSpouseInfo?.dependentChildren?.toString()
                              ? personalInformation?.sponsoredSpouseInfo?.dependentChildren?.toString()
                              : 'Select Children'}
                          </option>
                          <option value="0">0</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                        </Form.Select> */}
                        {errors?.spouseInformation?.sDependentChildren ? (
                          <span className="error-msg">
                            {
                              errors?.spouseInformation?.sDependentChildren
                                ?.message
                            }
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                    </div>
                    <div className="box-grid-two">
                      <Form.Group
                        className={`form-group ${
                          errors?.spouseInformation?.sCriminalOffense
                            ? 'error-msg'
                            : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>
                          Have you had any criminal offenses lodged against
                          you?*
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="sCriminalOffense"
                          disabled={spouseDis ? spouseDis : false}
                          defaultValue={
                            personalInformation?.spouseInfo?.criminalOffense
                              ? personalInformation?.spouseInfo?.criminalOffense
                              : personalInformation?.sponsoredSpouseInfo
                                  ?.criminalOffense
                              ? personalInformation?.sponsoredSpouseInfo
                                  ?.criminalOffense
                              : ''
                          }
                          {...register('spouseInformation.sCriminalOffense')}
                        >
                          <option value="">
                            Select
                          </option>
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </Form.Select>
                        {errors?.spouseInformation?.sCriminalOffense ? (
                          <span className="error-msg">
                            {errors?.spouseInformation.sCriminalOffense.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                      {sposeCriminalOffense === 'Yes' ||
                      personalInformation?.spouseInfo?.criminalOffense ===
                        'Yes' ||
                      personalInformation?.sponsoredSpouseInfo
                        ?.criminalOffense === 'Yes' ? (
                        <Form.Group
                          className={`form-group ${
                            errors?.sOffenseTypeDetail?.message
                              ? 'error-msg'
                              : ''
                          }`}
                          controlId="formBasicEmail"
                        >
                          <Form.Label>
                            Please describe your criminal offenses from the past
                            10 years
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="sOffenseTypeDetail"
                            disabled={spouseDis ? spouseDis : false}
                            defaultValue={
                              personalInformation?.spouseInfo?.offenseTypeDetail
                                ? personalInformation?.spouseInfo
                                    ?.offenseTypeDetail
                                : personalInformation?.sponsoredSpouseInfo
                                    ?.offenseTypeDetail
                            }
                            {...register(
                              'spouseInformation.sOffenseTypeDetail',
                            )}
                          />
                          {errors?.spouseInformation?.sOffenseTypeDetail ? (
                            <span className="error-msg">
                              {
                                errors?.spouseInformation.sOffenseTypeDetail
                                  .message
                              }
                            </span>
                          ) : (
                            ''
                          )}
                        </Form.Group>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="box-grid-two">
                      <Form.Group
                        className={`form-group ${
                          errors?.spouseInformation?.sHealthCondition?.message
                            ? 'error-msg'
                            : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Health Problems*</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="sHealthCondition"
                          disabled={spouseDis ? spouseDis : false}
                          //onChange={handleSpouseSelect}
                          defaultValue={
                            personalInformation?.spouseInfo?.healthCondition
                              ? personalInformation?.spouseInfo?.healthCondition
                              : personalInformation?.sponsoredSpouseInfo
                                  ?.healthCondition
                              ? personalInformation?.sponsoredSpouseInfo
                                  ?.healthCondition
                              : ''
                          }
                          {...register('spouseInformation.sHealthCondition')}
                        >
                          <option value="">
                            Select
                          </option>
                          <option value="No">No</option>
                          <option value="Yes">Yes</option>
                        </Form.Select>
                        {errors?.spouseInformation?.sHealthCondition ? (
                          <span className="error-msg">
                            {errors?.spouseInformation.sHealthCondition.message}
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                      {(spouseHealthCondition &&
                        spouseHealthCondition === 'Yes') ||
                      personalInformation?.spouseInfo?.healthCondition ===
                        'Yes' ||
                      personalInformation?.sponsoredSpouseInfo
                        ?.healthCondition === 'Yes' ? (
                        <Form.Group
                          className={`form-group ${
                            errors?.spouseInformation?.sHealthConditionDetail
                              ?.message
                              ? 'error-msg'
                              : ''
                          }`}
                          controlId="formBasicEmail"
                        >
                          <Form.Label>
                            If yes, Please describe your health condition/s
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="sHealthConditionDetail"
                            disabled={spouseDis ? spouseDis : false}
                            defaultValue={
                              personalInformation?.spouseInfo
                                ?.healthConditionDetail
                                ? personalInformation?.spouseInfo
                                    ?.healthConditionDetail
                                : personalInformation?.sponsoredSpouseInfo
                                    ?.healthConditionDetail
                            }
                            {...register(
                              'spouseInformation.sHealthConditionDetail',
                            )}
                          />
                          {errors?.spouseInformation?.sHealthConditionDetail ? (
                            <span className="error-msg">
                              {
                                errors?.spouseInformation.sHealthConditionDetail
                                  .message
                              }
                            </span>
                          ) : (
                            ''
                          )}
                        </Form.Group>
                      ) : (
                        ''
                      )}
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </div>
          ) : (
            ''
          )}
          {selectedVisa?.visaName !== 'Parent Sponsorship Visa' &&
          showChildrenTab ? (
            <div
              className={`accordion-list ${
                childrenFormState === 'open' ? 'change-accordion-icon' : ''
              }`}
            >
              <Card>
                <Card.Header
                  className={customAccordBtn === '2' ? 'accord-open' : ''}
                  onClick={() =>
                    childrenFormState === 'open' || childrenInfoDis
                      ? handleClose('childrenFormState', 'person-2')
                      : ''
                  }
                >
                  <div className="accordian-title">
                    {textChildInfo
                      ? textChildInfo
                      : childrenInfoDis || childrenFormState === 'open'
                      ? 'Children Information'
                      : 'Add Children Information'}
                    {childrenFormState === 'open' && !childrenInfoDis && (
                      <div className="delete-btn">
                        <Button
                          onClick={() =>
                            handleDelete('childrenFormState', 'person-2')
                          }
                        >
                          Delete <i className="las la-minus"></i>
                        </Button>
                      </div>
                    )}
                  </div>

                  <CustomToggle eventKey="2">
                    {childrenFormState === 'plus' && !childrenInfoDis ? (
                      <img src={addIcon} alt="Add Icon" />
                    ) : (childrenFormState === 'open' && !childrenInfoDis) ||
                      (childrenFormState === 'open' && childrenInfoDis) ||
                      (childrenFormState === 'plus' && childrenInfoDis) ||
                      (childrenFormState === 'plus' && !childrenInfoDis) ? (
                      <img
                        className="arrow-icon"
                        src={customAccordBtn !== '2' ? closeArrow : activeArrow}
                        alt="Icon"
                      />
                    ) : (
                      ''
                    )}
                  </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body className="accord-cardbody-scnd">
                    {childs && childs?.length > 0
                      ? childs?.map((child, i) => (
                          <Card.Body key={i}>
                            <h6 className="child-heading">Child #{i + 1}</h6>
                            <duiv className="child-info">
                              <div className="box-grid-three">
                                <Form.Group
                                  className={`form-group ${
                                    errors?.childrenInfo
                                    &&
                                    errors?.childrenInfo
                                    [`${i}`]
                                      ?.childFirstName
                                      ? 'error-msg'
                                      : ''
                                  }`}
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>First Name*</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="childFirstName"
                                    disabled={
                                      childrenInfoDis ? childrenInfoDis : false
                                    }
                                    defaultValue={child?.childFirstName}
                                    onChange={(e) => handleChange(e, i)}
                                  />
                                  {errors?.childrenInfo && errors?.childrenInfo
                                    [`${i}`]
                                      ?.childFirstName ? (
                                    <span className="error-msg">
                                      {errors?.childrenInfo
                                    [`${i}`]
                                      ?.childFirstName?.message}
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </Form.Group>
                                <Form.Group
                                  className={`form-group`}
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Middle Name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="childMiddleName"
                                    disabled={
                                      childrenInfoDis ? childrenInfoDis : false
                                    }
                                    // {...register('childMiddleName', {
                                    //   required: 'Middle name is required',
                                    // })}
                                    defaultValue={child?.childMiddleName}
                                    onChange={(e) => handleChange(e, i)}
                                  />
                                  {error && error[`[${i}].childMiddleName`] ? (
                                    <span className="error-msg">
                                      {error[`[${i}].childMiddleName`]}
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </Form.Group>
                                <Form.Group
                                 className={`form-group ${
                                  errors?.childrenInfo
                                  &&
                                  errors?.childrenInfo
                                  [`${i}`]
                                    ?.childLastName
                                    ? 'error-msg'
                                    : ''
                                }`}
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Last Name*</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    name="childLastName"
                                    disabled={
                                      childrenInfoDis ? childrenInfoDis : false
                                    }
                                    defaultValue={child?.childLastName}
                                    onChange={(e) => handleChange(e, i)}
                                  />
                                  {errors?.childrenInfo && errors?.childrenInfo
                                    [`${i}`]
                                      ?.childLastName ? (
                                    <span className="error-msg">
                                      {errors?.childrenInfo
                                    [`${i}`]
                                      ?.childLastName?.message}
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </Form.Group>
                              </div>
                              <div className="box-grid-two">
                                <Form.Group
                                  className={`form-group ${
                                    error && error[`[${i}].childGender`]
                                      ? 'error-msg'
                                      : ''
                                  }`}
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Gender</Form.Label>
                                  <Form.Select
                                    aria-label="Default select example"
                                    name="childGender"
                                    disabled={
                                      childrenInfoDis ? childrenInfoDis : false
                                    }
                                    defaultValue={child?.childGender?child?.childGender:''}
                                    onChange={(e) => handleChange(e, i)}
                                  >
                                    <option value="">
                                      Select Gender
                                    </option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                  </Form.Select>
                                  {/* {error && error[`[${i}].childGender`] ? (
                                      <span className="error-msg">
                                        {error[`[${i}].childGender`]}
                                      </span>
                                    ) : (
                                      ""
                                    )} */}
                                </Form.Group>

                                <Form.Group
                                  className={`form-group ${
                                    errors?.childDateOfBirth &&
                                    errors?.childDateOfBirth[`${i}`]
                                      ?.childDateOfBirth
                                      ? 'error-msg'
                                      : ''
                                  }`}
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Date of Birth</Form.Label>
                                  <DatePicker
                                    type="childDateOfBirth"
                                    name="childDateOfBirth"
                                    placeholderText="Select Date"
                                    disabled={
                                      childrenInfoDis ? childrenInfoDis : false
                                    }
                                    selected={
                                      child?.childDateOfBirth
                                        ? new Date(child?.childDateOfBirth)
                                        : new Date()
                                    }
                                    onChange={(date) =>
                                      handleChildDatePicker(date, i)
                                    }
                                    className="form-select"
                                    dateFormat="dd-MM-yyyy"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                  />

                                  {/* {error &&
                                    error[`[${i}]?.childDateOfBirth`] ? (
                                      <span className="error-msg">
                                        {error[`[${i}].childDateOfBirth`]}
                                      </span>
                                    ) : (
                                      ""
                                    )} */}
                                </Form.Group>
                              </div>
                              <div className="box-grid-two">
                                <Form.Group
                                  className={`form-group ${
                                    error && error[`[${i}].childBirthCountry`]
                                      ? 'error-msg'
                                      : ''
                                  }`}
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Birth Country</Form.Label>
                                  <Form.Select
                                    name="childBirthCountry"
                                    disabled={
                                      childrenInfoDis ? childrenInfoDis : false
                                    }
                                    onChange={(e) => handleChange(e, i)}
                                  >
                                    <option
                                      value={
                                        child?.childBirthCountry !== ''
                                          ? child?.childBirthCountry
                                          : userGeoData?.country_name
                                      }
                                    >
                                      {child?.childBirthCountry !== ''
                                        ? child?.childBirthCountry
                                        : userGeoData?.country_name}
                                    </option>
                                    {countries &&
                                      countries?.length > 0 &&
                                      countries.map((el) => (
                                        <option value={el?.name}>
                                          {el?.name}
                                        </option>
                                      ))}
                                  </Form.Select>
                                  {/* {error &&
                                    error[`[${i}].childBirthCountry`] ? (
                                      <span className="error-msg">
                                        {error[`[${i}].childBirthCountry`]}
                                      </span>
                                    ) : (
                                      ""
                                    )} */}
                                </Form.Group>

                                <Form.Group
                                  className={`form-group ${
                                    errors?.childrenInfo
                                    &&
                                    errors?.childrenInfo
                                    [`${i}`]
                                      ?.childBirthCity
                                      ? 'error-msg'
                                      : ''
                                  }`}
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Birth City*</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    name="childBirthCity"
                                    disabled={
                                      childrenInfoDis ? childrenInfoDis : false
                                    }
                                    onChange={(e) => handleChange(e, i)}
                                    defaultValue={child?.childBirthCity}
                                  />
                                  {errors?.childrenInfo && errors?.childrenInfo
                                    [`${i}`]
                                      ?.childBirthCity ? (
                                    <span className="error-msg">
                                      {errors?.childrenInfo
                                    [`${i}`]
                                      ?.childBirthCity?.message}
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </Form.Group>
                              </div>
                              <div className="box-grid-three">
                                <Form.Group
                                  className={`form-group ${
                                    error && error[`[${i}].childCitizenship`]
                                      ? 'error-msg'
                                      : ''
                                  }`}
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Citizenship</Form.Label>
                                  <Form.Select
                                    name="childCitizenship"
                                    disabled={
                                      childrenInfoDis ? childrenInfoDis : false
                                    }
                                    onChange={(e) => handleChange(e, i)}
                                  >
                                    <option
                                      value={
                                        child?.childCitizenship
                                          ? child?.childCitizenship
                                          : userGeoData?.country_name
                                      }
                                    >
                                      {child?.childCitizenship
                                        ? child?.childCitizenship
                                        : userGeoData?.country_name}
                                    </option>
                                    {countries &&
                                      countries?.length > 0 &&
                                      countries.map((el) => (
                                        <option value={el?.name}>
                                          {el?.name}
                                        </option>
                                      ))}
                                  </Form.Select>
                                  {/* {error && error[`[${i}].childCitizenship`] ? (
                                <span className="error-msg">
                                  {error[`[${i}].childCitizenship`]}
                                </span>
                              ) : (
                                ''
                              )} */}
                                </Form.Group>

                                <Form.Group
                                  className={`form-group ${
                                    error && error[`[${i}].childBirthCountry`]
                                      ? 'error-msg'
                                      : ''
                                  }`}
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Second Citizenship</Form.Label>
                                  <Form.Select
                                    name="childSecondCitizenship"
                                    disabled={
                                      childrenInfoDis ? childrenInfoDis : false
                                    }
                                    defaultValue={child?.childSecondCitizenship
                                      ? child?.childSecondCitizenship
                                      : ''}
                                    onChange={(e) => handleChange(e, i)}
                                  >
                                    <option value="">
                                      Select Country
                                    </option>
                                    {countries &&
                                      countries?.length > 0 &&
                                      countries.map((el) => (
                                        <option value={el?.name}>
                                          {el?.name}
                                        </option>
                                      ))}
                                  </Form.Select>
                                  {/* {error &&
                              error[`[${i}].childSecondCitizenship`] ? (
                                <span className="error-msg">
                                  {error[`[${i}].childSecondCitizenship`]}
                                </span>
                              ) : (
                                ''
                              )} */}
                                </Form.Group>
                                <Form.Group
                                  className={`form-group ${
                                    errors?.childrenInfo
                                    &&
                                    errors?.childrenInfo
                                    [`${i}`]
                                      ?.childMaritalStatus
                                      ? 'error-msg'
                                      : ''
                                  }`}
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Marital Status*</Form.Label>
                                  <Form.Select
                                  
                                    disabled={
                                      childrenInfoDis ? childrenInfoDis : false
                                    }
                                    name="childMaritalStatus"
                                    defaultValue={child?.childMaritalStatus
                                      ? child?.childMaritalStatus
                                      : ''}
                                    onChange={(e) => handleChange(e, i)}
                                  >
                                    <option value="">
                                      Select Status
                                    </option>
                                    <option value="Single">Single</option>
                                    <option value="Married">Married</option>
                                    <option value="Married">
                                      Common-Law Partnership{' '}
                                      <span>
                                        (you are living together for at least 1
                                        year)
                                      </span>
                                    </option>
                                    <option value="Legally Separated">
                                      Legally Separated
                                    </option>
                                    <option value="Divorced">Divorced</option>
                                    <option value="Widowed">Widowed</option>
                                  </Form.Select>
                                  {errors?.childrenInfo && errors?.childrenInfo
                                    [`${i}`]
                                      ?.childMaritalStatus ? (
                                    <span className="error-msg">
                                      {errors?.childrenInfo
                                    [`${i}`]
                                      ?.childMaritalStatus?.message}
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </Form.Group>
                              </div>
                              <div className="box-grid-three">
                                <Form.Group
                                  className={`form-group ${
                                    errors?.childrenInfo
                                    &&
                                    errors?.childrenInfo
                                    [`${i}`]
                                      ?.childOccupation
                                      ? 'error-msg'
                                      : ''
                                  }`}
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Occupation*</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="childOccupation"
                                    disabled={
                                      childrenInfoDis ? childrenInfoDis : false
                                    }
                                    defaultValue={child?.childOccupation}
                                    onChange={(e) => handleChange(e, i)}
                                  />
                                  {errors?.childrenInfo && errors?.childrenInfo
                                    [`${i}`]
                                      ?.childOccupation ? (
                                    <span className="error-msg">
                                      {errors?.childrenInfo
                                    [`${i}`]
                                      ?.childOccupation?.message}
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </Form.Group>
                                <Form.Group
                                  className={`form-group ${
                                    errors?.childrenInfo
                                    &&
                                    errors?.childrenInfo
                                    [`${i}`]
                                      ?.childResidentialAddress
                                      ? 'error-msg'
                                      : ''
                                  }`}
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>
                                    Residential Address (current)*
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="childResidentialAddress"
                                    disabled={
                                      childrenInfoDis ? childrenInfoDis : false
                                    }
                                    // {...register('childMiddleName', {
                                    //   required: 'Middle name is required',
                                    // })}
                                    defaultValue={
                                      child?.childResidentialAddress
                                    }
                                    onChange={(e) => handleChange(e, i)}
                                  />
                                  {errors?.childrenInfo && errors?.childrenInfo
                                    [`${i}`]
                                      ?.childResidentialAddress ? (
                                    <span className="error-msg">
                                      {errors?.childrenInfo
                                    [`${i}`]
                                      ?.childResidentialAddress?.message}
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </Form.Group>
                                <Form.Group
                                  className={`form-group ${
                                    error &&
                                    error[`[${i}].childEmailAddress`]
                                      ? 'error-msg'
                                      : ''
                                  }`}
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Email Address</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    name="childEmailAddress"
                                    disabled={
                                      childrenInfoDis ? childrenInfoDis : false
                                    }
                                    defaultValue={child?.childEmailAddress}
                                    onChange={(e) => handleChange(e, i)}
                                  />
                                  {/* {error && error[`[${i}].childLastName`] ? (
                                    <span className="error-msg">
                                      {error[`[${i}].childLastName`]}
                                    </span>
                                  ) : (
                                    ''
                                  )} */}
                                </Form.Group>
                              </div>
                              <div
                                className="add-btn"
                                disabled={
                                  childrenInfoDis ? childrenInfoDis : false
                                }
                              >
                                {i >= 0 &&
                                  (!childrenInfoDis ? (
                                    <Button
                                      className="delete-btn"
                                      disabled={
                                        childrenInfoDis
                                          ? childrenInfoDis
                                          : false
                                      }
                                      onClick={() => removeChild(i)}
                                    >
                                      {`Delete Child #${i + 1}`}{' '}
                                      <i class="las la-minus"></i>
                                    </Button>
                                  ) : (
                                    ''
                                  ))}
                                <Button
                                  onClick={handleAddChild}
                                  disabled={
                                    childrenInfoDis ? childrenInfoDis : false
                                  }
                                >
                                  Add <AddIcon />
                                </Button>
                              </div>
                            </duiv>
                          </Card.Body>
                        ))
                      : ''}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </div>
          ) : (
            ''
          )}

          {sponsorApplicant ? (
            <>
              <div
                className={`accordion-list ${
                  sponsoringFormState === 'open' ? 'change-accordion-icon' : ''
                }`}
              >
                <Card>
                  <Card.Header
                    className={customAccordBtn === '3' ? 'accord-open' : ''}
                    onClick={() =>
                      sponsoringFormState === 'open' || sponsorDis
                        ? handleClose('sponsoringFormState', 'person-3')
                        : ''
                    }
                  >
                    <div className="accordian-title">
                      {textSponsoringAccordion
                        ? textSponsoringAccordion
                        : 'Sponsoring Applicant'}
                      {sponsoringFormState === 'open' &&
                        showDeleteIcon &&
                        !sponsorDis && (
                          <div className="delete-btn">
                            <Button
                              onClick={() =>
                                handleDelete('sponsoringFormState', 'person-3')
                              }
                            >
                              Delete <i className="las la-minus"></i>
                            </Button>
                          </div>
                        )}
                    </div>

                    <CustomToggle eventKey="3">
                      {sponsoringFormState === 'plus' ? (
                        <img src={addIcon} alt="Add Icon" />
                      ) : sponsoringFormState === 'open' ? (
                        <img
                          className="arrow-icon"
                          src={
                            customAccordBtn !== '3' ? closeArrow : activeArrow
                          }
                          alt="Icon"
                        />
                      ) : (
                        ''
                      )}
                    </CustomToggle>
                  </Card.Header>
                  {/* {sponsoredApplicantFormState === 'open' && (
                  <div className="delete-btn">
                    <Button
                      onClick={() =>
                        handleDelete('sponsoredApplicantFormState')
                      }
                    >
                      Delete <i class="las la-minus"></i>
                    </Button>
                  </div>
                )} */}
                  <Accordion.Collapse eventKey="3">
                    <Card.Body>
                      <div className="box-grid-three">
                        <Form.Group
                          className={`form-group ${
                            errors?.sponsorInformation?.sponsorFirstName
                              ?.message
                              ? 'error-msg'
                              : ''
                          }`}
                          controlId="formBasicEmail"
                        >
                          <Form.Label>First Name*</Form.Label>
                          <Form.Control
                            type="text"
                            name="sponsorFirstName"
                            disabled={sponsorDis ? sponsorDis : false}
                            defaultValue={
                              personalInformation &&
                              personalInformation?.sponsoringInformation
                                ?.firstName
                            }
                            {...register('sponsorInformation.sponsorFirstName')}
                          />

                          {errors?.sponsorInformation?.sponsorFirstName ? (
                            <span className="error-msg">
                              {
                                errors?.sponsorInformation?.sponsorFirstName
                                  ?.message
                              }
                            </span>
                          ) : (
                            ''
                          )}
                        </Form.Group>
                        {/* <Work control={control} /> */}
                        <Form.Group
                          className={`form-group`}
                          controlId="formBasicEmail"
                        >
                          <Form.Label>Middle Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="sponsorMidName"
                            disabled={sponsorDis ? sponsorDis : false}
                            defaultValue={
                              personalInformation?.sponsoringInformation
                                ?.middleName
                                ? personalInformation?.sponsoringInformation
                                    ?.middleName
                                : ''
                            }
                            {...register('sponsorInformation.sponsorMidName')}
                          />
                          {errors?.sponsorMidName ? (
                            <span className="error-msg">
                              {errors?.sponsorMidName?.message}
                            </span>
                          ) : (
                            ''
                          )}
                        </Form.Group>
                        <Form.Group
                          className={`form-group ${
                            errors?.sponsorInformation?.sponsorLastName?.message
                              ? 'error-msg'
                              : ''
                          }`}
                          controlId="formBasicEmail"
                        >
                          <Form.Label>Last Name*</Form.Label>
                          <Form.Control
                            type="text"
                            name="sponsorLastName"
                            disabled={sponsorDis ? sponsorDis : false}
                            defaultValue={
                              personalInformation?.sponsoringInformation
                                ?.lastName
                                ? personalInformation?.sponsoringInformation
                                    ?.lastName
                                : ''
                            }
                            {...register('sponsorInformation.sponsorLastName')}
                          />
                          {errors?.sponsorInformation?.sponsorLastName ? (
                            <span className="error-msg">
                              {
                                errors?.sponsorInformation?.sponsorLastName
                                  ?.message
                              }
                            </span>
                          ) : (
                            ''
                          )}
                        </Form.Group>
                      </div>
                      <div className="box-grid-two">
                        <Form.Group
                          className={`form-group ${
                            errors?.sponsorInformation?.sponsorGender?.message
                              ? 'error-msg'
                              : ''
                          }`}
                          controlId="formBasicEmail"
                        >
                          <Form.Label>Gender*</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            name="sponsorGender"
                            disabled={sponsorDis ? sponsorDis : false}
                            defaultValue={
                              personalInformation?.sponsoringInformation
                                ? personalInformation?.sponsoringInformation
                                    ?.gender
                                : ''
                            }
                            {...register('sponsorInformation.sponsorGender')}
                          >
                            <option value="">
                              Select
                            </option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                          </Form.Select>
                          {errors?.sponsorInformation?.sponsorGender ? (
                            <span className="error-msg">
                              {
                                errors?.sponsorInformation?.sponsorGender
                                  ?.message
                              }
                            </span>
                          ) : (
                            ''
                          )}
                        </Form.Group>

                        <Form.Group
                          className={`form-group ${
                            errors?.sponsorInformation?.sponsorDateOfBirth
                              ? 'error-msg'
                              : ''
                          }`}
                          controlId="formBasicEmail"
                        >
                          <Form.Label>Date of Birth*</Form.Label>
                          <DatePicker
                            type="sponsorDateOfBirth"
                            name="sponsorDateOfBirth"
                            disabled={sponsorDis ? sponsorDis : false}
                            selected={sponsoredDateBirth}
                            placeholderText="Select Date"
                            onChange={(date) =>
                              changeDatePicker(
                                date,
                                'sponsorDateOfBirth',
                                'sponsoredAge',
                              )
                            }
                            className="form-select"
                            dateFormat="dd-MM-yyyy"
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                          />
                          {errors?.sponsorInformation?.sponsorDateOfBirth ? (
                            <span className="error-msg">
                              {
                                errors?.sponsorInformation?.sponsorDateOfBirth
                                  ?.message
                              }
                            </span>
                          ) : (
                            ''
                          )}
                        </Form.Group>
                      </div>
                      <div className="box-grid-three">
                        <Form.Group
                          className={`form-group ${
                            errors?.sponsorInformation?.sponsorPhoneNumber
                              ? 'error-msg'
                              : ''
                          }`}
                          controlId="formBasicEmail"
                        >
                          <Form.Label>Phone*</Form.Label>
                          {/* <Form.Control type="email" placeholder="" /> */}
                          <Form.Group>
                            <PhoneInput
                              country={
                                personalInformation?.sponsoringInformation
                                  ?.phoneNumber
                                  ? ''
                                  : userGeoData?.country_code?.toLowerCase()
                              }
                              disabled={sponsorDis ? sponsorDis : false}
                              value={
                                personalInformation?.sponsoringInformation
                                  ?.phoneNumber
                                  ? personalInformation?.sponsoringInformation?.phoneNumber?.substring(
                                      1,
                                    )
                                  : ''
                              }
                              onChange={(e) =>
                                setValue(
                                  'sponsorInformation.sponsorPhoneNumber',
                                  '+' + e,
                                )
                              }
                              inputProps={{
                                name: 'sponsorPhoneNumber',
                              }}
                            />
                            {errors?.sponsorInformation?.sponsorPhoneNumber ? (
                              <span className="error-msg">
                                {
                                  errors?.sponsorInformation?.sponsorPhoneNumber
                                    ?.message
                                }
                              </span>
                            ) : (
                              ''
                            )}
                          </Form.Group>
                        </Form.Group>
                        <Form.Group
                          className={`form-group`}
                          controlId="formBasicEmail"
                        >
                          <Form.Label>Mobile</Form.Label>
                          <PhoneInput
                            country={
                              personalInformation?.sponsoringInformation?.mobile
                                ? ''
                                : userGeoData?.country_code?.toLowerCase()
                            }
                            disabled={sponsorDis ? sponsorDis : false}
                            value={
                              personalInformation?.sponsoringInformation?.mobile
                                ? personalInformation?.sponsoringInformation?.mobile?.substring(
                                    1,
                                  )
                                : ''
                            }
                            onChange={(e) =>
                              setValue(
                                'sponsorInformation.sponsorMobile',
                                '+' + e,
                              )
                            }
                            inputProps={{
                              name: 'sponsorMobile',
                            }}
                          />
                          {errors?.sponsorMobile ? (
                            <span className="error-msg">
                              {errors?.sponsorMobile?.message}
                            </span>
                          ) : (
                            ''
                          )}
                        </Form.Group>
                        <Form.Group
                          className={`form-group`}
                          controlId="formBasicEmail"
                        >
                          <Form.Label>Office</Form.Label>
                          <PhoneInput
                            country={
                              personalInformation?.sponsoringInformation
                                ?.officePhoneNumber
                                ? ''
                                : userGeoData?.country_code?.toLowerCase()
                            }
                            disabled={sponsorDis ? sponsorDis : false}
                            value={
                              personalInformation?.sponsoringInformation
                                ?.officePhoneNumber
                                ? personalInformation?.sponsoringInformation?.officePhoneNumber?.substring(
                                    1,
                                  )
                                : ''
                            }
                            onChange={(e) =>
                              setValue(
                                'sponsorInformation.sponsorOfficePhoneNumber',
                                '+' + e,
                              )
                            }
                            inputProps={{
                              name: 'sponsorOfficePhoneNumber',
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className="box-grid-two">
                        <Form.Group
                          className={`form-group ${
                            errors?.sponsorInformation?.sponsorPrimaryEmail
                              ? 'error-msg'
                              : ''
                          }`}
                          controlId="formBasicEmail"
                        >
                          <Form.Label>Primary Email*</Form.Label>
                          <Form.Control
                            type="email"
                            name="sponsorPrimaryEmail"
                            disabled={sponsorDis ? sponsorDis : false}
                            defaultValue={
                              personalInformation?.sponsoringInformation
                                ?.primaryEmail
                                ? personalInformation?.sponsoringInformation
                                    ?.primaryEmail
                                : ''
                            }
                            {...register(
                              'sponsorInformation.sponsorPrimaryEmail',
                            )}
                          />
                          {/* <p>
                    <i class="las la-exclamation-triangle"></i>Please enter an
                    email address in the format{' '}
                    <span> username@domain.com</span>
                  </p> */}
                          {errors?.sponsorInformation?.sponsorPrimaryEmail ? (
                            <span className="error-msg">
                              <i class="las la-exclamation-triangle"></i>
                              {
                                errors?.sponsorInformation?.sponsorPrimaryEmail
                                  ?.message
                              }
                            </span>
                          ) : (
                            ''
                          )}
                        </Form.Group>

                        <Form.Group
                          className={`form-group`}
                          controlId="formBasicEmail"
                        >
                          <Form.Label>Secondary Email</Form.Label>
                          <Form.Control
                            type="email"
                            name="sponsorSecondaryEmail"
                            disabled={sponsorDis ? sponsorDis : false}
                            defaultValue={
                              personalInformation?.sponsoringInformation
                                ?.secondaryEmail
                            }
                            {...register(
                              'sponsorInformation.sponsorSecondaryEmail',
                            )}
                          />
                          {errors?.sponsorInformation?.secondaryEmail ? (
                            <span className="error-msg">
                              {
                                errors?.sponsorInformation?.secondaryEmail
                                  ?.message
                              }
                            </span>
                          ) : (
                            ''
                          )}
                        </Form.Group>
                      </div>
                      <div className="box-grid-three">
                        <Form.Group
                          className={`form-group ${
                            errors?.sponsorInformation?.sponsorBirthCountry
                              ? 'error-msg'
                              : ''
                          }`}
                          controlId="formBasicEmail"
                        >
                          <Form.Label>Birth Country*</Form.Label>
                          <Form.Select
                            name="sponsorBirthCountry"
                            disabled={sponsorDis ? sponsorDis : false}
                            {...register(
                              'sponsorInformation.sponsorBirthCountry',
                            )}
                          >
                            <option value={userGeoData?.country_name}>
                              {personalInformation?.sponsoringInformation
                                ?.birthCountry
                                ? personalInformation?.sponsoringInformation
                                    ?.birthCountry
                                : userGeoData?.country_name}
                            </option>
                            {countries &&
                              countries?.length > 0 &&
                              countries.map((el) => (
                                <option value={el?.name}>{el?.name}</option>
                              ))}
                          </Form.Select>
                          {errors?.sponsorInformation?.sponsorBirthCountry ? (
                            <span className="error-msg">
                              {
                                errors?.sponsorInformation?.sponsorBirthCountry
                                  ?.message
                              }
                            </span>
                          ) : (
                            ''
                          )}
                        </Form.Group>
                        <Form.Group
                          className={`form-group ${
                            errors?.sponsorInformation
                              ?.sponsorCountryCitizenship
                              ? 'error-msg'
                              : ''
                          }`}
                          controlId="formBasicEmail"
                        >
                          <Form.Label>Country of Citizenship*</Form.Label>

                          <Form.Select
                            //onChange={handleSelectChange}
                            disabled={sponsorDis ? sponsorDis : false}
                            name="sponsorCountryCitizenship"
                            {...register(
                              'sponsorInformation.sponsorCountryCitizenship',
                            )}
                          >
                            <option value={userGeoData?.country_name}>
                              {personalInformation?.sponsoringInformation
                                ?.countryCitizenship
                                ? personalInformation?.sponsoringInformation
                                    ?.countryCitizenship
                                : userGeoData?.country_name}
                            </option>
                            {countries &&
                              countries?.length > 0 &&
                              countries.map((el) => (
                                <option value={el?.name}>{el?.name}</option>
                              ))}
                          </Form.Select>
                          {errors?.sponsorInformation
                            ?.sponsorCountryCitizenship ? (
                            <span className="error-msg">
                              {
                                errors?.sponsorInformation
                                  ?.sponsorCountryCitizenship?.message
                              }
                            </span>
                          ) : (
                            ''
                          )}
                        </Form.Group>
                        <Form.Group
                          className={`form-group ${
                            errors?.sponsorInformation?.sponsorCountryResidence
                              ? 'error-msg'
                              : ''
                          }`}
                          controlId="formBasicEmail"
                        >
                          <Form.Label>Country of Residence*</Form.Label>
                          <Form.Select
                            //onChange={handleSelectChange}
                            disabled={sponsorDis ? sponsorDis : false}
                            name="sponsorCountryResidence"
                            {...register(
                              'sponsorInformation.sponsorCountryResidence',
                            )}
                          >
                            <option value={userGeoData?.country_name}>
                              {personalInformation?.sponsoringInformation
                                ?.countryResidence
                                ? personalInformation?.sponsoringInformation
                                    ?.countryResidence
                                : userGeoData?.country_name}
                            </option>
                            {countries &&
                              countries?.length > 0 &&
                              countries.map((el) => (
                                <option value={el?.name}>{el?.name}</option>
                              ))}
                          </Form.Select>
                          {errors?.sponsorInformation
                            ?.sponsorCountryResidence ? (
                            <span className="error-msg">
                              {
                                errors?.sponsorInformation
                                  ?.sponsorCountryResidence?.message
                              }
                            </span>
                          ) : (
                            ''
                          )}
                        </Form.Group>
                      </div>
                      <div className="box-grid-three">
                        <Form.Group
                          className={`form-group ${
                            errors?.sponsorInformation?.sponsorCity
                              ? 'error-msg'
                              : ''
                          }`}
                          controlId="formBasicEmail"
                        >
                          <Form.Label>City*</Form.Label>
                          <Form.Control
                            type="text"
                            name="sponsorCity"
                            disabled={sponsorDis ? sponsorDis : false}
                            defaultValue={
                              personalInformation?.sponsoringInformation?.city
                                ? personalInformation?.sponsoringInformation
                                    ?.city
                                : ''
                            }
                            {...register('sponsorInformation.sponsorCity')}
                          />
                          {errors?.sponsorInformation?.sponsorCity ? (
                            <span className="error-msg">
                              {errors?.sponsorInformation?.sponsorCity?.message}
                            </span>
                          ) : (
                            ''
                          )}
                        </Form.Group>
                        <Form.Group
                          className={`form-group ${
                            errors?.sponsorInformation?.sponsorStreet
                              ? 'error-msg'
                              : ''
                          }`}
                          controlId="formBasicEmail"
                        >
                          <Form.Label>Street*</Form.Label>
                          <Form.Control
                            type="text"
                            name="sponsorStreet"
                            disabled={sponsorDis ? sponsorDis : false}
                            defaultValue={
                              personalInformation?.sponsoringInformation?.street
                                ? personalInformation?.sponsoringInformation
                                    ?.street
                                : ''
                            }
                            {...register('sponsorInformation.sponsorStreet')}
                          />
                          {errors?.sponsorInformation?.sponsorStreet ? (
                            <span className="error-msg">
                              {
                                errors?.sponsorInformation?.sponsorStreet
                                  ?.message
                              }
                            </span>
                          ) : (
                            ''
                          )}
                        </Form.Group>
                        <Form.Group
                          className={`form-group ${
                            errors?.sponsorInformation?.sponsorZipCode
                              ? 'error-msg'
                              : ''
                          }`}
                          controlId="formBasicEmail"
                        >
                          <Form.Label>Zip Code / Postal Code*</Form.Label>
                          <Form.Control
                            type="number"
                            name="sponsorZipCode"
                            disabled={sponsorDis ? sponsorDis : false}
                            defaultValue={
                              personalInformation?.sponsoringInformation
                                ?.zipCode
                                ? personalInformation?.sponsoringInformation
                                    ?.zipCode
                                : ''
                            }
                            {...register('sponsorInformation.sponsorZipCode')}
                          />
                          {errors?.sponsorInformation?.sponsorZipCode ? (
                            <span className="error-msg">
                              {
                                errors?.sponsorInformation?.sponsorZipCode
                                  ?.message
                              }
                            </span>
                          ) : (
                            ''
                          )}
                        </Form.Group>
                      </div>
                      <div className="box-grid-two">
                        <Form.Group
                          className={`form-group ${
                            errors?.sponsorInformation?.sponsorMaritalStatus
                              ? 'error-msg'
                              : ''
                          }`}
                          controlId="formBasicEmail"
                        >
                          <Form.Label>Marital Status*</Form.Label>
                          <Form.Select
                            // aria-label="Default select example"
                            // onChange={handleSelectChange}
                            disabled={sponsorDis ? sponsorDis : false}
                            name="sponsorMaritalStatus"
                            defaultValue={personalInformation?.sponsoringInformation
                              ?.maritalStatus
                              ? personalInformation?.sponsoringInformation
                                  ?.maritalStatus
                              : ''}
                            {...register(
                              'sponsorInformation.sponsorMaritalStatus',
                            )}
                          >
                            <option value="">
                              Select Status
                            </option>
                            <option value="Single">Single</option>
                            <option value="Married">Married</option>
                            <option value="Married">
                              Common-Law Partnership{' '}
                              <span>
                                (you are living together for at least 1 year)
                              </span>
                            </option>
                            <option value="Legally Separated">
                              Legally Separated
                            </option>
                            <option value="Divorced">Divorced</option>
                            <option value="Widowed">Widowed</option>
                          </Form.Select>
                          {errors?.sponsorInformation?.sponsorMaritalStatus ? (
                            <span className="error-msg">
                              {
                                errors?.sponsorInformation?.sponsorMaritalStatus
                                  ?.message
                              }
                            </span>
                          ) : (
                            ''
                          )}
                        </Form.Group>
                        <Form.Group
                          className={`form-group ${
                            errors?.sponsorInformation?.sponsorDependentChildren
                              ? 'error-msg'
                              : ''
                          }`}
                          controlId="formBasicEmail"
                        >
                          <Form.Label>
                            Number of dependent children under 22
                          </Form.Label>
                          <Form.Control
                            type="text"
                            disabled={sponsorDis ? sponsorDis : false}
                            name="sponsorDependentChildren"
                            defaultValue={
                              personalInformation?.sponsoringInformation?.dependentChildren?.toString()
                                ? personalInformation?.sponsoringInformation?.dependentChildren?.toString()
                                : ''
                            }
                            {...register(
                              'sponsorInformation.sponsorDependentChildren',
                            )}
                          />

                          {errors?.sponsorInformation
                            ?.sponsorDependentChildren ? (
                            <span className="error-msg">
                              {
                                errors?.sponsorInformation
                                  ?.sponsorDependentChildren?.message
                              }
                            </span>
                          ) : (
                            ''
                          )}
                        </Form.Group>
                      </div>
                      <div className="box-grid-two">
                        <Form.Group
                          className={`form-group ${
                            errors?.sponsorInformation?.sponsorCriminalOffense
                              ? 'error-msg'
                              : ''
                          }`}
                          controlId="formBasicEmail"
                        >
                          <Form.Label>
                            Have you had any criminal offenses lodged against
                            you?*
                          </Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            name="sponsorCriminalOffense"
                            disabled={sponsorDis ? sponsorDis : false}
                            defaultValue={personalInformation?.sponsoringInformation
                              ?.criminalOffense
                              ? personalInformation?.sponsoringInformation
                                  ?.criminalOffense
                              : ''}
                            {...register(
                              'sponsorInformation.sponsorCriminalOffense',
                            )}
                          >
                            <option value="">
                              Select Criminal offense
                            </option>
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                          </Form.Select>
                          {errors?.sponsorInformation
                            ?.sponsorCriminalOffense ? (
                            <span className="error-msg">
                              {
                                errors?.sponsorInformation
                                  ?.sponsorCriminalOffense?.message
                              }
                            </span>
                          ) : (
                            ''
                          )}
                        </Form.Group>
                        {sponsorCriminalOffense === 'Yes' ||
                        personalInformation?.sponsoringInformation
                          ?.criminalOffense === 'Yes' ? (
                          <Form.Group
                            className={`form-group ${
                              errors?.sponsorInformation
                                ?.sponsorOffenseTypeDetail?.message
                                ? 'error-msg'
                                : ''
                            }`}
                            controlId="formBasicEmail"
                          >
                            <Form.Label>
                              Please describe your criminal offenses from the
                              past 10 years
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="sponsorOffenseTypeDetail"
                              disabled={sponsorDis ? sponsorDis : false}
                              defaultValue={
                                personalInformation?.sponsoringInformation
                                  ?.offenseTypeDetail
                                  ? personalInformation?.sponsoringInformation
                                      ?.offenseTypeDetail
                                  : ''
                              }
                              {...register(
                                'sponsorInformation.sponsorOffenseTypeDetail',
                              )}
                            />
                            {errors?.sponsorInformation
                              ?.sponsorOffenseTypeDetail ? (
                              <span className="error-msg">
                                {
                                  errors?.sponsorInformation
                                    ?.sponsorOffenseTypeDetail?.message
                                }
                              </span>
                            ) : (
                              ''
                            )}
                          </Form.Group>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className="box-grid-two">
                        <Form.Group
                          className={`form-group ${
                            errors?.sponsorInformation?.sponsorHealthCondition
                              ? 'error-msg'
                              : ''
                          }`}
                          controlId="formBasicEmail"
                        >
                          <Form.Label>Health Problems*</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            name="sponsorHealthCondition"
                            disabled={sponsorDis ? sponsorDis : false}
                            defaultValue={personalInformation?.sponsoringInformation
                              ?.healthCondition
                              ? personalInformation?.sponsoringInformation
                                  ?.healthCondition
                              : ''}
                            {...register(
                              'sponsorInformation.sponsorHealthCondition',
                            )}
                          >
                            <option value="">
                              Select health condition
                            </option>
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                          </Form.Select>
                          {errors?.sponsorInformation
                            ?.sponsorHealthCondition ? (
                            <span className="error-msg">
                              {
                                errors?.sponsorInformation
                                  ?.sponsorHealthCondition?.message
                              }
                            </span>
                          ) : (
                            ''
                          )}
                        </Form.Group>
                        {showHelthCondetionDetail === 'Yes' ||
                        personalInformation?.sponsoringInformation
                          ?.healthCondition === 'Yes' ? (
                          <Form.Group
                            className={`form-group ${
                              errors?.sponsorInformation
                                ?.sponsorHealthConditionDetail
                                ? 'error-msg'
                                : ''
                            }`}
                            controlId="formBasicEmail"
                          >
                            <Form.Label>
                              Please describe your health condition/s
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="sponsorHealthConditionDetail"
                              disabled={sponsorDis ? sponsorDis : false}
                              defaultValue={
                                personalInformation?.sponsoringInformation
                                  ?.healthConditionDetail
                                  ? personalInformation?.sponsoringInformation
                                      ?.healthConditionDetail
                                  : ''
                              }
                              {...register(
                                'sponsorInformation.sponsorHealthConditionDetail',
                              )}
                            />
                            {errors?.sponsorInformation
                              ?.sponsorHealthConditionDetail ? (
                              <span className="error-msg">
                                {
                                  errors?.sponsorInformation
                                    ?.sponsorHealthConditionDetail?.message
                                }
                              </span>
                            ) : (
                              ''
                            )}
                          </Form.Group>
                        ) : (
                          ''
                        )}
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </div>
              {selectedVisa?.visaName === 'Parent Sponsorship Visa' &&
              showChildrenTab ? (
                <div
                  className={`accordion-list ${
                    sponsorChildrenFormState === 'open'
                      ? 'change-accordion-icon'
                      : ''
                  }`}
                >
                  <Card>
                    <Card.Header
                      className={customAccordBtn === '5' ? 'accord-open' : ''}
                      onClick={() =>
                        sponsorChildrenFormState === 'open' || sponsorDis
                          ? handleClose('sponsorChildrenFormState', 'person-5')
                          : ''
                      }
                    >
                      <div className="accordian-title">
                        {textChildInfo
                          ? textChildInfo
                          : 'Add Children Information'}
                        {sponsorChildrenFormState === 'open' &&
                          selectedVisa?.visaName !==
                            'Parent Sponsorship Visa' && (
                            <div className="delete-btn">
                              <Button
                                onClick={() =>
                                  handleDelete(
                                    'sponsorChildrenFormState',
                                    'person-5',
                                  )
                                }
                              >
                                Delete <i className="las la-minus"></i>
                              </Button>
                            </div>
                          )}
                      </div>
                      <CustomToggle eventKey="5">
                        {sponsorChildrenFormState === 'plus' && !sponsorDis ? (
                          <img src={addIcon} alt="Add Icon" />
                        ) : (sponsorChildrenFormState === 'open' &&
                            !sponsorDis) ||
                          (sponsorChildrenFormState === 'plus' &&
                            !sponsorDis) ||
                          (sponsorChildrenFormState === 'open' && sponsorDis) ||
                          (sponsorChildrenFormState === 'plus' &&
                            sponsorDis) ? (
                          <img
                            className="arrow-icon"
                            src={
                              customAccordBtn !== '5' ? closeArrow : activeArrow
                            }
                            alt="Icon"
                          />
                        ) : (
                          ''
                        )}
                      </CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="5">
                      <Card.Body className="accord-cardbody-scnd">
                        {sponsorChilds && sponsorChilds?.length
                          ? sponsorChilds?.map((child, i) => (
                              <Card.Body key={i}>
                                <h6 className="child-heading">
                                  Child #{i + 1}
                                </h6>
                                <div className="child-info">
                                  <div className="box-grid-three">
                                    <Form.Group
                                      className={`form-group ${
                                        errors?.sponsorChildrenInfo &&
                                        errors?.sponsorChildrenInfo[`${i}`]
                                          ?.parentFullName
                                          ? 'error-msg'
                                          : ''
                                      }`}
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>
                                        Full Name (as appears in the passport)*
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="parentFullName"
                                        disabled={
                                          sponsorDis ? sponsorDis : false
                                        }
                                        defaultValue={child?.parentFullName}
                                        onChange={(e) =>
                                          handleSponsoredChange(e, i)
                                        }
                                      />
                                      {errors?.sponsorChildrenInfo &&
                                      errors?.sponsorChildrenInfo[`${i}`]
                                        ?.parentFullName ? (
                                        <span className="error-msg">
                                          {
                                            errors?.sponsorChildrenInfo[`${i}`]
                                              ?.parentFullName?.message
                                          }
                                        </span>
                                      ) : (
                                        ''
                                      )}
                                    </Form.Group>
                                    <Form.Group
                                      className={`form-group ${
                                        errors?.sponsorChildrenInfo &&
                                        errors?.sponsorChildrenInfo[`${i}`]
                                          ?.parentRelation
                                          ? 'error-msg'
                                          : ''
                                      }`}
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>Relation*</Form.Label>
                                      <Form.Select
                                        aria-label="Default select example"
                                        name="parentRelation"
                                        disabled={
                                          sponsorDis ? sponsorDis : false
                                        }
                                        defaultValue={child?.parentRelation
                                          ? child?.parentRelation
                                          : ''}
                                        onChange={(e) =>
                                          handleSponsoredChange(e, i)
                                        }
                                      >
                                        <option value="">
                                          Select Relation
                                        </option>
                                        <option value="mother">Mother</option>
                                        <option value="father">Father</option>
                                      </Form.Select>
                                      {errors?.sponsorChildrenInfo &&
                                      errors?.sponsorChildrenInfo[`${i}`]
                                        ?.parentRelation ? (
                                        <span className="error-msg">
                                          {
                                            errors?.sponsorChildrenInfo[`${i}`]
                                              ?.parentRelation?.message
                                          }
                                        </span>
                                      ) : (
                                        ''
                                      )}
                                    </Form.Group>
                                    <Form.Group
                                      className={`form-group ${
                                        errors?.sponsorChildrenInfo &&
                                        errors?.sponsorChildrenInfo[`${i}`]
                                          ?.parentGender
                                          ? 'error-msg'
                                          : ''
                                      }`}
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>Gender*</Form.Label>
                                      <Form.Select
                                        aria-label="Default select example"
                                        name="parentGender"
                                        disabled={
                                          childrenInfoDis
                                            ? childrenInfoDis
                                            : false
                                        }
                                        defaultValue={child?.parentGender
                                          ? child?.parentGender
                                          : ''}
                                        onChange={(e) =>
                                          handleSponsoredChange(e, i)
                                        }
                                      >
                                        <option value="">
                                          Select Gender
                                        </option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                      </Form.Select>
                                      {errors?.sponsorChildrenInfo &&
                                      errors?.sponsorChildrenInfo[`${i}`]
                                        ?.parentGender ? (
                                        <span className="error-msg">
                                          {
                                            errors?.sponsorChildrenInfo[`${i}`]
                                              ?.parentGender?.message
                                          }
                                        </span>
                                      ) : (
                                        ''
                                      )}
                                    </Form.Group>
                                  </div>
                                  <div className="box-grid-three">
                                    <Form.Group
                                      className={`form-group ${
                                        errors?.sponsorChildrenInfo &&
                                        errors?.sponsorChildrenInfo[`${i}`]
                                          ?.parentDateOfBirth
                                          ? 'error-msg'
                                          : ''
                                      }`}
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>Date of Birth*</Form.Label>
                                      <DatePicker
                                        type="parentDateOfBirth"
                                        name="parentDateOfBirth"
                                        placeholderText="Select Date"
                                        disabled={
                                          sponsorDis ? sponsorDis : false
                                        }
                                        selected={
                                          child?.parentDateOfBirth
                                            ? new Date(child?.parentDateOfBirth)
                                            : new Date()
                                        }
                                        onChange={(date) =>
                                          handleSponsorChildDatePicker(
                                            date,
                                            'parentDateOfBirth',
                                            i,
                                          )
                                        }
                                        className="form-select"
                                        dateFormat="dd-MM-yyyy"
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                      />

                                      {errors?.sponsorChildrenInfo &&
                                      errors?.sponsorChildrenInfo[`${i}`]
                                        ?.parentDateOfBirth ? (
                                        <span className="error-msg">
                                          {
                                            errors?.sponsorChildrenInfo[`${i}`]
                                              ?.parentDateOfBirth?.message
                                          }
                                        </span>
                                      ) : (
                                        ''
                                      )}
                                    </Form.Group>
                                    <Form.Group
                                      className={`form-group ${
                                        errors?.sponsorChildrenInfo &&
                                        errors?.sponsorChildrenInfo[`${i}`]
                                          ?.parentBirthCountry
                                          ? 'error-msg'
                                          : ''
                                      }`}
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>Birth Country*</Form.Label>
                                      <Form.Select
                                        disabled={
                                          sponsorDis ? sponsorDis : false
                                        }
                                        name="parentBirthCountry"
                                        defaultValue={
                                          child?.parentBirthCountry
                                            ? child?.parentBirthCountry
                                            : userGeoData?.country_name
                                        }
                                        onChange={(e) =>
                                          handleSponsoredChange(e, i)
                                        }
                                      >
                                        <option
                                          value={userGeoData?.country_name}
                                        >
                                          {child?.parentBirthCountry
                                            ? child?.parentBirthCountry
                                            : userGeoData?.country_name}
                                        </option>
                                        {countries &&
                                          countries?.length > 0 &&
                                          countries.map((el) => (
                                            <option value={el?.name}>
                                              {el?.name}
                                            </option>
                                          ))}
                                      </Form.Select>
                                      {errors?.sponsorChildrenInfo &&
                                      errors?.sponsorChildrenInfo[`${i}`]
                                        ?.parentBirthCountry ? (
                                        <span className="error-msg">
                                          {
                                            errors?.sponsorChildrenInfo[`${i}`]
                                              ?.parentBirthCountry?.message
                                          }
                                        </span>
                                      ) : (
                                        ''
                                      )}
                                    </Form.Group>

                                    <Form.Group
                                      className={`form-group ${
                                        errors?.sponsorChildrenInfo &&
                                        errors?.sponsorChildrenInfo[`${i}`]
                                          ?.parentBirthCity
                                          ? 'error-msg'
                                          : ''
                                      }`}
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>Birth City*</Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder=""
                                        name="parentBirthCity"
                                        disabled={
                                          sponsorDis ? sponsorDis : false
                                        }
                                        onChange={(e) =>
                                          handleSponsoredChange(e, i)
                                        }
                                        defaultValue={child?.parentBirthCity}
                                      />
                                      {errors?.sponsorChildrenInfo &&
                                      errors?.sponsorChildrenInfo[`${i}`]
                                        ?.parentBirthCity ? (
                                        <span className="error-msg">
                                          {
                                            errors?.sponsorChildrenInfo[`${i}`]
                                              ?.parentBirthCity?.message
                                          }
                                        </span>
                                      ) : (
                                        ''
                                      )}
                                    </Form.Group>
                                  </div>
                                  <div className="box-grid-three">
                                    <Form.Group
                                      className={`form-group ${
                                        errors?.sponsorChildrenInfo &&
                                        errors?.sponsorChildrenInfo[`${i}`]
                                          ?.parentDeceased
                                          ? 'error-msg'
                                          : ''
                                      }`}
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>
                                        If deceased, what is the date of
                                        passing? (date)*
                                      </Form.Label>
                                      <DatePicker
                                        type="parentDeceased"
                                        name="parentDeceased"
                                        placeholderText="Select Date"
                                        disabled={
                                          sponsorDis ? sponsorDis : false
                                        }
                                        selected={
                                          child?.parentDeceased
                                            ? new Date(child?.parentDeceased)
                                            : new Date()
                                        }
                                        onChange={(date) =>
                                          handleSponsorChildDatePicker(
                                            date,
                                            'parentDeceased',
                                            i,
                                          )
                                        }
                                        className="form-select"
                                        dateFormat="dd-MM-yyyy"
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                      />

                                      {errors?.sponsorChildrenInfo &&
                                      errors?.sponsorChildrenInfo[`${i}`]
                                        ?.parentDeceased ? (
                                        <span className="error-msg">
                                          {
                                            errors?.sponsorChildrenInfo[`${i}`]
                                              ?.parentDeceased?.message
                                          }
                                        </span>
                                      ) : (
                                        ''
                                      )}
                                    </Form.Group>
                                    <Form.Group
                                      className={`form-group ${
                                        errors?.sponsorChildrenInfo &&
                                        errors?.sponsorChildrenInfo[`${i}`]
                                          ?.parentResidentialAddress
                                          ? 'error-msg'
                                          : ''
                                      }`}
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>
                                        Residential Address(current)*
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="parentResidentialAddress"
                                        defaultValue={
                                          child?.parentResidentialAddress
                                        }
                                        disabled={
                                          sponsorDis ? sponsorDis : false
                                        }
                                        onChange={(e) =>
                                          handleSponsoredChange(e, i)
                                        }
                                      />
                                      {errors?.sponsorChildrenInfo &&
                                      errors?.sponsorChildrenInfo[`${i}`]
                                        ?.parentResidentialAddress ? (
                                        <span className="error-msg">
                                          {
                                            errors?.sponsorChildrenInfo[`${i}`]
                                              ?.parentResidentialAddress
                                              ?.message
                                          }
                                        </span>
                                      ) : (
                                        ''
                                      )}
                                    </Form.Group>

                                    <Form.Group
                                      className={`form-group ${
                                        errors?.sponsorChildrenInfo &&
                                        errors?.sponsorChildrenInfo[`${i}`]
                                          ?.parentMaritalStatus
                                          ? 'error-msg'
                                          : ''
                                      }`}
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>Marital Status*</Form.Label>
                                      <Form.Select
                                        disabled={
                                          mainApplicanDis
                                            ? mainApplicanDis
                                            : false
                                        }
                                        name="parentMaritalStatus"
                                        defaultValue={child?.parentMaritalStatus
                                          ? child?.parentMaritalStatus
                                          : ''}
                                        onChange={(e) =>
                                          handleSponsoredChange(e, i)
                                        }
                                      >
                                        <option value="">
                                          Select Status
                                        </option>
                                        <option value="Single">Single</option>
                                        <option value="Married">Married</option>
                                        <option value="Married">
                                          Common-Law Partnership{' '}
                                          <span>
                                            (you are living together for at
                                            least 1 year)
                                          </span>
                                        </option>
                                        <option value="Legally Separated">
                                          Legally Separated
                                        </option>
                                        <option value="Divorced">
                                          Divorced
                                        </option>
                                        <option value="Widowed">Widowed</option>
                                      </Form.Select>
                                      {errors?.sponsorChildrenInfo &&
                                      errors?.sponsorChildrenInfo[`${i}`]
                                        ?.parentMaritalStatus ? (
                                        <span className="error-msg">
                                          {
                                            errors?.sponsorChildrenInfo[`${i}`]
                                              ?.parentMaritalStatus?.message
                                          }
                                        </span>
                                      ) : (
                                        ''
                                      )}
                                    </Form.Group>
                                  </div>
                                  <div className="box-grid-two">
                                    <Form.Group
                                      className={`form-group ${
                                        errors?.sponsorChildrenInfo &&
                                        errors?.sponsorChildrenInfo[`${i}`]
                                          ?.parentEmailAddress
                                          ? 'error-msg'
                                          : ''
                                      }`}
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>Email Address*</Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="parentEmailAddress"
                                        disabled={
                                          sponsorDis ? sponsorDis : false
                                        }
                                        defaultValue={child?.parentEmailAddress}
                                        onChange={(e) =>
                                          handleSponsoredChange(e, i)
                                        }
                                      />
                                      {errors?.sponsorChildrenInfo &&
                                      errors?.sponsorChildrenInfo[`${i}`]
                                        ?.parentEmailAddress ? (
                                        <span className="error-msg">
                                          {
                                            errors?.sponsorChildrenInfo[`${i}`]
                                              ?.parentEmailAddress?.message
                                          }
                                        </span>
                                      ) : (
                                        ''
                                      )}
                                    </Form.Group>
                                    <Form.Group
                                      className={`form-group ${
                                        errors?.sponsorChildrenInfo &&
                                        errors?.sponsorChildrenInfo[`${i}`]
                                          ?.parentOccupation
                                          ? 'error-msg'
                                          : ''
                                      }`}
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>Occupation*</Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="parentOccupation"
                                        disabled={
                                          sponsorDis ? sponsorDis : false
                                        }
                                        onChange={(e) =>
                                          handleSponsoredChange(e, i)
                                        }
                                        defaultValue={
                                          child?.parentOccupation
                                            ? child?.parentOccupation
                                            : ''
                                        }
                                      />

                                      {errors?.sponsorChildrenInfo &&
                                      errors?.sponsorChildrenInfo[`${i}`]
                                        ?.parentOccupation ? (
                                        <span className="error-msg">
                                          {
                                            errors?.sponsorChildrenInfo[`${i}`]
                                              ?.parentOccupation?.message
                                          }
                                        </span>
                                      ) : (
                                        ''
                                      )}
                                    </Form.Group>
                                  </div>
                                  <div
                                    className="add-btn"
                                    disabled={sponsorDis ? sponsorDis : false}
                                  >
                                    {i >= 1 &&
                                      (!sponsorDis ? (
                                        <Button
                                          className="delete-btn"
                                          disabled={
                                            sponsorDis ? sponsorDis : false
                                          }
                                          onClick={() =>
                                            removeSponsoredChild(i)
                                          }
                                        >
                                          {`Delete parent #${i + 1}`}{' '}
                                          <i class="las la-minus"></i>
                                        </Button>
                                      ) : (
                                        ''
                                      ))}
                                    <Button
                                      onClick={handleAddSponsorChild}
                                      disabled={sponsorDis ? sponsorDis : false}
                                    >
                                      Add <AddIcon />
                                    </Button>
                                  </div>
                                </div>
                              </Card.Body>
                            ))
                          : ''}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </div>
              ) : (
                ''
              )}
            </>
          ) : (
            ''
          )}
          {isParentShow ? (
            <div
              className={`accordion-list ${
                parentFormState === 'open' ? 'change-accordion-icon' : ''
              }`}
            >
              <Card>
                <Card.Header
                  className={customAccordBtn === '6' ? 'accord-open' : ''}
                  onClick={() =>
                    parentFormState === 'open' || parentDis
                      ? handleClose('parentFormState', 'person-6')
                      : ''
                  }
                >
                  <div className="accordian-title">
                    {parentFormState === 'open' || parentDis
                      ? 'Parents Information'
                      : 'Add Parents Information'}
                    {parentFormState === 'open' && !parentDis && (
                      <div className="delete-btn">
                        <Button
                          onClick={() =>
                            handleDelete('parentFormState', 'person-6')
                          }
                        >
                          Delete <i className="las la-minus"></i>
                        </Button>
                      </div>
                    )}
                  </div>
                  <CustomToggle eventKey="6">
                    {parentFormState === 'plus' && !parentDis ? (
                      <img src={addIcon} alt="Add Icon" />
                    ) : (parentFormState === 'open' && !parentDis) ||
                      (parentFormState === 'open' && parentDis) ||
                      (parentFormState === 'plus' && parentDis) ||
                      (parentFormState === 'plus' && !parentDis) ? (
                      <img
                        className="arrow-icon"
                        src={customAccordBtn !== '6' ? closeArrow : activeArrow}
                        alt="Icon"
                      />
                    ) : (
                      ''
                    )}
                  </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="6">
                  <Card.Body className="accord-cardbody-scnd">
                    {parentDetail && parentDetail?.length
                      ? parentDetail?.map((parent, i) => (
                          <Card.Body key={i}>
                            <h6 className="child-heading">Parent #{i + 1}</h6>
                            <div className="child-info">
                              <div className="box-grid-three">
                                <Form.Group
                                  className={`form-group ${
                                    errors?.parentInformation &&
                                    errors?.parentInformation[`${i}`]
                                      ?.parentFullName
                                      ? 'error-msg'
                                      : ''
                                  }`}
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>
                                    Full Name (as appears in the passport)*
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="parentFullName"
                                    disabled={parentDis ? parentDis : false}
                                    defaultValue={parent?.parentFullName}
                                    onChange={(e) => handleParentChange(e, i)}
                                  />
                                  {errors?.parentInformation &&
                                  errors?.parentInformation[`${i}`]
                                    ?.parentFullName ? (
                                    <span className="error-msg">
                                      {
                                        errors?.parentInformation[`${i}`]
                                          ?.parentFullName?.message
                                      }
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </Form.Group>
                                <Form.Group
                                  className={`form-group ${
                                    errors?.parentInformation &&
                                    errors?.parentInformation[`${i}`]
                                      ?.parentRelation
                                      ? 'error-msg'
                                      : ''
                                  }`}
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Relation*</Form.Label>
                                  <Form.Select
                                    aria-label="Default select example"
                                    name="parentRelation"
                                    disabled={parentDis ? parentDis : false}
                                    defaultValue={parent?.parentRelation
                                      ? parent?.parentRelation
                                      : ''}
                                    onChange={(e) => handleParentChange(e, i)}
                                  >
                                    <option value="">
                                      Select Relation
                                    </option>
                                    <option value="mother">Mother</option>
                                    <option value="father">Father</option>
                                  </Form.Select>
                                  {errors?.parentInformation &&
                                  errors?.parentInformation[`${i}`]
                                    ?.parentRelation ? (
                                    <span className="error-msg">
                                      {
                                        errors?.parentInformation[`${i}`]
                                          ?.parentRelation?.message
                                      }
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </Form.Group>
                                <Form.Group
                                  className={`form-group ${
                                    errors?.parentInformation &&
                                    errors?.parentInformation[`${i}`]
                                      ?.parentGender
                                      ? 'error-msg'
                                      : ''
                                  }`}
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Gender*</Form.Label>
                                  <Form.Select
                                    aria-label="Default select example"
                                    name="parentGender"
                                    disabled={
                                      childrenInfoDis ? childrenInfoDis : false
                                    }
                                    defaultValue={parent?.parentGender
                                      ? parent?.parentGender
                                      : ''}
                                    onChange={(e) => handleChange(e, i)}
                                  >
                                    <option value="">
                                      Select Gender
                                    </option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                  </Form.Select>
                                  {errors?.parentInformation &&
                                  errors?.parentInformation[`${i}`]
                                    ?.parentGender ? (
                                    <span className="error-msg">
                                      {
                                        errors?.parentInformation[`${i}`]
                                          ?.parentGender?.message
                                      }
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </Form.Group>
                              </div>
                              <div className="box-grid-three">
                                <Form.Group
                                  className={`form-group ${
                                    errors?.parentInformation &&
                                    errors?.parentInformation[`${i}`]
                                      ?.parentDateOfBirth
                                      ? 'error-msg'
                                      : ''
                                  }`}
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Date of Birth*</Form.Label>
                                  <DatePicker
                                    type="parentDateOfBirth"
                                    name="parentDateOfBirth"
                                    placeholderText="Select Date"
                                    disabled={parentDis ? parentDis : false}
                                    selected={
                                      parent?.parentDateOfBirth
                                        ? new Date(parent?.parentDateOfBirth)
                                        : new Date()
                                    }
                                    onChange={(date) =>
                                      handleParentDatePicker(
                                        date,
                                        'parentDateOfBirth',
                                        i,
                                      )
                                    }
                                    className="form-select"
                                    dateFormat="dd-MM-yyyy"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                  />

                                  {errors?.parentInformation &&
                                  errors?.parentInformation[`${i}`]
                                    ?.parentDateOfBirth ? (
                                    <span className="error-msg">
                                      {
                                        errors?.parentInformation[`${i}`]
                                          ?.parentDateOfBirth?.message
                                      }
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </Form.Group>
                                <Form.Group
                                  className={`form-group ${
                                    errors?.parentInformation &&
                                    errors?.parentInformation[`${i}`]
                                      ?.parentBirthCountry
                                      ? 'error-msg'
                                      : ''
                                  }`}
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Birth Country*</Form.Label>
                                  <Form.Select
                                    disabled={parentDis ? parentDis : false}
                                    name="parentBirthCountry"
                                    defaultValue={
                                      parent?.parentBirthCountry
                                        ? parent?.parentBirthCountry
                                        : userGeoData?.country_name
                                    }
                                    onChange={(e) => handleChange(e, i)}
                                  >
                                    <option value={userGeoData?.country_name}>
                                      {parent?.parentBirthCountry
                                        ? parent?.parentBirthCountry
                                        : userGeoData?.country_name}
                                    </option>
                                    {countries &&
                                      countries?.length > 0 &&
                                      countries.map((el) => (
                                        <option value={el?.name}>
                                          {el?.name}
                                        </option>
                                      ))}
                                  </Form.Select>
                                  {errors?.parentInformation &&
                                  errors?.parentInformation[`${i}`]
                                    ?.parentBirthCountry ? (
                                    <span className="error-msg">
                                      {
                                        errors?.parentInformation[`${i}`]
                                          ?.parentBirthCountry?.message
                                      }
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </Form.Group>

                                <Form.Group
                                  className={`form-group ${
                                    errors?.parentInformation &&
                                    errors?.parentInformation[`${i}`]
                                      ?.parentBirthCity
                                      ? 'error-msg'
                                      : ''
                                  }`}
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Birth City*</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    name="parentBirthCity"
                                    disabled={parentDis ? parentDis : false}
                                    onChange={(e) => handleParentChange(e, i)}
                                    defaultValue={parent?.parentBirthCity}
                                  />
                                  {errors?.parentInformation &&
                                  errors?.parentInformation[`${i}`]
                                    ?.parentBirthCity ? (
                                    <span className="error-msg">
                                      {
                                        errors?.parentInformation[`${i}`]
                                          ?.parentBirthCity?.message
                                      }
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </Form.Group>
                              </div>
                              <div className="box-grid-three">
                                <Form.Group
                                  className={`form-group ${
                                    errors?.parentInformation &&
                                    errors?.parentInformation[`${i}`]
                                      ?.parentDeceased
                                      ? 'error-msg'
                                      : ''
                                  }`}
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>
                                    If deceased, what is the date of passing?
                                    (date)*
                                  </Form.Label>
                                  <DatePicker
                                    type="parentDeceased"
                                    name="parentDeceased"
                                    placeholderText="Select Date"
                                    disabled={parentDis ? parentDis : false}
                                    selected={
                                      parent?.parentDeceased
                                        ? new Date(parent?.parentDeceased)
                                        : new Date()
                                    }
                                    onChange={(date) =>
                                      handleParentDatePicker(
                                        date,
                                        'parentDeceased',
                                        i,
                                      )
                                    }
                                    className="form-select"
                                    dateFormat="dd-MM-yyyy"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                  />

                                  {errors?.parentInformation &&
                                  errors?.parentInformation[`${i}`]
                                    ?.parentDeceased ? (
                                    <span className="error-msg">
                                      {
                                        errors?.parentInformation[`${i}`]
                                          ?.parentDeceased?.message
                                      }
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </Form.Group>
                                <Form.Group
                                  className={`form-group ${
                                    errors?.parentInformation &&
                                    errors?.parentInformation[`${i}`]
                                      ?.parentResidentialAddress
                                      ? 'error-msg'
                                      : ''
                                  }`}
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>
                                    Residential Address(current)*
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="parentResidentialAddress"
                                    defaultValue={
                                      parent?.parentResidentialAddress
                                    }
                                    disabled={parentDis ? parentDis : false}
                                    onChange={(e) => handleParentChange(e, i)}
                                  />
                                  {errors?.parentInformation &&
                                  errors?.parentInformation[`${i}`]
                                    ?.parentResidentialAddress ? (
                                    <span className="error-msg">
                                      {
                                        errors?.parentInformation[`${i}`]
                                          ?.parentResidentialAddress?.message
                                      }
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </Form.Group>

                                <Form.Group
                                  className={`form-group ${
                                    errors?.parentInformation &&
                                    errors?.parentInformation[`${i}`]
                                      ?.parentMaritalStatus
                                      ? 'error-msg'
                                      : ''
                                  }`}
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Marital Status*</Form.Label>
                                  <Form.Select
                                    disabled={
                                      mainApplicanDis ? mainApplicanDis : false
                                    }
                                    name="parentMaritalStatus"
                                    defaultValue={parent?.parentMaritalStatus
                                      ? parent?.parentMaritalStatus
                                      : ''}
                                    onChange={(e) => handleParentChange(e, i)}
                                  >
                                    <option value="">
                                      Select Status
                                    </option>
                                    <option value="Single">Single</option>
                                    <option value="Married">Married</option>
                                    <option value="Married">
                                      Common-Law Partnership{' '}
                                      <span>
                                        (you are living together for at least 1
                                        year)
                                      </span>
                                    </option>
                                    <option value="Legally Separated">
                                      Legally Separated
                                    </option>
                                    <option value="Divorced">Divorced</option>
                                    <option value="Widowed">Widowed</option>
                                  </Form.Select>
                                  {errors?.parentInformation &&
                                  errors?.parentInformation[`${i}`]
                                    ?.parentMaritalStatus ? (
                                    <span className="error-msg">
                                      {
                                        errors?.parentInformation[`${i}`]
                                          ?.parentMaritalStatus?.message
                                      }
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </Form.Group>
                              </div>
                              <div className="box-grid-two">
                                <Form.Group
                                  className={`form-group ${
                                    errors?.parentInformation &&
                                    errors?.parentInformation[`${i}`]
                                      ?.parentEmailAddress
                                      ? 'error-msg'
                                      : ''
                                  }`}
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Email Address*</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="parentEmailAddress"
                                    disabled={parentDis ? parentDis : false}
                                    defaultValue={parent?.parentEmailAddress}
                                    onChange={(e) => handleParentChange(e, i)}
                                  />
                                  {errors?.parentInformation &&
                                  errors?.parentInformation[`${i}`]
                                    ?.parentEmailAddress ? (
                                    <span className="error-msg">
                                      {
                                        errors?.parentInformation[`${i}`]
                                          ?.parentEmailAddress?.message
                                      }
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </Form.Group>
                                <Form.Group
                                  className={`form-group ${
                                    errors?.parentInformation &&
                                    errors?.parentInformation[`${i}`]
                                      ?.parentOccupation
                                      ? 'error-msg'
                                      : ''
                                  }`}
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Occupation*</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="parentOccupation"
                                    disabled={parentDis ? parentDis : false}
                                    onChange={(e) => handleParentChange(e, i)}
                                    defaultValue={
                                      parent?.parentOccupation
                                        ? parent?.parentOccupation
                                        : ''
                                    }
                                  />

                                  {errors?.parentInformation &&
                                  errors?.parentInformation[`${i}`]
                                    ?.parentOccupation ? (
                                    <span className="error-msg">
                                      {
                                        errors?.parentInformation[`${i}`]
                                          ?.parentOccupation?.message
                                      }
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </Form.Group>
                              </div>
                              <div
                                className="add-btn"
                                disabled={parentDis ? parentDis : false}
                              >
                                {i >= 1 &&
                                  (!parentDis ? (
                                    <Button
                                      className="delete-btn"
                                      disabled={parentDis ? parentDis : false}
                                      onClick={() => removeParent(i)}
                                    >
                                      {`Delete parent #${i + 1}`}{' '}
                                      <i class="las la-minus"></i>
                                    </Button>
                                  ) : (
                                    ''
                                  ))}
                                <Button
                                  onClick={handleAddParent}
                                  disabled={parentDis ? parentDis : false}
                                >
                                  Add <AddIcon />
                                </Button>
                              </div>
                            </div>
                          </Card.Body>
                        ))
                      : ''}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </div>
          ) : (
            ''
          )}
          {isSiblingShow ? (
            <div
              className={`accordion-list ${
                siblingFormState === 'open' ? 'change-accordion-icon' : ''
              }`}
            >
              <Card>
                <Card.Header
                  className={customAccordBtn === '7' ? 'accord-open' : ''}
                  onClick={() =>
                    siblingFormState === 'open' || siblingDis
                      ? handleClose('siblingFormState', 'person-7')
                      : ''
                  }
                >
                  <div className="accordian-title">
                    {siblingFormState === 'open' || siblingDis
                      ? 'Sibling Information'
                      : 'Add Sibling Information'}
                    {siblingFormState === 'open' && !siblingDis && (
                      <div className="delete-btn">
                        <Button
                          onClick={() =>
                            handleDelete('siblingFormState', 'person-7')
                          }
                        >
                          Delete <i className="las la-minus"></i>
                        </Button>
                      </div>
                    )}
                  </div>
                  <CustomToggle eventKey="7">
                    {siblingFormState === 'plus' && !siblingDis ? (
                      <img src={addIcon} alt="Add Icon" />
                    ) : (siblingFormState === 'open' && !siblingDis) ||
                      (siblingFormState === 'open' && siblingDis) ||
                      (siblingFormState === 'plus' && !siblingDis) ||
                      (siblingFormState === 'plus' && siblingDis) ? (
                      <img
                        className="arrow-icon"
                        src={customAccordBtn !== '7' ? closeArrow : activeArrow}
                        alt="Icon"
                      />
                    ) : (
                      ''
                    )}
                  </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="7">
                  <Card.Body className="accord-cardbody-scnd">
                    {siblingDetail && siblingDetail?.length
                      ? siblingDetail?.map((sibling, i) => (
                          <Card.Body key={i}>
                            <h6 className="child-heading">Sibling #{i + 1}</h6>
                            <div className="child-info">
                              <div className="box-grid-three">
                                <Form.Group
                                  className={`form-group ${
                                    errors?.siblingInformation &&
                                    errors?.siblingInformation[`${i}`]
                                      ?.siblingFullName
                                      ? 'error-msg'
                                      : ''
                                  }`}
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>
                                    Full Name (as appears in the passport)*
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="siblingFullName"
                                    disabled={siblingDis ? siblingDis : false}
                                    defaultValue={sibling?.siblingFullName}
                                    onChange={(e) => handleSiblingChange(e, i)}
                                  />
                                  {errors?.siblingInformation &&
                                  errors?.siblingInformation[`${i}`]
                                    ?.siblingFullName ? (
                                    <span className="error-msg">
                                      {
                                        errors?.siblingInformation[`${i}`]
                                          ?.siblingFullName?.message
                                      }
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </Form.Group>
                                <Form.Group
                                  className={`form-group ${
                                    errors?.siblingInformation &&
                                    errors?.siblingInformation[`${i}`]
                                      ?.siblingRelation
                                      ? 'error-msg'
                                      : ''
                                  }`}
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Relation*</Form.Label>
                                  <Form.Select
                                    aria-label="Default select example"
                                    name="siblingRelation"
                                    disabled={siblingDis ? siblingDis : false}
                                    defaultValue={sibling?.siblingRelation
                                      ? sibling?.siblingRelation
                                      : ''}
                                    onChange={(e) => handleSiblingChange(e, i)}
                                  >
                                    <option value="">
                                      Select Relation
                                    </option>
                                    <option value="brother">Brother</option>
                                    <option value="sister">Sister</option>
                                  </Form.Select>
                                  {errors?.siblingInformation &&
                                  errors?.siblingInformation[`${i}`]
                                    ?.siblingRelation ? (
                                    <span className="error-msg">
                                      {
                                        errors?.siblingInformation[`${i}`]
                                          ?.siblingRelation?.message
                                      }
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </Form.Group>
                                <Form.Group
                                  className={`form-group ${
                                    errors?.siblingInformation &&
                                    errors?.siblingInformation[`${i}`]
                                      ?.siblingGender
                                      ? 'error-msg'
                                      : ''
                                  }`}
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Gender*</Form.Label>
                                  <Form.Select
                                    aria-label="Default select example"
                                    name="siblingGender"
                                    disabled={
                                      childrenInfoDis ? childrenInfoDis : false
                                    }
                                    defaultValue={sibling?.siblingGender
                                      ? sibling?.siblingGender
                                      : ''}
                                    onChange={(e) => handleChange(e, i)}
                                  >
                                    <option value="">
                                      Select Gender
                                    </option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                  </Form.Select>
                                  {errors?.siblingInformation &&
                                  errors?.siblingInformation[`${i}`]
                                    ?.siblingGender ? (
                                    <span className="error-msg">
                                      {
                                        errors?.siblingInformation[`${i}`]
                                          ?.siblingGender?.message
                                      }
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </Form.Group>
                              </div>
                              <div className="box-grid-three">
                                <Form.Group
                                  className={`form-group ${
                                    errors?.siblingInformation &&
                                    errors?.siblingInformation[`${i}`]
                                      ?.siblingDateOfBirth
                                      ? 'error-msg'
                                      : ''
                                  }`}
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Date of Birth*</Form.Label>
                                  <DatePicker
                                    type="siblingDateOfBirth"
                                    name="siblingDateOfBirth"
                                    placeholderText="Select Date"
                                    disabled={siblingDis ? siblingDis : false}
                                    selected={
                                      sibling?.siblingDateOfBirth
                                        ? new Date(sibling?.siblingDateOfBirth)
                                        : new Date()
                                    }
                                    onChange={(date) =>
                                      handleSiblingDatePicker(
                                        date,
                                        'siblingDateOfBirth',
                                        i,
                                      )
                                    }
                                    className="form-select"
                                    dateFormat="dd-MM-yyyy"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                  />

                                  {errors?.siblingInformation &&
                                  errors?.siblingInformation[`${i}`]
                                    ?.siblingDateOfBirth ? (
                                    <span className="error-msg">
                                      {
                                        errors?.siblingInformation[`${i}`]
                                          ?.siblingDateOfBirth?.message
                                      }
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </Form.Group>
                                <Form.Group
                                  className={`form-group ${
                                    errors?.siblingInformation &&
                                    errors?.siblingInformation[`${i}`]
                                      ?.siblingBirthCountry
                                      ? 'error-msg'
                                      : ''
                                  }`}
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Birth Country*</Form.Label>
                                  <Form.Select
                                    disabled={siblingDis ? siblingDis : false}
                                    name="siblingBirthCountry"
                                    defaultValue={
                                      sibling?.siblingBirthCountry
                                        ? sibling?.siblingBirthCountry
                                        : userGeoData?.country_name
                                    }
                                    onChange={(e) => handleChange(e, i)}
                                  >
                                    <option value={userGeoData?.country_name}>
                                      {sibling?.siblingBirthCountry
                                        ? sibling?.siblingBirthCountry
                                        : userGeoData?.country_name}
                                    </option>
                                    {countries &&
                                      countries?.length > 0 &&
                                      countries.map((el) => (
                                        <option value={el?.name}>
                                          {el?.name}
                                        </option>
                                      ))}
                                  </Form.Select>
                                  {errors?.siblingInformation &&
                                  errors?.siblingInformation[`${i}`]
                                    ?.siblingBirthCountry ? (
                                    <span className="error-msg">
                                      {
                                        errors?.siblingInformation[`${i}`]
                                          ?.siblingBirthCountry?.message
                                      }
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </Form.Group>

                                <Form.Group
                                  className={`form-group ${
                                    errors?.siblingInformation &&
                                    errors?.siblingInformation[`${i}`]
                                      ?.siblingBirthCity
                                      ? 'error-msg'
                                      : ''
                                  }`}
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Birth City*</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    name="siblingBirthCity"
                                    disabled={siblingDis ? siblingDis : false}
                                    onChange={(e) => handleSiblingChange(e, i)}
                                    defaultValue={sibling?.siblingBirthCity}
                                  />
                                  {errors?.siblingInformation &&
                                  errors?.siblingInformation[`${i}`]
                                    ?.siblingBirthCity ? (
                                    <span className="error-msg">
                                      {
                                        errors?.siblingInformation[`${i}`]
                                          ?.siblingBirthCity?.message
                                      }
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </Form.Group>
                              </div>
                              <div className="box-grid-three">
                                <Form.Group
                                  className={`form-group ${
                                    errors?.siblingInformation &&
                                    errors?.siblingInformation[`${i}`]
                                      ?.siblingDeceased
                                      ? 'error-msg'
                                      : ''
                                  }`}
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>
                                    If deceased, what is the date of passing?
                                    (date)*
                                  </Form.Label>
                                  <DatePicker
                                    type="siblingDeceased"
                                    name="siblingDeceased"
                                    placeholderText="Select Date"
                                    disabled={siblingDis ? siblingDis : false}
                                    selected={
                                      sibling?.siblingDeceased
                                        ? new Date(sibling?.siblingDeceased)
                                        : new Date()
                                    }
                                    onChange={(date) =>
                                      handleSiblingDatePicker(
                                        date,
                                        'siblingDeceased',
                                        i,
                                      )
                                    }
                                    className="form-select"
                                    dateFormat="dd-MM-yyyy"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                  />

                                  {errors?.siblingInformation &&
                                  errors?.siblingInformation[`${i}`]
                                    ?.siblingDeceased ? (
                                    <span className="error-msg">
                                      {
                                        errors?.siblingInformation[`${i}`]
                                          ?.siblingDeceased?.message
                                      }
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </Form.Group>
                                <Form.Group
                                  className={`form-group ${
                                    errors?.siblingInformation &&
                                    errors?.siblingInformation[`${i}`]
                                      ?.siblingResidentialAddress
                                      ? 'error-msg'
                                      : ''
                                  }`}
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>
                                    Residential Address(current)*
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="siblingResidentialAddress"
                                    defaultValue={
                                      sibling?.siblingResidentialAddress
                                        ? sibling?.siblingResidentialAddress
                                        : ''
                                    }
                                    disabled={siblingDis ? siblingDis : false}
                                    onChange={(e) => handleSiblingChange(e, i)}
                                  />
                                  {errors?.siblingInformation &&
                                  errors?.siblingInformation[`${i}`]
                                    ?.siblingResidentialAddress ? (
                                    <span className="error-msg">
                                      {
                                        errors?.siblingInformation[`${i}`]
                                          ?.siblingResidentialAddress?.message
                                      }
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </Form.Group>

                                <Form.Group
                                  className={`form-group ${
                                    errors?.siblingInformation &&
                                    errors?.siblingInformation[`${i}`]
                                      ?.siblingMaritalStatus
                                      ? 'error-msg'
                                      : ''
                                  }`}
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Marital Status*</Form.Label>
                                  <Form.Select
                                    disabled={
                                      mainApplicanDis ? mainApplicanDis : false
                                    }
                                    name="siblingMaritalStatus"
                                    defaultValue={sibling?.parentMaritalStatus
                                      ? sibling?.parentMaritalStatus
                                      : ''}
                                    onChange={(e) => handleSiblingChange(e, i)}
                                  >
                                    <option value="">
                                      Select Status
                                    </option>
                                    <option value="Single">Single</option>
                                    <option value="Married">Married</option>
                                    <option value="Married">
                                      Common-Law Partnership{' '}
                                      <span>
                                        (you are living together for at least 1
                                        year)
                                      </span>
                                    </option>
                                    <option value="Legally Separated">
                                      Legally Separated
                                    </option>
                                    <option value="Divorced">Divorced</option>
                                    <option value="Widowed">Widowed</option>
                                  </Form.Select>
                                  {errors?.siblingInformation &&
                                  errors?.siblingInformation[`${i}`]
                                    ?.siblingMaritalStatus ? (
                                    <span className="error-msg">
                                      {
                                        errors?.siblingInformation[`${i}`]
                                          ?.siblingMaritalStatus?.message
                                      }
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </Form.Group>
                              </div>
                              <div className="box-grid-two">
                                <Form.Group
                                  className={`form-group ${
                                    errors?.siblingInformation &&
                                    errors?.siblingInformation[`${i}`]
                                      ?.siblingEmailAddress
                                      ? 'error-msg'
                                      : ''
                                  }`}
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Email Address*</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="siblingEmailAddress"
                                    disabled={siblingDis ? siblingDis : false}
                                    onChange={(e) => handleSiblingChange(e, i)}
                                  />
                                  {errors?.siblingInformation &&
                                  errors?.siblingInformation[`${i}`]
                                    ?.siblingEmailAddress ? (
                                    <span className="error-msg">
                                      {
                                        errors?.siblingInformation[`${i}`]
                                          ?.siblingEmailAddress?.message
                                      }
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </Form.Group>
                                <Form.Group
                                  className={`form-group ${
                                    errors?.siblingInformation &&
                                    errors?.siblingInformation[`${i}`]
                                      ?.siblingOccupation
                                      ? 'error-msg'
                                      : ''
                                  }`}
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Occupation*</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="siblingOccupation"
                                    disabled={siblingDis ? siblingDis : false}
                                    onChange={(e) => handleSiblingChange(e, i)}
                                    defaultValue={
                                      sibling?.siblingOccupation
                                        ? sibling?.siblingOccupation
                                        : ''
                                    }
                                  />

                                  {errors?.siblingInformation &&
                                  errors?.siblingInformation[`${i}`]
                                    ?.siblingOccupation ? (
                                    <span className="error-msg">
                                      {
                                        errors?.siblingInformation[`${i}`]
                                          ?.siblingOccupation?.message
                                      }
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </Form.Group>
                              </div>
                              <div
                                className="add-btn"
                                disabled={siblingDis ? siblingDis : false}
                              >
                                {i >= 1 &&
                                  (!siblingDis ? (
                                    <Button
                                      className="delete-btn"
                                      disabled={siblingDis ? siblingDis : false}
                                      onClick={() => removeSibling(i)}
                                    >
                                      {`Delete sibling #${i + 1}`}{' '}
                                      <i class="las la-minus"></i>
                                    </Button>
                                  ) : (
                                    ''
                                  ))}
                                <Button
                                  onClick={handleAddsibling}
                                  disabled={siblingDis ? siblingDis : false}
                                >
                                  Add <AddIcon />
                                </Button>
                              </div>
                            </div>
                          </Card.Body>
                        ))
                      : ''}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </div>
          ) : (
            ''
          )}
        </Accordion>
        <div className="visa-btn">
          {sponsoredShow ? (
            !sponsorDis ? (
              <>
                <Button
                  type="submit"
                  onClick={() => {
                    handleSubmit(submitPersonalInfo)
                  }}
                  disabled={sponsorDis ? sponsorDis : false}
                  className={`${sponsorDis ? 'success-btn' : ''}`}
                >
                  Save {sponsorDis && <img src={approved} alt="approved" />}
                </Button>
                <div className="visa-desc-text">
                  <p>
                    Once your form is completed, you will be able to make new
                    changes by contacting our Support team.
                  </p>
                  <p>When you're ready, click "Submit"!</p>
                </div>
              </>
            ) : (
              <div className="for-new-changes">
                <p>
                  <span>Important! </span> Please contact our Support team if
                  you would like to make new changes .
                </p>
              </div>
            )
          ) : !mainApplicanDis ? (
            <>
              <Button
                type="submit"
                onClick={() => {
                  handleSubmit(submitPersonalInfo)
                }}
                disabled={mainApplicanDis ? mainApplicanDis : false}
                className={`${mainApplicanDis ? 'success-btn' : ''}`}
              >
                Save {mainApplicanDis && <img src={approved} alt="approved" />}
              </Button>
              <div className="visa-desc-text">
                <p>
                  Once your form is completed, you will be able to make new
                  changes by contacting our Support team.
                </p>
                <p>When you're ready, click "Submit"!</p>
              </div>
            </>
          ) : (
            <div className="for-new-changes">
              <p>
                <span>Important! </span> Please contact our Support team if you
                would like to make new changes .
              </p>
            </div>
          )}
        </div>
      </Form>
      <ErrorModal show={showErrorModal} handleClose={handleShowErrorModal} />
    </div>
  )
}
export default PersonalInfo

const AddIcon = () => {
  return (
    <svg
      width="9"
      height="10"
      viewBox="0 0 9 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.37278 0.0449985V3.69935H8.83996V5.33043H5.37278V9.00261H3.59017V5.33043H0.140825V3.69935H3.59017V0.0449985H5.37278Z"
        fill="#F35C5C"
      />
    </svg>
  )
}
