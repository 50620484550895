import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import profile1 from "../../assets/images/worldTravel/profile1.svg";
import profile2 from "../../assets/images/worldTravel/profile2.svg";
import profile3 from "../../assets/images/worldTravel/profile3.svg";

const ReviewData = [
  {
    id: 1,
    userprofile: profile1,
    add: "United States",
    user: "Samantha Thompson",
    desc: "UIS Canada gave me the adventure of a lifetime. They made the process seamless and supported me throughout my stay.",
  },
  {
    id: 2,
    userprofile: profile2,
    add: "United Kingdom",
    user: "Carlos López",
    desc: " Highly recommend UIS Canada! They provided excellent support and guidance, and my concierge handled everything I needed perfectly.",
  },
  {
    id: 3,
    userprofile: profile3,
    add: "Australia",
    user: "Samantha Thompson",
    desc: "Choosing UIS Canada was the best decision. From getting my visa within a month to getting my first job almost straight away everything went so smoothly and I couldn't have asked for anything better!",
  },
];

const PaymentTestimonial = () => {
  const settings = {
    dots: true,
    centerMode: false,
    className: "center",
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          centerMode: false,
          //   className: "center",
          speed: 500,
          infinite: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className='payment-slider'>
      <Slider {...settings} swipeToSlide={true} focusOnSelect={true}>
        {ReviewData.map((e) => (
          <div key={e.id}>
            <div className='payment-review-slider-content'>
              <div className='payment-review-slide'>
                <div className='payment-review-slide-head'>
                  <img src={e.userprofile} alt='profile' />
                </div>
                <div className='payment-review-slide-center'>
                  <p>{e.user}</p>
                  <span>{e.add}</span>
                </div>
                <div className='payment-review-slide-bottom'>
                  <div className='payment-review'>
                    <StarIcon />
                    <StarIcon />
                    <StarIcon />
                    <StarIcon />
                    <StarIcon />
                  </div>
                  <p>{e.desc}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default PaymentTestimonial;

const StarIcon = () => {
  return (
    <svg
      width='21'
      height='19'
      viewBox='0 0 21 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19.9975 8.04796L15.6492 12.4313L16.5905 18.5334C16.6021 18.6064 16.5934 18.6813 16.5651 18.7497C16.5368 18.8181 16.4901 18.8773 16.4303 18.9208C16.3704 18.9643 16.2997 18.9904 16.2259 18.9961C16.1521 19.0019 16.0782 18.9871 16.0123 18.9534L10.4998 16.1725L4.98734 18.9535C4.92145 18.9872 4.84751 19.002 4.77373 18.9962C4.69995 18.9905 4.62921 18.9644 4.56934 18.9209C4.50947 18.8774 4.46281 18.8182 4.43453 18.7498C4.40625 18.6814 4.39746 18.6065 4.40912 18.5334L5.35048 12.4313L1.00223 8.04791C0.950308 7.99545 0.913822 7.92971 0.896774 7.85789C0.879726 7.78607 0.882773 7.71095 0.905581 7.64074C0.92839 7.57054 0.97008 7.50797 1.02608 7.45989C1.08209 7.41181 1.15025 7.38006 1.22309 7.36814L7.31731 6.37775L10.1425 0.887865C10.1762 0.822197 10.2275 0.767106 10.2905 0.728643C10.3536 0.69018 10.426 0.669831 10.4998 0.669831C10.5737 0.669831 10.6461 0.69018 10.7091 0.728643C10.7722 0.767106 10.8234 0.822197 10.8572 0.887865L13.6823 6.37775L19.7766 7.36814C19.8494 7.38006 19.9176 7.41181 19.9736 7.45989C20.0296 7.50797 20.0713 7.57054 20.0941 7.64074C20.1169 7.71095 20.1199 7.78607 20.1029 7.85789C20.0858 7.92971 20.0494 7.9955 19.9975 8.04796Z'
        fill='#F4C431'
      />
    </svg>
  );
};
