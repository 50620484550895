export const parentsSpouseSponsorLetterClearance = [
  {
    name: 'canadianMedicalExamProof',
    label:
      'Proof of Medical Exam From A Canadian Approved Physician (see Guidelines)',
    secondLabel: '',
    isRequired: false,
  },
  {
    name: 'sponsoredPoliceClearance',
    label: 'Police Clearance (see Guidelines)',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'sponsoredLetterBySponsor',
    label: 'Letter by Sponsee (see Guidelines)',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
]
export const partnerSpouseLetterClearanceSponsored = [
  {
    name: 'sponsoredPoliceClearance',
    label: 'Police Clearance (see Guidelines)',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'sponsoredCanadianMedicalExamProof',
    label:
      'Proof of Medical Exam From A Canadian Approved Physician (see Guidelines)',
    secondLabel: '',
    isRequired: false,
  },
  {
    name: 'sponsoredLetterBySponsor',
    label: 'Letter by Sponsee (see Guidelines)',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
]
export const superVisaLetterClearanceSponsored = [
  {
    name: 'sponsoredPoliceClearance',
    label: 'Police Clearance (see Guidelines)',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'sponsoredCanadianMedicalExamProof',
    label:
      'Proof of Medical Exam From A Canadian Approved Physician (see Guidelines)',
    secondLabel: '',
    isRequired: false,
  },
  {
    name: 'sponsoredLetterInvitation',
    label: 'Letter of invitation (see Guidelines)',
    secondLabel: '',
    isRequired: false,
  },
  {
    name: 'sponsoredLetterIntent',
    label: 'Letter of intent (see Guidelines)',
    secondLabel: '',
    isRequired: false,
  },
]
export const selfEmpWorkPermitLetterClearanceSponsored = [
  {
    name: 'sponsoredPoliceClearance',
    label: 'Police Clearance (see Guidelines)',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'sponsoredCanadianMedicalExamProof',
    label:
      'Proof of Medical Exam From A Canadian Approved Physician (see Guidelines)',
    secondLabel: '',
    isRequired: false,
  },
  {
    name: 'sponsoredLangTestResult',
    label: 'Language Test Results',
    secondLabel: '',
    isRequired: false,
  },
  {
    name: 'sponsoredBackgroundDecl',
    label: 'Background/Declaration ',
    secondLabel: '',
    isRequired: false,
  },
]
