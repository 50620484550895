/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import ApplicationLayout from '../layout/layout'
import { Table, Dropdown, Form, Button, Modal } from 'react-bootstrap'
import axios from 'axios'
import { serverPath } from '../../config/key'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Loader from '../loader/loader'
import Pagination from '../pagination'
import ReactDatePicker from 'react-datepicker'
import { useContext } from 'react'
import UserContext from '../../context/UserContext'
import PhoneInput from 'react-phone-input-2'
import RefundInvoicePopup from '../modals/refunInvoiceModal'
let options = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
}
const AllInvoices = () => {
  const { userGeoData } = useContext(UserContext)
  const [allInvoice, setAllInvoice] = useState([])
  const [loading, setLoading] = useState(false)
  const [pageCount, setPageCount] = useState()
  const [invoiceCount, setInvoiceCount] = useState(0)
  const [skip, setSkip] = useState(0)
  const [invoiceDate, setInvoiceDate] = useState()
  const [allCompany, setAllCompany] = useState([])
  const [allBrand, setAllBrand] = useState([])
  const [show, setShow] = useState(false)
  const [refundDetail, setRefundDetail] = useState({})
  const [searchKey, setSearchKey] = useState({
    invoiceNumber: '',
    invoiceDate: '',
    amount: '',
    currency: '',
    type: '',
    company: '',
    brand: '',
    name: '',
    email: '',
    phone: '',
  })
  const pageLimit = 10
  const getAllCompany = async () => {
    try {
      const response = await axios.get(`${serverPath}/company/getAllCompany`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      const { company } = response?.data || []
      if (company.length > 0) {
        const filteredCompanies = company.filter((e) => {
          return e.companyCode !== 'UISI'
        })
        setAllCompany(filteredCompanies)
      }
    } catch (err) {
      toast.error('Something went wrong!', { toastId: 'payId' })
    }
  }
  const getAllBrand = async () => {
    try {
      const response = await axios.get(`${serverPath}/brands`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      const { brands } = response?.data || []
      if (brands.length > 0) {
        setAllBrand(brands)
      }
    } catch (err) {
      toast.error('Something went wrong!', { toastId: 'payId' })
    }
  }
  useEffect(() => {
    getAllCompany()
    getAllBrand()
  }, [])
  const getAllInvoices = async () => {
    try {
      setLoading(true)
      setAllInvoice([])
      setInvoiceCount([])
      const response = await axios.get(
        `${serverPath}/invoice/getAllInvoice/${skip}`,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          params: searchKey,
        },
      )
      setLoading(false)
      const { invoices, totalCount } = response?.data?.invoices || []
      if (invoices?.length !== 0) {
        setAllInvoice(invoices)
        setInvoiceCount(totalCount?.count)
      }
    } catch (err) {
      setLoading(false)
      toast.error(err?.response?.data?.msg || err?.response?.data?.message, {
        toastId: 'errr',
      })
    }
  }
  useEffect(() => {
    getAllInvoices()
  }, [skip, searchKey])

  useEffect(() => {
    setPageCount(Math.ceil(invoiceCount / pageLimit))
  }, [invoiceCount])
  const handlePageClick = (selected) => {
    setSkip(selected * pageLimit)
    // setSelected(selected);
  }
  const clearFilter = (e) => {
    e.preventDefault()
    setSearchKey({
      invoiceNumber: '',
      invoiceDate: '',
      amount: '',
      currency: '',
      type: '',
      company: '',
      brand: '',
      name: '',
      email: '',
      phone: '',
    })
    setInvoiceDate()
  }
  const handleRedirect = async (id) => {
    const response = await axios.post(
      `${serverPath}/invoice/generateSignedUrl`,
      { pdfId: id },
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      },
    )
    const { data, status } = response
    if (status === 200) {
      window.open(data?.signedUrl, '_blank')
    }
  }
  const searchInvoice = async (e) => {
    setSkip(0)
    const { name, value } = e.target
    setSearchKey({ ...searchKey, [name]: value })
  }
  const handleChildDatePicker = (date, type) => {
    setSkip(0)
    setSearchKey({ ...searchKey, [type]: date.toLocaleDateString() })
    setInvoiceDate(date)
  }
  const handleClose = () => {
    setShow(!show)
  }
  const handleRefund = (detail) => {
    setRefundDetail(detail)
    setShow(true)
  }
  return (
    <ApplicationLayout>
      <div className={`invoices-page`}>
        <div className="container">
          <h3>Invoices</h3>
          <div className={`invoices-page-content scroll-content`}>
            <div className={`manual-invoices-content`}>
              <Form>
                <div className="invoice-form-grid">
                  <Form.Group className="form-group">
                    <Form.Control
                      className="form-control"
                      type="text"
                      name="name"
                      placeholder="Client Name"
                      value={searchKey?.name}
                      onChange={searchInvoice}
                    />
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Control
                      className="form-control"
                      type="text"
                      name="email"
                      value={searchKey?.email}
                      placeholder="Client Email"
                      onChange={searchInvoice}
                    />
                  </Form.Group>
                  <Form.Group className="form-group">
                    <PhoneInput
                      country={userGeoData?.country_code?.toLowerCase()}
                      value={searchKey?.phone}
                      onChange={(e) =>
                        setSearchKey({ ...searchKey, phone: '+' + e })
                      }
                      inputProps={{
                        name: 'phone',
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Control
                      className="form-control"
                      type="text"
                      name="invoiceNumber"
                      value={searchKey?.invoiceNumber}
                      placeholder="Invoice number"
                      onChange={searchInvoice}
                    />
                  </Form.Group>

                  <Form.Group className="form-group">
                    <ReactDatePicker
                      type="invoiceDate"
                      name="invoiceDate"
                      placeholderText="Select Date"
                      selected={invoiceDate}
                      onChange={(date) =>
                        handleChildDatePicker(date, 'invoiceDate')
                      }
                      className="form-select"
                      dateFormat="dd-MM-yyyy"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Control
                      type="number"
                      className="form-control"
                      name="amount"
                      placeholder="Amount"
                      value={searchKey?.amount}
                      onChange={searchInvoice}
                    />
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Select
                      name="currency"
                      aria-label="Default select example"
                      value={searchKey?.currency}
                      onChange={searchInvoice}
                    >
                      <option value="">Select Currency</option>
                      <option value="USD">USD</option>
                      <option value="EUR">EUR</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Select
                      name="type"
                      aria-label="Default select example"
                      value={searchKey?.type}
                      onChange={searchInvoice}
                    >
                      <option value="">Select payment type</option>
                      <option value="Credit">Credit</option>
                      <option value="Debit">Debit</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Select
                      name="company"
                      aria-label="Default select example"
                      value={searchKey?.company}
                      onChange={searchInvoice}
                    >
                      <option value="">Select Company</option>
                      {allCompany &&
                        allCompany?.length > 0 &&
                        allCompany.map((el) => (
                          <React.Fragment key={el?._id}>
                            <option value={el?._id}>{el?.name}</option>
                          </React.Fragment>
                        ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Select
                      name="brand"
                      aria-label="Default select example"
                      value={searchKey?.brand}
                      onChange={searchInvoice}
                    >
                      <option value="">Select Brand</option>
                      {allBrand &&
                        allBrand?.length > 0 &&
                        allBrand.map((el) => (
                          <React.Fragment key={el?._id}>
                            <option value={el?._id}>{el?.name}</option>
                          </React.Fragment>
                        ))}
                    </Form.Select>
                  </Form.Group>
                  <div className="invoice-btn">
                    <Button type="submit" onClick={clearFilter}>
                      clear filters
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
            <Table
              className={allInvoice?.length === 0 ? 'table_space' : ''}
              responsive
            >
              {loading ? (
                <Loader />
              ) : (
                <>
                  <tbody>
                    {allInvoice && allInvoice.length > 0 ? (
                      allInvoice.map((el) => (
                        <tr key={el._id}>
                          <td>{el?.companyId?.name}</td>
                          <td>
                            <div className="invoice-date">
                              {new Date(el?.date)?.toLocaleDateString(
                                'en-US',
                                options,
                              )}
                              <span>#{el?.invoiceNumber}</span>
                            </div>
                          </td>
                          <td>
                            <div className="invoice-date">{el?.clientName}</div>
                          </td>
                          <td>
                            <div className="invoice-date">
                              {el?.clientEmail}
                            </div>
                          </td>
                          <td>
                            <div className="invoice-date">
                              {el?.clientPhone}
                            </div>
                          </td>
                          <td>
                            <span className="invoice-price">
                              {`${
                                el?.transaction?.currency
                                  ? el?.transaction?.currency
                                  : 'USD'
                              } ${
                                el?.transaction?.price
                                  ? el?.transaction?.price
                                  : 0
                              }`}
                            </span>
                          </td>
                          <td>
                            <span className="invoice-pdf-menu">
                              <Dropdown>
                                <Dropdown.Toggle>
                                  <i class="las la-ellipsis-v"></i>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  {el?.invoicePdf.map((ele) => (
                                    <>
                                      {(ele?.type === 'Proforma' ||
                                        ele?.type === 'Proforma-Credit') && (
                                        <Dropdown.Item
                                          onClick={() =>
                                            handleRedirect(ele?._id)
                                          }
                                        >
                                          {ele?.type}
                                        </Dropdown.Item>
                                      )}
                                      {ele?.companyId &&
                                        allCompany &&
                                        allCompany?.find(
                                          (comp) =>
                                            comp?._id === ele?.companyId,
                                        )?.name && (
                                          <Dropdown.Item
                                            onClick={() =>
                                              handleRedirect(ele?._id)
                                            }
                                          >
                                            {allCompany &&
                                              allCompany?.find(
                                                (comp) =>
                                                  comp?._id === ele?.companyId,
                                              )?.name}
                                          </Dropdown.Item>
                                        )}
                                    </>
                                  ))}
                                </Dropdown.Menu>
                              </Dropdown>
                            </span>
                          </td>
                          <td>
                            <div className="invoice-btn refund-btn">
                              <Button onClick={() => handleRefund(el)}>
                                Refund
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>No Record Found</td>
                      </tr>
                    )}
                  </tbody>
                </>
              )}
            </Table>

            {allInvoice && allInvoice?.length > 0 && (
              <Pagination
                pageCount={pageCount}
                handlePageClick={handlePageClick}
                setSkip={setSkip}
                skip={skip}
                paymentCount={invoiceCount}
              />
            )}
          </div>
          <Modal
            show={show}
            onHide={handleClose}
            centered
            className="consultation-popup refund-popup"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <RefundInvoicePopup
                refundDetail={refundDetail}
                setShow={setShow}
                getAllInvoices={getAllInvoices}
              />
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </ApplicationLayout>
  )
}

export default AllInvoices
