import React from "react";
import { Modal } from "react-bootstrap";

const ErrorModal = ({ handleClose, show }) => {
  return (
    <Modal
      className="error-modal consultation-popup"
      show={show}
      onHide={handleClose}
      size="sm"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>Please complete the missing fields</Modal.Body>
    </Modal>
  );
};

export default ErrorModal;
