/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Form, Button, Spinner } from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker'
import { toast } from 'react-toastify'
import { serverPath } from '../../config/key'
import Layout from '../layout/layout'
import './index.css'
import { useNavigate } from 'react-router-dom'
import { invoiceAddSchema, invoiceSchema } from '../../utils/validationSchema'
import { useContext } from 'react'
import UserContext from '../../context/UserContext'
import PhoneInput from 'react-phone-input-2'
const ManualInvoice = () => {
  const initialValue = {
    companyId: '',
    productId: '',
    clientEmail: '',
    clientName: '',
    clientAddress: '',
    clientCity: '',
    clientCountry: '',
    clientPhone: '',
    paymentMethod: '',
    invoiceNumber: '',
    userId: '',
    brandId: '',
    invoiceDate:new Date()
  }
  const navigate = useNavigate()
  const { userGeoData, countries } = useContext(UserContext)
  const [allCompany, setAllCompany] = useState([])
  const [allBrand, setAllBrand] = useState([])
  const [values, setValues] = useState(initialValue)
  const [errors, setErrors] = useState('')
  const [loading, setLoading] = useState(false)
  const [showPasswordField, setShowPasswordField] = useState(false)
  const [userDetail, setUserDetail] = useState({})
  const [invoiceDate, setInvoiceDate] = useState(new Date())
  const getAllCompany = async () => {
    try {
      const response = await axios.get(`${serverPath}/company/getAllCompany`, {
        headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      const { company } = response?.data || []
      if (company.length > 0) {
        const filteredCompanies = company.filter((e) => {
          return e.companyCode !== 'UISI'
        })
        setAllCompany(filteredCompanies)
      }
    } catch (err) {
      toast.error('Something went wrong!', { toastId: 'payId' })
    }
  }

  const getAllBrand = async () => {
    try {
      const response = await axios.get(`${serverPath}/brands`, {
        headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      const { brands } = response?.data || []
      if (brands.length > 0) {
        setAllBrand(brands)
      }
    } catch (err) {
      toast.error('Something went wrong!', { toastId: 'payId' })
    }
  }
  useEffect(() => {
    getAllCompany()
    getAllBrand()
  }, [])

  const findLeadDetail = async (e) => {
    try {
      const res = await axios.get(
        `${serverPath}/payment-provider/getLeadDetails`,
        {
          params: {
            opportunityId: values?.opportunityId,
            paymentId: values?.paymentId,
          },
        },
      )
      const { status, userDetail } = res?.data
      if (status === 200) {
        setUserDetail(userDetail)
        setValues({
          ...values,
          clientEmail: userDetail?.email
            ? userDetail?.email
            : userDetail?.invoiceDetails?.email,
          clientName: userDetail?.name
            ? userDetail?.name
            : userDetail?.invoiceDetails?.firstName,
          clientAddress: userDetail?.address
            ? userDetail?.address
            : userDetail?.invoiceDetails?.address,
          clientCity: userDetail?.city
            ? userDetail?.city
            : userDetail?.invoiceDetails?.city,
          clientCountry: userDetail?.country
            ? userDetail?.country
            : userDetail?.invoiceDetails?.city,
          clientPhone: userDetail?.phoneNumber
            ? userDetail?.phoneNumber
            : userDetail?.invoiceDetails?.phoneNumber,
          userId: userDetail?._id ? userDetail?._id : null,
          brandId: userDetail?.brandId ? userDetail?.brandId : null         
        })
      }
    } catch (err) {
      toast.error('Payment Not Found', { toastId: 'payId' })
    }
  }
  const handleChange = (e) => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }

  const getPaymentGatewayName = (code) => {
    if (code === 'MCI') {
      return 'Israel'
    } else if (code === 'UISG') {
      return 'EU'
    } else if (code === 'UISB') {
      return 'Non-EU'
    }
  }

  const getInvoiceNumber = async (e) => {
    if (e.target.value.length <= 3) {
      setErrors({
        ...errors,
        invoiceNumber: 'Invoice Number must be at least 3 character long',
      })
      return
    }
    try {
      const res = await axios.post(
        `${serverPath}/invoice/getInvoiceById`,
        {
          invoiceNumber: e.target.value,
        },
        {
          headers: { authorization: `Bearer ${localStorage.getItem}` },
        },
      )
      const {
        clientAddress,
        clientCity,
        clientCountry,
        clientEmail,
        clientName,
        clientPhone,
        invoiceNumber,
        userId: { _id: userId },
        companyId: { _id: companyId },
        productId: { _id: productId },
        brandId,
      } = res.data.invoice
      setValues({
        ...values,
        invoiceNumber,
        clientAddress,
        clientCity,
        clientCountry,
        clientEmail,
        clientName,
        brandId,
        userId,
        clientPhone,
        companyId,
        productId,
      })
      setErrors({ ...errors, invoiceNumber: '' })
    } catch (error) {
      if (error?.response?.status === 404) {
        setErrors({
          ...errors,
          invoiceNumber: 'Not a valid Invoice Number',
        })
      }
    }
  }
  useEffect(() => {
    if (userDetail && Object.keys(userDetail).length !== 0) {
      if (!userDetail._id) {
        setShowPasswordField(true)
      }
    }
  }, [userDetail])
  const handleChildDatePicker = (date, type) => {
    setInvoiceDate(date)
    setValues({...values,[type]:date})
  }
  const handleGenerateInvoice = async (e) => {
    e.preventDefault()
    let schema
    if (values.sfPaymentMethod === 'sfOpportunityId') {
      schema = invoiceAddSchema
    } else {
      schema = invoiceSchema
    }
    try {
      const validate = await schema.validate(values, {
        abortEarly: false,
      })
      if (validate) {
        setErrors({})
        setLoading(true)
        const response = await axios.post(
          `${serverPath}/invoice/createInvoice`,
          values,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          },
        )
        setLoading(false)
        const { status } = response
        if (status === 200) {
          setValues(initialValue)
          navigate('/allinvoices')
          toast.success('Invoice generated successfully', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          })
        }
      }
    } catch (err) {
      let er = {}
      err.inner.forEach((el) => {
        er[el.path] = el.message
      })
      setErrors({ ...er })
      toast.success(err?.data?.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      })
    }
  }
  return (
    <Layout>
      <div className={`manual-invoices-page`}>
        <div className="container">
          <h3>Manual Invoice</h3>
          <div className={`manual-invoices-content`}>
            <Form>
              <div className="invoice-form-grid">
                <Form.Group className="form-group">
                  <Form.Label>Choose Payment Method</Form.Label>
                  <Form.Select
                    aria-label="Choose Payment Method"
                    name="sfPaymentMethod"
                    value={values?.sfPaymentMethod}
                    onChange={handleChange}
                  >
                    <option>Select</option>
                    <option value="sfPaymentId">Sf payment ID</option>
                    <option value="sfOpportunityId">Sf opportunity Id</option>
                  </Form.Select>
                  {errors?.sfPaymentMethod && (
                    <span className="error-msg">{errors?.sfPaymentMethod}</span>
                  )}
                </Form.Group>
                {values?.sfPaymentMethod === 'sfOpportunityId' ? (
                  <>
                    <Form.Group className="form-group">
                      <Form.Label>Enter Sf Opportunity ID</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Sf Opportunity ID"
                        name="opportunityId"
                        value={values?.opportunityId}
                        onChange={handleChange}
                        onBlur={findLeadDetail}
                      />
                      {errors?.opportunityId && (
                        <span className="error-msg">
                          {errors?.opportunityId}
                        </span>
                      )}
                    </Form.Group>
                    <Form.Group className="form-group">
                      <Form.Label>Choose Currency</Form.Label>
                      <Form.Select
                        aria-label="Choose Payment Method"
                        name="currency"
                        value={values?.currency}
                        onChange={handleChange}
                      >
                        <option>Select</option>
                        <option value="USD">USD</option>
                        <option value="EUR">EUR</option>
                        <option value="GBP">GBP</option>
                      </Form.Select>
                      {errors?.currency && (
                        <span className="error-msg">{errors.currency}</span>
                      )}
                    </Form.Group>
                    <Form.Group className="form-group">
                      <Form.Label>Amount</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Amount"
                        name="amount"
                        onChange={handleChange}
                      />
                      {errors?.amount && (
                        <span className="error-msg">{errors.amount}</span>
                      )}
                    </Form.Group>
                  </>
                ) : (
                  ''
                )}
                {values?.sfPaymentMethod === 'sfPaymentId' ? (
                  <Form.Group className="form-group">
                    <Form.Label>Enter Sf Payment ID</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Sf payment ID"
                      name="paymentId"
                      value={values?.paymentId}
                      onChange={handleChange}
                      onBlur={findLeadDetail}
                    />
                    {errors?.paymentId && (
                      <span className="error-msg">{errors?.paymentId}</span>
                    )}
                  </Form.Group>
                ) : (
                  ''
                )}
                <Form.Group className="form-group">
                  <Form.Label>Choose Invoice Type</Form.Label>
                  <Form.Select
                    aria-label="Choose Invoice Type"
                    name="paymentMethod"
                    value={values?.paymentMethod}
                    onChange={handleChange}
                  >
                    <option>Select</option>
                    <option value="Wire Transfer">Wire Transfer</option>
                    <option value="Credit Card">Credit Card</option>
                    <option value="Full Refund">Refund</option>
                    <option value="Partial Refund">Partial Refund</option>
                    <option value="Chargeback">Chargeback</option>
                  </Form.Select>
                  {errors?.paymentMethod && (
                    <span className="error-msg">{errors.paymentMethod}</span>
                  )}
                </Form.Group>
                {values.paymentMethod === 'Full Refund' ||
                values.paymentMethod === 'Chargeback' ||
                values.paymentMethod === 'Partial Refund' ? (
                  <Form.Group className="form-group">
                    <Form.Label>Ref Invoice #</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Invoice #"
                      name="invoiceNumber"
                      onBlur={getInvoiceNumber}
                      onChange={handleChange}
                    />
                    {errors?.invoiceNumber && (
                      <span className="error-msg">{errors.invoiceNumber}</span>
                    )}
                  </Form.Group>
                ) : (
                  ''
                )}
                <Form.Group className="form-group">
                  <Form.Label>Payment Gateway</Form.Label>
                  <Form.Select
                    aria-label="Payment Gateway"
                    name="companyId"
                    value={values?.companyId}
                    onChange={handleChange}
                  >
                    {values?.companyId === '' && (
                      <option disabled value="">
                        Choose Gateway Location
                      </option>
                    )}
                    {allCompany &&
                      allCompany?.length > 0 &&
                      allCompany.map((el) => (
                        <option value={el?._id}>
                          {getPaymentGatewayName(el?.companyCode)}
                        </option>
                      ))}
                  </Form.Select>
                  {errors?.companyId && (
                    <span className="error-msg">{errors.companyId}</span>
                  )}
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label>Choose Brand</Form.Label>
                  <Form.Select
                    aria-label="Choose Brand"
                    name="brandId"
                    value={values?.brandId}
                    onChange={handleChange}
                  >
                    {values?.brandId ? (
                      <option disabled value={values?.brandId}>
                        {allBrand && allBrand.find((el)=>el._id===values?.brandId)?.name}
                      </option>
                    ):<option disabled value="">
                    Select brand
                  </option>
                  }
                    {allBrand &&
                      allBrand?.length > 0 &&
                      allBrand.map((el) => (
                        <option value={el?._id}>{el?.name}</option>
                      ))}
                  </Form.Select>
                  {errors?.brandId && (
                    <span className="error-msg">{errors.brandId}</span>
                  )}
                </Form.Group>
                <Form.Group className="form-group">
                    <ReactDatePicker
                      type="invoiceDate"
                      name="invoiceDate"
                      placeholderText="Select Date"
                      selected={invoiceDate}
                      onChange={(date) =>
                        handleChildDatePicker(date, 'invoiceDate')
                      }
                      className="form-select"
                      dateFormat="dd-MM-yyyy"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </Form.Group>
                {/* <Form.Group className="form-group">
                  <Form.Label>Choose Product</Form.Label>
                  <Form.Select
                    aria-label="Choose Product"
                    name="productId"
                    value={values?.productId}
                    onChange={handleChange}
                  >
                    {values?.productId === '' && (
                      <option disabled value="">
                        Choose Product
                      </option>
                    )}
                    {allProduct &&
                      allProduct?.length > 0 &&
                      allProduct?.map((el) => (
                        <option value={el?._id}>{el?.name}</option>
                      ))}
                  </Form.Select>
                  {errors?.productId && (
                    <span className="error-msg">{errors.productId}</span>
                  )}
                </Form.Group> */}
                {/* <Form.Group className="form-group">
                  <Form.Label>Invoice Amount</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Invoice Amount"
                    name="totalAmount"
                    value={values?.totalAmount}
                    onChange={handleChange}
                  />
                  {errors?.totalAmount && (
                    <span className="error-msg">{errors?.totalAmount}</span>
                  )}
                </Form.Group> */}
                <Form.Group className="form-group">
                  <Form.Label>Client Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    name="clientEmail"
                    value={values?.clientEmail}
                    onChange={handleChange}
                  />
                  {errors?.clientEmail && (
                    <span className="error-msg">{errors.clientEmail}</span>
                  )}
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label>Client Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Full Name"
                    name="clientName"
                    value={values?.clientName}
                    onChange={handleChange}
                  />
                  {errors?.clientName && (
                    <span className="error-msg">{errors.clientName}</span>
                  )}
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label>Client Address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Address"
                    name="clientAddress"
                    value={values?.clientAddress}
                    onChange={handleChange}
                  />
                  {errors?.clientAddress && (
                    <span className="error-msg">{errors.clientAddress}</span>
                  )}
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label>Client City</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="City"
                    name="clientCity"
                    value={values?.clientCity}
                    onChange={handleChange}
                  />
                  {errors?.clientCity && (
                    <span className="error-msg">{errors.clientCity}</span>
                  )}
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label>Client Country</Form.Label>

                  <Form.Select onChange={handleChange} name="clientCountry">
                    <option value={userGeoData?.country_name} name="country">
                      {values?.clientCountry
                        ? values?.clientCountry
                        : userGeoData?.country_name}
                    </option>
                    {countries &&
                      countries?.length > 0 &&
                      countries.map((el) => (
                        <option key={el.name} value={el?.name} name="country">
                          {el?.name}
                        </option>
                      ))}
                  </Form.Select>
                  {errors?.clientCountry && (
                    <span className="error-msg">{errors.clientCountry}</span>
                  )}
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label>Client Phone</Form.Label>

                  <PhoneInput
                    country={  
                      userGeoData?.country_code?.toLowerCase()
                    }
                    onChange={(e) =>
                      setValues({ ...values, clientPhone: '+' + e })
                    }
                    value={
                     userDetail?.phoneNumber}
                    inputProps={{
                      name: 'clientPhone',
                    }}
                  />
                  {errors?.clientPhone && (
                    <span className="error-msg">{errors.clientPhone}</span>
                  )}
                </Form.Group>

                {showPasswordField ? (
                  <Form.Group className="form-group">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="City"
                      name="password"
                      value={values?.password}
                      onChange={handleChange}
                    />
                    {errors?.password && (
                      <span className="error-msg">{errors.password}</span>
                    )}
                  </Form.Group>
                ) : (
                  ''
                )}

                <div className="invoice-btn">
                  <Button
                    type="submit"
                    onClick={handleGenerateInvoice}
                    disabled={loading}
                  >
                    {loading && (
                      <Spinner
                        as="span"
                        variant="light"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        animation="border"
                      />
                    )}
                    &nbsp;Generate Invoice
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ManualInvoice
