import React, { useState, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { serverPath } from '../../config/key'
import Layout from '../layout/layout'
import Helmet from 'react-helmet'
import { toast } from 'react-toastify'
import { Form, Button, Spinner } from 'react-bootstrap'
import logo from '../../assets/images/footer/logo.svg'
import envelope from '../../assets/images/login/envelope.svg'
import call from '../../assets/images/login/call.svg'
import facebook from '../../assets/images/login/facebook.svg'
import linkedin from '../../assets/images/login/linkedin.svg'
import twitter from '../../assets/images/login/twitter.svg'
import showicon from '../../assets/images/login/show-icon.svg'
import hideicon from '../../assets/images/login/hide-icon.svg'
import 'react-toastify/dist/ReactToastify.css'
import './login.css'
import { LoginSchema } from '../../utils/validationSchema'
import UserContext from '../../context/UserContext'
import { useTranslation } from 'react-i18next'

const Login = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(LoginSchema) })
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { setUser, setToken } = useContext(UserContext)
  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false)
  const [logInData, setLogInData] = useState({
    email: '',
    password: '',
    rememberMe: false,
  })

  const handleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleChange = (e) => {
    const { name, value, checked } = e.target
    if (name === 'rememberMe') {
      setLogInData({
        ...logInData,
        rememberMe: checked,
      })
    } else
      setLogInData({
        ...logInData,
        [name]: value,
      })
  }

  const logInUser = async (values) => {
    try {
      setLoading(true)
      const response = await axios.post(`${serverPath}/users/login`, values)
      setLoading(false)
      const { status, token, user } = response.data
      if (status === 200) {
        toast.success('Login Successful', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
        localStorage.setItem('token', token)
        localStorage.setItem('user', JSON.stringify(user))
        setUser(user)
        setToken(token)
        if (
          user.role === 'client' &&
          localStorage.getItem('selfPurchase') &&
          localStorage.getItem('selfProduct')
        ) {
          console.log("Redirect on the payment page")
          return navigate(
            `/Payment/?pId=${localStorage.getItem('selfProduct')}`,
          )
        }
        if (user.role === 'client') navigate('/welcome')
        else if (user.role === 'admin') navigate('/admin')
      }
    } catch (error) {
      if (error?.response?.data) {
        if (error.response.data.status === 401) {
          navigate('/verifyUser')
          localStorage.setItem('phone', error.response.data.phone)
        }
        setLoading(false)
        toast.error(error.response.data.msg, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
      }
    }
  }

  return (
    <Layout>
      <Helmet>
        <body className="hide-layout" />
      </Helmet>

      <div className="login-page">
        <div className="login-grid">
          <div className="login-content">
            <Link to="/">
              <img src={logo} alt="" className="logo-login" />
            </Link>

            <h2>Welcome!</h2>

            <div className="login-contact-info">
              <span>
                <img src={envelope} alt="" />
                {t('contactEmail')}
              </span>
              <span>
                <img src={call} alt="" />
                {t('contactPhone')}
              </span>
            </div>

            <div className="login-social">
              <ul>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/uis.canada"
                  >
                    <img src={facebook} alt="" />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkedin.com/company/uis-canada-official/"
                  >
                    <img src={linkedin} alt="" />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://twitter.com/uis_canada"
                  >
                    <img src={twitter} alt="" />
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="login-form">
            <div className="login-popup-content">
              <h2>Sign In</h2>
              <p>Enter your email and password to sign in</p>

              <Form onSubmit={handleSubmit(logInUser)}>
                <Form.Group>
                  <Form.Control
                    className={`${errors?.email ? `error-border` : ``}`}
                    type="text"
                    name="email"
                    placeholder="Email Address"
                    onChange={handleChange}
                    {...register('email')}
                    defaultValue={logInData.email}
                  />
                  {errors?.email ? (
                    <span className="error-msg">{errors.email.message}</span>
                  ) : (
                    ''
                  )}
                </Form.Group>

                <Form.Group>
                  <div className="pass-show">
                    <span className="pass-icon" onClick={handleShowPassword}>
                      {showPassword ? (
                        <img src={hideicon} alt="" />
                      ) : (
                        <img src={showicon} alt="" />
                      )}
                    </span>
                    <Form.Control
                      className={`${errors?.password ? `error-border` : ``}`}
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Password"
                      name="password"
                      onChange={handleChange}
                      defaultValue={logInData.password}
                      {...register('password')}
                    />
                  </div>

                  {errors?.password ? (
                    <span className="error-msg">{errors.password.message}</span>
                  ) : (
                    ''
                  )}
                </Form.Group>

                <div className="one-time">
                  <Link to="/phoneverify">Login with a one-time password</Link>
                </div>

                <div className="forget-section">
                  <div className="remember-me">
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label="Remember me"
                      name="rememberMe"
                      onChange={handleChange}
                      {...register('rememberMe', { required: false })}
                    />
                  </div>
                  <Link to="/phoneverify">Forgot Password</Link>
                </div>

                <Button type="submit" disabled={loading}>
                  {loading && (
                    <Spinner
                      as="span"
                      variant="light"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      animation="border"
                    />
                  )}
                  &nbsp;Sign In
                </Button>
                <div className="one-time">
                  Don't have an account?{' '}
                  <Link to="/register"> &nbsp;Register</Link>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Login
