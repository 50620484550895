/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import {
  Form,
  Accordion,
  Button,
  Card,
  useAccordionButton,
} from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import DatePicker from 'react-datepicker'
import axios from 'axios'
import { toast } from 'react-toastify'

import addIcon from '../../assets/images/visa/add-icon.svg'
import activeArrow from '../../assets/images/visa/active-arrow.svg'
import closeArrow from '../../assets/images/visa/close-arrow.svg'

import { serverPath } from '../../config/key'
import { useContext } from 'react'
import UserContext from '../../context/UserContext'
import { workExperienceMainValid } from '../../utils/validationSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import approved from '../../assets/images/header/approved.svg'
import ErrorModal from '../modals/errorModal'
const WorkExperience = ({
  experienceInfo,
  setExperienceInfo,
  adminFormDisable,
}) => {
  const urlParams = new URLSearchParams(window.location.search)
  const token = urlParams.get('t')
  const { selectedVisa, setSelectedVisa } = useContext(UserContext)
  let intialState = {
    officialJobTitle: '',
    jobDescription: '',
    yearsExperience: '',
    fullTimePosition: '',
    hoursPerWeek: '',
    jobStartDate: '',
    jobEndTime: '',
    monthlyIncome: '',
    moneyBringToCanada: '',
  }
  let intialSpouseState = {
    sOfficialJobTitle: '',
    sJobDescription: '',
    sYearsExperience: '',
    sFullTimePosition: '',
    sHoursPerWeek: '',
    sJobStartDate: '',
    sJobEndTime: '',
    sMonthlyIncome: '',
    sMoneyBringToCanada: '',
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(workExperienceMainValid),
    shouldUnregister: true,
  })

  const [jobs, setJobs] = useState([{ ...intialState }])
  const [spouseJobsOffshore, setSpouseJobsOffshore] = useState([
    { ...intialSpouseState },
  ])
  const [offshoreDis, setOffshoreDis] = useState(false)
  const [spouseOffshoreDis, setSpouseOffshoreDis] = useState(false)
  const [customAccordBtn, setCustomAccordBtn] = useState(true)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [spouseFormState, setSpouseformstate] = useState('plus')
  const [textSpouseMessage, setTextSpouseMessage] = useState('')
  useEffect(() => {
    setValue('workExpOffshoreInfo', jobs)
    if (spouseFormState === 'open') {
      setValue('workOffshoreSpouseInfo', spouseJobsOffshore)
    }
  }, [jobs, spouseJobsOffshore, spouseFormState])
  useEffect(() => {
    if (selectedVisa?.visaName === 'Double Evaluation' && !spouseOffshoreDis) {
      setTextSpouseMessage(' Add Spouse (Partner) Applicant')
    }
    if (
      (selectedVisa?.visaName === 'Double Evaluation' &&
        spouseFormState === 'open') ||
      (selectedVisa?.visaName === 'Double Evaluation' && spouseOffshoreDis)
    ) {
      setTextSpouseMessage('Spouse (Partner) Applicant')
    }
  }, [selectedVisa, spouseFormState])
  useEffect(() => {
    //set fetched value of main applicant offshore job
    if (experienceInfo && experienceInfo?.offshoreJob?.length > 0) {
      // setOffshoreDis(token ? adminFormDisable : true)
      setJobs(experienceInfo?.offshoreJob)
    }
    if (
      experienceInfo &&
      experienceInfo?.offshoreJob?.length !== 0 &&
      experienceInfo.isLocked
    ) {
      setSpouseOffshoreDis(token ? adminFormDisable : true)
      setOffshoreDis(token ? adminFormDisable : true)
    }

    //set fetched spouse offshore job
    if (experienceInfo && experienceInfo?.spouseOffshoreJob?.length !== 0) {
      setSpouseJobsOffshore(experienceInfo?.spouseOffshoreJob)
    }
  }, [experienceInfo, token, adminFormDisable])
  //add main applicant job offshore form
  const handleAddJob = () => {
    setJobs([...jobs, { ...intialState }])
  }
  //remove main offshore job form
  const removeAddJob = (index) => {
    const oldJob = [...jobs]
    oldJob.splice(index, 1)
    setJobs(oldJob)
  }
  const handleAddSpouseJobOffshore = () => {
    setSpouseJobsOffshore([...spouseJobsOffshore, { ...intialSpouseState }])
  }
  const removeAddSpouseOffshoreJob = (index) => {
    const oldJob = [...spouseJobsOffshore]
    oldJob.splice(index, 1)
    setSpouseJobsOffshore(oldJob)
  }
  const handleChange = (e, index) => {
    const { name, value } = e.target
    let prevData = [...jobs]
    prevData[index][name] = value
    setJobs([...prevData])
  }

  const handleSpouseOffshoreChange = (e, index) => {
    const { name, value } = e.target
    let prevData = [...spouseJobsOffshore]
    prevData[index][name] = value
    setSpouseJobsOffshore([...prevData])
  }

  const handleJobStartDatePicker = (date, index, type) => {
    if (type === 'jobStartDate') {
      let prevData = [...jobs]
      prevData[index][type] = date
      setJobs([...prevData])
    }

    if (type === 'spouseOffshoreStartDate') {
      let prevData = [...spouseJobsOffshore]
      prevData[index].sJobStartDate = date
      setSpouseJobsOffshore([...prevData])
    }
  }
  const handleJobEndTimePicker = (date, index, type) => {
    if (type === 'jobEndTime') {
      let prevData = [...jobs]
      prevData[index][type] = date
      setJobs([...prevData])
    }

    if (type === 'spouseOffshoreJobEndTime') {
      let prevData = [...spouseJobsOffshore]
      prevData[index].sJobEndTime = date
      setSpouseJobsOffshore([...prevData])
    }
  }

  //for open the accordion
  const changeAccordionselect = (key) => {
    if (key === '1') {
      setSpouseformstate('open')
      setValue('isSpouseOpen', true)
    }
  }
  const handleDelete = (value, id) => {
    if (value === 'spouseFormState') {
      setSpouseformstate('plus')
      setValue('isSpouseOpen', false)
      setCustomAccordBtn(false)
    }
    document.getElementById(id).click()
  }
  const handleClose = (value, id) => {
    document.getElementById(id).click()
  }
  
  const workExperience = async (values) => {
    try {
      let apiPath = token
        ? 'supportEvaluation/UpdateExperience'
        : 'evaluation/evaluationWorkExperience'

      const res = await axios.post(
        `${serverPath}/${apiPath}`,
        {
          ...values,
          offshoreJob: jobs,
          spouseOffshoreJob: spouseJobsOffshore,
          productId: selectedVisa.productId,
          invoiceId: selectedVisa.invoiceId,
        },
        {
          headers: {
            authorization: `Bearer ${
              token ? token : localStorage.getItem('token')
            }`,
          },
        },
      )
      const { code, create } = res.data
      if (code === 200) {
        setExperienceInfo(create)
        setSelectedVisa({
          invoiceId: selectedVisa?.invoiceId,
          productId: selectedVisa?.productId,
          caseId: selectedVisa?.caseId,
          visaName: selectedVisa?.visaName,
          isEvaluation: selectedVisa?.isEvaluation,
        })
        toast.success('Your information saved successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
      }
    } catch (err) {
      if (err?.response?.data) {
        toast.error(err.response.data.msg, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
      }
    }
  }
  //custom accordian toggle
  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      if (customAccordBtn === eventKey) {
        setCustomAccordBtn('')
      } else setCustomAccordBtn(eventKey)
    })
    return (
      <Button id={`work-${eventKey}`} onClick={decoratedOnClick}>
        {children}
      </Button>
    )
  }

  //For Error popup
  const handleShowErrorModal = () => {
    setShowErrorModal(false)
  }
  useEffect(() => {
    if (errors && Object.keys(errors).length !== 0) {
      setShowErrorModal(true)
    } else {
      setShowErrorModal(false)
    }
  }, [errors])

  return (
    <div className="personal-info work-exp-section">
      <Form onSubmit={handleSubmit(workExperience)}>
        <Accordion
          defaultActiveKey="0"
          onSelect={(key) => changeAccordionselect(key)}
        >
          <div className={`accordion-list ${'change-accordion-icon'}`}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Main Applicant</Accordion.Header>
              <Accordion.Body>
                <div className="outside-country">
                  <h6>Offshore (Outside Canada)</h6>
                  <Form.Group
                    className={`form-group ${
                      errors?.numberOfJobOffshore?.message ? 'error-msg' : ''
                    }`}
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      Number of Jobs in the Past 10 Years*
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="numberOfJobOffshore"
                      defaultValue={
                        experienceInfo?.numberOfJobOffshore
                          ? experienceInfo?.numberOfJobOffshore
                          : experienceInfo?.sponsoredNumberOfJobOffshore
                      }
                      disabled={offshoreDis ? offshoreDis : false}
                      {...register('numberOfJobOffshore')}
                    />
                    {errors?.numberOfJobOffshore ? (
                      <span className="error-msg">
                        {errors?.numberOfJobOffshore?.message}
                      </span>
                    ) : (
                      ''
                    )}
                  </Form.Group>
                  {jobs &&
                    jobs?.length > 0 &&
                    jobs?.map((child, i) => (
                      <>
                        <h6 className="child-heading">Offshore Job #{i + 1}</h6>
                        <div className="child-info">
                          <div className="box-grid-two">
                            <Form.Group
                              className={`form-group ${
                                errors?.workExpOffshoreInfo &&
                                errors?.workExpOffshoreInfo[`${i}`]
                                  ?.officialJobTitle
                                  ? 'error-msg'
                                  : ''
                              }`}
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Official Job Title*</Form.Label>
                              <Form.Control
                                type="text"
                                name="officialJobTitle"
                                disabled={offshoreDis ? offshoreDis : false}
                                defaultValue={child?.officialJobTitle}
                                onChange={(e) => handleChange(e, i)}
                              />
                              {errors?.workExpOffshoreInfo &&
                              errors?.workExpOffshoreInfo[`${i}`]
                                ?.officialJobTitle ? (
                                <span className="error-msg">
                                  {
                                    errors?.workExpOffshoreInfo[`${i}`]
                                      ?.officialJobTitle?.message
                                  }
                                </span>
                              ) : (
                                ''
                              )}
                            </Form.Group>
                            <Form.Group
                              className={`form-group ${
                                errors?.workExpOffshoreInfo &&
                                errors?.workExpOffshoreInfo[`${i}`]
                                  ?.jobDescription
                                  ? 'error-msg'
                                  : ''
                              }`}
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Job Description*</Form.Label>
                              <Form.Control
                                type="text"
                                name="jobDescription"
                                placeholder="Example: dentistry, law, optometry..."
                                disabled={offshoreDis ? offshoreDis : false}
                                defaultValue={child?.jobDescription}
                                onChange={(e) => handleChange(e, i)}
                              />
                              {errors?.workExpOffshoreInfo &&
                              errors?.workExpOffshoreInfo[`${i}`]
                                ?.jobDescription ? (
                                <span className="error-msg">
                                  {
                                    errors?.workExpOffshoreInfo[`${i}`]
                                      ?.jobDescription?.message
                                  }
                                </span>
                              ) : (
                                ''
                              )}
                            </Form.Group>
                          </div>
                          <div className="box-grid-two">
                            <Form.Group
                              className={`form-group ${
                                errors?.workExpOffshoreInfo &&
                                errors?.workExpOffshoreInfo[`${i}`]
                                  ?.fullTimePosition
                                  ? 'error-msg'
                                  : ''
                              }`}
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Full-Time Position?*</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                name="fullTimePosition"
                                disabled={offshoreDis ? offshoreDis : false}
                                defaultValue={child.fullTimePosition
                                  ? child.fullTimePosition
                                  : ""}
                                onChange={(e) => handleChange(e, i)}
                              >
                                <option value={child.fullTimePosition
                                  ? child?.fullTimePosition
                                  : ""}>
                                  {child?.fullTimePosition
                                  ? child?.fullTimePosition
                                  : ""}
                                </option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </Form.Select>
                              {errors?.workExpOffshoreInfo &&
                              errors?.workExpOffshoreInfo[`${i}`]
                                ?.fullTimePosition ? (
                                <span className="error-msg">
                                  {
                                    errors?.workExpOffshoreInfo[`${i}`]
                                      ?.fullTimePosition?.message
                                  }
                                </span>
                              ) : (
                                ''
                              )}
                            </Form.Group>

                            <Form.Group
                              className={`form-group ${
                                errors?.workExpOffshoreInfo &&
                                errors?.workExpOffshoreInfo[`${i}`]
                                  ?.hoursPerWeek
                                  ? 'error-msg'
                                  : ''
                              }`}
                              controlId="formBasicEmail"
                            >
                              <Form.Label>How many hours per week?*</Form.Label>
                              <Form.Control
                                type="text"
                                name="hoursPerWeek"
                                defaultValue={child?.hoursPerWeek}
                                disabled={offshoreDis ? offshoreDis : false}
                                onChange={(e) => handleChange(e, i)}
                                placeholder=""
                              />
                              {errors?.workExpOffshoreInfo &&
                              errors?.workExpOffshoreInfo[`${i}`]
                                ?.hoursPerWeek ? (
                                <span className="error-msg">
                                  {
                                    errors?.workExpOffshoreInfo[`${i}`]
                                      ?.hoursPerWeek?.message
                                  }
                                </span>
                              ) : (
                                ''
                              )}
                            </Form.Group>
                          </div>
                          <div className="box-grid-three">
                            <Form.Group
                              className={`form-group ${
                                errors?.workExpOffshoreInfo &&
                                errors?.workExpOffshoreInfo[`${i}`]
                                  ?.yearsExperience
                                  ? 'error-msg'
                                  : ''
                              }`}
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Years of Experience*</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder=""
                                name="yearsExperience"
                                disabled={offshoreDis ? offshoreDis : false}
                                defaultValue={child?.yearsExperience}
                                onChange={(e) => handleChange(e, i)}
                              />
                              {errors?.workExpOffshoreInfo &&
                              errors?.workExpOffshoreInfo[`${i}`]
                                ?.yearsExperience ? (
                                <span className="error-msg">
                                  {
                                    errors?.workExpOffshoreInfo[`${i}`]
                                      ?.yearsExperience?.message
                                  }
                                </span>
                              ) : (
                                ''
                              )}
                            </Form.Group>

                            <Form.Group
                              className={`form-group ${
                                errors?.workExpOffshoreInfo &&
                                errors?.workExpOffshoreInfo[`${i}`]
                                  ?.jobStartDate
                                  ? 'error-msg'
                                  : ''
                              }`}
                              controlId="formBasicEmail"
                            >
                              <Form.Label>
                                Job Start Date (Month/Year)*
                              </Form.Label>
                              <DatePicker
                                type="jobStartDate"
                                name="jobStartDate"
                                disabled={offshoreDis ? offshoreDis : false}
                                placeholderText="Select Date"
                                selected={
                                  child?.jobStartDate
                                    ? new Date(child?.jobStartDate)
                                    : new Date()
                                }
                                onChange={(date) =>
                                  handleJobStartDatePicker(
                                    date,
                                    i,
                                    'jobStartDate',
                                  )
                                }
                                className="form-select"
                                dateFormat="dd-MM-yyyy"
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                              />
                              {errors?.workExpOffshoreInfo &&
                              errors?.workExpOffshoreInfo[`${i}`]
                                ?.jobStartDate ? (
                                <span className="error-msg">
                                  {
                                    errors?.workExpOffshoreInfo[`${i}`]
                                      ?.jobStartDate?.message
                                  }
                                </span>
                              ) : (
                                ''
                              )}
                            </Form.Group>
                            <Form.Group
                              className={`form-group ${
                                errors?.workExpOffshoreInfo &&
                                errors?.workExpOffshoreInfo[`${i}`]?.jobEndTime
                                  ? 'error-msg'
                                  : ''
                              }`}
                              controlId="formBasicEmail"
                            >
                              <Form.Label>
                                Job End Date (Month/Year)*
                              </Form.Label>
                              <DatePicker
                                type="jobEndTime"
                                name="jobEndTime"
                                disabled={offshoreDis ? offshoreDis : false}
                                placeholderText="Select Date"
                                selected={
                                  child?.jobEndTime
                                    ? new Date(child?.jobEndTime)
                                    : new Date()
                                }
                                onChange={(date) =>
                                  handleJobEndTimePicker(date, i, 'jobEndTime')
                                }
                                className="form-select"
                                dateFormat="dd-MM-yyyy"
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                              />
                              {errors?.workExpOffshoreInfo &&
                              errors?.workExpOffshoreInfo[`${i}`]
                                ?.jobEndTime ? (
                                <span className="error-msg">
                                  {
                                    errors?.workExpOffshoreInfo[`${i}`]
                                      ?.jobEndTime?.message
                                  }
                                </span>
                              ) : (
                                ''
                              )}
                            </Form.Group>
                          </div>
                          <div className="box-grid-two">
                            <Form.Group
                              className={`form-group ${
                                errors?.workExpOffshoreInfo &&
                                errors?.workExpOffshoreInfo[`${i}`]
                                  ?.monthlyIncome
                                  ? 'error-msg'
                                  : ''
                              }`}
                              controlId="formBasicEmail"
                            >
                              <Form.Label>
                                What is Your Monthly Income*
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="monthlyIncome"
                                disabled={offshoreDis ? offshoreDis : false}
                                defaultValue={child?.monthlyIncome}
                                onChange={(e) => handleChange(e, i)}
                              />
                              {errors?.workExpOffshoreInfo &&
                              errors?.workExpOffshoreInfo[`${i}`]
                                ?.monthlyIncome ? (
                                <span className="error-msg">
                                  {
                                    errors?.workExpOffshoreInfo[`${i}`]
                                      ?.monthlyIncome?.message
                                  }
                                </span>
                              ) : (
                                ''
                              )}
                            </Form.Group>
                            <Form.Group
                              className={`form-group ${
                                errors?.workExpOffshoreInfo &&
                                errors?.workExpOffshoreInfo[`${i}`]
                                  ?.moneyBringToCanada
                                  ? 'error-msg'
                                  : ''
                              }`}
                              controlId="formBasicEmail"
                            >
                              <Form.Label>
                                How Much Money Bring to Canada*
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="moneyBringToCanada"
                                disabled={offshoreDis ? offshoreDis : false}
                                defaultValue={child?.moneyBringToCanada}
                                onChange={(e) => handleChange(e, i)}
                              />
                              {errors?.workExpOffshoreInfo &&
                              errors?.workExpOffshoreInfo[`${i}`]
                                ?.moneyBringToCanada ? (
                                <span className="error-msg">
                                  {
                                    errors?.workExpOffshoreInfo[`${i}`]
                                      ?.moneyBringToCanada?.message
                                  }
                                </span>
                              ) : (
                                ''
                              )}
                            </Form.Group>
                          </div>
                          <div className="add-btn">
                            {jobs && jobs.length === 1 ? (
                              <Button
                                onClick={handleAddJob}
                                disabled={offshoreDis ? offshoreDis : false}
                              >
                                Add Job <i className="las la-plus"></i>
                              </Button>
                            ) : (
                              ''
                            )}
                            {i >= 0 && i !== 0 && (
                              <>
                                <Button
                                  className="delete-btn"
                                  disabled={offshoreDis ? offshoreDis : false}
                                  onClick={() => removeAddJob(i)}
                                >
                                  Delete Job #{i + 1}{' '}
                                  <i class="las la-minus"></i>
                                </Button>
                                <Button
                                  onClick={handleAddJob}
                                  disabled={offshoreDis ? offshoreDis : false}
                                >
                                  Add Job <i className="las la-plus"></i>
                                </Button>
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    ))}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </div>
          <div
            className={`accordion-list ${
              spouseFormState === 'open' ? 'change-accordion-icon' : ''
            }`}
          >
            <Card>
              <Card.Header
                className={customAccordBtn === '1' ? 'accord-open' : ''}
                onClick={() =>
                  spouseFormState === 'open' || spouseOffshoreDis
                    ? handleClose('spouseFormState', 'work-1')
                    : ''
                }
              >
                <div className="accordian-title">
                  {textSpouseMessage
                    ? textSpouseMessage
                    : spouseOffshoreDis || spouseFormState === 'open'
                    ? 'Spouse Information'
                    : 'Add Spouse Information'}
                  {spouseFormState === 'open' && !spouseOffshoreDis && (
                    <div className="delete-btn">
                      <Button
                        onClick={() =>
                          handleDelete('spouseFormState', 'work-1')
                        }
                      >
                        Delete <i className="las la-minus"></i>
                      </Button>
                    </div>
                  )}
                </div>
                <CustomToggle eventKey="1">
                  {spouseFormState === 'plus' && !spouseOffshoreDis ? (
                    <img src={addIcon} alt="Add Icon" />
                  ) : (spouseFormState === 'open' && !spouseOffshoreDis) ||
                    (spouseFormState === 'plus' && !spouseOffshoreDis) ||
                    (spouseFormState === 'plus' && spouseOffshoreDis) ||
                    (spouseFormState === 'open' && spouseOffshoreDis) ? (
                    <img
                      className="arrow-icon"
                      src={customAccordBtn !== '1' ? closeArrow : activeArrow}
                      alt="Icon"
                    />
                  ) : (
                    ''
                  )}
                </CustomToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <div className="outside-country">
                    <h6>Offshore (Outside Canada)</h6>
                    <Form.Group
                      className={`form-group ${
                        errors?.sNumberOfJobOffshore?.message ? 'error-msg' : ''
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>
                        Number of Jobs in the Past 10 Years*
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="sNumberOfJobOffshore"
                        disabled={spouseOffshoreDis ? spouseOffshoreDis : false}
                        defaultValue={
                          experienceInfo?.sNumberOfJobOffshore
                            ? experienceInfo?.sNumberOfJobOffshore
                            : experienceInfo?.sponsoredSpouseNumberOfJobOffshore
                        }
                        {...register('sNumberOfJobOffshore')}
                      />
                      {errors?.sNumberOfJobOffshore ? (
                        <span className="error-msg">
                          {errors?.sNumberOfJobOffshore?.message}
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Group>
                    {spouseJobsOffshore &&
                      spouseJobsOffshore.length > 0 &&
                      spouseJobsOffshore.map((child, i) => (
                        <>
                          <h6 className="child-heading">
                            Offshore Job #{i + 1}
                          </h6>
                          <div className="child-info">
                            <div className="box-grid-two">
                              <Form.Group
                                className={`form-group ${
                                  errors?.workOffshoreSpouseInfo &&
                                  errors?.workOffshoreSpouseInfo[`${i}`]
                                    ?.sOfficialJobTitle
                                    ? 'error-msg'
                                    : ''
                                }`}
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Official Job Title*</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="sOfficialJobTitle"
                                  disabled={
                                    spouseOffshoreDis
                                      ? spouseOffshoreDis
                                      : false
                                  }
                                  defaultValue={child?.sOfficialJobTitle}
                                  onChange={(e) =>
                                    handleSpouseOffshoreChange(e, i)
                                  }
                                  placeholder=""
                                />
                                {errors?.workOffshoreSpouseInfo &&
                                errors?.workOffshoreSpouseInfo[`${i}`]
                                  ?.sOfficialJobTitle ? (
                                  <span className="error-msg">
                                    {
                                      errors?.workOffshoreSpouseInfo[`${i}`]
                                        ?.sOfficialJobTitle?.message
                                    }
                                  </span>
                                ) : (
                                  ''
                                )}
                              </Form.Group>
                              <Form.Group
                                className={`form-group ${
                                  errors?.workOffshoreSpouseInfo &&
                                  errors?.workOffshoreSpouseInfo[`${i}`]
                                    ?.sJobDescription
                                    ? 'error-msg'
                                    : ''
                                }`}
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Job Description*</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="sJobDescription"
                                  disabled={
                                    spouseOffshoreDis
                                      ? spouseOffshoreDis
                                      : false
                                  }
                                  defaultValue={child?.sJobDescription}
                                  onChange={(e) =>
                                    handleSpouseOffshoreChange(e, i)
                                  }
                                  placeholder="Example: dentistry, law, optometry..."
                                />
                                {errors?.workOffshoreSpouseInfo &&
                                errors?.workOffshoreSpouseInfo[`${i}`]
                                  ?.sJobDescription ? (
                                  <span className="error-msg">
                                    {
                                      errors?.workOffshoreSpouseInfo[`${i}`]
                                        ?.sJobDescription?.message
                                    }
                                  </span>
                                ) : (
                                  ''
                                )}
                              </Form.Group>
                            </div>
                            <div className="box-grid-two">
                              <Form.Group
                                className={`form-group ${
                                  errors?.workOffshoreSpouseInfo &&
                                  errors?.workOffshoreSpouseInfo[`${i}`]
                                    ?.sFullTimePosition
                                    ? 'error-msg'
                                    : ''
                                }`}
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Full-Time Position?*</Form.Label>
                                <Form.Select
                                  aria-label="Default select example"
                                  name="sFullTimePosition"
                                  disabled={
                                    spouseOffshoreDis
                                      ? spouseOffshoreDis
                                      : false
                                  }
                                  defaultValue={child?.sFullTimePosition
                                    ? child?.sFullTimePosition
                                    : ''}
                                  onChange={(e) =>
                                    handleSpouseOffshoreChange(e, i)
                                  }
                                >
                                  <option value="">
                                    Select
                                  </option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </Form.Select>
                                {errors?.workOffshoreSpouseInfo &&
                                errors?.workOffshoreSpouseInfo[`${i}`]
                                  ?.sFullTimePosition ? (
                                  <span className="error-msg">
                                    {
                                      errors?.workOffshoreSpouseInfo[`${i}`]
                                        ?.sFullTimePosition?.message
                                    }
                                  </span>
                                ) : (
                                  ''
                                )}
                              </Form.Group>

                              <Form.Group
                                className={`form-group ${
                                  errors?.workOffshoreSpouseInfo &&
                                  errors?.workOffshoreSpouseInfo[`${i}`]
                                    ?.sHoursPerWeek
                                    ? 'error-msg'
                                    : ''
                                }`}
                                controlId="formBasicEmail"
                              >
                                <Form.Label>
                                  How many hours per week?*
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="sHoursPerWeek"
                                  disabled={
                                    spouseOffshoreDis
                                      ? spouseOffshoreDis
                                      : false
                                  }
                                  defaultValue={child?.sHoursPerWeek}
                                  onChange={(e) =>
                                    handleSpouseOffshoreChange(e, i)
                                  }
                                  placeholder=""
                                />
                                {errors?.workOffshoreSpouseInfo &&
                                errors?.workOffshoreSpouseInfo[`${i}`]
                                  ?.sHoursPerWeek ? (
                                  <span className="error-msg">
                                    {
                                      errors?.workOffshoreSpouseInfo[`${i}`]
                                        ?.sHoursPerWeek?.message
                                    }
                                  </span>
                                ) : (
                                  ''
                                )}
                              </Form.Group>
                            </div>
                            <div className="box-grid-three">
                              <Form.Group
                                className={`form-group ${
                                  errors?.workOffshoreSpouseInfo &&
                                  errors?.workOffshoreSpouseInfo[`${i}`]
                                    ?.sYearsExperience
                                    ? 'error-msg'
                                    : ''
                                }`}
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Years of Experience*</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder=""
                                  name="sYearsExperience"
                                  defaultValue={child?.sYearsExperience}
                                  disabled={
                                    spouseOffshoreDis
                                      ? spouseOffshoreDis
                                      : false
                                  }
                                  onChange={(e) =>
                                    handleSpouseOffshoreChange(e, i)
                                  }
                                />
                                {errors?.workOffshoreSpouseInfo &&
                                errors?.workOffshoreSpouseInfo[`${i}`]
                                  ?.sYearsExperience ? (
                                  <span className="error-msg">
                                    {
                                      errors?.workOffshoreSpouseInfo[`${i}`]
                                        ?.sYearsExperience?.message
                                    }
                                  </span>
                                ) : (
                                  ''
                                )}
                              </Form.Group>

                              <Form.Group
                                className={`form-group ${
                                  errors?.workOffshoreSpouseInfo &&
                                  errors?.workOffshoreSpouseInfo[`${i}`]
                                    ?.sJobStartDate
                                    ? 'error-msg'
                                    : ''
                                }`}
                                controlId="formBasicEmail"
                              >
                                <Form.Label>
                                  Job Start Date (Month/Year)*
                                </Form.Label>
                                <DatePicker
                                  type="sJobStartDate"
                                  name="sJobStartDate"
                                  placeholderText="Select Date"
                                  //selected={child.jobStartDate}
                                  disabled={
                                    spouseOffshoreDis
                                      ? spouseOffshoreDis
                                      : false
                                  }
                                  selected={
                                    child?.sJobStartDate
                                      ? new Date(child?.sJobStartDate)
                                      : new Date()
                                  }
                                  onChange={(date) =>
                                    handleJobStartDatePicker(
                                      date,
                                      i,
                                      'spouseOffshoreStartDate',
                                    )
                                  }
                                  className="form-select"
                                  dateFormat="dd-MM-yyyy"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                />
                                {errors?.workOffshoreSpouseInfo &&
                                errors?.workOffshoreSpouseInfo[`${i}`]
                                  ?.sJobStartDate ? (
                                  <span className="error-msg">
                                    {
                                      errors?.workOffshoreSpouseInfo[`${i}`]
                                        ?.sJobStartDate?.message
                                    }
                                  </span>
                                ) : (
                                  ''
                                )}
                              </Form.Group>
                              <Form.Group
                                className={`form-group ${
                                  errors?.workOffshoreSpouseInfo &&
                                  errors?.workOffshoreSpouseInfo[`${i}`]
                                    ?.sJobEndTime
                                    ? 'error-msg'
                                    : ''
                                }`}
                                controlId="formBasicEmail"
                              >
                                <Form.Label>
                                  Job End Date (Month/Year)*
                                </Form.Label>
                                <DatePicker
                                  type="sJobEndTime"
                                  name="sJobEndTime"
                                  placeholderText="Select Date"
                                  disabled={
                                    spouseOffshoreDis
                                      ? spouseOffshoreDis
                                      : false
                                  }
                                  selected={
                                    child?.sJobEndTime
                                      ? new Date(child?.sJobEndTime)
                                      : new Date()
                                  }
                                  onChange={(date) =>
                                    handleJobEndTimePicker(
                                      date,
                                      i,
                                      'spouseOffshoreJobEndTime',
                                    )
                                  }
                                  className="form-select"
                                  dateFormat="dd-MM-yyyy"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                />
                                {errors?.workOffshoreSpouseInfo &&
                                errors?.workOffshoreSpouseInfo[`${i}`]
                                  ?.sJobEndTime ? (
                                  <span className="error-msg">
                                    {
                                      errors?.workOffshoreSpouseInfo[`${i}`]
                                        ?.sJobEndTime?.message
                                    }
                                  </span>
                                ) : (
                                  ''
                                )}
                              </Form.Group>
                            </div>
                            <div className="box-grid-two">
                              <Form.Group
                                className={`form-group ${
                                  errors?.workOffshoreSpouseInfo &&
                                  errors?.workOffshoreSpouseInfo[`${i}`]
                                    ?.sMonthlyIncome
                                    ? 'error-msg'
                                    : ''
                                }`}
                                controlId="formBasicEmail"
                              >
                                <Form.Label>
                                  What is Your Monthly Income*
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="sMonthlyIncome"
                                  disabled={offshoreDis ? offshoreDis : false}
                                  defaultValue={child?.sMonthlyIncome}
                                  onChange={(e) =>
                                    handleSpouseOffshoreChange(e, i)
                                  }
                                />
                                {errors?.workOffshoreSpouseInfo &&
                                errors?.workOffshoreSpouseInfo[`${i}`]
                                  ?.sMonthlyIncome ? (
                                  <span className="error-msg">
                                    {
                                      errors?.workOffshoreSpouseInfo[`${i}`]
                                        ?.sMonthlyIncome?.message
                                    }
                                  </span>
                                ) : (
                                  ''
                                )}
                              </Form.Group>
                              <Form.Group
                                className={`form-group ${
                                  errors?.workOffshoreSpouseInfo &&
                                  errors?.workOffshoreSpouseInfo[`${i}`]
                                    ?.sMoneyBringToCanada
                                    ? 'error-msg'
                                    : ''
                                }`}
                                controlId="formBasicEmail"
                              >
                                <Form.Label>
                                  How Much Money Bring to Canada*
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="sMoneyBringToCanada"
                                  disabled={offshoreDis ? offshoreDis : false}
                                  defaultValue={child?.sMoneyBringToCanada}
                                  onChange={(e) =>
                                    handleSpouseOffshoreChange(e, i)
                                  }
                                />
                                {errors?.workOffshoreSpouseInfo &&
                                errors?.workOffshoreSpouseInfo[`${i}`]
                                  ?.sMoneyBringToCanada ? (
                                  <span className="error-msg">
                                    {
                                      errors?.workOffshoreSpouseInfo[`${i}`]
                                        ?.sMoneyBringToCanada?.message
                                    }
                                  </span>
                                ) : (
                                  ''
                                )}
                              </Form.Group>
                            </div>
                            <div className="add-btn">
                              {spouseJobsOffshore &&
                              spouseJobsOffshore.length === 1 ? (
                                <Button
                                  onClick={handleAddSpouseJobOffshore}
                                  disabled={
                                    spouseOffshoreDis
                                      ? spouseOffshoreDis
                                      : false
                                  }
                                >
                                  Add Job <i className="las la-plus"></i>
                                </Button>
                              ) : (
                                ''
                              )}
                              {i >= 0 && i !== 0 && (
                                <>
                                  <Button
                                    className="delete-btn"
                                    disabled={
                                      spouseOffshoreDis
                                        ? spouseOffshoreDis
                                        : false
                                    }
                                    onClick={() =>
                                      removeAddSpouseOffshoreJob(i)
                                    }
                                  >
                                    Delete Job #{i + 1}{' '}
                                    <i class="las la-minus"></i>
                                  </Button>
                                  <Button
                                    onClick={handleAddSpouseJobOffshore}
                                    disabled={
                                      spouseOffshoreDis
                                        ? spouseOffshoreDis
                                        : false
                                    }
                                  >
                                    Add Job <i className="las la-plus"></i>
                                  </Button>
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      ))}
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </div>
        </Accordion>

        <div className="visa-btn">
          {!offshoreDis ? (
            <Button
              type="submit"
              disabled={offshoreDis ? offshoreDis : false}
              className={`${offshoreDis ? 'success-btn' : ''}`}
            >
              Save {offshoreDis && <img src={approved} alt="approved" />}
            </Button>
          ) : (
            <div className="for-new-changes">
              <p>
                <span>Important! </span> Please contact our Support team if you
                would like to make new changes .
              </p>
            </div>
          )}
          {!offshoreDis ? (
            <div className="visa-desc-text">
              <p>
                Once your form is completed, you will be able to make new
                changes by contacting our Support team.
              </p>
              <p>When you're ready, click "Submit"!</p>
            </div>
          ) : (
            ''
          )}
        </div>
      </Form>
      <ErrorModal show={showErrorModal} handleClose={handleShowErrorModal} />
    </div>
  )
}
export default WorkExperience
