const UploadIcon = () => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.30481 3.79659H8.08315V11.9375C8.08315 12.0434 8.16979 12.13 8.27567 12.13H9.71952C9.8254 12.13 9.91203 12.0434 9.91203 11.9375V3.79659H11.6952C11.8564 3.79659 11.9455 3.6113 11.8468 3.48616L9.1516 0.0738647C9.1336 0.0508551 9.11059 0.0322463 9.08432 0.0194486C9.05805 0.00665091 9.02922 0 9 0C8.97078 0 8.94195 0.00665091 8.91568 0.0194486C8.88941 0.0322463 8.8664 0.0508551 8.8484 0.0738647L6.15321 3.48376C6.05455 3.6113 6.14358 3.79659 6.30481 3.79659ZM17.8075 11.2156H16.3636C16.2578 11.2156 16.1711 11.3022 16.1711 11.4081V15.114H1.82888V11.4081C1.82888 11.3022 1.74225 11.2156 1.63636 11.2156H0.192513C0.086631 11.2156 0 11.3022 0 11.4081V16.1728C0 16.5987 0.344118 16.9428 0.770053 16.9428H17.2299C17.6559 16.9428 18 16.5987 18 16.1728V11.4081C18 11.3022 17.9134 11.2156 17.8075 11.2156Z"
        fill="#6E6F6F"
      />
    </svg>
  );
};

export default UploadIcon;
