import { createContext } from 'react'

const UserContext = createContext({
  token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
  user: {},
  users: [],
  countries: [],
  savePercentage: {},
 setUser: () => {},
  setCountries: () => {}, 
  setSavePercentage: () => {},
  selfProduct: () => {},
  setSelfProduct: () => {},
})

export default UserContext
