import { React, useEffect, useState } from "react";
import Layout from "../layout/layout";
// import img from "../../assets/images/worldTravel/image.png";
// import img1 from "../../assets/images/worldTravel/image1.png";
import "./WorldTravel.css";
import PlansSection from "./planSection";
import WorldTestimonial from "./Testimonial";
import TravelSection from "./TravelSection";
import { Button } from "react-bootstrap";
import ChatPopup from "./ChatPopup";
import chatIcon from "../../assets/icons/chatimg.gif";
// import closeIcon from "../../assets/icons/Close.png";

const WorldTravel = () => {
  useEffect(() => {
    const position = document.getElementById("getplansection");
    position.scrollIntoView({ behavior: "smooth", block: "start" });
  });
  const [openChat, setOpenChat] = useState(false);
  return (
    <Layout>
      <div className='WorldTravel-page'>
        <div className='container'>
          <div className='WorldTravel-intro'>
            <h2>Work and Travel Packages by UIS Canada</h2>
            <p>
              Set out on an extraordinary adventure to Canada and break free
              from your regular 9-5 schedule! Take a trip to the Great White
              North and discover stunning destinations, national parks, and
              hidden gems. We have tailor-made Working Holiday packages that
              will allow you to meet new people and experience diverse cultures
              while earning money along the way.
            </p>
          </div>
        </div>
        <TravelSection />
        <PlansSection />
        <WorldTestimonial />
        <Button
          onClick={() => setOpenChat(!openChat)}
          className={`chat-icon ${openChat ? "close-icon" : "open-chat-icon"}`}
        >
          {openChat ? "" : <img src={chatIcon} alt='chat-img' />}
        </Button>
      </div>
      <ChatPopup openChat={openChat} setOpenChat={setOpenChat} />
    </Layout>
  );
};

export default WorldTravel;
