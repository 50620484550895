import React from "react";
import Layout from "../layout/layout";
import VisaContact from "./visacontact";
import { useTranslation } from "react-i18next";
import temporaryicon from "../../assets/images/visa/temporary-icon.svg";
import temporary from "../../assets/images/visa/temporary.png";
import "./visa.css";

const TemporaryVisa = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <div className='worker-visa-page'>
        <div className='worker-visa-banner'>
          <div className='container'>
            <div className='worker-grid'>
              <div className='worker-content'>
                <div className='worker-visa-banner-heading'>
                  <img src={temporaryicon} alt='' />
                  <div className='worker-visa-banner-heading-title'>
                    <h5>{t("visaTemporary.Heading1")}</h5>
                    <h2>{t("visaTemporary.Heading2")}</h2>
                  </div>
                </div>

                <p>{t("visaTemporary.Paragraph1")}</p>

                <p>{t("visaTemporary.Paragraph2")}</p>

                
              </div>

              <div className='worker-cover'>
                <img src={temporary} alt='' />
              </div>
            </div>
          </div>
        </div>

        <VisaContact />
      </div>
    </Layout>
  );
};

export default TemporaryVisa;
