export const parentsSpouseSponsorGen = [
  {
    name: 'sponsoredFrontPassportCopy',
    label: 'Passport Copy',
    secondLabel: '(front page + stamp / visa pages if any)',
    isRequired: false,
  },
  {
    name: 'sponsoredUpdatedCv',
    label: 'Updated C.V',
    secondLabel: '',
    isRequired: false,
  },
  {
    name: 'sponsoredTwoDigitalPhoto',
    label: 'Two digital Photo',
    secondLabel: '',
    isRequired: false,
  },
  {
    name: 'sponsoredBirthCertificate',
    label: 'Copy of Birth Certificate',
    secondLabel: '',
    isRequired: false,
  },
  {
    name: 'sponsoredProofOfAddress',
    label: 'Proof of Address',
    secondLabel: '(example: water, gas, or electricity bill)',
    isRequired: false,
  },
  {
    name: 'sponsoredDivorceCertificate',
    label: 'Divorce certificate',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'sponsoredServiceOrderAgreement',
    label: 'Service order agreement',
    secondLabel: '',
    isRequired: false,
  },
]
export const partnerSpouseVisaSponsoredGen = [
  {
    name: 'sponsoredFrontPassportCopy',
    label: 'Passport Copy',
    secondLabel: '(front page + stamp / visa pages if any)',
    isRequired: false,
  },
  {
    name: 'sponsoredUpdatedCv',
    label: 'Updated C.V',
    secondLabel: '',
    isRequired: false,
  },
  {
    name: 'sponsoredTwoDigitalPhoto',
    label: 'Two digital Photos',
    secondLabel: '',
    isRequired: false,
  },
  {
    name: 'sponsoredBirthCertificate',
    label: 'Copy of Birth Certificate',
    secondLabel: '',
    isRequired: false,
  },
  {
    name: 'sponsoredProofOfAddress',
    label: 'Proof of Address',
    secondLabel: '(example: water, gas, or electricity bill)',
    isRequired: false,
  },
  {
    name: 'sponsoredServiceOrderAgreement',
    label: 'Service order agreement',
    secondLabel: '',
    isRequired: false,
  },
]
export const superVisaSponsoredGen = [
  {
    name: 'sponsoredFrontPassportCopy',
    label: 'Passport Copy',
    secondLabel: '(front page + stamp / visa pages if any)',
    isRequired: false,
  },
  {
    name: 'sponsoredUpdatedCv',
    label: 'Updated C.V',
    secondLabel: '',
    isRequired: false,
  },
  {
    name: 'sponsoredTwoDigitalPhoto',
    label: 'Two digital Photo',
    secondLabel: '',
    isRequired: false,
  },
  {
    name: 'sponsoredBirthCertificate',
    label: 'Copy of Birth Certificate',
    secondLabel: '',
    isRequired: false,
  },
  {
    name: 'sponsoredMarriageCertificate',
    label: 'Marriage Certificate',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'sponsoredDeathCertificate',
    label: 'Death Certificate',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'sponsoredCommonLawDeclaration',
    label: 'Common-Law Declaration',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'sponsoredDivorceCertificate',
    label: 'Divorce certificate',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'sponsoredServiceOrderAgreement',
    label: 'Service order agreement',
    secondLabel: '',
    isRequired: false,
  },
]
export const selfEmpWorkPermitVisaSponsoredGen = [
  {
    name: 'sponsoredFrontPassportCopy',
    label: 'Passport Copy',
    secondLabel: '(front page + stamp / visa pages if any)',
    isRequired: false,
  },
  {
    name: 'sponsoredUpdatedCv',
    label: 'Updated C.V',
    secondLabel: '',
    isRequired: false,
  },
  {
    name: 'sponsoredTwoDigitalPhoto',
    label: 'A digital Photo',
    secondLabel: '',
    isRequired: false,
  },
  {
    name: 'sponsoredBirthCertificate',
    label: 'Copy of Birth Certificate',
    secondLabel: '',
    isRequired: false,
  },
  {
    name: 'sponsoredMarriageCertificate',
    label: 'Marriage Certificate',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'sponsoredDeathCertificate',
    label: 'Death Certificate',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'sponsoredCommonLawDeclaration',
    label: 'Common-Law Declaration',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'sponsoredDivorceCertificate',
    label: 'Divorce certificate',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'sponsoredServiceOrderAgreement',
    label: 'Service order agreement',
    secondLabel: '',
    isRequired: false,
  },
]
