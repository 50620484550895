import React, { useState } from 'react'
import Layout from '../layout/layout'
import './pricing.css'
// import icon1 from '../../assets/images/home/permanent-icon-2.svg'
// import icon2 from '../../assets/images/home/permanent-icon-3.svg'
// import icon3 from '../../assets/images/home/permanent-icon-4.svg'
import PricingBox from './pricingBox'
import PricingModal from './pricingModal'
import axios from 'axios'
import { serverPath } from '../../config/key'
import { useEffect } from 'react'
import Loader from '../loader/loader'

const Pricing = ({ user }) => {
  const [pricingModal, setPricingModal] = useState(false)
  const [products, setProducts] = useState([])
  const [product, setProduct] = useState({})
  const [loading, setLoading] = useState(false)
  const allProducts = async () => {
    setLoading(true)
    const res = await axios.get(`${serverPath}/product/allPublicProduct`)
    const {
      data: { products },
    } = res
    setLoading(false)
    if (products?.length > 0) {
      setProducts(products)
    }
  }
  useEffect(() => {
    allProducts()
  }, [])
  const handlePricingModel = (pro) => {
    setPricingModal(true)
    setProduct(pro)
  }
  return (
    <Layout>
      <div className="pricing-page">
        <div className="container">
          <div className="heading-section">
            <h2>
              Congratulations
              {user && <> {user}</>}
            </h2>
            <p>Please select the product you need to complete the payment</p>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className="pricing-columns">
              {products &&
                products?.length > 0 &&
                products.map((pro) => (
                  <React.Fragment key={pro._id}>
                    <PricingBox
                      icon={pro?.image}
                      product={pro?.name}
                      mainPrice={pro?.price}
                      discPrice="50"
                      popular={pro.isPopular ? pro.isPopular : false}
                      onClick={() => handlePricingModel(pro)}
                      data={
                        pro?.publicDescription ? pro?.publicDescription : []
                      }
                    />
                  </React.Fragment>
                ))}
            </div>
          )}
        </div>
      </div>

      <PricingModal
        show={pricingModal}
        setShow={setPricingModal}
        product={product}
      />
    </Layout>
  )
}

export default Pricing
