/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import PhoneInput from 'react-phone-input-2'
import { useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import { serverPath } from '../../config/key'
import { useTranslation } from 'react-i18next'
import checkicon from '../../assets/images/header/success.svg'
// import icon1 from '../../assets/images/header/canada.svg'
// import facebook from '../../assets/images/header/fb.svg'
// import linkedin from '../../assets/images/header/linkedin.svg'
// import twitter from '../../assets/images/header/twitter.svg'

import './user.css'
import { updateUserSchema } from '../../utils/validationSchema'

import UserContext from '../../context/UserContext'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'

const updateUser = ({ activeId,  setEditPage,getUsers,user }) => {
  const { t } = useTranslation()
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue
  } = useForm({
    resolver: yupResolver(updateUserSchema),
  })
  const [succ, setSucc] = useState(false)
  const { countries, userGeoData } = useContext(UserContext)
useEffect(()=>{
if(Object.keys(user).length >0){
  setValue('phone',user?.phoneNumber)
}
},[user])
  const handlePhoneChange = (e) => {
    setValue('phone',+e)
  }
  const handleSubmitForm = async (values) => {
    try {
        const response = await axios.put(
          `${serverPath}/users/updateUser/${activeId}`,
          values,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          },
        )
        const { status } = response
        if (status === 200) {
          setEditPage(false)
            setSucc(true)
            toast.success('Update successfully', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
            })
            getUsers()
        }
    } catch (error) {
      if (error?.response?.data) {
        toast.error(error.response.data.msg, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
      }
    }
  }
  return (
    <div className="consultation-popup-grid">
      
        <div className="consultation-popup-content">
          <h2>Edit User</h2>
          <p>Edit your details to update your profile</p>
          <Form onSubmit={handleSubmit(handleSubmitForm)}>
            {/* <Form.Group>
              <Form.Control
                name="firstName"
                type="text"
                placeholder="First Name"
                value={values?.firstName}
                onChange={handleChange}
              />
              {errors?.firstName ? (
                <span className="text-danger">{errors.firstName}</span>
              ) : (
                ''
              )}
            </Form.Group> */}

            {/* <Form.Group>
              <Form.Control
                name="lastName"
                type="text"
                placeholder="Last Name"
                value={values?.lastName}
                onChange={handleChange}
              />
              {errors?.lastName ? (
                <span className="error-msg">{errors.lastName}</span>
              ) : (
                ''
              )}
            </Form.Group> */}
            <Form.Group>
            <Form.Label>Name</Form.Label>
              <Form.Control
                name="name"
                type="text"
                placeholder="Full Name"
                defaultValue={user?.name ||''}
                {...register('name')}
              />
              {errors?.name ? (
                <span className="error-msg">{errors?.name?.message}</span>
              ) : (
                ''
              )}
            </Form.Group>
            <Form.Group>
            <Form.Label>Email</Form.Label>
              <Form.Control
                name="email"
                type="email"
                placeholder={t('visaContact.emailaddress')}
                defaultValue={user?.email||''}
                {...register('email')}
                disabled
              />
              {errors?.email ? (
                <span className="error-msg">{errors?.email?.message}</span>
              ) : (
                ''
              )}
            </Form.Group>
            {/* <Form.Group>
              <Form.Control
                name="email2"
                type="email"
                placeholder={t('visaContact.emailaddress')}
                value={user?.email2}
                onChange={handleChange}
              />
              {errors?.email2 ? (
                <span className="error-msg">{errors.email2}</span>
              ) : (
                ''
              )}
            </Form.Group> */}

            {/* <Form.Group>
              <Form.Control
                type="text"
                name="address"
                placeholder="Address"
                value={user?.address}
                onChange={handleChange}
              />
              {errors?.address ? (
                <span className="error-msg">{errors.address}</span>
              ) : (
                ''
              )}
            </Form.Group> */}
            <Form.Group>
            <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                name="city"
                placeholder="city"
                defaultValue={user?.city ||''}
                {...register('city')}
              />
              {errors?.city ? (
                <span className="error-msg">{errors?.city?.message}</span>
              ) : (
                ''
              )}
            </Form.Group>
            <Form.Group>
            <Form.Label>Country</Form.Label>
              <Form.Select  name="country" {...register('country')}>
                <option
                  value={user?.country ? user?.country : ''}
                  name="country"
                  selected
                >
                  {user?.country ? user?.country : 'Select Country'}
                </option>
                {countries &&
                  countries?.length > 0 &&
                  countries.map((el) => (
                    <option value={el?.name} name="country">
                      {el?.name}
                    </option>
                  ))}
              </Form.Select>
              {errors?.country ? (
                <span className="error-msg">{errors?.country?.message}</span>
              ) : (
                ''
              )}
            </Form.Group>
            <Form.Group>
            <Form.Label>Phone</Form.Label>
              <PhoneInput
                country={userGeoData?.country_code?.toLowerCase()}
                onChange={handlePhoneChange}
                value={user?.phoneNumber ||''}
                inputProps={{
                  name: 'phone',
                }}
              />

              {errors?.phone ? (
                <span className="error-msg">{errors?.phone?.message}</span>
              ) : (
                ''
              )}
            </Form.Group>

            <Button
              variant="primary"
              type="submit"
              className={`${succ ? `success-btn` : ``}`}
              // onClick={handleSubmitForm}
            >
              {succ
                ? t('homeNewsletter.successButton')
                : t('consultationPopup.submit')}
              {succ ? <img src={checkicon} alt="" /> : ''}
            </Button>
          </Form>
        </div>
  
    </div>
  )
}

export default updateUser
