import React from "react";
import ApplicationLayout from "./layout";
import sign from "../../assets/images/application/regards.svg";

const Welcome = () => {
  return (
    <ApplicationLayout>
      <div className={`welcome-page`}>
        <div className="container">
          <div className={`welcome-page-content scroll-content`}>
            <p>Congratulations on opening your UIS Canada account!</p>

            <p>
              Go ahead and explore the Guidelines, Application, and Invoices
              sections.
            </p>

            <p>
              In the "Guidelines" section, you will find explanations about the
              required information and documents for your application process.
              In the "Application" section, you can start your visa application
              and complete the necessary information. If you purchased a Visa
              Evaluation, you can view your results by clicking on the
              "Products" section.
            </p>

            <p>
              Lastly, in the "Invoices" section, you will find a list of your
              purchases.
            </p>

            <p>
              If you have any questions, feel free to contact our support team
              at any time!
            </p>
            <div className="regards-sign">
              <img src={sign} alt="" />
            </div>
            <span>UIS Canada Team</span>
          </div>
        </div>
      </div>
    </ApplicationLayout>
  );
};

export default Welcome;
