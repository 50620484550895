import React, { useState } from "react";
import ApplicationLayout from "../layout";
import closeicon from "../../../assets/images/application/close.svg";

const Notification = () => {
  const [newNotification, setNewnotification] = useState('new');

const handleNotification = (value) => {
  setNewnotification(value);
}

  return (
    <ApplicationLayout>
      <div className={`notification-page`}>
        <div className="container">
          <div className="notification-heading">
            <h3>Notifications</h3>
            <div className="notification-tab">
              <h6 onClick={() => handleNotification('new')} className={`${newNotification === 'new' ? 'active-link' : ''}`}>New</h6>
              <h6 onClick={() => handleNotification('cleared')} className={`${newNotification === 'cleared' ? 'active-link' : ''}`}>Cleared</h6>
            </div>
          </div>
          <div className={`notification-page-content scroll-content`}>
            <div className="notification-grid">
              <div className={`notification-box-${newNotification}`}>
                <p>
                  Hi there,<br></br>
                  Your application was submitted by legal team.<br></br>
                  <br></br>
                  Good Luck!
                </p>
                <span>
                  <img src={closeicon} alt="" />
                </span>
              </div>
            </div>

            <div className="notification-grid">
            <div className={`notification-box-${newNotification}`}>
                <p>
                  Hi there,<br></br>
                  Thank you for submitting your documents.<br></br>
                  Our legal team is currently reviewing your application.
                  <br></br>
                  If you have any questions, feel free to contact us at any
                  time.<br></br>
                  <br></br>
                  UIS Canada
                </p>
                <span>
                  <img src={closeicon} alt="" />
                </span>
              </div>
            </div>

            <div className="notification-grid">
            <div className={`notification-box-${newNotification}`}>
                <p>
                  Hi, Congratulations!<br></br>
                  You have been submitted to the Working Holiday pool.<br></br>
                  Results are typically delivered within 4 - 8 weeks (although,
                  this could take a bit longer…). We will notify you as soon as
                  you are selected for a Working Holiday Visa.<br></br>
                  <br></br>
                  Good luck!<br></br>
                  UIS Canada
                </p>
                <span>
                  <img src={closeicon} alt="" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ApplicationLayout>
  );
};

export default Notification;
