export const spousePnpExpressEntryFinanceDocMain = [
  {
    name: 'spouseEmpReferenceLetter',
    label: 'Employment Reference Letter',
    secondLabel: ' (if applicable)',
  },
  {
    name: 'spouseBankStatement',
    label: 'Recent Bank Statement',
    secondLabel:
      '(account balance must include CAD$13,310 + CAD$3,586 for each family member that is joining you)',
  },
]
