import React from 'react'
import ApplicationLayout from '../layout'
import { Form, Button } from 'react-bootstrap'
import { useState } from 'react'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { serverPath } from '../../../config/key'
import { toast } from 'react-toastify'
import {
  updatePasswordSchema,
  // updateUserSchema,
} from '../../../utils/validationSchema'
// import { useContext } from "react";
// import UserContext from "../../../context/UserContext";
import approved from '../../../assets/images/header/approved.svg'
import showicon from '../../../assets/images/login/show-icon.svg'
import hideicon from '../../../assets/images/login/hide-icon.svg'
import { useContext } from 'react'
import UserContext from '../../../context/UserContext'
const Settings = () => {
  const { user } = useContext(UserContext)
  const [values, setValues] = useState({
    password: '',
    confirmPassword: '',
  })
  const [success, setSuccess] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfPassword, setShowConfPassword] = useState(false)
  const handleChange = (e) => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }
  const handleShowPassword = () => {
    setShowPassword(!showPassword)
  }
  const handleShowConfPassword = () => {
    setShowConfPassword(!showConfPassword)
  }
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(updatePasswordSchema) })
  const updatePassword = async (values) => {
    try {
      const response = await axios.post(
        `${serverPath}/users/updatePassword`,
        values,
        {
          headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
        },
      )
      const { upd } = response.data
      if (upd.modifiedCount === 1) {
        reset()
        toast.success('Password updated successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
        setSuccess(true)
      }
    } catch (error) {
      if (error?.response?.data) {
        toast.error(error.response.data.msg, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
      }
    }
  }
  return (
    <ApplicationLayout>
      {/* <UpdateUserDetail /> */}
      <div className={`settings-page`}>
        <div className="container">
          <h3>Settings</h3>
          <div className={`settings-page-content`}>
            <Form
              onSubmit={handleSubmit(updatePassword)}
              className={`${success ? 'success-form' : ''}`}
            >
              <h4>Username</h4>
              <Form.Group>
                <Form.Control
                  type="text"
                  name="username"
                  placeholder="Username"
                  defaultValue={user?.email ? user?.email : ''}
                  disabled
                />
                <h4>Password</h4>
                <p>Edit your account setting and change your password here.</p>
              </Form.Group>
              <Form.Group>
                <Form.Label>New Password*</Form.Label>
                <div className="pass-show">
                  <span className="pass-icon" onClick={handleShowPassword}>
                    {showPassword ? (
                      <img src={hideicon} alt="" />
                    ) : (
                      <img src={showicon} alt="" />
                    )}
                  </span>
                  <Form.Control
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    onChange={handleChange}
                    {...register('password')}
                    defaultValue={values.password}
                  />
                </div>

                {errors?.password ? (
                  <span className="error-msg">{errors.password.message}</span>
                ) : (
                  ''
                )}
              </Form.Group>
              <Form.Group>
                <Form.Label>Confirm New Password*</Form.Label>
                <div className="pass-show">
                  <span className="pass-icon" onClick={handleShowConfPassword}>
                    {showConfPassword ? (
                      <img src={hideicon} alt="" />
                    ) : (
                      <img src={showicon} alt="" />
                    )}
                  </span>
                  <Form.Control
                    type={showConfPassword ? 'text' : 'password'}
                    name="confirmPassword"
                    onChange={handleChange}
                    {...register('confirmPassword')}
                    defaultValue={values.confirmPassword}
                  />
                </div>

                {errors?.confirmPassword ? (
                  <span className="error-msg">
                    {errors.confirmPassword.message}
                  </span>
                ) : (
                  ''
                )}
              </Form.Group>
              <Button type="submit">
                Submit {success && <img src={approved} alt="approved" />}
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </ApplicationLayout>
  )
}

export default Settings

// const UpdateUserDetail = () => {
//   const { user, countries, setUser } = useContext(UserContext);
//   const {
//     handleSubmit,
//     register,
//     formState: { errors },
//     setValue,
//   } = useForm({
//     resolver: yupResolver(updateUserSchema),
//     defaultValues: {
//       name: user?.name,
//       email: user?.email,
//       phoneNumber: user?.phoneNumber,
//       city: user?.city,
//       country: user?.country,
//       countryCode: "",
//     },
//   });
//   const handleCountryChange = (e) => {
//     const data = countries.filter((el) => el.name === e.target.value);
//     setValue("country", e.target.value);
//     setValue("countryCode", data[0]?.countryCode);
//   };
//   const handleUpdateUserInfo = async (values) => {
//     try {
//       const res = await axios.put(
//         `${serverPath}/users/updateProfile`,
//         { ...user, ...values },
//         {
//           headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
//         }
//       );

//       const { data, status } = res;
//       if (status === 200) {
//         setUser(data.updateduser);
//         toast.success("Information updated successfully", {
//           position: "top-right",
//           autoClose: 5000,
//           hideProgressBar: false,
//           closeOnClick: true,
//         });
//       }
//     } catch (error) {
//       console.log("Error is here-->", error);
//       toast.error(error.response.data.msg, {
//         position: "top-right",
//         autoClose: 5000,
//         hideProgressBar: false,
//         closeOnClick: true,
//       });
//     }
//   };
//   return (
//     <div className={`settings-page`}>
//       <div className="container">
//         <h3>General Information</h3>
//         <div className={`settings-page-content`}>
//           <h4>User</h4>
//           <p>Update the user detail</p>
//           <Form onSubmit={handleSubmit(handleUpdateUserInfo)}>
//             <Form.Group className="form-group">
//               <Form.Label>Name</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Enter your First Name"
//                 name="name"
//                 defaultValue={user?.name}
//                 {...register("name")}
//               />
//               {errors?.name ? (
//                 <span className="error-msg">{errors.name.message}</span>
//               ) : (
//                 ""
//               )}
//             </Form.Group>
//             <Form.Group className="form-group">
//               <Form.Label>Mobile</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="phoneNumber"
//                 placeholder="Enter phone number"
//                 defaultValue={user?.phoneNumber}
//                 {...register("phoneNumber")}
//               />
//               {errors?.phoneNumber ? (
//                 <span className="error-msg">{errors.phoneNumber.message}</span>
//               ) : (
//                 ""
//               )}
//             </Form.Group>

//             <Form.Group className="form-group">
//               <Form.Label>Email</Form.Label>
//               <Form.Control
//                 type="email"
//                 name="email"
//                 placeholder="Enter your email"
//                 defaultValue={user?.email}
//                 {...register("email")}
//               />
//               {errors?.email ? (
//                 <span className="error-msg">{errors.email.message}</span>
//               ) : (
//                 ""
//               )}
//             </Form.Group>
//             <Form.Group className="form-group">
//               <Form.Label>City</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="city"
//                 placeholder="Enter your city"
//                 defaultValue={user?.city}
//                 {...register("city")}
//               />
//               {errors?.city ? (
//                 <span className="error-msg">{errors.city.message}</span>
//               ) : (
//                 ""
//               )}
//             </Form.Group>
//             <Form.Group>
//               <Form.Label>Country</Form.Label>
//               <Form.Select
//                 onChange={handleCountryChange}
//                 name="country"
//                 defaultValue={user?.country}
//               >
//                 <option
//                   value={user?.country ? user?.country : ""}
//                   name="country"
//                   selected
//                 >
//                   {user?.country ? user?.country : "Select Country"}
//                 </option>

//                 {countries &&
//                   countries?.length > 0 &&
//                   countries.map((el) => (
//                     <option value={el?.name} name="country">
//                       {el?.name}
//                     </option>
//                   ))}
//               </Form.Select>
//             </Form.Group>

//             <div className="step-btn">
//               <Button type="submit">Submit</Button>
//             </div>
//           </Form>
//         </div>
//       </div>
//     </div>
//   );
// };
