import React from "react";
import Layout from "../layout/layout";
import VisaContact from "./visacontact";
import { useTranslation } from "react-i18next";

import familyicon from "../../assets/images/visa/family-icon.svg";
import family from "../../assets/images/visa/family.png";
import "./visa.css";

const FamilyVisa = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <div className='worker-visa-page'>
        <div className='worker-visa-banner'>
          <div className='container'>
            <div className='worker-grid'>
              <div className='worker-content'>
                <div className='worker-visa-banner-heading'>
                  <img src={familyicon} alt='' />
                  <div className='worker-visa-banner-heading-title'>
                    <h5>{t("visaFamily.familyHeading1")}</h5>
                    <h2>{t("visaFamily.familyHeading2")}</h2>
                  </div>
                </div>

                <p>{t("visaFamily.familyParagraph1")}</p>

                <p>{t("visaFamily.familyParagraph2")}</p>

                <p>{t("visaFamily.familyParagraph3")}</p>

                <p>
                  <b>{t("visaFamily.familyParagraph4")}</b>
                </p>
              </div>

              <div className='worker-cover'>
                <img src={family} alt='' />
              </div>
            </div>
          </div>
        </div>

        <VisaContact />
      </div>
    </Layout>
  );
};

export default FamilyVisa;
