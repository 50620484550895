/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import {
  Form,
  Accordion,
  Button,
  useAccordionButton,
  Card,
} from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useContext } from 'react'
import UserContext from '../../context/UserContext'
import { serverPath } from '../../config/key'
import approved from '../../assets/images/header/approved.svg'
import { yupResolver } from '@hookform/resolvers/yup'
import addIcon from '../../assets/images/visa/add-icon.svg'
import activeArrow from '../../assets/images/visa/active-arrow.svg'
import closeArrow from '../../assets/images/visa/close-arrow.svg'
import { evaluationEducationMain } from '../../utils/validationSchema'
import { useParams } from 'react-router-dom'
import ErrorModal from '../modals/errorModal'
const EducationInfo = ({
  educationInfo,
  setEducationInfo,
  adminFormDisable,
  updFormType,
}) => {
  const { formType } = useParams()
  const urlParams = new URLSearchParams(window.location.search)
  const token = urlParams.get('t')
  let intialStateEducation = {
    fieldStudy: '',
    institution: '',
    countryStudy: '',
    completed: '',
    studyCompletionDetail: '',
  }
  let intialStateSpouseEducation = {
    sFieldStudy: '',
    sInstitution: '',
    sCountryStudy: '',
    sCompleted: '',
    sStudyCompletionDetail: '',
  }
  const { countries, selectedVisa, setSelectedVisa } = useContext(UserContext)
  const [addEducation, setAddEducation] = useState(
    educationInfo?.mainApplicantEducation &&
      educationInfo?.mainApplicantEducation?.length > 0
      ? educationInfo?.mainApplicantEducation
      : [{ intialStateEducation }],
  )

  const [addSpouseEducation, setAddSpouseEducation] = useState([])
  const [mainDis, setMainDis] = useState(false)
  const [spouseDis, setSpouseDis] = useState(false)
  const [spouseInfo, setSpouseInfo] = useState('plus')
  const [customAccordBtn, setCustomAccordBtn] = useState(true)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [spouseText, setSpouseText] = useState('')
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(evaluationEducationMain),
  })
  useEffect(() => {
    setValue('educationMainInfo', addEducation)
    if (spouseInfo === 'open') {
      setValue('educationSpouseInfo', addSpouseEducation)
    }
  }, [addEducation, addSpouseEducation, spouseInfo])
  useEffect(() => {
    if (selectedVisa?.visaName === 'Double Evaluation' && !spouseDis) {
      setSpouseText('Add Spouse (Partner) Applicant')
    }
    if (
      (selectedVisa?.visaName === 'Double Evaluation' &&
        spouseInfo === 'open') ||
      (selectedVisa?.visaName === 'Double Evaluation' && spouseDis)
    ) {
      setSpouseText('Spouse (Partner) Applicant')
    }
  }, [selectedVisa, spouseInfo])
  useEffect(() => {
    if (
      educationInfo &&
      educationInfo?.mainApplicantEducation?.length !== 0 &&
      educationInfo.isLocked
    ) {
      setMainDis(token ? adminFormDisable : true)
      setSpouseDis(token ? adminFormDisable : true)
    }

    if (educationInfo && educationInfo?.mainApplicantEducation?.length > 0) {
      setAddEducation(educationInfo?.mainApplicantEducation)
    }
    if (educationInfo && educationInfo?.spouseEducation?.length > 0) {
      setAddSpouseEducation(
        educationInfo?.spouseEducation || [{ intialStateSpouseEducation }],
      )
    }

    if (token) {
      setValue(
        'highestLevelEdu',
        educationInfo && educationInfo?.highestLevelEdu
          ? educationInfo?.highestLevelEdu
          : '',
      )
      setValue(
        'sHighestLevelEdu',
        educationInfo && educationInfo?.sHighestLevelEdu
          ? educationInfo?.sHighestLevelEdu
          : '',
      )
    }
  }, [educationInfo, token, adminFormDisable])
  const handleAddEducation = () => {
    setAddEducation([...addEducation, { ...intialStateEducation }])
  }
  //add spouse education form
  const handleAddSpouseEducation = () => {
    setAddSpouseEducation([
      ...addSpouseEducation,
      { ...intialStateSpouseEducation },
    ])
  }
  //handle input change main aplicant form
  const handleChange = (e, index) => {
    const { name, value } = e.target
    let prevData = [...addEducation]
    prevData[index][name] = value
    setAddEducation([...prevData])
  }
  //handle input value of spouse information
  const handleSpouseChange = (e, index) => {
    const { name, value } = e.target
    let prevData = [...addSpouseEducation]
    prevData[index][name] = value
    setAddSpouseEducation([...prevData])
  }
  //remove main education form with value
  const removeEducation = (index) => {
    const oldedu = [...addEducation]
    oldedu.splice(index, 1)
    setAddEducation(oldedu)
  }
  //remove spouse education form with value
  const removeSpouseEducation = (index) => {
    const oldedu = [...addSpouseEducation]
    oldedu.splice(index, 1)
    setAddSpouseEducation(oldedu)
  }
  const changeAccordionselect = (key) => {
    if (key === '1') {
      setValue('isEducationSpouseOpen', true)
      setSpouseInfo('open')
    }
  }

  const submitEducationForm = async (values) => {
    try {
      let apiPath = token
        ? 'supportEvaluation/updateEducation'
        : 'evaluation/evaluationEducation'

      const res = await axios.post(
        `${serverPath}/${apiPath}`,
        {
          ...values,
          mainApplicantEducation: addEducation,
          spouseEducation: addSpouseEducation,
          productId: selectedVisa.productId,
          invoiceId: selectedVisa.invoiceId,
          formType: formType || updFormType,
        },
        {
          headers: {
            authorization: `Bearer ${
              token ? token : localStorage.getItem('token')
            }`,
          },
        },
      )
      const { code, create } = res.data
      if (code === 200) {
        setEducationInfo(create)
        setSelectedVisa({
          invoiceId: create?.invoiceId,
          productId: selectedVisa?.productId,
          caseId: selectedVisa?.caseId,
          visaName: selectedVisa?.visaName,
          isEvaluation: selectedVisa?.isEvaluation,
        })
        toast.success('Your information is saved successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
      }
    } catch (err) {
      toast.success(err?.data?.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        toastId: 'err',
      })
    }
  }

  const handleDelete = (value, id) => {
    if (value === 'spouseInfo') {
      setSpouseInfo('plus')
      setValue('isEducationSpouseOpen', false)
    }
    document.getElementById(id).click()
  }
  const handleClose = (value, id) => {
    document.getElementById(id).click()
  }
  //custom accordian toggle
  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      if (customAccordBtn === eventKey) {
        setCustomAccordBtn('')
      } else setCustomAccordBtn(eventKey)
    })
    return (
      <Button id={`edu-${eventKey}`} onClick={decoratedOnClick}>
        {children}
      </Button>
    )
  }

  //For Error popup
  const handleShowErrorModal = () => {
    setShowErrorModal(false)
  }
  useEffect(() => {
    if (errors && Object.keys(errors).length !== 0) {
      setShowErrorModal(true)
    } else {
      setShowErrorModal(false)
    }
  }, [errors])

  return (
    <div className="personal-info education-info-section">
      <Form
        onSubmit={handleSubmit(submitEducationForm)}
        // className={`${mainDis ? 'success-btn' : ''}`}
      >
        <Accordion
          defaultActiveKey="0"
          onSelect={(key) => changeAccordionselect(key)}
        >
          <div className={`accordion-list ${'change-accordion-icon'}`}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Main Applicant</Accordion.Header>

              <Accordion.Body>
                <div className="child-info">
                  <div className="box-grid-two">
                    <Form.Group
                      className={`form-group mb-3 ${
                        errors?.yearsEducation ? 'error-msg' : ''
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>
                        Total years of education (since Grade 1)*
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="yearsEducation"
                        disabled={mainDis ? mainDis : false}
                        defaultValue={
                          educationInfo?.yearsEducation
                            ? educationInfo?.yearsEducation
                            : educationInfo?.sponsoredYearsEducation
                            ? educationInfo?.sponsoredYearsEducation
                            : ''
                        }
                        {...register('yearsEducation')}
                      />
                      {errors?.yearsEducation ? (
                        <span className="error-msg">
                          {errors?.yearsEducation?.message}
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Group>
                    <Form.Group
                      className={`form-group mb-3 ${
                        errors?.highestLevelEdu ? 'error-msg' : ''
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Your Highest Level of Education*</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="highestLevelEdu"
                        disabled={mainDis ? mainDis : false}
                        defaultValue={
                          educationInfo?.highestLevelEdu
                            ? educationInfo?.highestLevelEdu
                            : educationInfo?.sponsoredHighestLevelEdu
                            ? educationInfo?.sponsoredHighestLevelEdu
                            : ''
                        }
                        {...register('highestLevelEdu')}
                      >
                        <option value="">Select</option>
                        <option value="None">None</option>
                        <option value="High School">High School</option>
                        <option value="Diploma / Certificate">
                          Diploma / Certificate
                        </option>
                        <option value="Trade Certificate">
                          Trade Certificate
                        </option>
                        <option value="Bachelor's Degree">
                          Bachelor's Degree
                        </option>
                        <option value="Master's Degree">Master's Degree</option>
                        <option value="PhD">PhD</option>
                      </Form.Select>
                      {errors?.highestLevelEdu ? (
                        <span className="error-msg">
                          {errors?.highestLevelEdu?.message}
                        </span>
                      ) : (
                        ''
                      )}
                    </Form.Group>
                  </div>
                  {addEducation?.length === 0 && (
                    <div className="add-btn">
                      <Button onClick={handleAddEducation}>
                        Add Education <i className="las la-plus"></i>
                      </Button>
                    </div>
                  )}
                </div>
                {addEducation &&
                  addEducation?.length > 0 &&
                  addEducation?.map((child, i) => (
                    <>
                      <h6 className="child-heading education-heading">
                        Education #{i + 1}
                      </h6>
                      <div className="education-info">
                        <div className="box-grid-three">
                          <Form.Group
                            className={`form-group mb-3 ${
                              errors?.educationMainInfo &&
                              errors?.educationMainInfo[`${i}`]?.fieldStudy
                                ? 'error-msg'
                                : ''
                            }`}
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Field of Study*</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Example: dentistry, law, optometry..."
                              name="fieldStudy"
                              disabled={mainDis ? mainDis : false}
                              defaultValue={child.fieldStudy}
                              onChange={(e) => handleChange(e, i)}
                            />
                            {errors?.educationMainInfo &&
                            errors?.educationMainInfo[`${i}`]?.fieldStudy
                              ?.message ? (
                              <span className="error-msg">
                                {
                                  errors?.educationMainInfo[`${i}`]?.fieldStudy
                                    ?.message
                                }
                              </span>
                            ) : (
                              ''
                            )}
                          </Form.Group>
                          <Form.Group
                            className={`form-group mb-3 ${
                              errors?.educationMainInfo &&
                              errors?.educationMainInfo[`${i}`]?.institution
                                ? 'error-msg'
                                : ''
                            }`}
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Institution*</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              name="institution"
                              disabled={mainDis ? mainDis : false}
                              defaultValue={
                                child?.institution !== null &&
                                child?.institution !== null &&
                                child?.institution
                                  ? child?.institution
                                  : ''
                              }
                              onChange={(e) => handleChange(e, i)}
                            >
                              <option value="">{'Select'}</option>
                              <option value="None">None</option>
                              <option value="College">College</option>
                              <option value="University">University</option>
                              <option value="Trade School">Trade School</option>
                              <option value="Technical School">
                                Technical School
                              </option>
                              <option value="Online Course">
                                Online Course
                              </option>
                            </Form.Select>
                            {errors?.educationMainInfo &&
                            errors?.educationMainInfo[`${i}`]?.institution ? (
                              <span className="error-msg">
                                {
                                  errors?.educationMainInfo[`${i}`]?.institution
                                    ?.message
                                }
                              </span>
                            ) : (
                              ''
                            )}
                          </Form.Group>
                          <Form.Group
                            className={`form-group mb-3 ${
                              errors?.educationMainInfo &&
                              errors?.educationMainInfo[`${i}`]?.countryStudy
                                ? 'error-msg'
                                : ''
                            }`}
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Country of Study*</Form.Label>
                            <Form.Select
                              name="countryStudy"
                              disabled={mainDis ? mainDis : false}
                              defaultValue={
                                child.countryStudy ? child.countryStudy : ''
                              }
                              onChange={(e) => handleChange(e, i)}
                            >
                              {child?.countryStudy ? (
                                <option value={child?.countryStudy}>
                                  {child?.countryStudy}
                                </option>
                              ) : (
                                <option value="">Select</option>
                              )}

                              {countries &&
                                countries?.length > 0 &&
                                countries.map((el) => (
                                  <option value={el?.name}>{el?.name}</option>
                                ))}
                            </Form.Select>
                            {errors?.educationMainInfo &&
                            errors?.educationMainInfo[`${i}`]?.countryStudy ? (
                              <span className="error-msg">
                                {
                                  errors?.educationMainInfo[`${i}`]
                                    ?.countryStudy?.message
                                }
                              </span>
                            ) : (
                              ''
                            )}
                          </Form.Group>
                        </div>
                        <div className="box-grid-three">
                          <Form.Group
                            className={`form-group mb-3 ${
                              errors?.educationMainInfo &&
                              errors?.educationMainInfo[`${i}`]?.studyDuration
                                ? 'error-msg'
                                : ''
                            }`}
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Study Duration*</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              name="studyDuration"
                              disabled={mainDis ? mainDis : false}
                              defaultValue={
                                child?.studyDuration ? child?.studyDuration : ''
                              }
                              onChange={(e) => handleChange(e, i)}
                            >
                              <option value="">Select</option>
                              <option value="None">None</option>
                              <option value="Less than 1 Academic Year">
                                Less than 1 Academic Year
                              </option>
                              <option value="1 Academic Year">
                                1 Academic Year
                              </option>
                              <option value="2 Academic Years">
                                2 Academic Years
                              </option>
                              <option value="3 Academic Years">
                                3 Academic Years
                              </option>
                              <option value="4 Academic Years">
                                4 Academic Years
                              </option>
                              <option value="5 Academic Years and above">
                                5 Academic Years and above
                              </option>
                            </Form.Select>
                            {errors?.educationMainInfo &&
                            errors?.educationMainInfo[`${i}`]?.studyDuration ? (
                              <span className="error-msg">
                                {
                                  errors?.educationMainInfo[`${i}`]
                                    ?.studyDuration?.message
                                }
                              </span>
                            ) : (
                              ''
                            )}
                          </Form.Group>

                          <Form.Group
                            className={`form-group mb-3 ${
                              errors?.educationMainInfo &&
                              errors?.educationMainInfo[`${i}`]?.completed
                                ? 'error-msg'
                                : ''
                            }`}
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Completed?*</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              name="completed"
                              disabled={mainDis ? mainDis : false}
                              defaultValue={
                                child.completed ? child.completed : ''
                              }
                              onChange={(e) => handleChange(e, i)}
                            >
                              <option value="">Select</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </Form.Select>
                            {errors?.educationMainInfo &&
                            errors?.educationMainInfo[`${i}`]?.completed ? (
                              <span className="error-msg">
                                {
                                  errors?.educationMainInfo[`${i}`]?.completed
                                    ?.message
                                }
                              </span>
                            ) : (
                              ''
                            )}
                          </Form.Group>
                          {child && child.completed === 'No' ? (
                            <Form.Group
                              className={`form-group mb-3 ${
                                errors?.educationMainInfo &&
                                errors?.educationMainInfo[`${i}`]
                                  ?.studyCompletionDetail
                                  ? 'error-msg'
                                  : ''
                              }`}
                              controlId="formBasicEmail"
                            >
                              <Form.Label>
                                how long until studies are completed?
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="studyCompletionDetail"
                                disabled={mainDis ? mainDis : false}
                                defaultValue={child.studyCompletionDetail}
                                onChange={(e) => handleChange(e, i)}
                              />
                              {errors?.educationMainInfo &&
                              errors?.educationMainInfo[`${i}`]
                                ?.studyCompletionDetail ? (
                                <span className="error-msg">
                                  {
                                    errors?.educationMainInfo[`${i}`]
                                      ?.studyCompletionDetail?.message
                                  }
                                </span>
                              ) : (
                                ''
                              )}
                            </Form.Group>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className="add-btn">
                          {i >= 1 &&
                            (!mainDis ? (
                              <Button
                                className="delete-btn"
                                disabled={mainDis ? mainDis : false}
                                onClick={() => removeEducation(i)}
                              >
                                Delete Education #{i + 1}{' '}
                                <i class="las la-minus"></i>
                              </Button>
                            ) : (
                              ''
                            ))}

                          <Button
                            onClick={handleAddEducation}
                            disabled={mainDis ? mainDis : false}
                          >
                            Add Education <i className="las la-plus"></i>
                          </Button>
                        </div>
                      </div>
                    </>
                  ))}
              </Accordion.Body>
            </Accordion.Item>
          </div>
          <div
            className={`accordion-list ${
              spouseInfo === 'open' ? 'change-accordion-icon' : ''
            }`}
          >
            <Card>
              <Card.Header
                className={customAccordBtn === '1' ? 'accord-open' : ''}
                onClick={() =>
                  spouseInfo === 'open' || spouseDis
                    ? handleClose('spouseInfo', 'edu-1')
                    : ''
                }
              >
                <div className="accordian-title">
                  {spouseText
                    ? spouseText
                    : spouseDis || spouseInfo === 'open'
                    ? 'Spouse Information'
                    : 'Add Spouse Information'}
                  {spouseInfo === 'open' && !spouseDis && (
                    <div className="delete-btn">
                      <Button
                        onClick={() => handleDelete('spouseInfo', 'edu-1')}
                      >
                        Delete <i class="las la-minus"></i>
                      </Button>
                    </div>
                  )}
                </div>

                <CustomToggle eventKey="1">
                  {spouseInfo === 'plus' && !spouseDis ? (
                    <img src={addIcon} alt="Add Icon" />
                  ) : (spouseInfo === 'open' && !spouseDis) ||
                    (spouseInfo === 'plus' && !spouseDis) ||
                    (spouseInfo === 'open' && spouseDis) ||
                    (spouseInfo === 'plus' && spouseDis) ? (
                    <img
                      className="arrow-icon"
                      src={customAccordBtn !== '1' ? closeArrow : activeArrow}
                      alt="Icon"
                    />
                  ) : (
                    ''
                  )}
                </CustomToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <div className="child-info">
                    <div className="box-grid-two">
                      <Form.Group
                        className={`form-group mb-3 ${
                          errors?.educationSpouseInformation?.sYearsEducation
                            ? 'error-msg'
                            : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>
                          Total years of education (since Grade 1)*
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="sYearsEducation"
                          {...register(
                            'educationSpouseInformation.sYearsEducation',
                          )}
                          disabled={mainDis ? mainDis : false}
                          defaultValue={
                            educationInfo?.sYearsEducation
                              ? educationInfo?.sYearsEducation
                              : educationInfo?.sponsoredSpouseYearsEducation
                              ? educationInfo?.sponsoredSpouseYearsEducation
                              : ''
                          }
                        />
                        {errors?.educationSpouseInformation?.sYearsEducation ? (
                          <span className="error-msg">
                            {
                              errors?.educationSpouseInformation
                                ?.sYearsEducation?.message
                            }
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                      <Form.Group
                        className={`form-group mb-3 ${
                          errors?.educationSpouseInformation?.sHighestLevelEdu
                            ? 'error-msg'
                            : ''
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>
                          Your Highest Level of Education*
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="sHighestLevelEdu"
                          disabled={spouseDis ? spouseDis : false}
                          defaultValue={
                            educationInfo?.sHighestLevelEdu
                              ? educationInfo?.sHighestLevelEdu
                              : educationInfo?.sponsoredSpouseHighestLevelEdu
                              ? educationInfo?.sponsoredSpouseHighestLevelEdu
                              : ''
                          }
                          {...register(
                            'educationSpouseInformation.sHighestLevelEdu',
                          )}
                        >
                          <option value="">Select</option>
                          <option value="None">None</option>
                          <option value="High School">High School</option>
                          <option value="Diploma / Certificate">
                            Diploma / Certificate
                          </option>
                          <option value="Trade Certificate">
                            Trade Certificate
                          </option>
                          <option value="Bachelor's Degree">
                            Bachelor's Degree
                          </option>
                          <option value="Master's Degree">
                            Master's Degree
                          </option>
                          <option value="PhD">PhD</option>
                        </Form.Select>
                        {errors?.educationSpouseInformation
                          ?.sHighestLevelEdu ? (
                          <span className="error-msg">
                            {
                              errors?.educationSpouseInformation
                                ?.sHighestLevelEdu?.message
                            }
                          </span>
                        ) : (
                          ''
                        )}
                      </Form.Group>
                    </div>
                  </div>
                  {addSpouseEducation &&
                    addSpouseEducation.length > 0 &&
                    addSpouseEducation.map((child, i) => (
                      <>
                        <h6 className="child-heading education-heading">
                          Education #{i + 1}
                        </h6>
                        <div className="education-info">
                          <div className="box-grid-three">
                            <Form.Group
                              className={`form-group mb-3 ${
                                errors?.educationSpouseInfo &&
                                errors?.educationSpouseInfo[`${i}`]?.sFieldStudy
                                  ? 'error-msg'
                                  : ''
                              }`}
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Field of Study*</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Example: dentistry, law, optometry..."
                                name="sFieldStudy"
                                disabled={spouseDis ? spouseDis : false}
                                defaultValue={child.sFieldStudy}
                                onChange={(e) => handleSpouseChange(e, i)}
                              />
                              {errors?.educationSpouseInfo &&
                              errors?.educationSpouseInfo[`${i}`]
                                ?.sFieldStudy ? (
                                <span className="error-msg">
                                  {
                                    errors?.educationSpouseInfo[`${i}`]
                                      ?.sFieldStudy?.message
                                  }
                                </span>
                              ) : (
                                ''
                              )}
                            </Form.Group>
                            <Form.Group
                              className={`form-group mb-3 ${
                                errors?.educationSpouseInfo &&
                                errors?.educationSpouseInfo[`${i}`]
                                  ?.sInstitution
                                  ? 'error-msg'
                                  : ''
                              }`}
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Institution*</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                name="sInstitution"
                                disabled={spouseDis ? spouseDis : false}
                                defaultValue={
                                  child.sInstitution ? child.sInstitution : ''
                                }
                                onChange={(e) => handleSpouseChange(e, i)}
                              >
                                <option value="">Select</option>
                                <option value="None">None</option>
                                <option value="College">College</option>
                                <option value="University">University</option>
                                <option value="Trade School">
                                  Trade School
                                </option>
                                <option value="Technical School">
                                  Technical School
                                </option>
                                <option value="Online Course">
                                  Online Course
                                </option>
                              </Form.Select>
                              {errors?.educationSpouseInfo &&
                              errors?.educationSpouseInfo[`${i}`]
                                ?.sInstitution ? (
                                <span className="error-msg">
                                  {
                                    errors?.educationSpouseInfo[`${i}`]
                                      ?.sInstitution?.message
                                  }
                                </span>
                              ) : (
                                ''
                              )}
                            </Form.Group>
                            <Form.Group
                              className={`form-group mb-3 ${
                                errors?.educationSpouseInfo &&
                                errors?.educationSpouseInfo[`${i}`]
                                  ?.sCountryStudy
                                  ? 'error-msg'
                                  : ''
                              }`}
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Country of Study*</Form.Label>
                              <Form.Select
                                name="sCountryStudy"
                                disabled={spouseDis ? spouseDis : false}
                                defaultValue={
                                  child.sCountryStudy ? child.sCountryStudy : ''
                                }
                                onChange={(e) => handleSpouseChange(e, i)}
                              >
                                {child?.sCountryStudy ? (
                                <option value={child?.sCountryStudy}>
                                  {child?.sCountryStudy}
                                </option>
                              ) : (
                                <option value="">Select</option>
                              )}
                                <option value="">Select</option>
                                {countries &&
                                  countries?.length > 0 &&
                                  countries.map((el) => (
                                    <option value={el?.name}>{el?.name}</option>
                                  ))}
                              </Form.Select>
                              {errors?.educationSpouseInfo &&
                              errors?.educationSpouseInfo[`${i}`]
                                ?.sCountryStudy ? (
                                <span className="error-msg">
                                  {
                                    errors?.educationSpouseInfo[`${i}`]
                                      ?.sCountryStudy?.message
                                  }
                                </span>
                              ) : (
                                ''
                              )}
                            </Form.Group>
                          </div>
                          <div className="box-grid-three">
                            <Form.Group
                              className={`form-group mb-3 ${
                                errors?.educationSpouseInfo &&
                                errors?.educationSpouseInfo[`${i}`]
                                  ?.sStudyDuration
                                  ? 'error-msg'
                                  : ''
                              }`}
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Study Duration*</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                name="sStudyDuration"
                                disabled={spouseDis ? spouseDis : false}
                                defaultValue={
                                  child?.sStudyDuration
                                    ? child?.sStudyDuration
                                    : ''
                                }
                                onChange={(e) => handleSpouseChange(e, i)}
                              >
                                <option value="">Select</option>
                                <option value="None">None</option>
                                <option value="Less than 1 Academic Year">
                                  Less than 1 Academic Year
                                </option>
                                <option value="1 Academic Year">
                                  1 Academic Year
                                </option>
                                <option value="2 Academic Years">
                                  2 Academic Years
                                </option>
                                <option value="3 Academic Years">
                                  3 Academic Years
                                </option>
                                <option value="4 Academic Years">
                                  4 Academic Years
                                </option>
                                <option value="5 Academic Years and above">
                                  5 Academic Years and above
                                </option>
                              </Form.Select>
                              {errors?.educationSpouseInfo &&
                              errors?.educationSpouseInfo[`${i}`]
                                ?.sStudyDuration ? (
                                <span className="error-msg">
                                  {
                                    errors?.educationSpouseInfo[`${i}`]
                                      ?.sStudyDuration?.message
                                  }
                                </span>
                              ) : (
                                ''
                              )}
                            </Form.Group>

                            <Form.Group
                              className={`form-group mb-3 ${
                                errors?.educationSpouseInfo &&
                                errors?.educationSpouseInfo[`${i}`]?.sCompleted
                                  ? 'error-msg'
                                  : ''
                              }`}
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Completed?*</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                name="sCompleted"
                                disabled={spouseDis ? spouseDis : false}
                                defaultValue={
                                  child.sCompleted ? child.sCompleted : ''
                                }
                                onChange={(e) => handleSpouseChange(e, i)}
                              >
                                <option value="">Select</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </Form.Select>
                              {errors?.educationSpouseInfo &&
                              errors?.educationSpouseInfo[`${i}`]
                                ?.sCompleted ? (
                                <span className="error-msg">
                                  {
                                    errors?.educationSpouseInfo[`${i}`]
                                      ?.sCompleted?.message
                                  }
                                </span>
                              ) : (
                                ''
                              )}
                            </Form.Group>
                            {child && child.sCompleted === 'No' ? (
                              <Form.Group
                                className={`form-group mb-3 ${
                                  errors?.educationSpouseInfo &&
                                  errors?.educationSpouseInfo[`${i}`]
                                    ?.sStudyCompletionDetail
                                    ? 'error-msg'
                                    : ''
                                }`}
                                controlId="formBasicEmail"
                              >
                                <Form.Label>
                                  how long until studies are completed?
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="sStudyCompletionDetail"
                                  disabled={spouseDis ? spouseDis : false}
                                  defaultValue={child.sStudyCompletionDetail}
                                  onChange={(e) => handleSpouseChange(e, i)}
                                />
                                {errors?.educationSpouseInfo &&
                                errors?.educationSpouseInfo[`${i}`]
                                  ?.sStudyCompletionDetail ? (
                                  <span className="error-msg">
                                    {
                                      errors?.educationSpouseInfo[`${i}`]
                                        ?.sStudyCompletionDetail?.message
                                    }
                                  </span>
                                ) : (
                                  ''
                                )}
                              </Form.Group>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className="add-btn">
                            {i >= 1 && !spouseDis && (
                              <Button
                                className="delete-btn"
                                disabled={spouseDis ? spouseDis : false}
                                onClick={() => removeSpouseEducation(i)}
                              >
                                Delete Education #{i + 1}{' '}
                                <i class="las la-minus"></i>
                              </Button>
                            )}
                            <Button
                              onClick={handleAddSpouseEducation}
                              disabled={spouseDis ? spouseDis : false}
                            >
                              Add Education <i className="las la-plus"></i>
                            </Button>
                          </div>
                        </div>
                      </>
                    ))}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </div>
        </Accordion>
        <div className="visa-btn">
          {!mainDis ? (
            <>
              <Button
                type="submit"
                disabled={mainDis ? mainDis : false}
                className={`${mainDis ? 'success-btn' : ''}`}
              >
                Save {mainDis && <img src={approved} alt="approved" />}
              </Button>
              <div className="visa-desc-text">
                <p>
                  Once your form is completed, you will be able to make new
                  changes by contacting our Support team.
                </p>
                <p>When you're ready, click "Submit"!</p>
              </div>
            </>
          ) : (
            <div className="for-new-changes">
              <p>
                <span>Important! </span> Please contact our Support team if you
                would like to make new changes .
              </p>
            </div>
          )}
        </div>
      </Form>
      <ErrorModal show={showErrorModal} handleClose={handleShowErrorModal} />
    </div>
  )
}
export default EducationInfo
