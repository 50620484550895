import React from 'react'
import { Link } from 'react-router-dom'
import Helmet from 'react-helmet'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'
import PhoneInput from 'react-phone-input-2'
import Layout from '../layout/layout'
import { Form, Button } from 'react-bootstrap'
import logo from '../../assets/images/footer/logo.svg'
import envelope from '../../assets/images/login/envelope.svg'
import call from '../../assets/images/login/call.svg'
import facebook from '../../assets/images/login/facebook.svg'
import linkedin from '../../assets/images/login/linkedin.svg'
import twitter from '../../assets/images/login/twitter.svg'

import './login.css'
import { useState } from 'react'
import { PhoneOtpSchema, PhoneVerifySchema } from '../../utils/validationSchema'
import axios from 'axios'
import { serverPath } from '../../config/key'
import { useContext } from 'react'
import UserContext from '../../context/UserContext'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const PhoneVerify = () => {
  const { userGeoData } = useContext(UserContext)
  const [otpshow, setOtpShow] = useState(true)
  const [phone, setPhone] = useState('')
  const [values,setValues]=useState({
    phone:'',
    email:''
  })
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    register
  } = useForm({ resolver: yupResolver(PhoneVerifySchema) })
  const { t } = useTranslation()
  const handlePhoneChange = (e) => {
    setValue("isPhone",true)
    setValue("isEmail",false)
    setValue('phone', '+' + e)
    setValues({...values,'phone': + e})
  }
  const handleChange=(e)=>{
    setValue("isPhone",false)
    setValue("isEmail",true)
    setValues({...values,[e.target.name]:e.target.value})
    setValue([e.target.name],e.target.value)
    
  }
  const handlePhoneLogin = async (values) => {
    try {
      const res = await axios.post(`${serverPath}/users/loginWithOTP`, values)
      if (res.data.status === 200) {
        setPhone(values.phone || values.email)
        toast.success(res.data.msg, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
        setOtpShow(false)
      }
    } catch (error) {
      if (error?.response?.data) {
        toast.error(error.response.data.msg, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
      }
    }
  }
  return (
    <Layout>
      <Helmet>
        <body className="hide-layout" />
      </Helmet>

      <div className="login-page">
        <div className="login-grid">
          <div className="login-content">
            <Link to="/">
              <img src={logo} alt="" className="logo-login" />
            </Link>

            <h2>Welcome!</h2>

            <div className="login-contact-info">
              <span>
                <img src={envelope} alt="" />
                {t('contactEmail')}
              </span>
              <span>
                <img src={call} alt="" />
                {t('contactPhone')}
              </span>
            </div>

            <div className="login-social">
              <ul>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/uis.canada"
                  >
                    <img src={facebook} alt="" />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkedin.com/company/uis-canada-official/"
                  >
                    <img src={linkedin} alt="" />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://twitter.com/uis_canada"
                  >
                    <img src={twitter} alt="" />
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="login-form">
            {otpshow ? (
              <div className="login-popup-content">
                <h2>Sign In</h2>
                <p className='form-title'>Enter your phone number to receive an OTP</p>
                <Form onSubmit={handleSubmit(handlePhoneLogin)}>
                  <Form.Group>
                    <PhoneInput
                      country={userGeoData?.country_code?.toLowerCase()}
                      onChange={handlePhoneChange}
                      disabled={values?.email?true:false}
                      inputProps={{
                        name: 'phone',
                      }}
                    />

                    {errors?.phone ? (
                      <span className="error-msg">{errors.phone.message}</span>
                    ) : (
                      ''
                    )}
                  </Form.Group>
                  <p className='form-or-text'>OR</p>
                  <p className='form-title'>Enter your email to get a code to your email</p>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      placeholder="Enter your email"
                      name="email"
                      disabled={values?.phone ?true:false}
                     
                       {...register('email')}
                       onChange={(e)=>handleChange(e)}
                     
                    />
                    {errors?.email ? (
                      <span className="error-msg">{errors?.email?.message}</span>
                    ) : (
                      ''
                    )}
                  </Form.Group>
                  <Button type="submit" disabled={(values?.email || values?.phone)?false:true}>Submit</Button>
                </Form>
              </div>
            ) : (
              <OtpVerify phone={phone} handlePhoneLogin={handlePhoneLogin} />
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PhoneVerify

const OtpVerify = ({ phone, handlePhoneLogin }) => {
  const { setToken, setUser } = useContext(UserContext)

  const navigate = useNavigate()
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(PhoneOtpSchema) })

  const handleVerifyOtp = async (values) => {
    try {
      const res = await axios.post(`${serverPath}/users/verifyOtp`, {
        ...values,
        phone,
      })

      if (res.data.status === 200) {
        toast.success(res.data.msg, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
        setToken(res.data.token)
        setUser(res.data.user)
        localStorage.setItem('token', res.data.token)
        localStorage.setItem('user', JSON.stringify(res.data.user))
        if (res.data.user.role === 'client') navigate('/welcome')
        else if (res.data.user.role === 'admin') navigate('/admin')
      }
    } catch (error) {
      if (error?.response?.data) {
        toast.error(error.response.data.msg, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
      }
    }
  }

  return (
    <div className="login-popup-content">
      <h2>Sign In</h2>
      <p>Enter your OTP to verify</p>

      <Form onSubmit={handleSubmit(handleVerifyOtp)}>
        <Form.Group>
          <Form.Control
            type="string"
            placeholder="Enter Your OTP"
            name="otp"
            {...register('otp')}
          />
          {errors?.otp ? (
            <span className="error-msg">{errors.otp.message}</span>
          ) : (
            ''
          )}
        </Form.Group>
        <Button type="submit">Verify</Button>
        <div className="resend-otp">
          <Link to="/login">Go back</Link>
          <span onClick={() => handlePhoneLogin({ phone: phone })}>
            Resend OTP
          </span>
        </div>

        <br></br>
      </Form>
    </div>
  )
}
