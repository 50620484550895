import React from "react";
import { Button } from "react-bootstrap";
import closeIcon from "../../assets/icons/crossuis.svg";
import chatImage from "../../assets/icons/chat-image.png";
import whatsapp from "../../assets/icons/whatsapp.png";
import call from "../../assets/icons/call.png";
import calendar from "../../assets/icons/calendar.png";

const ChatPopup = ({ openChat, setOpenChat }) => {
  return (
    <div className={`chat-popup ${openChat ? "show-chat" : "hide-chat"}`}>
      <div className='chat-popup-inner'>
        <Button className='close-btn' onClick={() => setOpenChat(false)}>
          <img src={closeIcon} alt='close icon' />
        </Button>
        <div className='chat-bot-image'>
          <div className='chat-bot-image-inner'>
            <img src={chatImage} alt='chat-img' />
          </div>
        </div>
        <div className='chat-content'>
          <span>Chat with Us</span>
          <p>
            We are excited to assist you on your immigration journey to Canada!
          </p>
        </div>
        <div className='chat-action-section'>
          <div className='action'>
            <div
              className='action-img whatsapp'
              onClick={() =>
                
                window.open(
                  "https://api.whatsapp.com/send?phone=+12365581539&text=Hello",
                  "_blank"
                )
              }
              role='presentation'
            >
              <img src={whatsapp} alt='action' />
            </div>
            <span>Chat Now</span>
          </div>
          <div className='action'>
            <div
              className='action-img call'
              onClick={() => window.open("tel:+17789075674", "_blank")}
              role='presentation'
            >
              <img src={call} alt='action' />
            </div>
            <span>Call Now</span>
          </div>
          <div className='action'>
            <div
              className='action-img calendar'
              onClick={() =>
                window.open(
                  "https://calendly.com/uis_canada_team/30-minute-consultation-call?month=2023-05",
                  "_blank"
                )
              }
              role='presentation'
            >
              <img src={calendar} alt='action' />
            </div>
            <span>Book a Call</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatPopup;
