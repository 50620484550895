import axios from 'axios'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Form, Button, Spinner } from 'react-bootstrap'

import { serverPath } from '../../config/key'
import Layout from '../layout/layout'
import './index.css'
import { invoiceReportSchema } from '../../utils/validationSchema'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'
const InvoiceReport = () => {
  const [allCompany, setAllCompany] = useState([])
  const [allBrand, setAllBrand] = useState([])
  const [loading, setLoading] = useState(false)
  const getAllCompany = async () => {
    try {
      const response = await axios.get(`${serverPath}/company/getAllCompany`, {
        headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      const { company } = response?.data || []
      if (company.length > 0) {
        setAllCompany(company)
      }
    } catch (err) {
      toast.error('Something went wrong!', { toastId: 'payId' })
    }
  }

  const getAllBrand = async () => {
    try {
      const response = await axios.get(`${serverPath}/brands`, {
        headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      const { brands } = response?.data || []
      if (brands.length > 0) {
        setAllBrand(brands)
      }
    } catch (err) {
      toast.error('Something went wrong!', { toastId: 'payId' })
    }
  }
  useEffect(() => {
    getAllCompany()
    getAllBrand()
  }, [])
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(invoiceReportSchema),
  })
  const handleGenerateReport = async (values) => {
    try {
      const brandId = values?.brandId ? values?.brandId : ''
      const companyId = values?.companyId ? values?.companyId : ''
      setLoading(true)
      const response = await axios.get(
        `${serverPath}/payment-provider/paymentReports`,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          params: {
            type: values.type,
            fromDate: values?.fromDate,
            toDate: values.toDate,
            brandId: brandId,
            companyId: companyId,
            paymentType: values.paymentType,
          },
          responseType: 'blob',
        },
      )
      setLoading(false)
      const { status } = response

      if (status === 200) {
        if (values.type === 'zip') {
          var blob = new Blob([response.data], { type: 'application/zip' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'invoiceReport.zip'
          link.click()
        } else {
          var blobXls = new Blob([response.data], {
            type: response.headers['content-type'],
          })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blobXls)
          link.download = 'invoiceReport.xlsx'
          link.click()
        }
      }
    } catch (err) {
      toast.error('Request Failed', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      })
    }
  }

  return (
    <Layout>
      <div className={`manual-invoices-page`}>
        <div className="container">
          <h3>Invoice Report </h3>
          <div className={`manual-invoices-content`}>
            <Form onSubmit={handleSubmit(handleGenerateReport)}>
              <div className="invoice-form-grid">
                <Form.Group className="form-group">
                  <Form.Label>Choose Report Type</Form.Label>
                  <Form.Select
                    aria-label="Choose Report Type"
                    name="type"
                    {...register('type')}
                  >
                    <option value="">Select</option>
                    <option value="excel">Excel</option>
                    <option value="zip">Zip</option>
                  </Form.Select>
                  {errors?.type ? (
                    <span className="error-msg">{errors.type.message}</span>
                  ) : (
                    ''
                  )}
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label>From Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="fromDate"
                    {...register('fromDate')}
                  />
                  {errors?.fromDate ? (
                    <span className="error-msg">{errors.fromDate.message}</span>
                  ) : (
                    ''
                  )}
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label>To Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="toDate"
                    {...register('toDate')}
                  />
                  {errors?.toDate ? (
                    <span className="error-msg">{errors.toDate.message}</span>
                  ) : (
                    ''
                  )}
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label>Choose Company</Form.Label>
                  <Form.Select
                    aria-label="Choose Company"
                    name="companyId"
                    {...register('companyId')}
                  >
                    <option value="">Choose Company</option>
                    {allCompany &&
                      allCompany?.length > 0 &&
                      allCompany.map((el) => (
                        <option value={el?._id}>{el?.name}</option>
                      ))}
                  </Form.Select>
                  {errors?.companyId ? (
                    <span className="error-msg">
                      {errors.companyId.message}
                    </span>
                  ) : (
                    ''
                  )}
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label>Choose Brand</Form.Label>
                  <Form.Select
                    aria-label="Choose Brand"
                    name="brandId"
                    {...register('brandId')}
                  >
                    <option value="">Choose Brand</option>
                    {allBrand &&
                      allBrand?.length > 0 &&
                      allBrand.map((el) => (
                        <option value={el?._id}>{el?.name}</option>
                      ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label>Choose Type</Form.Label>
                  <Form.Select
                    name="paymentType"
                    aria-label="Default select example"
                    {...register('paymentType')}
                  >
                    <option value="">Select payment type</option>
                    <option value="Debit">Debit</option>
                    <option value="Credit">Credit</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="form-group"></Form.Group>

                <div className="invoice-btn">
                  <Button type="submit" disabled={loading}>
                    {loading && (
                      <Spinner
                        as="span"
                        variant="light"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        animation="border"
                      />
                    )}
                    &nbsp;Generate Report
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default InvoiceReport
