/* eslint-disable react-hooks/rules-of-hooks */
import React,{useState} from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { Form, Button } from 'react-bootstrap'
import { serverPath } from '../../config/key'
import { useTranslation } from 'react-i18next'
import checkicon from '../../assets/images/header/success.svg'

import './user.css'
import { updatePasswordSchema } from '../../utils/validationSchema'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'


const UpdateUserPassword = ({ activeId,  setPassChange }) => {

  const { t } = useTranslation()
  const [succ, setSucc] = useState(false)
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(updatePasswordSchema) })
  const handleSubmitForm = async (values) => {
    try {
        const response = await axios.post(
          `${serverPath}/users/updateUserPassword`,
          {...values,userId:activeId},
          {
            headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
          },
        )
        const { upd:{upd} } = response.data
        if (upd.modifiedCount === 1) {
          reset()
          toast.success('Password updated successfully', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          })
          setPassChange(false)
          setSucc(true)
        }
      } catch (error) {
        if (error?.response?.data) {
          toast.error(error.response.data.msg, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          })
        }
      }
}
  return (
    <div className="consultation-popup-grid">
      
        <div className="consultation-popup-content">
          <h2>Change User Password</h2>
          {/* <p>Edit your details to update your profile</p> */}
          <Form onSubmit={handleSubmit(handleSubmitForm)}>
            <Form.Group>
              <Form.Control
                name="password"
                type="password"
                placeholder="New password"
                {...register("password")}
              />
              {errors?.password ? (
                <span className="error-msg">{errors.password.message}</span>
              ) : (
                ''
              )}
            </Form.Group>
            <Form.Group>
              <Form.Control
                name="confirmPassword"
                type="password"
                placeholder="Confirm password"
                {...register("confirmPassword")}
              />
              {errors?.confirmPassword ? (
                <span className="error-msg">{errors.confirmPassword.message}</span>
              ) : (
                ''
              )}
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              className={`${succ ? `success-btn` : ``}`}
            >
              {succ
                ? t('homeNewsletter.successButton')
                : t('consultationPopup.submit')}
              {succ ? <img src={checkicon} alt="" /> : ''}
            </Button>
          </Form>
        </div>
    </div>
  )
}

export default UpdateUserPassword
