import React from "react";
import Layout from "../layout/layout";
import VisaContact from "./visacontact";
import { useTranslation } from "react-i18next";
import businessicon from "../../assets/images/visa/business-icon.svg";
import business from "../../assets/images/visa/business.png";
import "./visa.css";

const BusinessVisa = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <div className='worker-visa-page'>
        <div className='worker-visa-banner'>
          <div className='container'>
            <div className='worker-visa-banner-heading'>
              <img src={businessicon} alt='' />
              <div className='worker-visa-banner-heading-title'>
                <h5>{t("visaBusiness.businessHeading1")}</h5>
                <h2>{t("visaBusiness.businessHeading2")}</h2>
              </div>
            </div>

            <p>{t("visaBusiness.businessParagraph1")}</p>

            <div className='worker-grid'>
              <div className='worker-content'>
                <p>{t("gridBusiness.businessParagraph1")}</p>

                <p>{t("gridBusiness.businessParagraph2")}</p>

                <p>
                  <b>{t("gridBusiness.businessParagraph3")}</b>
                </p>
              </div>

              <div className='worker-cover'>
                <img src={business} alt='' />
              </div>
            </div>
          </div>
        </div>

        <VisaContact />
      </div>
    </Layout>
  );
};

export default BusinessVisa;
