import axios from 'axios'
import { useContext } from 'react'
import { serverPath } from '../../config/key'
import UserContext from '../../context/UserContext'
import { toast } from 'react-toastify'
import attached from '../../assets/images/application/check-circle.svg'
import trash from '../../assets/images/application/delete.svg'
import UploadIcon from '../../assets/icons/UploadIcon'
import { useParams } from 'react-router-dom'
import download from '../../assets/images/application/download.png'
const SponsoringLetterClearanceInfo = ({
  documentInfo,
  setDocumentInfo,
  documentDis,
  updFormType,
  sponsoringLetterClearance,
  errors,
}) => {
  const { formType } = useParams()
  const urlParams = new URLSearchParams(window.location.search)
  const token = urlParams.get('t')
  const { selectedVisa } = useContext(UserContext)
  const handleFile = async (e) => {
    let apiPath = token ? 'support/visaUpdateDocuments' : 'visa/visaDocuments'
    const {
      target: { files, name },
    } = e
    const formData = new FormData()
    formData.append('file', files[0])
    formData.append('type', name)
    const res = await axios.post(`${serverPath}/${apiPath}`, formData, {
      headers: {
        authorization: `Bearer ${
          token ? token : localStorage.getItem('token')
        }`,
      },
      params: {
        visaTypeId: selectedVisa?.visaId,
        invoiceId: selectedVisa?.invoiceId,
        formsType: formType || updFormType,
      },
    })
    if (res?.data?.create?.findDoc) {
      const data = res?.data?.create?.findDoc
      setDocumentInfo({ ...documentInfo, ...data })
    }
  }
  const removeFile = async (type) => {
    try {
      let apiPath = token
        ? 'support/removeUpdateDocument'
        : 'visa/removeDocument'
      const res = await axios.post(
        `${serverPath}/${apiPath}`,
        {
          visaTypeId: selectedVisa?.visaId,
          invoiceId: selectedVisa?.invoiceId,
          type: type,
          formsType: formType || updFormType,
        },
        {
          headers: {
            authorization: `Bearer ${
              token ? token : localStorage.getItem('token')
            }`,
          },
        },
      )
      if (res?.data?.create?.findDoc) {
        const data = res?.data?.create?.findDoc
        setDocumentInfo(data)
      }
    } catch (err) {
      toast.error(err.message, { toastId: 'erro' })
    }
  }
  const downloadFile = async (url) => {
    try {
      const res = await axios.post(
        `${serverPath}/visa/downloadSingleDocument`,
        {
          visaTypeId: documentInfo.visaTypeId,
          invoiceId: documentInfo?.invoiceId,
          type: url,
          formsType: formType || (updFormType === 'forms' && 'main'),
        },
        {
          headers: {
            authorization: `Bearer ${token ? token : localStorage.getItem('token')}`,
            responseType: 'blob',
          },
        },
      )
      const { status } = res
      if (status === 201) {
        const file = url.split('com/').reverse()[0].split('/').reverse()[0]
        var blob = new Blob([res.data], {
          type: res.headers['content-type'],
        })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `${file}`
        link.click()
      }
    } catch (err) {
      toast.error(err.message, { toastId: 'erro' })
    }
  }
  return (
    <div className="general-info">
      {sponsoringLetterClearance?.length > 0 &&
        sponsoringLetterClearance?.map((el, i) => (
          <div className="general-info-listing" key={el?.name}>
            <div className="left-info">
              <h6>{el?.label}</h6>
            </div>
            <div className="middle-info">
              <h6>{el?.secondLabel} </h6>
            </div>
            {documentInfo && documentInfo[`${el?.name?.toString()}`] ? (
              <div className="documents-attached-section">
                <div className="application-attached">
                  Attached <img src={attached} alt="" />
                </div>
                {!documentDis ? (
                  <>
                    <span onClick={() => removeFile(el?.name)}>
                      <img
                        src={trash}
                        alt="uploadimg"
                        style={{ width: '20px', height: '20px' }}
                      />
                    </span>
                    {token ? (
                      <span onClick={() => downloadFile(documentInfo[`${el?.name?.toString()}`])}>
                        <img
                          src={download}
                          alt="download"
                          style={{ width: '20px', height: '20px' }}
                        />
                      </span>
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  ''
                )}
              </div>
            ) : (
              <div
                className={`right-info ${documentDis ? 'upload-block' : ''}`}
              >
                <h6>Upload</h6>
                <div className="upload-image">
                  <input
                    id={`sponsoringLetterClearance${i}`}
                    type="file"
                    name={el?.name}
                    onChange={handleFile}
                    disabled={documentDis ? documentDis : false}
                  />
                  <label htmlFor={`sponsoringLetterClearance${i}`}>
                    <UploadIcon />{' '}
                  </label>
                </div>
                {errors &&
                  errors?.map((er) =>
                    er?.name === el?.name ? (
                      <span className="error-msg doc-error">{er?.message}</span>
                    ) : (
                      ''
                    ),
                  )}
              </div>
            )}
          </div>
        ))}
      {/* <div className="general-info-listing">
        <div className="left-info">
          <h6>Letter clearance Info</h6>
        </div>
        <div className="middle-info"></div>
        {documentInfo?.sponsoringLetterClearanceInfo ? (
          <div className="documents-attached-section">
            <div className="application-attached">
              Attached <img src={attached} alt="" />
            </div>
            {!documentDis ? (
              <>
                <span
                  onClick={() => removeFile('sponsoringLetterClearanceInfo')}
                >
                  <img
                    src={trash}
                    alt="uploadimg"
                    style={{ width: '20px', height: '20px' }}
                  />
                </span>
                {token ? (
                  <span
                    onClick={() =>
                      downloadFile(documentInfo?.sponsoringLetterClearanceInfo)
                    }
                  >
                    <img
                      src={download}
                      alt="download"
                      style={{ width: '20px', height: '20px' }}
                    />
                  </span>
                ) : (
                  ''
                )}
              </>
            ) : (
              ''
            )}
          </div>
        ) : (
          <div className={`right-info ${documentDis ? 'upload-block' : ''}`}>
            <h6>Upload</h6>
            <div className="upload-image">
              <input
                id="sponsoringUploadClearance1"
                type="file"
                name="sponsoringLetterClearanceInfo"
                onChange={handleFile}
                disabled={documentDis ? documentDis : false}
              />
              <label htmlFor="sponsoringUploadClearance1">
                <UploadIcon />{' '}
              </label>
            </div>
          </div>
        )}
      </div>
      <div className="general-info-listing">
        <div className="left-info">
          <h6>Letter of Acceptance</h6>
        </div>
        <div className="middle-info"></div>
        {documentInfo?.sponsoringLetterAcceptance ? (
          <div className="documents-attached-section">
            <div className="application-attached">
              Attached <img src={attached} alt="" />
            </div>
            {!documentDis ? (
              <>
                <span onClick={() => removeFile('sponsoringLetterAcceptance')}>
                  <img
                    src={trash}
                    alt="uploadimg"
                    style={{ width: '20px', height: '20px' }}
                  />
                </span>
                {token ? (
                  <span
                    onClick={() =>
                      downloadFile(documentInfo?.sponsoringLetterAcceptance)
                    }
                  >
                    <img
                      src={download}
                      alt="download"
                      style={{ width: '20px', height: '20px' }}
                    />
                  </span>
                ) : (
                  ''
                )}
              </>
            ) : (
              ''
            )}
          </div>
        ) : (
          <div className={`right-info ${documentDis ? 'upload-block' : ''}`}>
            <h6>Upload</h6>
            <div className="upload-image">
              <input
                id="sponsoringUploadClearance2"
                type="file"
                name="sponsoringLetterAcceptance"
                onChange={handleFile}
                disabled={documentDis ? documentDis : false}
              />
              <label htmlFor="sponsoringUploadClearance2">
                <UploadIcon />{' '}
              </label>
            </div>
          </div>
        )}
      </div>
      <div className="general-info-listing">
        <div className="left-info">
          <h6>Educational Diploma</h6>
        </div>
        <div className="middle-info"></div>
        {documentInfo?.sponsoringEducationalDiploma ? (
          <div className="documents-attached-section">
            <div className="application-attached">
              Attached <img src={attached} alt="" />
            </div>
            {!documentDis ? (
              <>
                <span
                  onClick={() => removeFile('sponsoringEducationalDiploma')}
                >
                  <img
                    src={trash}
                    alt="uploadimg"
                    style={{ width: '20px', height: '20px' }}
                  />
                </span>
                {token ? (
                  <span
                    onClick={() =>
                      downloadFile(documentInfo?.sponsoringEducationalDiploma)
                    }
                  >
                    <img
                      src={download}
                      alt="download"
                      style={{ width: '20px', height: '20px' }}
                    />
                  </span>
                ) : (
                  ''
                )}
              </>
            ) : (
              ''
            )}
          </div>
        ) : (
          <div className={`right-info ${documentDis ? 'upload-block' : ''}`}>
            <h6>Upload</h6>
            <div className="upload-image">
              <input
                id="sponsoringUploadClearance3"
                type="file"
                name="sponsoringEducationalDiploma"
                onChange={handleFile}
                disabled={documentDis ? documentDis : false}
              />
              <label htmlFor="sponsoringUploadClearance3">
                <UploadIcon />{' '}
              </label>
            </div>
          </div>
        )}
      </div>
      <div className="general-info-listing">
        <div className="left-info">
          <h6>
            Proof of Medical Exam From A Canadian Approved Physician (see
            Guidelines)
          </h6>
        </div>
        <div className="middle-info"></div>
        {documentInfo?.sponsoringCanadianMedicalExamProof ? (
          <div className="documents-attached-section">
            <div className="application-attached">
              Attached <img src={attached} alt="" />
            </div>
            {!documentDis ? (
              <>
                <span
                  onClick={() =>
                    removeFile('sponsoringCanadianMedicalExamProof')
                  }
                >
                  <img
                    src={trash}
                    alt="uploadimg"
                    style={{ width: '20px', height: '20px' }}
                  />
                </span>
                {token ? (
                  <span
                    onClick={() =>
                      downloadFile(
                        documentInfo?.sponsoringCanadianMedicalExamProof,
                      )
                    }
                  >
                    <img
                      src={download}
                      alt="download"
                      style={{ width: '20px', height: '20px' }}
                    />
                  </span>
                ) : (
                  ''
                )}
              </>
            ) : (
              ''
            )}
          </div>
        ) : (
          <div className={`right-info ${documentDis ? 'upload-block' : ''}`}>
            <h6>Upload</h6>
            <div className="upload-image">
              <input
                id="sponsoringUploadClearance4"
                type="file"
                name="sponsoringCanadianMedicalExamProof"
                onChange={handleFile}
                disabled={documentDis ? documentDis : false}
              />
              <label htmlFor="sponsoringUploadClearance4">
                <UploadIcon />{' '}
              </label>
            </div>
          </div>
        )}
      </div>
      <div className="general-info-listing">
        <div className="left-info">
          <h6>Police Clearance (see Guidelines) </h6>
        </div>
        <div className="middle-info">
          <h6>(if applicable) </h6>
        </div>
        {documentInfo?.sponsoringPoliceClearance ? (
          <div className="documents-attached-section">
            <div className="application-attached">
              Attached <img src={attached} alt="" />
            </div>
            {!documentDis ? (
              <>
                <span onClick={() => removeFile('sponsoringPoliceClearance')}>
                  <img
                    src={trash}
                    alt="uploadimg"
                    style={{ width: '20px', height: '20px' }}
                  />
                </span>
                {token ? (
                  <span
                    onClick={() =>
                      downloadFile(documentInfo?.sponsoringPoliceClearance)
                    }
                  >
                    <img
                      src={download}
                      alt="download"
                      style={{ width: '20px', height: '20px' }}
                    />
                  </span>
                ) : (
                  ''
                )}
              </>
            ) : (
              ''
            )}
          </div>
        ) : (
          <div className={`right-info ${documentDis ? 'upload-block' : ''}`}>
            <h6>Upload</h6>
            <div className="upload-image">
              <input
                id="sponsoringUploadClearance5"
                type="file"
                name="sponsoringPoliceClearance"
                onChange={handleFile}
                disabled={documentDis ? documentDis : false}
              />
              <label htmlFor="sponsoringUploadClearance5">
                <UploadIcon />{' '}
              </label>
            </div>
          </div>
        )}
      </div>
      <div className="general-info-listing">
        <div className="left-info">
          <h6>Language Test Results</h6>
        </div>
        <div className="middle-info">
          <h6>(if applicable) </h6>
        </div>
        {documentInfo?.sponsoringLangTestResult ? (
          <div className="documents-attached-section">
            <div className="application-attached">
              Attached <img src={attached} alt="" />
            </div>
            {!documentDis ? (
              <>
                <span onClick={() => removeFile('sponsoringLangTestResult')}>
                  <img
                    src={trash}
                    alt="uploadimg"
                    style={{ width: '20px', height: '20px' }}
                  />
                </span>
                {token ? (
                  <span
                    onClick={() =>
                      downloadFile(documentInfo?.sponsoringLangTestResult)
                    }
                  >
                    <img
                      src={download}
                      alt="download"
                      style={{ width: '20px', height: '20px' }}
                    />
                  </span>
                ) : (
                  ''
                )}
              </>
            ) : (
              ''
            )}
          </div>
        ) : (
          <div className={`right-info ${documentDis ? 'upload-block' : ''}`}>
            <h6>Upload</h6>
            <div className="upload-image">
              <input
                id="sponsoringUploadClearance6"
                type="file"
                name="sponsoringLangTestResult"
                onChange={handleFile}
                disabled={documentDis ? documentDis : false}
              />
              <label htmlFor="sponsoringUploadClearance6">
                <UploadIcon />{' '}
              </label>
            </div>
          </div>
        )}
      </div>
      <div className="general-info-listing">
        <div className="left-info">
          <h6>Copy of Visa or Resident Visa</h6>
        </div>
        <div className="middle-info">
          <h6>(if you are a resident of another country) </h6>
        </div>
        {documentInfo?.sponsoringVisaResident ? (
          <div className="documents-attached-section">
            <div className="application-attached">
              Attached <img src={attached} alt="" />
            </div>
            {!documentDis ? (
              <>
                <span onClick={() => removeFile('sponsoringVisaResident')}>
                  <img
                    src={trash}
                    alt="uploadimg"
                    style={{ width: '20px', height: '20px' }}
                  />
                </span>
                {token ? (
                  <span
                    onClick={() =>
                      downloadFile(documentInfo?.sponsoringVisaResident)
                    }
                  >
                    <img
                      src={download}
                      alt="download"
                      style={{ width: '20px', height: '20px' }}
                    />
                  </span>
                ) : (
                  ''
                )}
              </>
            ) : (
              ''
            )}
          </div>
        ) : (
          <div className={`right-info ${documentDis ? 'upload-block' : ''}`}>
            <h6>Upload</h6>
            <div className="upload-image">
              <input
                id="sponsoringUploadClearance7"
                type="file"
                name="sponsoringVisaResident"
                onChange={handleFile}
                disabled={documentDis ? documentDis : false}
              />
              <label htmlFor="sponsoringUploadClearance7">
                <UploadIcon />{' '}
              </label>
            </div>
          </div>
        )}
      </div>
      <div className="general-info-listing">
        <div className="left-info">
          <h6>Letter of Motives (see Guidelines)</h6>
        </div>
        <div className="middle-info">
          <h6>(if applicable) </h6>
        </div>
        {documentInfo?.sponsoringLetterOfMotive ? (
          <div className="documents-attached-section">
            <div className="application-attached">
              Attached <img src={attached} alt="" />
            </div>
            {!documentDis ? (
              <>
                <span onClick={() => removeFile('sponsoringLetterOfMotive')}>
                  <img
                    src={trash}
                    alt="uploadimg"
                    style={{ width: '20px', height: '20px' }}
                  />
                </span>
                {token ? (
                  <span
                    onClick={() =>
                      downloadFile(documentInfo?.sponsoringLetterOfMotive)
                    }
                  >
                    <img
                      src={download}
                      alt="download"
                      style={{ width: '20px', height: '20px' }}
                    />
                  </span>
                ) : (
                  ''
                )}
              </>
            ) : (
              ''
            )}
          </div>
        ) : (
          <div className={`right-info ${documentDis ? 'upload-block' : ''}`}>
            <h6>Upload</h6>
            <div className="upload-image">
              <input
                id="sponsoringUploadClearance8"
                type="file"
                name="sponsoringLetterOfMotive"
                onChange={handleFile}
                disabled={documentDis ? documentDis : false}
              />
              <label htmlFor="sponsoringUploadClearance8">
                <UploadIcon />{' '}
              </label>
            </div>
          </div>
        )}
      </div> */}
    </div>
  )
}
export default SponsoringLetterClearanceInfo
