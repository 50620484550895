import React from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import { Link, Navigate, NavLink } from 'react-router-dom'
import logo from '../../../assets/images/application/logo.svg'
import caseicon from '../../../assets/images/application/case.svg'
import closeIconMob from '../../../assets/images/application/mobCloseIcon.svg'
import { useContext } from 'react'
import UserContext from '../../../context/UserContext'

const AdminSidebar = () => {
  const { setToken, setUser, setSelectedVisa } = useContext(UserContext)
  const handleLogout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    setSelectedVisa({
      visaId: '',
      invoiceId: '',
      caseId: '',
      visaName: '',
      invoiceNumber: '',
    })
    setToken('')
    setUser({})
    Navigate('/login')
  }

  return (
    <div className={`application-sidebar`}>
      <div className="application-logo">
        <Link to="/">
          <img src={logo} alt="" />
        </Link>
      </div>

      {/* <div className="custom-dropdown" ref={wrapperRef}>
        <h6 onClick={handleDropdown}>
          <img className="dropdown-avatar" src={profile} alt="profile" />{' '}
          <span>
            {(visaType &&
              visaType?.length > 0 &&
              visaType.find((el) => el?._id === selectedVisa?.visaId)?.name) ||
              'Select Visa Type'}
          </span>
          <img src={arrow} alt="profile" />
        </h6>
        {dropdown && (
          <ul>
            {visaType &&
              visaType.length > 0 &&
              visaType.map((el) => (
                <li
                  onClick={() =>
                    selectChange(
                      el?._id,
                      el?.invoiceId,
                      el?.caseId,
                      el?.agreements,
                    )
                  }
                >
                  {el.name}
                </li>
              ))}
          </ul>
        )}
      </div> */}
      <div className="application-menu">
        <div className="close-icon-mob">
          <img src={closeIconMob} alt="" />
        </div>
        <div className="application-case-mob">
          <div className="application-case-icon">
            <span>Carlos Martines</span>
            <div className="application-case-id">
              <img src={caseicon} alt="" /> case:{' '}
              {/* {selectedVisa?.caseId ? selectedVisa?.caseId : ''} */}
            </div>
          </div>
        </div>
        <Navbar>
          <Nav>
            <NavLink to="/admin">
              <span>
                <GuidelineIcon />
              </span>{' '}
              Dashboard
            </NavLink>

            <NavLink to="/manualinvoice">
              <span>
                <InvoiceIcon />
              </span>{' '}
              Manual Invoice
            </NavLink>

            <NavLink to="/invoiceReport">
              <span>
                <InvoiceIcon />
              </span>{' '}
              View Invoice Reports
            </NavLink>

            <NavLink to="/viewApplication">
              <span>
                <InvoiceIcon />
              </span>{' '}
              View Applications
            </NavLink>
            <NavLink to="/allinvoices">
              <span>
                <InvoiceIcon />
              </span>{' '}
              All Invoices
            </NavLink>
            <NavLink to="/users">
              <span>
                <GuidelineIcon />
              </span>{' '}
              Users
            </NavLink>
          </Nav>

          <Nav className="settings-mob-view">
            <div className="application-logout" onClick={handleLogout}>
              <span>
                <LogoutIcon /> Log Out
              </span>
            </div>
          </Nav>
        </Navbar>
      </div>
      <div className="application-logout" onClick={handleLogout}>
        <span>
          <LogoutIcon /> Log Out
        </span>
      </div>
    </div>
  )
}

export default AdminSidebar

const GuidelineIcon = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16 2H11.82C11.4 0.84 10.3 0 9 0C7.7 0 6.6 0.84 6.18 2H2C0.9 2 0 2.9 0 4V18C0 19.1 0.9 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM9 2C9.55 2 10 2.45 10 3C10 3.55 9.55 4 9 4C8.45 4 8 3.55 8 3C8 2.45 8.45 2 9 2ZM4 16H11V14H4V16ZM14 12H4V10H14V12ZM4 8H14V6H4V8Z"
        fill="#F35C5C"
      />
    </svg>
  )
}

const InvoiceIcon = () => {
  return (
    <svg
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0528 12.5001V13.3335C17.0528 14.2502 16.2002 15.0002 15.1581 15.0002H1.89476C0.843167 15.0002 0 14.2502 0 13.3335V1.66668C0 0.750008 0.843167 0 1.89476 0H15.1581C16.2002 0 17.0528 0.750008 17.0528 1.66668V2.50003H8.52641C7.47482 2.50003 6.63165 3.25003 6.63165 4.16671V10.8334C6.63165 11.7501 7.47482 12.5001 8.52641 12.5001H17.0528ZM8.5264 10.8335H18.0002V4.16672H8.5264V10.8335ZM10.8949 7.50016C10.8949 8.19183 11.5296 8.75017 12.316 8.75017C13.1023 8.75017 13.737 8.19183 13.737 7.50016C13.737 6.80848 13.1023 6.25014 12.316 6.25014C11.5296 6.25014 10.8949 6.80848 10.8949 7.50016Z"
        fill="#3A416F"
      />
    </svg>
  )
}

const LogoutIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.08 12.59L13.67 10H4V8H13.67L11.08 5.41L12.5 4L17.5 9L12.5 14L11.08 12.59ZM16 0C16.5304 0 17.0391 0.210714 17.4142 0.585786C17.7893 0.960859 18 1.46957 18 2V6.67L16 4.67V2H2V16H16V13.33L18 11.33V16C18 16.5304 17.7893 17.0391 17.4142 17.4142C17.0391 17.7893 16.5304 18 16 18H2C0.89 18 0 17.1 0 16V2C0 0.89 0.89 0 2 0H16Z"
        fill="#6243CE"
        // fill-opacity="0.2"
      />
    </svg>
  )
}
