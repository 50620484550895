import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import flag from "../../assets/images/worldTravel/Flags.svg";
import flag2 from "../../assets/images/worldTravel/Flags2.svg";
import flag3 from "../../assets/images/worldTravel/Flags3.svg";
import flag4 from "../../assets/images/worldTravel/Flag4.svg";
import profile1 from "../../assets/images/worldTravel/profile1.svg";
import profile2 from "../../assets/images/worldTravel/profile2.svg";
import profile3 from "../../assets/images/worldTravel/profile3.svg";
import profile4 from "../../assets/images/worldTravel/profile4.svg";
import profile5 from "../../assets/images/worldTravel/profile5.svg";
import profile6 from "../../assets/images/worldTravel/profile6.svg";

const ReviewData = [
  {
    id: 1,
    userprofile: profile1,
    flag: flag,
    user: "Samantha Thompson",
    desc: "UIS Canada gave me the adventure of a lifetime. They made the process seamless and supported me throughout my stay.",
  },
  {
    id: 2,
    userprofile: profile2,
    flag: flag2,
    user: "Carlos López",
    desc: " Highly recommend UIS Canada! They provided excellent support and guidance, and my concierge handled everything I needed perfectly.",
  },
  {
    id: 3,
    userprofile: profile3,
    flag: flag3,
    user: "Emily Wilson",
    desc: "Choosing UIS Canada was the best decision. From getting my visa within a month to getting my first job almost straight away everything went so smoothly and I couldn't have asked for anything better!",
  },

  {
    id: 4,
    userprofile: profile5,
    flag: flag,
    user: "Mia Rodriguez",
    desc: "Big ups to UIS Canada for making my working holiday dreams epic! They made the whole process a breeze, and their travel package had me living my best life in Canada. I'll forever cherish the memories. Thanks, UIS Canada.",
  },
  {
    id: 5,
    userprofile: profile4,
    flag: flag2,
    user: "Liam Patel",
    desc: "Choosing UIS Canada was the best decision ever! They took care of everything, and their travel package let me explore Canada's beauty while working. Highly recommended!",
  },
  {
    id: 6,
    userprofile: profile6,
    flag: flag4,
    user: "Sofia Santos",
    desc: "I can't thank you enough for my unforgettable working holiday adventure! From the moment I landed in Canada, UIS Canada's expert guidance and support made everything seamless. I got to enjoy the Canadian culture while working and exploring stunning places",
  },
];

const WorldTestimonial = () => {
  const settings = {
    dots: true,
    centerMode: false,
    className: "center",
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          centerMode: true,
          className: "center",
          speed: 500,
          infinite: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className='WorldTravel-plans WorldTravel-testimonial'>
      <div className='container'>
        <div className='WorldTestimonial-content'>
          <h3>
            Our Customers Have Great Things to Share from Their Experiences in
            Canada
          </h3>
          <div className='review-slider'>
            <Slider {...settings} swipeToSlide={true} focusOnSelect={true}>
              {ReviewData.map((e) => (
                <div key={e.id}>
                  <div className='review-slider-content'>
                    <div className='review-slide'>
                      <div className='review-slide-head'>
                        <img src={e.userprofile} alt='profile' />
                      </div>
                      <div className='review-slide-center'>
                        <img src={e.flag} alt='flag' />
                        <p>{e.user}</p>
                      </div>
                      <div className='review-slide-bottom'>
                        <div className='review'>
                          <StarIcon />
                          <StarIcon />
                          <StarIcon />
                          <StarIcon />
                          <StarIcon />
                        </div>
                        <p>{e.desc}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorldTestimonial;

const StarIcon = () => {
  return (
    <svg
      width='21'
      height='19'
      viewBox='0 0 21 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19.9975 8.04796L15.6492 12.4313L16.5905 18.5334C16.6021 18.6064 16.5934 18.6813 16.5651 18.7497C16.5368 18.8181 16.4901 18.8773 16.4303 18.9208C16.3704 18.9643 16.2997 18.9904 16.2259 18.9961C16.1521 19.0019 16.0782 18.9871 16.0123 18.9534L10.4998 16.1725L4.98734 18.9535C4.92145 18.9872 4.84751 19.002 4.77373 18.9962C4.69995 18.9905 4.62921 18.9644 4.56934 18.9209C4.50947 18.8774 4.46281 18.8182 4.43453 18.7498C4.40625 18.6814 4.39746 18.6065 4.40912 18.5334L5.35048 12.4313L1.00223 8.04791C0.950308 7.99545 0.913822 7.92971 0.896774 7.85789C0.879726 7.78607 0.882773 7.71095 0.905581 7.64074C0.92839 7.57054 0.97008 7.50797 1.02608 7.45989C1.08209 7.41181 1.15025 7.38006 1.22309 7.36814L7.31731 6.37775L10.1425 0.887865C10.1762 0.822197 10.2275 0.767106 10.2905 0.728643C10.3536 0.69018 10.426 0.669831 10.4998 0.669831C10.5737 0.669831 10.6461 0.69018 10.7091 0.728643C10.7722 0.767106 10.8234 0.822197 10.8572 0.887865L13.6823 6.37775L19.7766 7.36814C19.8494 7.38006 19.9176 7.41181 19.9736 7.45989C20.0296 7.50797 20.0713 7.57054 20.0941 7.64074C20.1169 7.71095 20.1199 7.78607 20.1029 7.85789C20.0858 7.92971 20.0494 7.9955 19.9975 8.04796Z'
        fill='#F4C431'
      />
    </svg>
  );
};
