import React from 'react'
import info from '../../../assets/images/visa/info.svg'
import ApplicationLayout from '../layout'
import digitalPhoto from '../../../assets/images/application/digitalphoto.svg'
import { Form } from 'react-bootstrap'
import { useContext } from 'react'
import UserContext from '../../../context/UserContext'
import { useState } from 'react'
import { useEffect } from 'react'
import {
  studentVisaGuidLine,
  evaluationGuideline,
  touristVisaGuidLine,
  workingHolidayVisaGuidLine,
} from '../../../utils/guidline'

const Guidelines = () => {
  const { selectedVisa } = useContext(UserContext)
  const [guidLins, setGuidLines] = useState([])
  useEffect(() => {
    if (selectedVisa?.visaName === 'Student Visa') {
      setGuidLines(studentVisaGuidLine)
    }
    if (
      selectedVisa?.visaName === 'Single Evaluation' ||
      selectedVisa?.visaName === 'Double Evaluation'
    ) {
      setGuidLines(evaluationGuideline)
    }
    if (selectedVisa?.visaName === 'Tourist Visa') {
      setGuidLines(touristVisaGuidLine)
    }
    if (selectedVisa?.visaName === 'Working Holiday Visa') {
      setGuidLines(workingHolidayVisaGuidLine)
    }
  }, [selectedVisa])
  return (
    <ApplicationLayout>
      <div className={`guidelines-page`}>
        <div className="container">
          {guidLins?.length > 0 ? (
            <div className={`guidelines-page-content scroll-content`}>
              {guidLins &&
                guidLins?.length > 0 &&
                guidLins?.map((el, i) => (
                  <React.Fragment key={i}>
                    <h4>{el?.title}</h4>
                    {el?.links ? (
                      <>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={el?.links?.link}
                        >
                          {el?.links?.linkTitle}
                        </a>
                        {el?.links?.linkDescription ? (
                          <>
                            <br />
                            <i>{el?.links?.linkDescription}</i>
                            <br />
                            <br />
                          </>
                        ) : (
                          ''
                        )}
                      </>
                    ) : (
                      ''
                    )}
                    {el?.multipleDesc && el?.multipleDesc?.length > 0 ? (
                      <div className="digital-photo-wrapper">
                        <div className="digital-photo-content">
                          <ul>
                            {el?.multipleDesc?.map((item) => (
                              <li>{item}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    <p dangerouslySetInnerHTML={{ __html: el?.description }} />
                  </React.Fragment>
                ))}
            </div>
          ) : (
            <div className={`guidelines-page-content scroll-content`}>
              <h3>additional information</h3>
              <p>
                Here you can review the guidelines for your visa application.
                Please note that these are general guidelines. Ignore if any are
                not applicable to your application.
              </p>
              <h4>
                <img src={info} alt="info" /> How should I submit my documents
              </h4>
              <p>All documentation must be scanned in a PDF format.</p>
              <h4>
                <img src={info} alt="info" /> Do I need to translate my
                documents
              </h4>
              <p>
                Every document must be written in one of Canada's official
                languages: English or French. If you don't have your documents
                in one of these languages, you must have them translated by a
                translator who is authorized to perform this kind of work by the
                corresponding GOV body who regulates this profession in your
                country.
              </p>
              <h4>
                <img src={info} alt="info" /> What should my digital photo look
                like
              </h4>
              <div className="digital-photo-wrapper">
                <div className="digital-photo-content">
                  <ul>
                    <li> Frame size: 35mm X 45mm.</li>
                    <li>
                      For scanned photos, the minimum resolution must be 600
                      pixles per inch.
                    </li>
                    <li>
                      Full frontal view of the face and head, and top of the
                      shoulders.
                    </li>
                    <li>
                      Size of the head, from chin to crown, must be between 31mm
                      and 36mm.
                    </li>
                    <li>
                      The physical dimentions in pixles must be at least 420 X
                      540 (digital dimensions are often expressed in pixles or
                      DPI - Dots per Inch.
                    </li>
                  </ul>
                </div>
                <div className="digital-photo-image">
                  <img src={digitalPhoto} alt="" />
                </div>
              </div>
              <h4>
                <img src={info} alt="info" /> What should the file formats be
              </h4>
              <ul>
                <li> JPEG or JPEG2000 format.</li>
                <li>
                  The final size of an image should be 240 KB, and no less than
                  60 KB.
                </li>
                <li>
                  The image must be in color (24 bits per pixel) in RGB color
                  space (a common output for most digital cameras)
                </li>
              </ul>
              <h4>
                <img src={info} alt="info" /> What should be included in your
                Letter of Motives
              </h4>

              <a
                target="_blank"
                rel="noreferrer"
                href="https://drive.google.com/drive/folders/1CcXmHMgvP7SdWo7qnnJ4p0mcCtgkUvsQ"
              >
                Letter Example
              </a>
              <p>
                Your letter must clearly state that you have a solid intention
                to return to your country of origin upon the expiry of your
                visa.
              </p>
              <h4>
                <img src={info} alt="info" /> What should be included in your
                Employment Reference Letter
              </h4>

              <a
                target="_blank"
                rel="noreferrer"
                href="https://drive.google.com/drive/folders/1CcXmHMgvP7SdWo7qnnJ4p0mcCtgkUvsQ"
              >
                Letter Example
              </a>
              <div className="letter-example">
                <ul>
                  <li>
                    The letter should be produced by the company's letterhead.
                  </li>
                  <li>
                    The letter should clearly indicate that the employer
                    approved your trip to Canada.
                  </li>
                  <li>
                    The dates the employer approved for your time off should
                    match your flight tickets and letter of motives.
                  </li>
                </ul>
              </div>
              <div className="guideline-letter-select">
                <Form.Select aria-label="Default select example">
                  <option>Addition Details the Letter Should iInclude</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </div>
              <h4>
                <img src={info} alt="info" /> What should be in included in your
                Police Clearence
              </h4>
              <p>
                If you have a police record, you must submit it in the Documents
                section. Your police record should include all criminal offences
                from the past 10 years, including from countries that you
                visited for <span>6 months or more</span>.
              </p>
              <h4>
                <img src={info} alt="info" /> Notice of Assessment
              </h4>
              <p>
                To obtain these printout from the Canada Revenue Agency call 1
                800 959 8281
              </p>
            </div>
          )}
        </div>
      </div>
    </ApplicationLayout>
  )
}

export default Guidelines
