import { useEffect, useState } from 'react'
import { Dropdown, Table } from 'react-bootstrap'
import Loader from '../../loader/loader'
import AdminLayout from '../adminLayout/adminLayout'
import 'react-toastify/dist/ReactToastify.css'
import axios from 'axios'
import dot from '../../../assets/images/header/dot.svg'
import { serverPath, clientPath } from '../../../config/key'
import '../../users/user.css'
import Pagination from '../../pagination/index'
import '../../modals/modal.css'

const ViewApplication = () => {
  const [allUsers, setAllUSers] = useState([])
  const [loading, setLoading] = useState(false)
  const [userCount, setUserCount] = useState(0)
  const [pageCount, setPageCount] = useState()
  const [skip, setSkip] = useState(0)
  const pageLimit = 10
  const getUsers = async () => {
    setLoading(true)
    setUserCount(0)
    setAllUSers([])
    const response = await axios.get(
      `${serverPath}/users/viewApplicationForAdmin/${skip}`,
      {
        headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
      },
    )
    setLoading(false)
    const { data, status } = response || []
    if (status === 200) {
      if (data?.applicationDetail?.applicationData.length > 0) {
        data?.applicationDetail?.applicationData?.forEach((el) => {
          const { tokens, caseIds } = el
          caseIds?.map((elem) => {
            const index = tokens.findIndex((t) => elem?.caseId === t?.caseId)
            if (index !== -1) {
              elem.token = tokens[index].token
            } else {
              elem.token = ''
            }
            return elem
          })
        })
        setAllUSers(data?.applicationDetail?.applicationData)
        setUserCount(data?.applicationDetail?.counts)
      }
    }
  }
  useEffect(() => {
    getUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip])
  useEffect(() => {
    setPageCount(Math.ceil(userCount / pageLimit))
  }, [userCount])
  const handlePageClick = (selected) => {
    setSkip(selected * pageLimit)
  }
  return (
    <AdminLayout>
      <div className="users-list">
        <div className="container">
          <div className="user-list-head">
            <h3>Applications</h3>
          </div>

          <div className="users-page-content">
            {loading ? (
              <Loader />
            ) : (
              <Table responsive>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>View Application</th>
                  </tr>
                </thead>

                <tbody>
                  {allUsers &&
                    allUsers?.length > 0 &&
                    allUsers.map((el, i) => (
                      <tr>
                        <td>{el?.userId?.name}</td>
                        <td>{el?.userId?.email}</td>
                        <td>{el?.userId?.phoneNumber}</td>

                        <td>
                          <div className="user_btn_dot">
                            <Dropdown>
                              <Dropdown.Toggle id="dropdown-basic">
                                <img src={dot} alt="" />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {el?.caseIds?.map((t) => (
                                  <Dropdown.Item
                                    href={`${clientPath}/support/application?t=${t?.token}`}
                                  >
                                    {t?.productId?.name}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            )}
            {allUsers && allUsers?.length > 0 && (
              <Pagination
                pageCount={pageCount}
                handlePageClick={handlePageClick}
                setSkip={setSkip}
                skip={skip}
                paymentCount={userCount}
              />
            )}
          </div>
        </div>
      </div>
    </AdminLayout>
  )
}

export default ViewApplication
