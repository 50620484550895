import Paginate from 'react-bootstrap/Pagination'

const Pagination = ({
  pageCount,
  handlePageClick,
  setSkip,
  // paymentCount,
  // skip
}) => {
  const handleFirstPage = () => {
    setSkip(1)
  }
  const handleLastPage = () => {
    setSkip((pageCount - 1) * 5)
  }
  // const handlePrevPage=()=>{
  //     if(pageCount-1>0){
  //         setSkip((pageCount-1)-1)
  //     }
  // }
  // const handleNextPage=()=>{
  //     if (Math.ceil(paymentCount /5) > skip) {
  //         setSkip(skip + 1);
  //       }
  // }
  let item = []
  for (let i = 0; i < pageCount; i++) {
    item.push(i)
  }
  return (
    <div className="pagination">
      <Paginate>
        <Paginate.First onClick={handleFirstPage} />
        {item.map((i) => (
          <Paginate.Item onClick={() => handlePageClick(i)}>
            {i === 0 ? 1 : i + 1}
          </Paginate.Item>
        ))}
        <Paginate.Last onClick={handleLastPage} />
      </Paginate>
    </div>
  )
}

export default Pagination
